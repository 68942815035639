{
    "0": {
        "latin": "Abortiporus biennis",
        "eng": "blushing rosette",
        "hun": "rőt likacsosgomba"
    },
    "1": {
        "latin": "Achroomyces disciformis",
        "latin_syn": [
            "Platygloea tiliae"
        ],
        "hun": "hársfa-fülgomba"
    },
    "2": {
        "latin": "Adelphella babingtonii",
        "latin_syn": [
            "Pachyella babingtonii"
        ],
        "hun": "barna kocsonyás-csészegomba"
    },
    "3": {
        "latin": "Agaricus annulospecialis",
        "hun": "körösi csiperke"
    },
    "4": {
        "latin": "Agaricus arvensis",
        "eng": "horse mushroom",
        "hun": "erdőszéli csiperke"
    },
    "5": {
        "latin": "Agaricus augustus",
        "eng": "the prince",
        "latin_syn": [
            "Agaricus perrarus"
        ],
        "hun": "óriás csiperke",
        "hun_syn": [
            "okkerpikkelyes csiperke"
        ]
    },
    "6": {
        "latin": "Agaricus babosiae",
        "hun": "Babos-csiperke"
    },
    "7": {
        "latin": "Agaricus benesii",
        "eng": "mull mushroom",
        "latin_syn": [
            "Agaricus squamulifer"
        ],
        "hun": "ligeti csiperke"
    },
    "8": {
        "latin": "Agaricus bernardii",
        "eng": "salty mushroom",
        "latin_syn": [
            "Agaricus maleolens"
        ],
        "hun": "sziki csiperke",
        "hun_syn": [
            "halszagú csiperke"
        ]
    },
    "9": {
        "latin": "Agaricus bisporus",
        "eng": "cultivated mushroom",
        "latin_syn": [
            "Agaricus hortensis"
        ],
        "hun": "kétspórás csiperke",
        "hun_syn": [
            "termesztett csiperke"
        ]
    },
    "10": {
        "latin": "Agaricus bitorquis",
        "eng": "pavement mushroom",
        "latin_syn": [
            "Agaricus edulis"
        ],
        "hun": "ízletes csiperke"
    },
    "11": {
        "latin": "Agaricus bohusii",
        "eng": "Medusa mushroom",
        "latin_syn": [
            "Agaricus elvensis"
        ],
        "hun": "csoportos csiperke"
    },
    "12": {
        "latin": "Agaricus bresadolanus",
        "eng": "parkland mushroom",
        "latin_syn": [
            "Agaricus romagnesii"
        ],
        "hun": "akáccsiperke",
        "hun_syn": [
            "akác-csiperke",
            "gyökeres csiperke"
        ]
    },
    "13": {
        "latin": "Agaricus brunneolus",
        "latin_syn": [
            "Agaricus porphyrizon"
        ],
        "hun": "lilásvörös csiperke"
    },
    "14": {
        "latin": "Agaricus campestris",
        "eng": "field mushroom",
        "hun": "mezei csiperke",
        "hun_syn": [
            "kerti csiperke"
        ]
    },
    "15": {
        "latin": "Agaricus cappellii",
        "hun": "bolyhos csiperke"
    },
    "16": {
        "latin": "Agaricus comtulus",
        "eng": "mini mushroom",
        "hun": "kis csiperke"
    },
    "17": {
        "latin": "Agaricus crocodilinus",
        "latin_syn": [
            "Agaricus macrosporus",
            "Agaricus urinascens"
        ],
        "hun": "nagyspórás csiperke",
        "hun-syn": [
            "rojtos csiperke"
        ]
    },
    "18": {
        "latin": "Agaricus cupreobrunneus",
        "eng": "copper mushrooom",
        "hun": "rézbarna csiperke",
        "hun_syn": [
            "bíborbarna csiperke"
        ]
    },
    "19": {
        "latin": "Agaricus dulcidulus",
        "eng": "rosy wood mushroom",
        "latin_syn": [
            "Agaricus semotus s. auct."
        ],
        "hun": "apró csiperke",
        "hun-syn": [
            "édeskés csiperke",
            "törpe csiperke"
        ]
    },
    "20": {
        "latin": "Agaricus essettei",
        "hun": "gumós csiperke"
    },
    "21": {
        "latin": "Agaricus fissuratus",
        "latin_syn": [
            "Agaricus macrosporoides"
        ],
        "hun": "repedezettkalapú csiperke"
    },
    "22": {
        "latin": "Agaricus gennadii",
        "eng": "conifer mushroom",
        "hun": "bocskoros csiperke"
    },
    "23": {
        "latin": "Agaricus impudicus",
        "eng": "tufted wood mushroom",
        "hun": "büdös csiperke",
        "hun_syn": [
            "nehézszagú csiperke"
        ]
    },
    "24": {
        "latin": "Agaricus iodosmus",
        "latin_syn": [
            "Agaricus pilatianus"
        ],
        "hun": "fenolszagú csiperke"
    },
    "25": {
        "latin": "Agaricus langei",
        "eng": "scaly wood mushroom",
        "hun": "kárminhúsú csiperke",
        "hun_syn": [
            "hegyi csiperke"
        ]
    },
    "26": {
        "latin": "Agaricus lanipes",
        "eng": "the princess",
        "hun": "szélespikkelyű csiperke",
        "hun-syn": [
            "pelyhestönkű csiperke"
        ]
    },
    "27": {
        "latin": "Agaricus litoralis",
        "eng": "coastal mushoom",
        "latin_syn": [
            "Agaricus maskae",
            "Agaricusspissicaulis"
        ],
        "hun": "szekszárdi csiperke",
        "hun-syn": [
            "nyomottönkű csiperke"
        ]
    },
    "28": {
        "latin": "Agaricus macrocarpus",
        "eng": "mammoth mushroom",
        "hun": "termetes csiperke"
    },
    "29": {
        "latin": "Agaricus moelleri",
        "eng": "inky mushroom",
        "latin_syn": [
            "Agaricus meleagris",
            "Agaricus praeclaresquamosus"
        ],
        "hun": "tintaszagú csiperke"
    },
    "30": {
        "latin": "Agaricus moellerianus",
        "eng": "meadow mushroom",
        "latin_syn": [
            "Agaricus floccipes"
        ],
        "hun": "pelyhestönkű csiperke"
    },
    "31": {
        "latin": "Agaricus pampeanus",
        "eng": "pored field mushroom",
        "hun": "pusztai csiperke"
    },
    "32": {
        "latin": "Agaricus pequinii",
        "hun": "nagybocskoros csiperke",
        "hun_syn": [
            "bocskoros csiperke"
        ]
    },
    "33": {
        "latin": "Agaricus phaeolepidotus",
        "eng": "dusky scaled mushroom",
        "hun": "barnapikkelyű csiperke"
    },
    "34": {
        "latin": "Agaricus porphyrocephalus",
        "hun": "bíborbarna csiperke"
    },
    "35": {
        "latin": "Agaricus pseudopratensis",
        "hun": "homoki csiperke"
    },
    "36": {
        "latin": "Agaricus subfloccosus",
        "eng": "petticoat mushroom",
        "hun": "pelyhes csiperke"
    },
    "37": {
        "latin": "Agaricus subperonatus",
        "eng": "tapered mushroom",
        "latin_syn": [
            "Agaricus vaporarius"
        ],
        "hun": "komposztcsiperke",
        "hun_syn": [
            "öves csiperke"
        ]
    },
    "38": {
        "latin": "Agaricus sylvaticus",
        "latin_syn": [
            "Agaricus fuscofibrillosus",
            "Agaricus haemorrhoidarius"
        ],
        "hun": "erdei csiperke",
        "hun-syn": [
            "fenyves csiperke",
            "sötétszálas csiperke"
        ]
    },
    "39": {
        "latin": "Agaricus sylvicola",
        "latin_syn": [
            "Agaricus flavescens"
        ],
        "hun": "karcsú csiperke"
    },
    "40": {
        "latin": "Agaricus xanthodermus",
        "eng": "yellow stainer",
        "latin_syn": [
            "Agaricus pseudocretaceus"
        ],
        "hun": "karbolszagú csiperke",
        "hun-syn": [
            "okkeresedő csiperke",
            "sárguló csiperke"
        ]
    },
    "41": {
        "latin": "Agrocybe arvalis",
        "eng": "digitate fieldcap",
        "hun": "gumós rétgomba"
    },
    "42": {
        "latin": "Agrocybe dura",
        "hun": "kerti rétgomba"
    },
    "43": {
        "latin": "Agrocybe firma",
        "hun": "bársonyos rétgomba"
    },
    "44": {
        "latin": "Agrocybe pediades",
        "eng": "common fieldcap",
        "latin_syn": [
            "Agrocybe semiorbicularis",
            "Agrocybe splendida",
            "Naucoria semiorbicularis"
        ],
        "hun": "sárga rétgomba",
        "hun-syn": [
            "fénylő rétgomba",
            "nyári szemétgomba",
            "réti szemétgomba",
            "sárga szemétgomba"
        ]
    },
    "45": {
        "latin": "Agrocybe praecox",
        "eng": "spring fieldcap",
        "hun": "tavaszi rétgomba",
        "hun_syn": [
            "tavaszi tőkegomba"
        ]
    },
    "46": {
        "latin": "Agrocybe putaminum",
        "eng": "mulch fieldcap",
        "hun": "fakó rétgomba"
    },
    "47": {
        "latin": "Agrocybe vervacti",
        "eng": "fallow fieldcap",
        "hun": "üregestönkű rétgomba"
    },
    "48": {
        "latin": "Albatrellopsis confluens",
        "latin_syn": [
            "Albatrellus confluens",
            "Albatrellus ovinus",
            "Scutiger ovinus"
        ],
        "hun": "sárga zsemlegomba",
        "hun-syn": [
            "fakó zsemlegomba"
        ]
    },
    "49": {
        "latin": "Albatrellus subrubescens",
        "latin_syn": [
            "Albatrellus< similis"
        ],
        "hun": "piruló zsemlegomba"
    },
    "50": {
        "latin": "Aleuria aurantia",
        "eng": "orange peel fungus",
        "latin_syn": [
            "Peziza aurantia"
        ],
        "hun": "narancsszínű csészegomba",
        "hun_syn": [
            "narancsvörös csészegomba"
        ]
    },
    "51": {
        "latin": "Aleurocystidiellum disciforme",
        "latin_syn": [
            "Aleurodiscus amorphus",
            "Aleurodiscus disciformis"
        ],
        "hun": "fehéres koronggombácska",
        "hun-syn": [
            "változó koronggombácska"
        ]
    },
    "52": {
        "latin": "Aleurodiscus aurantius",
        "eng": "briar disco",
        "hun": "vörös koronggombácska"
    },
    "53": {
        "latin": "Aleurodiscus croceus",
        "latin_syn": [
            "Corticium croceum"
        ],
        "hun": "sáfrányszínű koronggombácska",
        "hun_syn": [
            "vargomba"
        ]
    },
    "54": {
        "latin": "Alpova diplophloeus",
        "latin_syn": [
            "Rhizopogon diplophloeus"
        ],
        "hun": "kettőskérgű égerpöfeteg"
    },
    "55": {
        "latin": "Amanita argentea",
        "eng": "silvery amanita",
        "hun": "ezüstszürke selyemgomba",
        "hun_syn": [
            "ezüstfehér selyemgalóca"
        ]
    },
    "56": {
        "latin": "Amanita baccata",
        "hun": "gyöngyös galóca"
    },
    "57": {
        "latin": "Amanita battarrae",
        "eng": "banded amanita",
        "latin_syn": [
            "Amanita fuscoolivacea"
        ],
        "hun": "sárgásbarna selyemgomba",
        "hun_syn": [
            "barnásselyem  galóca"
        ]
    },
    "58": {
        "latin": "Amanita beckeri",
        "hun": "mogyoróbarna galóca"
    },
    "59": {
        "latin": "Amanita caesarea",
        "eng": "Caesar's amanita",
        "hun": "császárgalóca",
        "hun_syn": [
            "császárgomba"
        ]
    },
    "60": {
        "latin": "Amanita ceciliae",
        "eng": "snakeskin grisette",
        "latin_syn": [
            "Amanita inaurata, Amanita strangulata"
        ],
        "hun": "óriás selyemgomba"
    },
    "61": {
        "latin": "Amanita citrina",
        "eng": "false deathcap",
        "hun": "citromgalóca"
    },
    "62": {
        "latin": "Amanita crocea",
        "eng": "orange grisette",
        "hun": "narancsszínű selyemgomba",
        "hun_syn": [
            "narancssárga selyemgomba",
            "narancsselymes galóca"
        ]
    },
    "63": {
        "latin": "Amanita echinocephala",
        "eng": "solitary amanita",
        "latin_syn": [
            "Amanita solitaria"
        ],
        "hun": "tüskés galóca"
    },
    "64": {
        "latin": "Amanita eliae",
        "eng": "pale amanita",
        "hun": "fésűsperemű galóca"
    },
    "65": {
        "latin": "Amanita excelsa",
        "eng": "var. spissa grey spotted amanita",
        "latin_syn": [
            "Amanita spissa"
        ],
        "hun": "szürke galóca"
    },
    "66": {
        "latin": "Amanita franchetii",
        "eng": "gilded amanita",
        "hun": "érdes galóca"
    },
    "67": {
        "latin": "Amanita friabilis",
        "eng": "fragile amanita",
        "hun": "éger-galóca",
        "hun_syn": [
            "égergalóca"
        ]
    },
    "68": {
        "latin": "Amanita fulva",
        "eng": "tawny grisette",
        "hun": "rőt selyemgomba"
    },
    "69": {
        "latin": "Amanita gemmata",
        "hun": "sárga galóca"
    },
    "70": {
        "latin": "Amanita gilbertii",
        "hun": "fenyő-galóca"
    },
    "71": {
        "latin": "Amanita lepiotoides",
        "hun": "húsbarna galóca"
    },
    "72": {
        "latin": "Amanita lividopallescens",
        "eng": "pale grisette",
        "hun": "fakó selyemgomba",
        "hun_syn": [
            "fakóselymes galóca"
        ]
    },
    "73": {
        "latin": "Amanita magnivolvata",
        "hun": "nagybocskorú selyemgomba",
        "hun_syn": [
            "nagybocskorú galóca"
        ]
    },
    "74": {
        "latin": "Amanita mairei",
        "hun": "ezüstfehér selyemgomba",
        "hun_syn": [
            "ezüstselymes galóca"
        ]
    },
    "75": {
        "latin": "Amanita muscaria",
        "eng": "fly agaric",
        "hun": "légyölő galóca"
    },
    "76": {
        "latin": "Amanita ovoidea",
        "eng": "bearded amanita",
        "hun": "nagy galóca"
    },
    "77": {
        "latin": "Amanita pachyvolvata",
        "eng": "cloaked amanita",
        "hun": "vastagburkú selyemgomba",
        "hun_syn": [
            "nagybocskoros selyemgomba",
            "vastagburkú galóca"
        ]
    },
    "78": {
        "latin": "Amanita pantherina",
        "eng": "panthercap",
        "hun": "párducgalóca"
    },
    "79": {
        "latin": "Amanita phalloides",
        "eng": "deathcap",
        "hun": "gyilkos galóca"
    },
    "80": {
        "latin": "Amanita porphyria",
        "eng": "grey veiled amanita",
        "hun": "bíbor galóca"
    },
    "81": {
        "latin": "Amanita regalis",
        "hun": "barna galóca",
        "hun_syn": [
            "királygalóca"
        ]
    },
    "82": {
        "latin": "Amanita rubescens",
        "eng": "blusher",
        "hun": "piruló galóca"
    },
    "83": {
        "latin": "Amanita strobiliformis",
        "eng": "warted amanita",
        "hun": "cafrangos galóca"
    },
    "84": {
        "latin": "Amanita submembranacea",
        "eng": "olive amanita",
        "hun": "szürkebocskorú selyemgomba",
        "hun_syn": [
            "pettyes selyemgomba"
        ]
    },
    "85": {
        "latin": "Amanita vaginata",
        "eng": "grisette",
        "hun": "szürke selyemgomba",
        "hun_syn": [
            "selymes galóca",
            "szürkeselymes galóca"
        ]
    },
    "86": {
        "latin": "Amanita verna",
        "eng": "spring amanita",
        "hun": "fehér galóca"
    },
    "87": {
        "latin": "Amanita virosa",
        "eng": "destroying angel",
        "hun": "hegyeskalapú galóca"
    },
    "88": {
        "latin": "Amanita vittadinii",
        "eng": "barefoot amanita",
        "hun": "őzlábgalóca"
    },
    "89": {
        "latin": "Amphinema byssoides",
        "eng": "cratered duster",
        "latin_syn": [
            "Peniophora byssoidea"
        ],
        "hun": "molyhos terülőgomba"
    },
    "90": {
        "latin": "Ampulloclitocybe clavipes",
        "eng": "club foot",
        "latin_syn": [
            "Clitocybe clavipes"
        ],
        "hun": "duzzadttönkű tölcsérgomba"
    },
    "91": {
        "latin": "Amylocystis lapponica",
        "hun": "vörös likacsgomba"
    },
    "92": {
        "latin": "Annulohypoxylon multiforme",
        "latin_syn": [
            "Hypoxylon multiforme"
        ],
        "hun": "változékony ripacsgomba"
    },
    "93": {
        "latin": "Anomoporia bombycina",
        "hun": "pókhálós kéreggomba"
    },
    "94": {
        "latin": "Anthracobia maurilabra",
        "hun": "sárga tűznyomcsészegomba"
    },
    "95": {
        "latin": "Antrodia albida",
        "eng": "whitish porecrust",
        "hun": "fehéres kéreggomba",
        "hun_syn": [
            "fehéres lemezestapló"
        ]
    },
    "96": {
        "latin": "Antrodia crassa",
        "latin_syn": [
            "Amyloporia crassa"
        ],
        "hun": "többrétegű kéreggomba"
    },
    "97": {
        "latin": "Antrodia Coriolellus heteromorphus",
        "hun": "okkerszürkés egyrétűtapló"
    },
    "98": {
        "latin": "Antrodia Coriolellus ramentaceus",
        "hun": "fordított egyrétűtapló"
    },
    "99": {
        "latin": "Antrodia serialis",
        "eng": "serried porecrust",
        "latin_syn": [
            "Coriolellus serilis"
        ],
        "hun": "szalagtapló"
    },
    "100": {
        "latin": "Antrodia sinuosa",
        "eng": "sinuous porecrust",
        "latin_syn": [
            "Poria vaporaria"
        ],
        "hun": "házi kéreggomba"
    },
    "101": {
        "latin": "Antrodiella pallescens",
        "latin_syn": [
            "Antrodiella semisupina s. auct."
        ],
        "hun": " porcos kéreggombácska",
        "hun_syn": [
            "fakósárga egyrétűtapló "
        ]
    },
    "102": {
        "latin": "Antrodiella serpula",
        "latin_syn": [
            "Antrodiella hoehnelii"
        ],
        "hun": "sárgaperemű kéreggombácska",
        "hun_syn": [
            "apró egyrétűtapló"
        ]
    },
    "103": {
        "latin": "Aphanobasidium pseudotsugae",
        "hun": "apró kéreggombácska"
    },
    "104": {
        "latin": "Aphelaria tuberosa",
        "latin_syn": [
            "Tremellodendropsis tuberosa"
        ],
        "hun": "gumós álkorallgomba"
    },
    "105": {
        "latin": "Aphroditeola olida",
        "latin_syn": [
            "Hygrophoropsis olida"
        ],
        "hun": "rőt tölcsérgomba"
    },
    "106": {
        "latin": "Arcangeliella laevis",
        "latin_syn": [
            "Hydnangium laeve"
        ],
        "hun": "kopasz álpöfeteg",
        "hun_syn": [
            "kopasz pénzecskepöfeteg"
        ]
    },
    "107": {
        "latin": "Armillaria cepistipes",
        "eng": "mullet honey fungus",
        "hun": "hagymatönkű tuskógomba"
    },
    "108": {
        "latin": "Armillaria lutea",
        "latin_syn": [
            "Armillaria bulbosa",
            "Armillaria gallica"
        ],
        "hun": "gumós tuskógomba",
        "hun_syn": [
            "sárgabolyhos tuskógomba",
            "sárgapelyhes tuskógomba"
        ]
    },
    "109": {
        "latin": "Armillaria mellea",
        "eng": "honey tuft",
        "hun": "gyűrűs tuskógomba",
        "hun_syn": [
            "csuprosgomba",
            "mézszínű tölcsérgomba"
        ]
    },
    "110": {
        "latin": "Armillaria ostoyae",
        "eng": "dark honey fungus",
        "latin_syn": [
            "Armillaria obscura  Armillaria polymyces"
        ],
        "hun": "sötétpikkelyes tuskógomba"
    },
    "111": {
        "latin": "Arrhenia acerosa",
        "eng": "moss oysterling",
        "latin_syn": [
            "Omphalina acerosa"
        ],
        "hun": "fenyves mohagomba",
        "hun_syn": [
            "fenyves laskagomba"
        ]
    },
    "112": {
        "latin": "Arrhenia epichysium",
        "eng": "pitcher navel",
        "latin_syn": [
            "Omphalina epichysium"
        ],
        "hun": "fánlakó mohagomba"
    },
    "113": {
        "latin": "Arrhenia griseopallida",
        "eng": "pelargonium navel",
        "latin_syn": [
            "Leptoglossum griseopallidum",
            "Omphalina griseopallida"
        ],
        "hun": "szürke mohagomba"
    },
    "114": {
        "latin": "Arrhenia lobata",
        "eng": "lobed oysterling",
        "hun": "barna mohagomba"
    },
    "115": {
        "latin": "Arrhenia obscurata",
        "eng": "dark navel",
        "hun": "sötét mohagomba"
    },
    "116": {
        "latin": "Arrhenia retiruga",
        "eng": "small moss oysterling",
        "hun": "recés mohagomba"
    },
    "117": {
        "latin": "Arrhenia rickenii",
        "eng": "moss navel",
        "latin_syn": [
            "Omphalina cupulata"
        ],
        "hun": "bordás mohagomba"
    },
    "118": {
        "latin": "Arrhenia rustica",
        "eng": "rural navel",
        "latin_syn": [
            "Omphalina rustica"
        ],
        "hun": "parlagi mohagomba"
    },
    "119": {
        "latin": "Arrhenia spathulata",
        "eng": "spatulate oysterling",
        "latin_syn": [
            "Leptoglossum muscigenum"
        ],
        "hun": "karéjos mohagomba",
        "hun_syn": [
            "barna mohagomba"
        ]
    },
    "120": {
        "latin": "Artomyces pyxidatus",
        "latin_syn": [
            "Clavicorona pyxidata"
        ],
        "hun": "csészés álkorallgomba",
        "hun_syn": [
            "serleges korallgomba"
        ]
    },
    "121": {
        "latin": "Ascocoryne cylichnium",
        "hun": "bíbor porcoscsészegomba",
        "hun_syn": [
            "őszi kocsonyagomba"
        ]
    },
    "122": {
        "latin": "Ascocoryne sarcoides",
        "eng": "purple jellydisc",
        "hun": "hússzínű porcoscsészegomba",
        "hun_syn": [
            "lila kocsonyagomba"
        ]
    },
    "123": {
        "latin": "Ascotremella faginea",
        "eng": "striated jellydisc",
        "hun": "bükk-álrezgőgomba",
        "hun_syn": [
            "bükkös tömlősrezgőgomba"
        ]
    },
    "124": {
        "latin": "Aspropaxillus giganteus",
        "latin_syn": [
            "Leucopaxillus giganteus"
        ],
        "hun": "hatalmas tölcsérgomba",
        "hun_syn": [
            "óriás álpereszke, óriás cölöppereszke"
        ]
    },
    "125": {
        "latin": "Aspropaxillus lepistoides",
        "latin_syn": [
            "Leucopaxillus lepistoides "
        ],
        "hun": "tejpereszke",
        "hun_syn": [
            "tejfehér cölöppereszke",
            "tejgomba"
        ]
    },
    "126": {
        "latin": "Asterophora lycoperdoides",
        "eng": "powdery piggyback",
        "latin_syn": [
            "Nyctalis asterophora"
        ],
        "hun": "porzó élősdigomba"
    },
    "127": {
        "latin": "Asterophora parasitica",
        "eng": "silky piggyback",
        "latin_syn": [
            "Nyctalis parasitica"
        ],
        "hun": "fátyolos élősdigomba"
    },
    "128": {
        "latin": "Astraeus hygrometricus",
        "eng": "barometer earthstar",
        "hun": "repedéses csillaggomba"
    },
    "129": {
        "latin": "Atheniella adonis",
        "latin_syn": [
            "Mycena adonis"
        ],
        "hun": "piros kígyógomba"
    },
    "130": {
        "latin": "Atheniella flavoalba",
        "latin_syn": [
            "Mycena flavoalba"
        ],
        "hun": "sárgásfehér kígyógomba"
    },
    "131": {
        "latin": "Atractosporocybe inornata",
        "eng": "plain funnel",
        "latin_syn": [
            "Clitocybe inornata"
        ],
        "hun": "szürkéslemezű tölcsérgomba"
    },
    "132": {
        "latin": "Aurantiporus croceus",
        "latin_syn": [
            "Hapalopilus croceus",
            "Phaeolus croceus"
        ],
        "hun": "sáfrányszínű likacsgomba"
    },
    "133": {
        "latin": "Aurantiporus fissilis",
        "eng": "greasy bracket",
        "latin_syn": [
            "Tyromyces fissilis"
        ],
        "hun": "színváltó likacsosgomba"
    },
    "134": {
        "latin": "Aureoboletus gentilis",
        "eng": "gilded bolete",
        "latin_syn": [
            "Pulveroboletus gentilis"
        ],
        "hun": "aranybélésű tinóru"
    },
    "135": {
        "latin": "Aureoboletus moravicus",
        "latin_syn": [
            "Xerocomus moravicus"
        ],
        "hun": "morva tinóru",
        "hun_syn": [
            "morava nemezestinóru"
        ]
    },
    "136": {
        "latin": "Auricularia auricula-judae",
        "eng": "jelly ear",
        "latin_syn": [
            "Hirneola auricula-judae"
        ],
        "hun": "júdásfülgomba",
        "hun_syn": [
            "júdásfüle gomba",
            "júdásfüle-gomba"
        ]
    },
    "137": {
        "latin": "Auricularia mesenterica",
        "eng": "tripe fungus",
        "hun": "szalagos fülgomba"
    },
    "138": {
        "latin": "Auriscalpium vulgare",
        "eng": "earpick fungus",
        "hun": "tobozgereben",
        "hun_syn": [
            "gyakori tobozgereben"
        ]
    },
    "139": {
        "latin": "Baeospora myosura",
        "eng": "conifercone cap",
        "hun": "toboz-fenyőfülőke",
        "hun_syn": [
            "toboz fenyőfülőke"
        ]
    },
    "140": {
        "latin": "Balsamia fragiformis",
        "hun": "göbös balzámia"
    },
    "141": {
        "latin": "Balsamia platyspora",
        "hun": "szemölcsös balzámia"
    },
    "142": {
        "latin": "Balsamia polysperma",
        "hun": "sokmagvú balzámia"
    },
    "143": {
        "latin": "Balsamia vulgaris ",
        "hun": "közönséges balzámia",
        "hun_syn": [
            "büdös szarvasgomba",
            "közönséges balzsamtrifla"
        ]
    },
    "144": {
        "latin": "Bankera fuligineoalba",
        "eng": "drab tooth",
        "latin_syn": [
            "Hydnum fuligineoalbum"
        ],
        "hun": "színváltó gereben",
        "hun_syn": [
            "színváltó fenyőgereben"
        ]
    },
    "145": {
        "latin": "Bankera violascens",
        "eng": "spruce tooth",
        "latin-syn": [
            "Bankera cinerea",
            "Hydnum violascens"
        ],
        "hun": "lilás gereben"
    },
    "146": {
        "latin": "Basidioradulum radula",
        "eng": "toothed crust",
        "latin_syn": [
            "Hyphoderma radula"
        ],
        "hun": "kerek ráspolygomba"
    },
    "147": {
        "latin": "Battarrea phalloides",
        "eng": "sandy stiltball",
        "hun": "álszömörcsög"
    },
    "148": {
        "latin": "Biscogniauxia marginata",
        "hun": "szegett tekegomba"
    },
    "149": {
        "latin": "Biscogniauxia nummularia",
        "eng": "beech tarcrust",
        "latin_syn": [
            "Hypoxylon nummularium"
        ],
        "hun": "pénzecske-ripacsgomba"
    },
    "150": {
        "latin": "Bisporella citrina",
        "eng": "lemon disco",
        "hun": "citromsárga csészegombácska"
    },
    "151": {
        "latin": "Bisporella pallescens",
        "hun": "sápadt csészegombácska"
    },
    "152": {
        "latin": "Bjerkandera adusta",
        "eng": "smoky bracket",
        "hun": "szenes likacsosgomba",
        "hun_syn": [
            "szenes likacsgomba"
        ]
    },
    "153": {
        "latin": "Bjerkandera fumosa",
        "eng": "big smoky bracket",
        "latin_syn": [
            "Polyporus imberbis",
            "Polyporus fumosus"
        ],
        "hun": "krémszínű likacsosgomba",
        "hun_syn": [
            "szürkéssárga likacsgomba"
        ]
    },
    "154": {
        "latin": "Bogbodia uda",
        "latin_syn": [
            "Hypholoma udum"
        ],
        "hun": "cingár kénvirággomba"
    },
    "155": {
        "latin": "Bolbitius coprophilus",
        "eng": "dung fieldcap",
        "hun": "rózsás kérészgomba",
        "hun_syn": [
            "trágya kérészgomba"
        ]
    },
    "156": {
        "latin": "Bolbitius lacteus",
        "eng": "creamy fieldcap",
        "hun": "fehér kérészgomba"
    },
    "157": {
        "latin": "Bolbitius reticulatus",
        "eng": "netted fieldcap",
        "latin_syn": [
            "Bolbitius aleuriatus"
        ],
        "hun": "szürkés kérészgomba",
        "hun_syn": [
            "eres kérészgomba"
        ]
    },
    "158": {
        "latin": "Bolbitius titubans",
        "eng": "yellow fieldcap",
        "latin_syn": [
            "Bolbitius vitellinus p.p."
        ],
        "hun": "sárga kérészgomba"
    },
    "159": {
        "latin": "Bolbitius variicolor",
        "latin_syn": [
            "Bolbitius vitellinus p.p."
        ],
        "hun": "áltozékony kérészgomba"
    },
    "160": {
        "latin": "Boletopsis leucomelaena",
        "hun": "vörösödő zsemlegomba",
        "hun_syn": [
            "sötét taplótinóru"
        ]
    },
    "161": {
        "latin": "Boletus aereus",
        "eng": "bronze bolete",
        "hun": "bronzos vargánya",
        "hun_syn": [
            "bronzos tinóru"
        ]
    },
    "162": {
        "latin": "Boletus edulis",
        "eng": "penny bun / cep",
        "hun": "ízletes vargánya",
        "hun_syn": [
            "ízletes tinóru",
            "jóféle vargánya",
            "úrigomba"
        ]
    },
    "163": {
        "latin": "Boletus pinophilus",
        "eng": "pine bolete",
        "hun": "vörösbarna vargánya"
    },
    "164": {
        "latin": "Boletus reticulatus",
        "eng": "summer bolete",
        "latin_syn": [
            "Boletus aestivalis"
        ],
        "hun": "nyári vargánya",
        "hun_syn": [
            "nyári tinóru"
        ]
    },
    "165": {
        "latin": "Bondarzewia mesenterica",
        "latin_syn": [
            "Bondarzewia montana"
        ],
        "hun": "hegyi likacsosgomba"
    },
    "166": {
        "latin": "Bonomyces sinopicus",
        "latin_syn": [
            "Clitocybe sinopica"
        ],
        "hun": "rókaszínű tölcsérgomba",
        "hun_syn": [
            "pirosas tölcsérgomba"
        ]
    },
    "167": {
        "latin": "Botryobasidium subcoronatum",
        "eng": "coronet crust",
        "latin_syn": [
            "Corticium subcoronatum"
        ],
        "hun": "krémszínű vargomba"
    },
    "168": {
        "latin": "Botryohypochnus isabellinus",
        "hun": "agyagszínű nemezgomba"
    },
    "169": {
        "latin": "Bovista aestivalis",
        "eng": "summer puffball",
        "latin_syn": [
            "Bovista polymorpha",
            "Lycoperdon pusilliforme"
        ],
        "hun": "ligeti pöfeteg",
        "hun_syn": [
            "tömött sötétedőpöfeteg"
        ]
    },
    "170": {
        "latin": "Bovista dermoxantha",
        "eng": "dwarf puffball",
        "hun": "sárgás pöfeteg"
    },
    "171": {
        "latin": "Bovista furfuracea",
        "latin_syn": [
            "Bovista pusilla s. auct."
        ],
        "hun": "apró pöfeteg"
    },
    "172": {
        "latin": "Bovista nigrescens",
        "eng": "brown puffball",
        "hun": "feketedő pöfeteg",
        "hun_syn": [
            "fekete sötétedőpöfeteg"
        ]
    },
    "173": {
        "latin": "Bovista paludosa ",
        "hun": "lápi pöfeteg"
    },
    "174": {
        "latin": "Bovista plumbea",
        "eng": "grey puffball",
        "hun": "szürke pöfeteg",
        "hun_syn": [
            "szürke sötétedőpöfeteg"
        ]
    },
    "175": {
        "latin": "Bovista pusilla",
        "eng": "least puffball",
        "hun": "kicsiny pöfeteg",
        "hun_syn": [
            "tömött pöfeteg"
        ]
    },
    "176": {
        "latin": "Bovista tomentosa",
        "hun": "molyhos pöfeteg",
        "hun_syn": [
            "molyhos sötétedőpöfeteg"
        ]
    },
    "177": {
        "latin": "Bryoglossum rehmii",
        "latin_syn": [
            "Mitrula rehmii"
        ],
        "hun": "fejes sapkásgomba"
    },
    "178": {
        "latin": "Buglossoporus quercinus",
        "eng": "oak polypore",
        "latin_syn": [
            "Buglossoporus pulvinus, Piptoporus quercinus"
        ],
        "hun": "tölgyfa-kérgestapló"
    },
    "179": {
        "latin": "Bulgaria inquinans ",
        "hun": "kocsonyás koronggomba"
    },
    "180": {
        "latin": "Butyriboletus appendiculatus",
        "latin_syn": [
            "Boletus appendiculatus"
        ],
        "hun": "sárgahúsú tinóru",
        "hun_syn": [
            "sárgahúsú vargánya"
        ]
    },
    "181": {
        "latin": "Butyriboletus fechtneri",
        "latin_syn": [
            "Boletus fechtneri"
        ],
        "hun": "fakó tinóru",
        "hun_syn": [
            "fakótinóru"
        ]
    },
    "182": {
        "latin": "Butyriboletus fuscoroseus",
        "latin_syn": [
            "Boletus pseudoregius"
        ],
        "hun": "rózsásbarna tinóru",
        "hun_syn": [
            "meggyvörös tinóru",
            "pompás tinóru"
        ]
    },
    "183": {
        "latin": "Butyriboletus regius",
        "latin_syn": [
            "Boletus regius"
        ],
        "hun": "királytinóru",
        "hun_syn": [
            "királyvargánya"
        ]
    },
    "184": {
        "latin": "Byssomerulius corium",
        "eng": "netted crust",
        "latin-syn": [
            "Meruliopsis corium",
            "Merulius papyrinus"
        ],
        "hun": "bőrszerű redősgomba"
    },
    "185": {
        "latin": "Byssonectria fusispora",
        "hun": "narancsszínű gömbös-csészegomba"
    },
    "186": {
        "latin": "Callistosporium luteo-olivaceum",
        "eng": "olive starcap",
        "hun": "sárgalemezű fülőke"
    },
    "187": {
        "latin": "Caloboletus calopus",
        "latin_syn": [
            "Boletus calopus"
        ],
        "hun": "farkastinóru"
    },
    "188": {
        "latin": "Caloboletus radicans",
        "latin_syn": [
            "Boletus radicans"
        ],
        "hun": "kesernyés tinóru",
        "hun_syn": [
            "gyökeres tinóru"
        ]
    },
    "189": {
        "latin": "Calocera cornea",
        "eng": "small stagshorn",
        "hun": "árszerű enyveskorallgomba"
    },
    "190": {
        "latin": "Calocera furcata",
        "eng": "forked stagshorn",
        "hun": "villás enyveskorallgomba"
    },
    "191": {
        "latin": "Calocera gracillima",
        "hun": "apró enyveskorallgomba"
    },
    "192": {
        "latin": "Calocera palmata",
        "hun": "leveles enyveskorallgomba"
    },
    "193": {
        "latin": "Calocera striata",
        "hun": "barázdás enyveskorallgomba"
    },
    "194": {
        "latin": "Calocera stricta",
        "hun": "magányos enyveskorallgomba"
    },
    "195": {
        "latin": "Calocera tuberosa",
        "hun": "gumós enyveskorallgomba"
    },
    "196": {
        "latin": "Calocera viscosa",
        "eng": "yellow stagshorn",
        "hun": "narancsszínű enyveskorallgomba",
        "hun_syn": [
            "narancsszínű álkorallgomba"
        ]
    },
    "197": {
        "latin": "Calocybe carnea",
        "latin_syn": [
            "Rugosomyces carneus"
        ],
        "hun": "hússzínű pereszke"
    },
    "198": {
        "latin": "Calocybe chrysenteron",
        "latin_syn": [
            "Rugosomyces chrysenteron"
        ],
        "hun": "sárga pereszke",
        "hun_syn": [
            "sárga lisztespereszke"
        ]
    },
    "199": {
        "latin": "Calocybe fallax",
        "latin-syn": [
            "Calocybe naucoria",
            "Rugosomyces fallax"
        ],
        "hun": "aranysárga pereszke"
    },
    "200": {
        "latin": "Calocybe gambosa",
        "eng": "St. George's mushroom",
        "hun": "májusi pereszke",
        "hun_syn": [
            "szentgyörgygomba",
            "szentgyörgypereszke"
        ]
    },
    "201": {
        "latin": "Calocybe gangraenosa",
        "eng": "smoky domecap",
        "latin_syn": [
            "Lyophyllum leucophaeatum"
        ],
        "hun": "feketedő álpereszke",
        "hun_syn": [
            "halszagú álpereszke"
        ]
    },
    "202": {
        "latin": "Calocybe ionides",
        "latin_syn": [
            "Rugosomyces ionides"
        ],
        "hun": "ibolyás pereszke"
    },
    "203": {
        "latin": "Calocybe obscurissima",
        "latin_syn": [
            "Rugosomyces obscurissimus"
        ],
        "hun": "sötét pereszke",
        "hun_syn": [
            "sötét lisztespereszke"
        ]
    },
    "204": {
        "latin": "Calocybe persicolor",
        "latin_syn": [
            "Rugosomyces persicolor"
        ],
        "hun": "rózsás pereszke"
    },
    "205": {
        "latin": "Caloscypha fulgens",
        "eng": "golden cup",
        "hun": "narancsszínű foltoscsészegomba"
    },
    "206": {
        "latin": "Calvatia candida",
        "hun": "foltos pöfeteg",
        "hun_syn": [
            "foltos szétesőpöfeteg"
        ]
    },
    "207": {
        "latin": "Calvatia lilacina",
        "hun": "lilabélű pöfeteg",
        "hun_syn": [
            "lilabelű szétesőpöfeteg"
        ]
    },
    "208": {
        "latin": "Cantharellula umbonata",
        "eng": "the humpback",
        "hun": "vörösödő tölcsérgomba"
    },
    "209": {
        "latin": "Cantharellus amethysteus",
        "eng": "amethyst chanterelle",
        "hun": "ibolyás rókagomba"
    },
    "210": {
        "latin": "Cantharellus cibarius",
        "eng": "chanterelle",
        "hun": "sárga rókagomba",
        "hun_syn": [
            "közönséges rókagomba"
        ]
    },
    "211": {
        "latin": "Cantharellus ferruginascens",
        "eng": "pale chanterelle",
        "latin_syn": [
            "Cantharellus cibarius var. ferruginascens"
        ],
        "hun": "rozsdásodó rókagomba"
    },
    "212": {
        "latin": "Cantharellus friesii",
        "eng": "orange chanterelle",
        "hun": "narancsvörös rókagomba",
        "hun_syn": [
            "bársonyos rókagomba",
            "vöröses rókagomba"
        ]
    },
    "213": {
        "latin": "Cantharellus pallens",
        "eng": "frosted chanterelle",
        "latin_syn": [
            "Cantharellus cibarius var. pallens"
        ],
        "hun": "halvány rókagomba"
    },
    "214": {
        "latin": "Capitotricha bicolor",
        "latin_syn": [
            "Dasyscyphus bicolor",
            "Lachnum bicolor"
        ],
        "hun": "kétszínű csészegombácska"
    },
    "215": {
        "latin": "Catathelasma imperiale",
        "eng": "imperial mushroom",
        "hun": "kétgyűrűs tölcsérgomba",
        "hun_syn": [
            "kétszergyűrűs tölcsérgomba"
        ]
    },
    "216": {
        "latin": "Catinella olivacea",
        "eng": "olive salver",
        "latin_syn": [
            "Peziza olivacea"
        ],
        "hun": "olívzöld csészegomba",
        "hun_syn": [
            "olajbarna csészegomba",
            "olajzöld csészegomba"
        ]
    },
    "217": {
        "latin": "Ceraceomyces serpens",
        "hun": "terjengős redősgomba",
        "hun_syn": [
            "fehér redősgomba"
        ]
    },
    "218": {
        "latin": "Cercopemyces rickenii",
        "latin-syn": [
            "Armillaria rickenii",
            "Floccularia rickenii"
        ],
        "hun": "akácpereszke",
        "hun_syn": [
            "akác-pereszke",
            "akác pikkelyespereszke"
        ]
    },
    "219": {
        "latin": "Ceriporia purpurea",
        "eng": "bruising porecrust",
        "hun": "puha kéreggomba"
    },
    "220": {
        "latin": "Ceriporia reticulata",
        "eng": "reticulate porecrust",
        "hun": "recés kéreggomba"
    },
    "221": {
        "latin": "Ceriporia viridans",
        "eng": "greening porecrust",
        "hun": "zöldes kéreggomba"
    },
    "222": {
        "latin": "Ceriporiopsis gilvescens",
        "eng": "pink porecrust",
        "latin_syn": [
            "Tyromyces gilvescens"
        ],
        "hun": "vörösödő kéreggomba"
    },
    "223": {
        "latin": "Ceriporiopsis mucida",
        "latin_syn": [
            "Fibuloporia donkii"
        ],
        "hun": "vékony kéreggomba"
    },
    "224": {
        "latin": "Ceriporiopsis resinascens",
        "hun": "nyárfa-kéreggomba"
    },
    "225": {
        "latin": "Cerrena unicolor",
        "eng": "labyrinth turkeytail",
        "hun": "szürke egyrétűtapló",
        "hun_syn": [
            "egyszínű egyrétűtapló"
        ]
    },
    "226": {
        "latin": "Chalciporus piperatus",
        "eng": "peppery bolete",
        "hun": "borsos tinóru"
    },
    "227": {
        "latin": "Chalciporus rubinus",
        "hun": "málnavörös tinóru"
    },
    "228": {
        "latin": "Chamaemyces fracidus",
        "eng": "dewdrop dapperling",
        "hun": "foltosodó őzlábgomba"
    },
    "229": {
        "latin": "Chamonixia caespitosa",
        "eng": "blueing false truffle",
        "hun": "alpi kékülőpöfeteg",
        "hun_syn": [
            "kék álpöfeteg"
        ]
    },
    "230": {
        "latin": "Chlorociboria aeruginascens",
        "eng": "green elfcup",
        "hun": "rézrozsdaszínű csészegomba",
        "hun_syn": [
            "zöld facsészegomba"
        ]
    },
    "231": {
        "latin": "Chlorophyllum agaricoides",
        "eng": "puffball parasol",
        "latin_syn": [
            "Endoptychum agaricoides"
        ],
        "hun": "lemezes pöfeteg",
        "hun-syn": [
            "csiperkeszerű lemezespöfeteg"
        ]
    },
    "232": {
        "latin": "Chlorophyllum brunneum",
        "eng": "brown parasol",
        "latin_syn": [
            "Macrolepiota bohemica",
            "Macrolepiota rachodes var. hortensis",
            "Macrolepiota rhacodes var. hortensis",
            "Macrolepiota venenata"
        ],
        "hun": "kerti őzlábgomba",
        "hun-syn": [
            "barna zöldes-őzlábgomba",
            "mérgező őzlábgomba"
        ]
    },
    "233": {
        "latin": "Chlorophyllum rachodes",
        "latin_syn": [
            "Macrolepiota rachodes",
            "Macrolepiota rhacodes"
        ],
        "hun": "piruló őzlábgomba",
        "hun-syn": [
            "piruló nagyőzlábgomba"
        ]
    },
    "234": {
        "latin": "Choiromyces meandriformis",
        "latin_syn": [
            "Choiromyces venosus"
        ],
        "hun": "fehér szarvasgomba",
        "hun-syn": [
            "eres fehér-szarvasgomba"
        ]
    },
    "235": {
        "latin": "Chondrostereum purpureum",
        "eng": "silverleaf fungus",
        "hun": "lilás réteggomba",
        "hun_syn": [
            "lilás rétegtapló"
        ]
    },
    "236": {
        "latin": "Chromocyphella muscicola",
        "eng": "moss ear",
        "latin_syn": [
            "Arrhenia muscicola",
            "Phaeocyphella muscicola"
        ],
        "hun": "mohánélő szemcsegomba"
    },
    "237": {
        "latin": "Chroogomphus helveticus",
        "latin_syn": [
            "Gomphidius helveticus"
        ],
        "hun": "szálaskalapú nyálkásgomba"
    },
    "238": {
        "latin": "Chroogomphus rutilus",
        "eng": "copper spike",
        "latin-syn": [
            "Gomphidius rutilus",
            "Gomphidius viscidus"
        ],
        "hun": "vöröses nyálkásgomba",
        "hun_syn": [
            "narancsszínű nyirokgomba"
        ]
    },
    "239": {
        "latin": "Chrysomphalina chrysophylla",
        "eng": "golden navel",
        "latin-syn": [
            "Gerronema chrysophylla",
            "Gerronema xanthophyllum"
        ],
        "hun": "sárgalemezű békagomba",
        "hun_syn": [
            "sárgalemezű aranygombácska"
        ]
    },
    "240": {
        "latin": "Ciboria amentacea",
        "hun": "égerbarka csészegomba"
    },
    "241": {
        "latin": "Ciboria batschiana",
        "hun": "tölgymakkfeketítő csészegomba"
    },
    "242": {
        "latin": "Ciboria coryli",
        "hun": "mogyoróbarka-csészegomba"
    },
    "243": {
        "latin": "Cladomeris umbellatus",
        "latin_syn": [
            "Dendropolyporus umbellatus",
            "Polyporus umbellatus"
        ],
        "hun": "tüskegomba",
        "hun_syn": [
            "ágas-bogas likacsosgomba"
        ]
    },
    "244": {
        "latin": "Clathrus archeri",
        "eng": "devil's fingers",
        "latin_syn": [
            "Anthurus archeri"
        ],
        "hun": "tintahalgomba"
    },
    "245": {
        "latin": "Clathrus ruber",
        "eng": "red cage",
        "latin_syn": [
            "Clathrus cancellatus"
        ],
        "hun": "piros kosárgomba",
        "hun_syn": [
            "rostélygomba"
        ]
    },
    "246": {
        "latin": "Clavaria argillacea",
        "eng": "moor club",
        "latin_syn": [
            "Clavaria flavipes"
        ],
        "hun": "krémszínű bunkógomba",
        "hun_syn": [
            "sárganyelű bunkógomba",
            "sárganyelű palánkagomba"
        ]
    },
    "247": {
        "latin": "Clavaria falcata",
        "latin_syn": [
            "Clavaria acuta"
        ],
        "hun": "fehér bunkógomba"
    },
    "248": {
        "latin": "Clavaria fragilis",
        "eng": "white spindles",
        "latin_syn": [
            "Clavaria vermicularis"
        ],
        "hun": "árvalányhajgomba"
    },
    "249": {
        "latin": "Clavaria fumosa",
        "eng": "smoky spindles",
        "hun": "szürke bunkógomba",
        "hun-syn": [
            "szürke palánkagomba"
        ]
    },
    "250": {
        "latin": "Clavaria nigrita",
        "hun": "fekete bunkógomba",
        "hun-syn": [
            "fekete palánkagomba"
        ]
    },
    "251": {
        "latin": "Clavaria purpurea",
        "eng": "purple spindles",
        "hun": "bíbor bunkógomba",
        "hun-syn": [
            "bíbor palánkagomba"
        ]
    },
    "252": {
        "latin": "Clavaria rosea",
        "eng": "rose spindles",
        "hun": "rózsaszín bunkógomba"
    },
    "253": {
        "latin": "Clavariadelphus helveticus",
        "hun": "svájci mozsárütőgomba"
    },
    "254": {
        "latin": "Clavariadelphus ligula",
        "eng": "ochre club",
        "latin_syn": [
            "Clavaria ligula"
        ],
        "hun": "vékony mozsárütőgomba"
    },
    "255": {
        "latin": "Clavariadelphus pistillaris",
        "eng": "giant club",
        "hun": "vaskos mozsárütőgomba",
        "hun_syn": [
            "mozsártörőgomba"
        ]
    },
    "256": {
        "latin": "Clavariadelphus sachalinensis",
        "hun": "szibériai mozsárütőgomba"
    },
    "257": {
        "latin": "Clavariadelphus truncatus",
        "eng": "truncated club",
        "hun": "lapos mozsárütőgomba"
    },
    "258": {
        "latin": "Claviceps purpurea",
        "eng": "ergot",
        "hun": "anyarozs"
    },
    "259": {
        "latin": "Clavulina amethystina",
        "hun": "ametiszt-korallgomba",
        "hun-syn": [
            "lila korallgomba"
        ]
    },
    "260": {
        "latin": "Clavulina cinerea",
        "eng": "grey coral",
        "hun": "szürke korallgomba",
        "hun-syn": [
            "szürke fakó-korallgomba"
        ]
    },
    "261": {
        "latin": "Clavulina coralloides",
        "eng": "crested coral",
        "latin_syn": [
            "Clavulina cristata",
            "Ramaria coralloides"
        ],
        "hun": "fésűs korallgomba",
        "hun-syn": [
            "fésűs fakó-korallgomba"
        ]
    },
    "262": {
        "latin": "Clavulina rugosa",
        "eng": "wrinkled club",
        "hun": "barázdás korallgomba",
        "hun-syn": [
            "barázdás bunkógomba",
            "barázdás fakó-korallgomba"
        ]
    },
    "263": {
        "latin": "Clavulinopsis corniculata",
        "eng": "meadow coral",
        "hun": "mohakorallgomba",
        "hun-syn": [
            "mohaközti gyep-korallgomba"
        ]
    },
    "264": {
        "latin": "Clavulinopsis fusiformis",
        "eng": "golden spindles",
        "latin_syn": [
            "Clavaria fusiformis"
        ],
        "hun": "sárga bunkógomba"
    },
    "265": {
        "latin": "Clavulinopsis helvola",
        "eng": "yellow club",
        "hun": "sáfrányszínű bunkógomba",
        "hun-syn": [
            "sáfrány gyep-korallgomba"
        ]
    },
    "266": {
        "latin": "Clavulinopsis laeticolor",
        "eng": "handsome club",
        "latin_syn": [
            "Clavaria pulchra"
        ],
        "hun": "narancsszínű bunkógomba"
    },
    "267": {
        "latin": "Clavulinopsis subtilis",
        "eng": "slender coral",
        "latin_syn": [
            "Ramariopsis subtilis"
        ],
        "hun": "késői korallgomba"
    },
    "268": {
        "latin": "Climacocystis borealis",
        "hun": "északi likacsosgomba"
    },
    "269": {
        "latin": "Climacodon septentrionalis",
        "eng": "northern tooth fungus",
        "hun": "északi tüskéslaska"
    },
    "270": {
        "latin": "Clitocella fallax",
        "eng": "bitter miller",
        "latin_syn": [
            "Clitopilus fallax",
            "Rhodocybe fallax"
        ],
        "hun": "fehér álcölöpgomba"
    },
    "271": {
        "latin": "Clitocella mundula",
        "latin_syn": [
            "Rhodocybe mundula"
        ],
        "hun": "szürkülő álcölöpgomba"
    },
    "272": {
        "latin": "Clitocella popinalis",
        "latin_syn": [
            "Clitopilus popinalis",
            "Rhodocybe popinalis"
        ],
        "hun": "keserű álcölöpgomba",
        "hun_syn": [
            "barnafoltos álcölöpgomba"
        ]
    },
    "273": {
        "latin": "Clitocybe agrestis",
        "eng": "field funnel",
        "hun": "réti tölcsérgomba"
    },
    "274": {
        "latin": "Clitocybe alexandri",
        "eng": "Alexander's funnel",
        "hun": "szürkésbarna tölcsérgomba",
        "hun_syn": [
            "vastagtönkű tölcsérgomba"
        ]
    },
    "275": {
        "latin": "Clitocybe amarescens",
        "latin_syn": [
            "Clitocybe nitrophila"
        ],
        "hun": "komposzttölcsérgomba"
    },
    "276": {
        "latin": "Clitocybe barbularum",
        "eng": "dune funnel",
        "hun": "buckaközi tölcsérgomba"
    },
    "277": {
        "latin": "Clitocybe bresadolana",
        "hun": "vörösbarna tölcsérgomba"
    },
    "278": {
        "latin": "Clitocybe candida",
        "latin_syn": [
            "Aspropaxillus candidus",
            "Leucopaxillus candidus"
        ],
        "hun": "fehér tölcsérgomba"
    },
    "279": {
        "latin": "Clitocybe diatreta",
        "eng": "pierced funnel",
        "hun": "hússzínű tölcsérgomba"
    },
    "280": {
        "latin": "Clitocybe ditopa",
        "eng": "mealy frosted funnel",
        "hun": "kisspórás tölcsérgomba",
        "hun_syn": [
            "lisztízű tölcsérgomba"
        ]
    },
    "281": {
        "latin": "Clitocybe ericetorum",
        "eng": "heath funnel",
        "hun": "csarabos tölcsérgomba"
    },
    "282": {
        "latin": "Clitocybe fragrans",
        "eng": "fragrant funnel",
        "latin_syn": [
            "Clitocybe suaveolens"
        ],
        "hun": "illatos tölcsérgomba",
        "hun_syn": [
            "szagos tölcsérgomba"
        ]
    },
    "283": {
        "latin": "Clitocybe metachroa",
        "eng": "twotone funnel",
        "hun": "kétszínű tölcsérgomba"
    },
    "284": {
        "latin": "Clitocybe herbarum",
        "hun": "világosszürke tölcsérgomba"
    },
    "285": {
        "latin": "Clitocybe lituus",
        "hun": "csíkostönkű tölcsérgomba"
    },
    "286": {
        "latin": "Clitocybe nebularis",
        "eng": "clouded funnel",
        "latin_syn": [
            "Lepista nebularis"
        ],
        "hun": "szürke tölcsérgomba",
        "hun_syn": [
            "szürke tölcsérpereszke"
        ]
    },
    "287": {
        "latin": "Clitocybe odora",
        "eng": "aniseed funnel",
        "hun": "zöld ánizsgomba",
        "hun_syn": [
            "ánizsszagú tölcsérgomba",
            ""
        ]
    },
    "288": {
        "latin": "Clitocybe phyllophila",
        "eng": "frosty funnel",
        "latin-syn": [
            "Clitocybe cerussata",
            "Clitocybe pithyophila"
        ],
        "hun": "viaszfehér tölcsérgomba",
        "hun_syn": [
            "lomberdei tölcsérgomba"
        ]
    },
    "289": {
        "latin": "Clitocybe rivulosa",
        "eng": "fool's funnel",
        "latin_syn": [
            "Clitocybe dealbata",
            "Clitocybe dealbata var. corda"
        ],
        "hun": "mezei tölcsérgomba",
        "hun_syn": [
            "parlagi tölcsérgomba"
        ]
    },
    "290": {
        "latin": "Clitocybe subcordispora",
        "eng": "hearty funnel",
        "hun": "kifakuló tölcsérgomba"
    },
    "291": {
        "latin": "Clitocybe subspadicea",
        "eng": "pale date funnel",
        "hun": "köldökös tölcsérgomba"
    },
    "292": {
        "latin": "Clitocybe trulliformis",
        "eng": "dapper funnel",
        "hun": "sötétköldökű tölcsérgomba"
    },
    "293": {
        "latin": "Clitocybe truncicola",
        "eng": "trunk funnel",
        "hun": "ligeti tölcsérgomba"
    },
    "294": {
        "latin": "Clitocybe vibecina",
        "eng": "mealy funnel",
        "latin_syn": [
            "Clitocybe langei",
            "Clitocybe pausiaca"
        ],
        "hun": "lisztszagú tölcsérgomba",
        "hun_syn": [
            "lágy tölcsérgomba"
        ]
    },
    "295": {
        "latin": "Clitocybula platyphylla",
        "latin_syn": [
            "Megacollybia platyphylla",
            "Oudemansiella platyphylla"
        ],
        "hun": "széleslemezű fülőke"
    },
    "296": {
        "latin": "Clitopilopsis hirneola",
        "latin_syn": [
            "Clitopilus hirneolus",
            "Rhodocybe hirneola"
        ],
        "hun": "fakó álcölöpgomba"
    },
    "297": {
        "latin": "Clitopilus prunulus",
        "eng": "the miller",
        "hun": "kajsza lisztgomba",
        "hun_syn": [
            "fehér cölöpgomba",
            "réti cölöpgomba"
        ]
    },
    "298": {
        "latin": "Colpoma quercinum",
        "hun": "tölgyfa-pajzsoscsészegomba"
    },
    "299": {
        "latin": "Coltricia perennis",
        "eng": "tiger's eye",
        "hun": "szalagos likacsosgomba"
    },
    "300": {
        "latin": "Collybia cirrata",
        "latin_syn": [
            "Collybia cirrhata",
            "Microcollybia cirrhata"
        ],
        "hun": "élősdi fülőke",
        "hun_syn": [
            "selymes fülőke"
        ]
    },
    "301": {
        "latin": "Collybia cookei",
        "eng": "splitpea shanklet",
        "latin_syn": [
            "Microcollybia cookei"
        ],
        "hun": "sárgagumós fülőke",
        "hun_syn": [
            "gumós fülőke"
        ]
    },
    "302": {
        "latin": "Collybia tuberosa",
        "eng": "lentil shanklet",
        "latin_syn": [
            "Microcollybia tuberosa"
        ],
        "hun": "barnagumós fülőke",
        "hun_syn": [
            "gombántermő fülőke"
        ]
    },
    "303": {
        "latin": "Conferticium ochraceum",
        "latin_syn": [
            "Gloeocystidiellum ochraceum"
        ],
        "hun": "okkerszínű vargomba"
    },
    "304": {
        "latin": "Coniophora arida",
        "eng": "dry duster",
        "hun": "sárgásbarna foltgomba",
        "hun_syn": [
            "nemezes foltgomba"
        ]
    },
    "305": {
        "latin": "Coniophora fusispora",
        "eng": "spindle duster",
        "latin_syn": [
            "Coniophora bourdotii"
        ],
        "hun": "francia foltgomba"
    },
    "306": {
        "latin": "Coniophora olivacea",
        "eng": "olive duster",
        "latin_syn": [
            "Coniophora umbrina"
        ],
        "hun": "sugaras foltgomba"
    },
    "307": {
        "latin": "Coniophora puteana",
        "eng": "wet rot",
        "latin_syn": [
            "Coniophora cerebella"
        ],
        "hun": "pincegomba",
        "hun_syn": [
            "vastagbőrű foltgomba"
        ]
    },
    "308": {
        "latin": "Connopus acervatus",
        "latin_syn": [
            "Collybia acervata",
            "Gymnopus acervatus"
        ],
        "hun": "csoportos fülőke"
    },
    "309": {
        "latin": "Conocybe albipes",
        "latin_syn": [
            "Conocybe lactea"
        ],
        "hun": "tejfehér haranggomba",
        "hun_syn": [
            "fehér szemétgomba"
        ]
    },
    "310": {
        "latin": "Conocybe arrhenii",
        "eng": "ringed conecap",
        "latin_syn": [
            "Pholiotina arrhenii"
        ],
        "hun": "vörösesbarna haranggomba",
        "hun_syn": [
            "vörösesbarna tőkegombácska"
        ]
    },
    "311": {
        "latin": "Conocybe deliquescens",
        "eng": "beansprout conecap",
        "latin_syn": [
            "Gastrocybe lateritia"
        ],
        "hun": "kérész haranggomba",
        "hun_syn": [
            "pusztai álkérészgomba"
        ]
    },
    "312": {
        "latin": "Conocybe digitalina",
        "latin_syn": [
            "Conocybe pulchella"
        ],
        "hun": "bársonyos haranggomba"
    },
    "313": {
        "latin": "Conocybe hexagonospora",
        "eng": "angled conecap",
        "hun": "sötétcsúcsú haranggomba",
        "hun_syn": [
            "sötétcsúcsú szemétgomba"
        ]
    },
    "314": {
        "latin": "Conocybe semiglobata",
        "eng": "roundhead conecap",
        "hun": "domború haranggomba"
    },
    "315": {
        "latin": "Conocybe siliginea",
        "eng": "pallid conecap",
        "latin_syn": [
            "Conocybe rickenii",
            "Galera pygmaeoaffinis"
        ],
        "hun": "trágyaharanggomba",
        "hun_syn": [
            "trágya szemétgomba"
        ]
    },
    "316": {
        "latin": "Conocybe spicula",
        "latin_syn": [
            "Conocybe rickeniana"
        ],
        "hun": "bordás haranggomba",
        "hun_syn": [
            "bordás szemétgomba"
        ]
    },
    "317": {
        "latin": "Conocybe subovalis",
        "eng": "ellipsoid conecap",
        "hun": "rozsdabarna haranggomba"
    },
    "318": {
        "latin": "Conocybe subpallida",
        "hun": "sápadt haranggomba"
    },
    "319": {
        "latin": "Conocybe tenera",
        "eng": "common conecap",
        "hun": "karcsú haranggomba"
    },
    "320": {
        "latin": "Conocybe vestita",
        "eng": "caped conecap",
        "latin_syn": [
            "Pholiotina vestita"
        ],
        "hun": "rojtosszegélyű haranggomba "
    },
    "321": {
        "latin": "Coprinellus angulatus",
        "eng": "bishop's inkcap",
        "latin_syn": [
            "Coprinus angulatus"
        ],
        "hun": "szenes tintagomba"
    },
    "322": {
        "latin": "Coprinellus disseminatus",
        "eng": "fairy inkcap",
        "latin_syn": [
            "Coprinus disseminatus"
        ],
        "hun": "sereges tintagomba"
    },
    "323": {
        "latin": "Coprinellus domesticus",
        "eng": "firerug inkcap",
        "latin_syn": [
            "Coprinus domesticus"
        ],
        "hun": "ház  i tintagomba"
    },
    "324": {
        "latin": "Coprinellus ephemerus",
        "eng": "fleeting inkcap",
        "hun": "kérésztintagomba"
    },
    "325": {
        "latin": "Coprinellus flocculosus",
        "eng": "flocculose inkcap",
        "latin_syn": [
            "Coprinus flocculosus"
        ],
        "hun": "pelyhes tintagomba"
    },
    "326": {
        "latin": "Coprinellus hiascens",
        "eng": "splitting inkcap",
        "hun": "hegyesspórás tintagomba"
    },
    "327": {
        "latin": "Coprinellus impatiens",
        "eng": "impatient inkcap",
        "latin_syn": [
            "Coprinus impatiens"
        ],
        "hun": "szürkelemezű tintagomba"
    },
    "328": {
        "latin": "Coprinellus jonesii",
        "hun": "kerekspórás tintagomba"
    },
    "329": {
        "latin": "Coprinellus marculentus",
        "eng": "hexagonal inkcap",
        "latin_syn": [
            "Coprinus hexagonosporus",
            "Coprinus marculentus"
        ],
        "hun": "szögletesspórás tintagomba"
    },
    "330": {
        "latin": "Coprinellus micaceus",
        "eng": "glistening inkcap",
        "latin_syn": [
            "Coprinus micaceus"
        ],
        "hun": "kerti tintagomba"
    },
    "331": {
        "latin": "Coprinellus radians",
        "latin_syn": [
            "Coprinus radians"
        ],
        "hun": "szöszös tintagomba"
    },
    "332": {
        "latin": "Coprinellus silvaticus",
        "eng": "woodland inkcap",
        "latin_syn": [
            "Coprinus silvaticus",
            "Coprinus tardus"
        ],
        "hun": "erdei tintagomba"
    },
    "333": {
        "latin": "Coprinellus truncorum",
        "eng": "trunk inkcap",
        "latin_syn": [
            "Coprinus truncorum"
        ],
        "hun": "csillámló tintagomba",
        "hun_syn": [
            "fűzfa tintagomba"
        ]
    },
    "334": {
        "latin": "Coprinellus xanthothrix",
        "eng": "blond inkcap",
        "latin_syn": [
            "Coprinus xanthothrix"
        ],
        "hun": "sárgaszemcsés tintagomba"
    },
    "335": {
        "latin": "Coprinopsis acuminata",
        "eng": "humpback inkcap",
        "latin_syn": [
            "Coprinus acuminatus"
        ],
        "hun": "csúcsoskalapú tintagomba",
        "hun_syn": [
            "púpos tintagomba"
        ]
    },
    "336": {
        "latin": "Coprinopsis atramentaria",
        "eng": "common inkcap",
        "latin_syn": [
            "Coprinus atramentarius"
        ],
        "hun": "ráncos tintagomba"
    },
    "337": {
        "latin": "Coprinopsis cinerea",
        "eng": "grey inkcap",
        "latin_syn": [
            "Coprinus cinereus",
            "Coprinus fimetarius"
        ],
        "hun": "trágyatintagomba"
    },
    "338": {
        "latin": "Coprinopsis cothurnata",
        "eng": "powdered inkcap",
        "latin_syn": [
            "Coprinus cothurnatus"
        ],
        "hun": "rózsástövű tintagomba"
    },
    "339": {
        "latin": "Coprinopsis echinospora",
        "eng": "warty inkcap",
        "latin_syn": [
            "Coprinus echinosporus"
        ],
        "hun": "tüskésspórás tintagomba"
    },
    "340": {
        "latin": "Coprinopsis ephemeroides",
        "eng": "diaphanous inkcap",
        "latin_syn": [
            "Coprinus ephemeroides",
            "Coprinus hendersonii"
        ],
        "hun": "galléros tintagomba"
    },
    "341": {
        "latin": "Coprinopsis insignis",
        "latin_syn": [
            "Coprinus alopecia",
            "Coprinus insignis"
        ],
        "hun": "ezüstszálas tintagomba"
    },
    "342": {
        "latin": "Coprinopsis lagopus",
        "eng": "hare'sfoot inkcap",
        "latin_syn": [
            "Coprinus lagopus"
        ],
        "hun": "gatyás tintagomba",
        "hun_syn": [
            "nyúlszőrmés tintagomba"
        ]
    },
    "343": {
        "latin": "Coprinopsis marcescibilis",
        "eng": "ephemeral inkcap",
        "latin_syn": [
            "Psathyrella marcescibilis"
        ],
        "hun": "szürkésfehér tintagomba",
        "hun_syn": [
            "agyagszürke porhanyósgomba",
            "szürkésfehér porhanyósgomba"
        ]
    },
    "344": {
        "latin": "Coprinopsis melanthina",
        "latin_syn": [
            "Psathyrella melanthina"
        ],
        "hun": "ligeti tintagomba",
        "hun_syn": [
            "ligeti porhanyósgomba"
        ]
    },
    "345": {
        "latin": "Coprinopsis nivea",
        "eng": "snowy inkcap",
        "latin_syn": [
            "Coprinus niveus"
        ],
        "hun": "fehér tintagomba",
        "hun_syn": [
            "hófehér tintagomba"
        ]
    },
    "346": {
        "latin": "Coprinopsis phaeospora",
        "eng": "darkspored inkcap",
        "latin_syn": [
            "Coprinus phaeosporus"
        ],
        "hun": "barnaspórás tintagomba",
        "hun_syn": [
            "kis tintagomba"
        ]
    },
    "347": {
        "latin": "Coprinopsis picacea",
        "eng": "magpie inkcap",
        "latin_syn": [
            "Coprinus picaceus"
        ],
        "hun": "harkálytintagomba"
    },
    "348": {
        "latin": "Coprinopsis pseudonivea",
        "eng": "aromatic inkcap",
        "latin_syn": [
            "Coprinus pseudoniveus"
        ],
        "hun": "piszkosfehér tintagomba",
        "hun_syn": [
            "szennyesfehér tintagomba"
        ]
    },
    "349": {
        "latin": "Coprinopsis radiata",
        "eng": "rayed inkcap",
        "latin_syn": [
            "Coprinus radiatus"
        ],
        "hun": "csillagtintagomba"
    },
    "350": {
        "latin": "Coprinus comatus",
        "eng": "shaggy inkcap / lawyer's wig",
        "hun": "gyapjas tintagomba"
    },
    "351": {
        "latin": "Coprinus levisticolens",
        "hun": "lestyánszagú tintagomba"
    },
    "352": {
        "latin": "Coprinus sterquilinus",
        "eng": "midden inkcap",
        "hun": "bocskoros tintagomba"
    },
    "353": {
        "latin": "Cordyceps militaris",
        "eng": "scarlet caterpillarclub",
        "hun": "vörös rovarrontógomba"
    },
    "354": {
        "latin": "Corticium anthochroum",
        "hun": "rikítószínű vargomba"
    },
    "355": {
        "latin": "Corticium lacteum",
        "hun": "fehér vargomba"
    },
    "356": {
        "latin": "Corticium roseum",
        "eng": "pink crust",
        "hun": "rózsaszínű vargomba"
    },
    "357": {
        "latin": "Cortinarius acetosus",
        "eng": "vinegar webcap",
        "latin_syn": [
            "Cortinarius rigens"
        ],
        "hun": "orsós pókhálósgomba"
    },
    "358": {
        "latin": "Cortinarius acutus",
        "eng": "peaked webcap",
        "hun": "hegyeskalapú pókhálósgomba"
    },
    "359": {
        "latin": "Cortinarius albertii",
        "hun": "papucsos pókhálósgomba"
    },
    "360": {
        "latin": "Cortinarius alboviolaceus",
        "eng": "pearly webcap",
        "hun": "lilásfehér pókhálósgomba",
        "hun_syn": [
            "halványlila pókhálósgomba"
        ]
    },
    "361": {
        "latin": "Cortinarius alnetorum",
        "eng": "alder webcap",
        "hun": "égeres pókhálósgomba"
    },
    "362": {
        "latin": "Cortinarius amurceus",
        "hun": "okkersárga pókhálósgomba"
    },
    "363": {
        "latin": "Cortinarius angelesianus",
        "eng": "angelic webcap",
        "hun": "borzas pókhálósgomba"
    },
    "364": {
        "latin": "Cortinarius anomalochrascens",
        "hun": "okkerlilás pókhálósgomba"
    },
    "365": {
        "latin": "Cortinarius anomalus",
        "eng": "variable webcap",
        "latin_syn": [
            "Cortinarius azureus"
        ],
        "hun": "lilás pókhálósgomba",
        "hun_syn": [
            "azúr pókhálósgomba"
        ]
    },
    "366": {
        "latin": "Cortinarius anserinus",
        "latin_syn": [
            "Cortinarius amoenolens"
        ],
        "hun": "bükkös pókhálósgomba"
    },
    "367": {
        "latin": "Cortinarius arcuatorum",
        "eng": "arched webcap ",
        "latin_syn": [
            "Cortinarius fulvoincarnatus"
        ],
        "hun": "lilásperemű pókhálósgomba"
    },
    "368": {
        "latin": "Cortinarius armeniacus",
        "hun": "barackszínű pókhálósgomba"
    },
    "369": {
        "latin": "Cortinarius armillatus",
        "eng": "red banded webcap",
        "hun": "vörösövű pókhálósgomba"
    },
    "370": {
        "latin": "Cortinarius atrocoeruleus",
        "hun": "kékesfekete pókhálósgomba"
    },
    "371": {
        "latin": "Cortinarius atrovirens",
        "eng": "olive webcap",
        "hun": "feketészöld pókhálósgomba"
    },
    "372": {
        "latin": "Cortinarius balteatoalbus",
        "eng": "pallid webcap",
        "hun": "nemezes pókhálósgomba"
    },
    "373": {
        "latin": "Cortinarius balteatocumatilis",
        "eng": "undulating webcap",
        "hun": "lilásburkú pókhálósgomba"
    },
    "374": {
        "latin": "Cortinarius barbatus",
        "eng": "snowy webcap",
        "hun": "gyökerező pókhálósgomba"
    },
    "375": {
        "latin": "Cortinarius betulinus",
        "eng": "birchwood webcap",
        "hun": "nyír-pókhálósgomba",
        "hun_syn": [
            "karcsúnyír pókhálósgomba"
        ]
    },
    "376": {
        "latin": "Cortinarius biformis",
        "eng": "doubled webcap",
        "hun": "ferdeövű pókhálósgomba"
    },
    "377": {
        "latin": "Cortinarius bivelus",
        "eng": "woollyfoot webcap ",
        "hun": "kettősburkú pókhálósgomba"
    },
    "378": {
        "latin": "Cortinarius bolaris",
        "eng": "dappled webcap",
        "hun": "vöröspikkelyes pókhálósgomba"
    },
    "379": {
        "latin": "Cortinarius bovinus",
        "eng": "bovine webcap",
        "hun": "vastaglábú pókhálósgomba"
    },
    "380": {
        "latin": "Cortinarius brunneus",
        "eng": "brown webcap",
        "hun": "barna pókhálósgomba",
        "hun_syn": [
            "sötétbarna pókhálósgomba"
        ]
    },
    "381": {
        "latin": "Cortinarius bulliardii",
        "eng": "hotfoot webcap",
        "hun": "vöröslábú pókhálósgomba"
    },
    "382": {
        "latin": "Cortinarius caerulescens",
        "eng": "mealy bigfoot webcap",
        "hun": "kék pókhálósgomba"
    },
    "383": {
        "latin": "Cortinarius cagei",
        "eng": "bicoloured webcap",
        "latin_syn": [
            "Cortinarius bicolor"
        ],
        "hun": "kétszínű pókhálósgomba"
    },
    "384": {
        "latin": "Cortinarius callisteus",
        "eng": "tawny webcap",
        "hun": "mozdonyfüstszagú pókhálósgomba",
        "hun_syn": [
            "rebarbara pókhálósgomba"
        ]
    },
    "385": {
        "latin": "Cortinarius calochrous",
        "eng": "beautiful webcap",
        "hun": "liláslemezű pókhálósgomba"
    },
    "386": {
        "latin": "Cortinarius camphoratus",
        "eng": "goatcheese webcap",
        "hun": "bakszagú pókhálósgomba",
        "hun_syn": [
            "erősszagú pókhálósgomba"
        ]
    },
    "387": {
        "latin": "Cortinarius camptoros",
        "hun": "rugalmas pókhálósgomba"
    },
    "388": {
        "latin": "Cortinarius caninus",
        "eng": "canine webcap",
        "hun": "rozsdabarna pókhálósgomba",
        "hun_syn": [
            "vékonyövű pókhálósgomba"
        ]
    },
    "389": {
        "latin": "Cortinarius caperatus",
        "eng": "the gypsy",
        "latin_syn": [
            "Rozites caperatus"
        ],
        "hun": "gyűrűs ráncosgomba",
        "hun_syn": [
            "ráncos fenyőgomba"
        ]
    },
    "390": {
        "latin": "Cortinarius caroviolaceus",
        "eng": "mealy webcap",
        "latin_syn": [
            "Cortinarius europaeus"
        ],
        "hun": "krémfehér pókhálósgomba"
    },
    "391": {
        "latin": "Cortinarius casimiri",
        "eng": "umbonate webcap",
        "hun": "színestönkű pókhálósgomba"
    },
    "392": {
        "latin": "Cortinarius castaneus",
        "hun": "selymes pókhálósgomba"
    },
    "393": {
        "latin": "Cortinarius cinnabarinus",
        "eng": "cinnabar webcap",
        "latin_syn": [
            "Dermocybe cinnabarina"
        ],
        "hun": "cinóberszínű pókhálósgomba",
        "hun_syn": [
            "cinóberszínű bőrgomba"
        ]
    },
    "394": {
        "latin": "Cortinarius cinnamomeoluteus",
        "eng": "ochre webcap",
        "latin_syn": [
            "Dermocybe cinnamomeolutea"
        ],
        "hun": "mustársárga pókhálósgomba",
        "hun_syn": [
            "fahéjszínű pókhálósgomba",
            "változékony bőrgomba"
        ]
    },
    "395": {
        "latin": "Cortinarius cinnamomeus",
        "eng": "cinnamon webcap",
        "latin_syn": [
            "Dermocybe cinnamomea"
        ],
        "hun": "fahéjbarna pókhálósgomba",
        "hun_syn": [
            "fahéjbarna bőrgomba",
            "fahéjszínű pókhálósgomba"
        ]
    },
    "396": {
        "latin": "Cortinarius citrinus",
        "eng": "citrine webcap",
        "latin_syn": [
            "Cortinarius pseudosulphureus"
        ],
        "hun": "sárgászöld pókhálósgomba",
        "hun_syn": [
            "citromsárga pókhálósgomba"
        ]
    },
    "397": {
        "latin": "Cortinarius claricolor",
        "eng": "bright webcap",
        "hun": "gyapjastönkű pókhálósgomba"
    },
    "398": {
        "latin": "Cortinarius cliduchus",
        "latin_syn": [
            "Cortinarius cephalixus",
            "Cortinarius olidus"
        ],
        "hun": "sárgaöves pókhálósgomba",
        "hun_syn": [
            "pikkelykés pókhálósgomba",
            "szemcséskalapú pókhálósgomba"
        ]
    },
    "399": {
        "latin": "Cortinarius collinitus",
        "eng": "blue-girdled webcap",
        "latin_syn": [
            "Cortinarius muscigenus"
        ],
        "hun": "kéktönkű pókhálósgomba"
    },
    "400": {
        "latin": "Cortinarius coracis",
        "latin_syn": [
            "Cortinarius crassifolius s. auct."
        ],
        "hun": "széleslemezű pókhálósgomba"
    },
    "401": {
        "latin": "Cortinarius cotoneus",
        "eng": "buckskin webcap",
        "hun": "aprópikkelyes pókhálósgomba",
        "hun_syn": [
            "olajzöld pókhálósgomba"
        ]
    },
    "402": {
        "latin": "Cortinarius crassus",
        "eng": "stout webcap",
        "latin_syn": [
            "Cortinarius pseudocrassus"
        ],
        "hun": "vastaghúsú pókhálósgomba"
    },
    "403": {
        "latin": "Cortinarius croceocaeruleus",
        "eng": "handsome webcap",
        "hun": "sárgáskék pókhálósgomba"
    },
    "404": {
        "latin": "Cortinarius croceus",
        "eng": "saffron webcap",
        "hun": "sárgalemezű pókhálósgomba"
    },
    "405": {
        "latin": "Cortinarius cumatilis",
        "eng": "wavy webcap",
        "hun": "galambkék pókhálósgomba",
        "hun_syn": [
            "szőlőkék pókhálósgomba"
        ]
    },
    "406": {
        "latin": "Cortinarius cyanites",
        "eng": "blushing webcap",
        "hun": "vörösödő pókhálósgomba"
    },
    "407": {
        "latin": "Cortinarius decipiens",
        "eng": "sepia webcap",
        "hun": "sötétpúpos pókhálósgomba"
    },
    "408": {
        "latin": "Cortinarius delibutus",
        "eng": "yellow webcap",
        "hun": "kéklemezű pókhálósgomba",
        "hun_syn": [
            "sárga nyálkástönkűgomba"
        ]
    },
    "409": {
        "latin": "Cortinarius dibaphus",
        "eng": "doubledyed webcap",
        "hun": "foltos pókhálósgomba"
    },
    "410": {
        "latin": "Cortinarius dionysae",
        "eng": "millers webcap",
        "hun": "lisztszagú pókhálósgomba"
    },
    "411": {
        "latin": "Cortinarius disjungendus",
        "eng": "inrolled webcap",
        "hun": "faszínű pókhálósgomba"
    },
    "412": {
        "latin": "Cortinarius duracinus",
        "hun": "orsóstönkű pókhálósgomba"
    },
    "413": {
        "latin": "Cortinarius eburneus",
        "hun": "csontfehér pókhálósgomba"
    },
    "414": {
        "latin": "Cortinarius elatior",
        "eng": "tall webcap",
        "hun": "ráncoskalapú pókhálósgomba"
    },
    "415": {
        "latin": "Cortinarius elegantior",
        "eng": "elegant webcap",
        "hun": "elegáns pókhálósgomba"
    },
    "416": {
        "latin": "Cortinarius elegantissimus",
        "eng": "dapper webcap",
        "latin_syn": [
            "Cortinarius aurantioturbinatus",
            "Cortinarius auroturbinatus"
        ],
        "hun": "pompás pókhálósgomba"
    },
    "417": {
        "latin": "Cortinarius emollitoides",
        "latin_syn": [
            "Cortinarius causticus s. Moser"
        ],
        "hun": "deres pókhálósgomba"
    },
    "418": {
        "latin": "Cortinarius emunctus",
        "eng": "viscid webcap",
        "latin_syn": [
            "Cortinarius epipoleus"
        ],
        "hun": "fakóibolyás pókhálósgomba"
    },
    "419": {
        "latin": "Cortinarius evernius",
        "eng": "silky webcap",
        "hun": "lilatönkű pókhálósgomba"
    },
    "420": {
        "latin": "Cortinarius flexipes",
        "eng": "var. flexipes  pine pelargonium webcap",
        "latin_syn": [
            "Cortinarius paleaceus",
            "Cortinarius paleiferus"
        ],
        "hun": "muskátliszagú pókhálósgomba"
    },
    "421": {
        "latin": "Cortinarius fraudulosus",
        "hun": "csalóka pókhálósgomba"
    },
    "422": {
        "latin": "Cortinarius fulvoochrascens",
        "latin_syn": [
            "Cortinarius cyanophyllus"
        ],
        "hun": "kékeslemezű pókhálósgomba"
    },
    "423": {
        "latin": "Cortinarius gentilis",
        "eng": "conifer webcap",
        "hun": "aranysárga pókhálósgomba"
    },
    "424": {
        "latin": "Cortinarius glaucopus",
        "eng": "greenfoot webcap",
        "hun": "szálaskalapú pókhálósgomba"
    },
    "425": {
        "latin": "Cortinarius hemitrichus",
        "eng": "frosty webcap",
        "hun": "pelyhes pókhálósgomba",
        "hun_syn": [
            "kis övesgomba"
        ]
    },
    "426": {
        "latin": "Cortinarius hinnuleus",
        "eng": "earthy webcap",
        "hun": "rozsdás pókhálósgomba",
        "hun_syn": [
            "földszagú pókhálósgomba"
        ]
    },
    "427": {
        "latin": "Cortinarius humicola",
        "eng": "golden webcap",
        "hun": "kúpos pókhálósgomba"
    },
    "428": {
        "latin": "Cortinarius huronensis",
        "eng": "sphagnum webcap",
        "hun": "lápi pókhálósgomba"
    },
    "429": {
        "latin": "Cortinarius incisus",
        "eng": "incised webcap",
        "hun": "hasadtkalapú pókhálósgomba"
    },
    "430": {
        "latin": "Cortinarius infractus",
        "eng": "bitter webcap",
        "hun": "keserű pókhálósgomba"
    },
    "431": {
        "latin": "Cortinarius ionophyllus",
        "eng": "violetgilled webcap",
        "hun": "ibolyáslemezű pókhálósgomba"
    },
    "432": {
        "latin": "Cortinarius laniger",
        "eng": "woolly webcap",
        "hun": "fahéjvörös pókhálósgomba"
    },
    "433": {
        "latin": "Cortinarius largus",
        "eng": "big bellied webcap",
        "hun": "ligeti pókhálósgomba",
        "hun_syn": [
            "fakólilás pókhálósgomba",
            "nagy pókhálósgomba",
            "színváltó pókhálósgomba"
        ]
    },
    "434": {
        "latin": "Cortinarius limonius",
        "eng": "sunset webcap",
        "hun": "oroszlánsárga pókhálósgomba",
        "hun_syn": [
            "sárgazónás pókhálósgomba"
        ]
    },
    "435": {
        "latin": "Cortinarius lucorum",
        "eng": "aspen webcap",
        "hun": "berki pókhálósgomba"
    },
    "436": {
        "latin": "Cortinarius magicus",
        "eng": "magical webcap",
        "hun": "zöldperemű pókhálósgomba"
    },
    "437": {
        "latin": "Cortinarius malachius",
        "eng": "mallow webcap",
        "hun": "bőrszínű pókhálósgomba"
    },
    "438": {
        "latin": "Cortinarius malicorius",
        "eng": "gilded webcap",
        "hun": "narancsvörös pókhálósgomba"
    },
    "439": {
        "latin": "Cortinarius meinhardii",
        "latin_syn": [
            "Cortinarius vitellinus"
        ],
        "hun": "krómsárga pókhálósgomba"
    },
    "440": {
        "latin": "Cortinarius melanotus",
        "hun": "olajbarna pókhálósgomba"
    },
    "441": {
        "latin": "Cortinarius moënne-loccozii",
        "latin_syn": [
            "Cortinarius volvatus s. auct."
        ],
        "hun": "bocskoros pókhálósgomba"
    },
    "442": {
        "latin": "Cortinarius mucifluus",
        "eng": "slimy webcap",
        "hun": "északi pókhálósgomba"
    },
    "443": {
        "latin": "Cortinarius mucosus",
        "eng": "orange webcap",
        "hun": "fehértönkű pókhálósgomba"
    },
    "444": {
        "latin": "Cortinarius multiformis",
        "latin_syn": [
            "Cortinarius allutus"
        ],
        "hun": "sárgásbarna pókhálósgomba",
        "hun_syn": [
            "fűrészeslemezű pókhálósgomba",
            "közönséges pókhálósgomba"
        ]
    },
    "445": {
        "latin": "Cortinarius obtusus",
        "eng": "blunt webcap",
        "hun": "apró pókhálósgomba",
        "hun_syn": [
            "fehérszálas pókhálósgomba"
        ]
    },
    "446": {
        "latin": "Cortinarius ochrophyllus",
        "hun": "okkerlemezű pókhálósgomba"
    },
    "447": {
        "latin": "Cortinarius odorifer",
        "hun": "ánizsszagú pókhálósgomba"
    },
    "448": {
        "latin": "Cortinarius olivaceofuscus",
        "eng": "olivaceous webcap",
        "hun": "gyertyán-pókhálósgomba"
    },
    "449": {
        "latin": "Cortinarius orellanus",
        "eng": "fool's webcap",
        "hun": "mérges pókhálósgomba",
        "hun_syn": [
            "mérges bőrgomba",
            ""
        ]
    },
    "450": {
        "latin": "Cortinarius osmophorus",
        "eng": "sweet webcap",
        "latin_syn": [
            "Cortinarius parfumatus"
        ],
        "hun": "erősillatú pókhálósgomba"
    },
    "451": {
        "latin": "Cortinarius papulosus",
        "hun": "szemcsés-szálas pókhálósgomba"
    },
    "452": {
        "latin": "Cortinarius paracephalixus",
        "hun": "nyárfa-pókhálósgomba"
    },
    "453": {
        "latin": "Cortinarius parvannulatus",
        "eng": "ringed webcap",
        "hun": "aprógallérú pókhálósgomba"
    },
    "454": {
        "latin": "Cortinarius percomis",
        "hun": "majoránnaszagú pókhálósgomba",
        "hun_syn": [
            "fűszerillatú",
            "pókhálósgomba"
        ]
    },
    "455": {
        "latin": "Cortinarius phaeosmus",
        "latin_syn": [
            "Cortinarius rheubarbarinus</i> s. auct."
        ],
        "hun": "zöldségillatú pókhálósgomba"
    },
    "456": {
        "latin": "Cortinarius pholideus",
        "eng": "scaly webcap",
        "hun": "barnapikkelyes pókhálósgomba",
        "hun_syn": [
            "pikkelyesövű pókhálósgomba"
        ]
    },
    "457": {
        "latin": "Cortinarius platypus",
        "eng": "flatfooted webcap",
        "hun": "peremes pókhálósgomba"
    },
    "458": {
        "latin": "Cortinarius porphyropus",
        "eng": "purplefoot webcap",
        "hun": "ibolyástönkű pókhálósgomba"
    },
    "459": {
        "latin": "Cortinarius praestans",
        "eng": "goliath webcap",
        "hun": "óriás pókhálósgomba"
    },
    "460": {
        "latin": "Cortinarius pratensis",
        "eng": "meadow webcap",
        "latin_syn": [
            "Dermocybe pratense"
        ],
        "hun": "gyepi pókhálósgomba"
    },
    "461": {
        "latin": "Cortinarius privignoides",
        "hun": "mogyorószínű pókhálósgomba"
    },
    "462": {
        "latin": "Cortinarius pseudosalor",
        "hun": "mézillatú pókhálósgomba"
    },
    "463": {
        "latin": "Cortinarius purpurascens",
        "eng": "bruising webcap",
        "hun": "bíborlila pókhálósgomba",
        "hun_syn": [
            "bíbor pókhálósgomba",
            "rozsdafoltos pókhálósgomba"
        ]
    },
    "464": {
        "latin": "Cortinarius purpureus",
        "eng": "imperial webcap",
        "latin_syn": [
            "Cortinarius phoeniceus",
            "Dermocybe phoenicea"
        ],
        "hun": "lángvörös pókhálósgomba",
        "hun_syn": [
            "lángvörös bőrgomba"
        ]
    },
    "465": {
        "latin": "Cortinarius quercilicis",
        "hun": "barnafoltos pókhálósgomba"
    },
    "466": {
        "latin": "Cortinarius raphanoides",
        "eng": "radishy webcap",
        "hun": "retekszagú pókhálósgomba"
    },
    "467": {
        "latin": "Cortinarius renidens",
        "hun": "sárga pókhálósgomba"
    },
    "468": {
        "latin": "Cortinarius rubellus",
        "eng": "deadly webcap",
        "latin_syn": [
            "Cortinarius orellanoides",
            "Cortinarius speciosissimus"
        ],
        "hun": "csúcsos pókhálósgomba"
    },
    "469": {
        "latin": "Cortinarius rubicundulus",
        "eng": "ruddy webcap",
        "hun": "borvöröstönkű pókhálósgomba"
    },
    "470": {
        "latin": "Cortinarius rufoolivaceus",
        "hun": "ibolyásvörös pókhálósgomba"
    },
    "471": {
        "latin": "Cortinarius russeoides",
        "hun": "rossz-szagú pókhálósgomba"
    },
    "472": {
        "latin": "Cortinarius saginus",
        "eng": "cloaked webcap",
        "latin_syn": [
            "Cortinarius subtriumphans",
            "Cortinarius subvalidus"
        ],
        "hun": "díszes pókhálósgomba",
        "hun_syn": [
            "sárgahúsú pókhálósgomba"
        ]
    },
    "473": {
        "latin": "Cortinarius salor",
        "eng": "flamboyant webcap",
        "hun": "ibolyás pókhálósgomba",
        "hun_syn": [
            "kék nyálkástönkűgomba",
            "lila pókhálósgomba"
        ]
    },
    "474": {
        "latin": "Cortinarius sanguineus",
        "eng": "bloodred webcap",
        "latin_syn": [
            "Dermocybe sanguinea"
        ],
        "hun": "vérvörös pókhálósgomba",
        "hun_syn": [
            "vérvörös bőrgomba"
        ]
    },
    "475": {
        "latin": "Cortinarius saniosus",
        "eng": "golden banded webcap",
        "hun": "narancsszínű pókhálósgomba"
    },
    "476": {
        "latin": "Cortinarius saturninus",
        "eng": "trooping webcap",
        "latin_syn": [
            "Cortinarius cohabitans"
        ],
        "hun": "fűzfa-pókhálósgomba",
        "hun_syn": [
            "füzes pókhálósgomba"
        ]
    },
    "477": {
        "latin": "Cortinarius scaurotraganoides",
        "hun": "illatos pókhálósgomba"
    },
    "478": {
        "latin": "Cortinarius scaurus",
        "eng": "green webcap",
        "hun": "olívlemezű pókhálósgomba"
    },
    "479": {
        "latin": "Cortinarius semisanguineus",
        "eng": "surprise webcap",
        "latin_syn": [
            "Dermocybe semisanguinea"
        ],
        "hun": "vöröslemezű pókhálósgomba",
        "hun_syn": [
            "vöröslemezű bőrgomba"
        ]
    },
    "480": {
        "latin": "Cortinarius sertipes",
        "hun": "nyáras pókhálósgomba"
    },
    "481": {
        "latin": "Cortinarius simulatus",
        "eng": "copycat webcap",
        "latin_syn": [
            "Cortinarius violaceocinereus s. auct."
        ],
        "hun": "ibolyásszürke pókhálósgomba"
    },
    "482": {
        "latin": "Cortinarius sodagnitus",
        "eng": "bitter bigfoot webcap",
        "hun": "ibolyáskék pókhálósgomba"
    },
    "483": {
        "latin": "Cortinarius sommerfeltii",
        "eng": "concentric webcap",
        "hun": "narancssárga-lemezű pókhálósgomba"
    },
    "484": {
        "latin": "Cortinarius spilomeus",
        "eng": "freckled webcap",
        "hun": "pikkelyestönkű pókhálósgomba",
        "hun_syn": [
            "rezespikkelyű pókhálósgomba"
        ]
    },
    "485": {
        "latin": "Cortinarius splendens",
        "eng": "splendid webcap",
        "hun": "kénsárga pókhálósgomba",
        "hun_syn": [
            "sárgafényű pókhálósgomba"
        ]
    },
    "486": {
        "latin": "Cortinarius stemmatus",
        "hun": "koszorús pókhálósgomba"
    },
    "487": {
        "latin": "Cortinarius subcompar",
        "hun": "tömzsi pókhálósgomba"
    },
    "488": {
        "latin": "Cortinarius subpurpurascens",
        "hun": "lilafoltos pókhálósgomba"
    },
    "489": {
        "latin": "Cortinarius subtortus",
        "eng": "incense webcap",
        "hun": "kesernyés pókhálósgomba"
    },
    "490": {
        "latin": "Cortinarius suillus",
        "eng": "piggy webcap",
        "hun": "keményhúsú pókhálósgomba"
    },
    "491": {
        "latin": "Cortinarius tabularis",
        "eng": "wan webcap",
        "latin_syn": [
            "Cortinarius decoloratus"
        ],
        "hun": "fakó pókhálósgomba"
    },
    "492": {
        "latin": "Cortinarius talus",
        "eng": "honey webcap",
        "hun": "fakólemezű pókhálósgomba"
    },
    "493": {
        "latin": "Cortinarius torvus",
        "eng": "stocking webcap",
        "hun": "szagos pókhálósgomba",
        "hun_syn": [
            "erősillatú pókhálósgomba",
            "szagos övesgomba"
        ]
    },
    "494": {
        "latin": "Cortinarius traganus",
        "eng": "gassy webcap",
        "hun": "hagymatönkű pókhálósgomba",
        "hun_syn": [
            "lila hagymatönkű pókhálósgomba"
        ]
    },
    "495": {
        "latin": "Cortinarius tricognandus",
        "hun": "susulykás pókhálósgomba"
    },
    "496": {
        "latin": "Cortinarius triformis",
        "eng": "tricoloured webcap",
        "hun": "változékony pókhálósgomba"
    },
    "497": {
        "latin": "Cortinarius triumphans",
        "eng": "birch webcap",
        "hun": "övestönkű pókhálósgomba"
    },
    "498": {
        "latin": "Cortinarius trivialis",
        "eng": "girdled webcap",
        "hun": "nyálkástönkű pókhálósgomba",
        "hun_syn": [
            "barnanyálkás pókhálósgomba",
            "barna nyálkástönkű pókhálósgomba"
        ]
    },
    "499": {
        "latin": "Cortinarius tubarius",
        "eng": "mire webcap",
        "latin_syn": [
            "Dermocybe sphagneti"
        ],
        "hun": "tőzegmoha-pókhálósgomba"
    },
    "500": {
        "latin": "Cortinarius turmalis",
        "latin_syn": [
            "Cortinarius sebaceus"
        ],
        "hun": "selymestönkű pókhálósgomba",
        "hun_syn": [
            "selymeskalapú pókhálósgomba"
        ]
    },
    "501": {
        "latin": "Cortinarius uliginosus",
        "eng": "marsh webcap",
        "hun": "rézvörös pókhálósgomba"
    },
    "502": {
        "latin": "Cortinarius umbrinolens",
        "eng": "umber webcap",
        "hun": "sötétbarna pókhálósgomba"
    },
    "503": {
        "latin": "Cortinarius uraceus",
        "hun": "feketésbarna pókhálósgomba"
    },
    "504": {
        "latin": "Cortinarius valgus",
        "eng": "bowed webcap",
        "hun": "palaszínlemezű pókhálósgomba"
    },
    "505": {
        "latin": "Cortinarius variecolor",
        "hun": "földszagú pókhálósgomba",
        "hun_syn": [
            "vastaghúsú pókhálósgomba",
            "változékony pókhálósgomba"
        ]
    },
    "506": {
        "latin": "Cortinarius variiformis",
        "eng": "varied webcap",
        "hun": "változatos pókhálósgomba"
    },
    "507": {
        "latin": "Cortinarius varius",
        "hun": "zsemlebarna pókhálósgomba"
    },
    "508": {
        "latin": "Cortinarius venetus",
        "eng": "fluorescent webcap",
        "hun": "olajzöldes pókhálósgomba",
        "hun_syn": [
            "olajzöld pókhálósgomba"
        ]
    },
    "509": {
        "latin": "Cortinarius venustus",
        "eng": "charming webcap",
        "hun": "díszestönkű pókhálósgomba"
    },
    "510": {
        "latin": "Cortinarius vibratilis",
        "eng": "quivering webcap",
        "hun": "epeízű pókhálósgomba"
    },
    "511": {
        "latin": "Cortinarius violaceus",
        "eng": "violet webcap",
        "latin_syn": [
            "Cortinarius harcynicus"
        ],
        "hun": "sötétlila pókhálósgomba"
    },
    "512": {
        "latin": "Cortinarius vulpinus",
        "eng": "foxy webcap",
        "hun": "csizmás pókhálósgomba"
    },
    "513": {
        "latin": "Cortinarius xanthochlorus",
        "eng": "sooty webcap",
        "latin_syn": [
            "Cortinarius olivascentium"
        ],
        "hun": "olívsárga pókhálósgomba"
    },
    "514": {
        "latin": "Cortinarius xanthophyllus",
        "eng": "yellow gilled webcap",
        "hun": "aranylemezű pókhálósgomba"
    },
    "515": {
        "latin": "Coryne atrovirens",
        "hun": "sötétzöld csuklyásodógomba"
    },
    "516": {
        "latin": "Craterellus cinereus",
        "eng": "ashen chanterelle",
        "latin_syn": [
            "Cantharellus cinereus",
            "Pseudocraterellus cinereus"
        ],
        "hun": "szürke rókagomba"
    },
    "517": {
        "latin": "Craterellus cornucopioides",
        "eng": "horn of plenty",
        "hun": "sötét trombitagomba"
    },
    "518": {
        "latin": "Craterellus konradii",
        "hun": "sárga trombitagomba"
    },
    "519": {
        "latin": "Craterellus lutescens",
        "eng": "golden chanterelle",
        "latin_syn": [
            "Cantharellus aurora",
            "Cantharellus lutescens",
            "Cantharellus xanthopus"
        ],
        "hun": "szagos rókagomba",
        "hun_syn": [
            "fénylő rókagomba",
            "sárgatönkű rókagomba"
        ]
    },
    "520": {
        "latin": "Craterellus melanoxeros",
        "eng": "blackening chanterelle",
        "latin_syn": [
            "Cantharellus melanoxeros"
        ],
        "hun": "sötétedőhúsú rókagomba"
    },
    "521": {
        "latin": "Craterellus tubaeformis",
        "latin_syn": [
            "Cantharellus tubaeformis",
            "Cantharellus infundibuliformis"
        ],
        "hun": "tölcséres rókagomba"
    },
    "522": {
        "latin": "Craterocolla cerasi",
        "latin_syn": [
            "Ditangium cerasi"
        ],
        "hun": "cseresznye-mirigygomba"
    },
    "523": {
        "latin": "Crepidotus applanatus",
        "eng": "flat oysterling",
        "latin_syn": [
            "Crepidotus scalaris"
        ],
        "hun": "csontfehér kacskagomba",
        "hun_syn": [
            "bordás kacskagomba"
        ]
    },
    "524": {
        "latin": "Crepidotus calolepis",
        "hun": "barnapikkelyes kacskagomba"
    },
    "525": {
        "latin": "Crepidotus cesatii",
        "eng": "roundspored oysterling",
        "hun": "ritkalemezű kacskagomba"
    },
    "526": {
        "latin": "Crepidotus crocophyllus",
        "eng": "saffron oysterling",
        "hun": "sárgalemezű kacskagomba"
    },
    "527": {
        "latin": "Crepidotus lundellii",
        "hun": "simakalapú kacskagomba"
    },
    "528": {
        "latin": "Crepidotus mollis",
        "eng": "peeling oysterling",
        "hun": "kocsonyás kacskagomba"
    },
    "529": {
        "latin": "Crepidotus subverrucisporus",
        "hun": "érdesspórás kacskagomba"
    },
    "530": {
        "latin": "Crepidotus variabilis",
        "eng": "variable oysterling",
        "hun": "változékony kacskagomba"
    },
    "531": {
        "latin": "Crinipellis scabella",
        "eng": "hairy parachute",
        "latin_syn": [
            "Crinipellis stipitaria"
        ],
        "hun": "pikkelyes álszegfűgomba",
        "hun_syn": [
            "pikkelyes álszegfűgombácska"
        ]
    },
    "532": {
        "latin": "Cristinia mucida",
        "latin_syn": [
            "Radulum mucidum"
        ],
        "hun": "lágy ráspolygomba"
    },
    "533": {
        "latin": "Crucibulum crucibuliforme",
        "latin_syn": [
            "Crucibulum laeve"
        ],
        "hun": "sárga tégelygomba",
        "hun_syn": [
            "tégelygomba"
        ]
    },
    "534": {
        "latin": "Cudonia circinans",
        "eng": "redleg jellybaby",
        "hun": "bordás pajzsgomba"
    },
    "535": {
        "latin": "Cudonia confusa",
        "eng": "cinnamon jellybaby",
        "hun": "ráncos pajzsgomba"
    },
    "536": {
        "latin": "Cudoniella clavus",
        "eng": "spring pin",
        "latin_syn": [
            "Cudoniella aquatica",
            "Ombrophila aquatica"
        ],
        "hun": "vízi pajzsgomba"
    },
    "537": {
        "latin": "Cudoniella stagnalis",
        "hun": "mocsári pajzsgomba"
    },
    "538": {
        "latin": "Cuphophyllus fornicatus",
        "eng": "earthy waxcap",
        "latin_syn": [
            "Hygrocybe fornicata"
        ],
        "hun": "szürkés nyirokgomba",
        "hun_syn": [
            "szürkés nedűgomba"
        ]
    },
    "539": {
        "latin": "Cuphophyllus pratensis",
        "eng": "meadow waxcap",
        "latin_syn": [
            "Camarophyllus pratensis",
            "Hygrocybe pratensis"
        ],
        "hun": "élénkszínű nyirokgomba",
        "hun_syn": [
            "réti nyirokgomba"
        ]
    },
    "540": {
        "latin": "Cuphophyllus virgineus",
        "eng": "snowy waxcap",
        "latin_syn": [
            "Camarophyllus niveus",
            "Camarophyllus virgineus",
            "Hygrocybe virginea"
        ],
        "hun": "fehér nyirokgomba"
    },
    "541": {
        "latin": "Cyanoboletus pulverulentus",
        "latin_syn": [
            "Boletus pulverulentus"
        ],
        "hun": "ligeti tinóru"
    },
    "542": {
        "latin": "Cyathus olla",
        "eng": "field bird's nest",
        "hun": "szürkés pohárgomba"
    },
    "543": {
        "latin": "Cyathus stercoreus",
        "eng": "dung bird's nest",
        "hun": "karcsú pohárgomba"
    },
    "544": {
        "latin": "Cyathus striatus",
        "eng": "fluted bird's nest",
        "hun": "csíkos pohárgomba"
    },
    "545": {
        "latin": "Cyclocybe cylindracea",
        "latin_syn": [
            "Agrocybe aegerita",
            "Agrocybe cylindracea"
        ],
        "hun": "déli tőkegomba"
    },
    "546": {
        "latin": "Cyclocybe erebia",
        "latin_syn": [
            "Agrocybe erebia"
        ],
        "hun": "sötét rétgomba"
    },
    "547": {
        "latin": "Cylindrobasidium laeve",
        "eng": "tear dropper",
        "latin_syn": [
            "Corticium laeve",
            "Cylindrobasidium evolvens"
        ],
        "hun": "sima vargomba"
    },
    "548": {
        "latin": "Cyphella digitalis",
        "hun": "gyűszűs csőgomba",
        "hun_syn": [
            "gyűszű szemcsegomba"
        ]
    },
    "549": {
        "latin": "Cystoagaricus silvestris",
        "latin_syn": [
            "Psathyrella populina",
            "Psathyrella sylvestris"
        ],
        "hun": "erdei álporhanyósgomba",
        "hun_syn": [
            "ligeti porhanyósgomba"
        ]
    },
    "550": {
        "latin": "Cystoderma amianthinum",
        "eng": "earthy powdercap",
        "hun": "sárga szemcsésgomba",
        "hun_syn": [
            "sárga őzlábgomba",
            "sárga szemcsés-őzlábgomba"
        ]
    },
    "551": {
        "latin": "Cystoderma carcharias",
        "eng": "pearly powdercap",
        "hun": "erősszagú szemcsésgomba",
        "hun_syn": [
            "erősszagú őzlábgomba",
            "erősszagú szemcsés-őzlábgomba"
        ]
    },
    "552": {
        "latin": "Cystoderma fallax",
        "hun": "csalóka szemcsésgomba",
        "hun_syn": [
            "csalóka szemcsésőzlábgomba"
        ]
    },
    "553": {
        "latin": "Cystoderma jasonis",
        "eng": "pine powdercap",
        "latin_syn": [
            "Cystoderma longisporum"
        ],
        "hun": "rozsdasárga szemcsésgomba",
        "hun_syn": [
            "okkeres szemcsés-őzlábgomba",
            "rozsdasárga szemcsés-őzlábgomba"
        ]
    },
    "554": {
        "latin": "Cystoderma superbum",
        "hun": "borvörös szemcsésgomba",
        "hun_syn": [
            "borvörös szemcsés-őzlábgomba"
        ]
    },
    "555": {
        "latin": "Cystodermella adnatifolia",
        "latin_syn": [
            "Cystoderma adnatifolium"
        ],
        "hun": "villáslemezű szemcsésgomba",
        "hun_syn": [
            "villáslemezű szemcsésőzláb"
        ]
    },
    "556": {
        "latin": "Cystodermella Cystoderma granulosum",
        "hun": "rozsdás szemcsésgomba",
        "hun_syn": [
            "rozsdás őzlábgomba"
        ]
    },
    "557": {
        "latin": "Cystodermella terreyi",
        "latin_syn": [
            "Cystoderma cinnabarina",
            "Cystoderma terrei"
        ],
        "hun": "cinóbervörös szemcsésgomba",
        "hun_syn": [
            "cinóbervörös őzlábgomba"
        ]
    },
    "558": {
        "latin": "Cystolepiota adulterina",
        "hun": "gyengegallérú lisztesőzlábgomba",
        "hun_syn": [
            "gyengegallérú lisztes-őzlábgomba"
        ]
    },
    "559": {
        "latin": "Cystolepiota bucknallii",
        "eng": "lilac dapperling",
        "hun": "lila lisztesőzlábgomba",
        "hun_syn": [
            "büdös lisztes-őzlábgomba",
            "lila lisztes-őzlábgomba"
        ]
    },
    "560": {
        "latin": "Cystolepiota hetieri",
        "latin_syn": [
            "Cystoderma hetieri",
            "Lepiota rufescens"
        ],
        "hun": "vörösödő lisztesőzlábgomba",
        "hun_syn": [
            "vörösödő lisztes-őzlábgomba"
        ]
    },
    "561": {
        "latin": "Cystolepiota moelleri",
        "hun": "rózsaszínű lisztesőzlábgomba",
        "hun_syn": [
            "rózsaszínű lisztes-őzlábgomba"
        ]
    },
    "562": {
        "latin": "Cystolepiota petasiformis",
        "latin_syn": [
            "Cystolepiota pulverulenta",
            "Leucoagaricus pulverulentus",
            "Pulverolepiota pulverulenta"
        ],
        "hun": "kúposkalapú lisztesőzlábgomba",
        "hun_syn": [
            "barnás lisztes-őzlábgomba",
            "kúposkalapú porosőzláb"
        ]
    },
    "563": {
        "latin": "Cystolepiota seminuda",
        "eng": "bearded dapperling",
        "latin_syn": [
            "Cystlepiota sistrata"
        ],
        "hun": "fehér lisztesőzlábgomba",
        "hun_syn": [
            "csillámló lisztes-őzlábgomba",
            "fehér lisztes-őzlábgomba",
            "lisztes őzlábgomba"
        ]
    },
    "564": {
        "latin": "Cytidia salicina",
        "eng": "scarlet splash",
        "latin_syn": [
            "Cytidia rutilans"
        ],
        "hun": "vörös gyűszűgomba"
    },
    "565": {
        "latin": "Dacrymyces caesius",
        "hun": "fehéres gümőgomba"
    },
    "566": {
        "latin": "Dacrymyces chrysocomus",
        "hun": "csészés gümőgomba",
        "hun_syn": [
            "csészegümőgomba"
        ]
    },
    "567": {
        "latin": "Dacrymyces chrysospermus",
        "eng": "orange jelly spot",
        "latin_syn": [
            "Dacrymyces palmatus"
        ],
        "hun": "narancssárga gümőgomba"
    },
    "568": {
        "latin": "Dacrymyces stillatus",
        "eng": "common jelly spot",
        "latin_syn": [
            "Dacrymyces deliquescens"
        ],
        "hun": "sárga gümőgomba"
    },
    "569": {
        "latin": "Daedalea quercina",
        "eng": "oak mazegill",
        "hun": "labirintustapló",
        "hun_syn": [
            "labirinttapló"
        ]
    },
    "570": {
        "latin": "Daedaleopsis confragosa",
        "eng": "blushing bracket",
        "latin_syn": [
            "Trametes confragosa"
        ],
        "hun": "rózsaszínes egyrétűtapló"
    },
    "571": {
        "latin": "Daedaleopsis confragosa var. tricolor",
        "hun": "háromszínű egyrétűtapló"
    },
    "572": {
        "latin": "Daldinia concentrica",
        "eng": "King Alfred's cakes / cramp balls",
        "hun": "szenes gömbgomba",
        "hun_syn": [
            "sávos széngömbgomba",
            "szén-gömbgomba"
        ]
    },
    "573": {
        "latin": "Daldinia fissa",
        "hun": "nyeles széngömbgomba"
    },
    "574": {
        "latin": "Dasyscyphella nivea",
        "hun": "hófehér csészegombácska"
    },
    "575": {
        "latin": "Datronia mollis",
        "eng": "common mazegill",
        "hun": "hanyattfekvő egyrétűtapló",
        "hun_syn": [
            "lágy őztapló"
        ]
    },
    "576": {
        "latin": "Datronia stereoides",
        "hun": "feketés egyrétűtapló"
    },
    "577": {
        "latin": "Deconia coprophila",
        "latin_syn": [
            "Psilocybe coprophila"
        ],
        "hun": "ganéjbadargomba"
    },
    "578": {
        "latin": "Deconia inquilina",
        "latin_syn": [
            "Psilocybe ecbola",
            "Psilocybe inquilina"
        ],
        "hun": "bordás badargomba",
        "hun_syn": [
            "bordás szemétgomba"
        ]
    },
    "579": {
        "latin": "Deconica horizontalis",
        "latin_syn": [
            "Phaeomarasmius horizontalis",
            "Psilocybe horizontalis"
        ],
        "hun": "kéregbadargomba",
        "hun_syn": [
            "kéregszemétgomba"
        ]
    },
    "580": {
        "latin": "Deconica merdaria",
        "latin_syn": [
            "Psilocybe merdaria"
        ],
        "hun": "trágyabadargomba",
        "hun_syn": [
            "trágyaharmatgomba"
        ]
    },
    "581": {
        "latin": "Deconica montana",
        "latin_syn": [
            "Psilocybe montana"
        ],
        "hun": "mohabadargomba",
        "hun_syn": [
            "moha badargomba"
        ]
    },
    "582": {
        "latin": "Deconica subviscida",
        "latin_syn": [
            "Psilocybe subviscida"
        ],
        "hun": "fényes badargomba"
    },
    "583": {
        "latin": "Delastria rosea",
        "hun": "rózsástrifla",
        "hun_syn": [
            "rózsásbélű szarvasgomba"
        ]
    },
    "584": {
        "latin": "Delicatula integrella",
        "hun": "ráncoslemezű kígyógombácska",
        "hun_synyn": [
            "ráncoslemezű kígyógomba"
        ]
    },
    "585": {
        "latin": "Dendrocollybia racemosa",
        "eng": "branched shanklet",
        "latin_syn": [
            "Collybia racemosa",
            "Microcollybia racemosa"
        ],
        "hun": "fürtös fülőke"
    },
    "586": {
        "latin": "Dendrothele acerina",
        "eng": "maple whitewash",
        "latin_syn": [
            "Aleurodiscus acerinus"
        ],
        "hun": "juhar-koronggombácska"
    },
    "587": {
        "latin": "Dendrothele griseocana",
        "hun": "fakószürke koronggombácska"
    },
    "588": {
        "latin": "Dermoloma cuneifolium",
        "eng": "crazed cap",
        "hun": "ráncos hamvaspereszke",
        "hun_syn": [
            "csövestönkű pereszke",
            "ráncos bársonypereszke"
        ]
    },
    "589": {
        "latin": "Desarmillaria tabescens",
        "latin_syn": [
            "Armillaria tabescens"
        ],
        "hun": "csoportos tuskógomba",
        "hun_syn": [
            "csoportos tölcsérgomba"
        ]
    },
    "590": {
        "latin": "Descomyces albus",
        "latin-syn": [
            "Hymenogaster albus",
            "Hymenogaster cerebellum"
        ],
        "hun": "foltosodó hártyáspöfeteg",
        "hun_syn": [
            "foltosodó álpöfeteg",
            "tekervényes álpöfeteg",
            "tekervényes hártyáspöfeteg"
        ]
    },
    "591": {
        "latin": "Dialonectria episphaeria",
        "hun": "vérvörös bibircsgomba"
    },
    "592": {
        "latin": "Diatrype bullata",
        "eng": "willow barkspot",
        "hun": "fűzfa-kéregtörőgomba"
    },
    "593": {
        "latin": "Diatrype disciformis",
        "eng": "beech barkspot",
        "hun": "sarkos kéregtörőgomba"
    },
    "594": {
        "latin": "Diatrype stigma",
        "eng": "common tarcrust",
        "hun": "varszerű kéregtörőgomba"
    },
    "595": {
        "latin": "Diatrypella quercina",
        "eng": "oak blackhead",
        "hun": "tölgyfa-kéreggomba",
        "hun_syn": [
            "tölgyfa érdes-fakéreggomba"
        ]
    },
    "596": {
        "latin": "Dichomitus campestris",
        "eng": "hazel porecrust",
        "hun": "mezei egyrétűtapló"
    },
    "597": {
        "latin": "Dichomitus squalens",
        "hun": "összetett egyrétűtapló"
    },
    "598": {
        "latin": "Dichostereum granulosum",
        "latin_syn": [
            "Grandinia granulosa"
        ],
        "hun": "szemcsés kéreggereben"
    },
    "599": {
        "latin": "Diplomitoporus flavescens",
        "latin_syn": [
            "Antrodia flavescens"
        ],
        "hun": "sárgás egyrétűtapló"
    },
    "600": {
        "latin": "Discina ancilis",
        "latin_syn": [
            "Discina perlata",
            "Gyromitra perlata"
        ],
        "hun": "ráncos koronggomba",
        "hun_syn": [
            "dudoros koronggomba",
            "dudoros papsapkagomba"
        ]
    },
    "601": {
        "latin": "Discina fastigiata",
        "latin_syn": [
            "Gyromitra fastigiata"
        ],
        "hun": "vörösbarna papsapkagomba"
    },
    "602": {
        "latin": "Disciotis maturescens",
        "hun": "bíboros tárcsagomba"
    },
    "603": {
        "latin": "Disciotis venosa",
        "eng": "bleach cup",
        "hun": "ráncos tárcsagomba"
    },
    "604": {
        "latin": "Disciseda bovista",
        "hun": "nagy csészéspöfeteg",
        "hun_syn": [
            "apró csészéspöfeteg",
            "csészés pöfeteg",
            "sötétedő csészés-pöfeteg"
        ]
    },
    "605": {
        "latin": "Disciseda candida",
        "latin_syn": [
            "Disciseda calva"
        ],
        "hun": "kicsiny csészéspöfeteg",
        "hun_syn": [
            "halvány csészés-pöfeteg"
        ]
    },
    "606": {
        "latin": "Disciseda verrucosa",
        "hun": "vastagkérgű csészéspöfeteg"
    },
    "607": {
        "latin": "Ditiola radicata",
        "latin_syn": [
            "Dacrymyces radicatus"
        ],
        "hun": "gyökerező álcsészegomba",
        "hun_syn": [
            "gyökerező gümőgomba"
        ]
    },
    "608": {
        "latin": "Dumontinia tuberosa",
        "eng": "anemone cup",
        "latin_syn": [
            "Sclerotinia tuberosa"
        ],
        "hun": "élősdi csészegomba",
        "hun_syn": [
            "élősdi serleggomba"
        ]
    },
    "609": {
        "latin": "Echinoderma asperum",
        "latin_syn": [
            "Lepiota aspera"
        ],
        "hun": "tüskés őzlábgomba"
    },
    "610": {
        "latin": "Echinoderma calcicola",
        "latin_syn": [
            "Lepiota calcicola"
        ],
        "hun": "barnatüskés őzlábgomba",
        "hun_syn": [
            "kakaóbarna tüskés-őzlábgomba"
        ]
    },
    "611": {
        "latin": "Echinoderma echinaceum",
        "latin_syn": [
            "Lepiota echincea"
        ],
        "hun": "sündisznó-őzlábgomba",
        "hun_syn": [
            "érdes tüskés-őzlábgomba"
        ]
    },
    "612": {
        "latin": "Echinoderma hystrix",
        "latin_syn": [
            "Lepiota hystrix"
        ],
        "hun": "barnaélű őzlábgomba",
        "hun_syn": [
            "barnaélű tüskés-őzlábgomba"
        ]
    },
    "613": {
        "latin": "Elaphomyces aculeatus",
        "hun": "varangyos álszarvasgomba "
    },
    "614": {
        "latin": "Elaphomyces anthracinus",
        "hun": "köldökös álszarvasgomba"
    },
    "615": {
        "latin": "Elaphomyces asperulus",
        "eng": "roughened false truffle",
        "hun": "szemölcsös álszarvasgomba "
    },
    "616": {
        "latin": "Elaphomyces atropurpureus",
        "hun": "bíborfekete álszarvasgomba "
    },
    "617": {
        "latin": "Elaphomyces citrinus",
        "hun": "sárgamolyhos álszarvasgomba"
    },
    "618": {
        "latin": "Elaphomyces cyanosporus",
        "hun": "kékspórás álszarvasgomba",
        "hun_syn": [
            "kékbélű álszarvasgomba"
        ]
    },
    "619": {
        "latin": "Elaphomyces decipiens",
        "hun": "sárgásbarna álszarvasgomba "
    },
    "620": {
        "latin": "Elaphomyces foetidus",
        "hun": "büdös álszarvasgomba "
    },
    "621": {
        "latin": "Elaphomyces granulatus",
        "eng": "false truffle",
        "latin_syn": [
            "Elaphomyces cervinus"
        ],
        "hun": "közönséges álszarvasgomba",
        "hun_syn": [
            "szemcsés szarvasgomba"
        ]
    },
    "622": {
        "latin": "Elaphomyces leucosporus",
        "hun": "borsónyi álszarvasgomba"
    },
    "623": {
        "latin": "Elaphomyces leveillei",
        "hun": "patinás álszarvasgomba"
    },
    "624": {
        "latin": "Elaphomyces maculatus",
        "hun": "foltos álszarvasgomba"
    },
    "625": {
        "latin": "Elaphomyces morettii ",
        "latin_syn": [
            "Elaphomyces echinatus"
        ],
        "hun": "tüskés álszarvasgomba"
    },
    "626": {
        "latin": "Elaphomyces muricatus",
        "eng": "marbled false truffle",
        "latin_syn": [
            "Elaphomyces hirtus",
            "Elaphomyces reticulatus",
            "Elaphomyces variegatus"
        ],
        "hun": "változékony álszarvasgomba"
    },
    "627": {
        "latin": "Elaphomyces mutabilis",
        "hun": "bundás álszarvasgomba"
    },
    "628": {
        "latin": "Elaphomyces papillatus",
        "hun": "ráncosodó álszarvasgomba"
    },
    "629": {
        "latin": "Elaphomyces persoonii",
        "hun": "kékbelű álszarvasgomba",
        "hun_syn": [
            "Persoon álszarvas-gombája"
        ]
    },
    "630": {
        "latin": "Elaphomyces pyriformis",
        "hun": "körtealakú álszarvasgomba"
    },
    "631": {
        "latin": "Elaphomyces septatus",
        "hun": "osztott álszarvasgomba"
    },
    "632": {
        "latin": "Elaphomyces virgatosporus",
        "hun": "csíkosspórájú álszarvasgomba"
    },
    "633": {
        "latin": "Elmerina caryae",
        "latin_syn": [
            "Aporpium canescens",
            "Proto-merulius caryae"
        ],
        "hun": "szürke kéreggomba"
    },
    "634": {
        "latin": "Emmia latemarginata",
        "latin_syn": [
            "Oxyporus latemargi-natus"
        ],
        "hun": "gumós kéregtapló"
    },
    "635": {
        "latin": "Encoelia furfuracea",
        "eng": "spring hazelcup",
        "hun": "közönséges mogyoró-csészegomba"
    },
    "636": {
        "latin": "Endogonef lammicorona",
        "hun": "lángkoronás borsógomba"
    },
    "637": {
        "latin": "Endogone lactiflua",
        "latin_syn": [
            "Endogone lanata",
            "Endogone rosea"
        ],
        "hun": "tejelő borsógomba",
        "hun_syn": [
            "tejelő földalattigomba"
        ]
    },
    "638": {
        "latin": "Endogone pisiformis",
        "hun": "gömbölyű borsógomba"
    },
    "639": {
        "latin": "Entoloma ameides",
        "hun": "illatos döggomba"
    },
    "640": {
        "latin": "Entoloma aprile",
        "eng": "april pinkgill",
        "hun": "áprilisi döggomba"
    },
    "641": {
        "latin": "Entoloma araneosum",
        "hun": "szöszös döggomba",
        "hun_syn": [
            "pókhálós döggomba"
        ]
    },
    "642": {
        "latin": "Entoloma bloxamii",
        "eng": "big blue pinkgill ",
        "hun": "ibolyakék döggomba"
    },
    "643": {
        "latin": "Entoloma byssisedum",
        "eng": "oysterling pinkgill",
        "hun": "suta döggomba",
        "hun_syn": [
            "sutagomba"
        ]
    },
    "644": {
        "latin": "Entoloma catalaunicum",
        "eng": "bluefoot pinkgill",
        "hun": "hússzínű pitykegomba",
        "hun_syn": [
            "ametiszt-pitykegomba"
        ]
    },
    "645": {
        "latin": "Entoloma cetratum",
        "eng": "honey pinkgill",
        "hun": "bordás kupakgomba",
        "hun_syn": [
            "okkerlemezű döggomba"
        ]
    },
    "646": {
        "latin": "Entoloma chalybeum",
        "latin_syn": [
            "Entoloma lazulinum"
        ],
        "hun": "kéklemezű pitykegomba",
        "hun_syn": [
            "feketeközepű döggomba",
            "kékes pitykegomba",
            "sötétkék döggomba"
        ]
    },
    "647": {
        "latin": "Entoloma clypeatum",
        "eng": "shield pinkgill",
        "hun": "tövisaljagomba",
        "hun_syn": [
            "tövisalja döggomba"
        ]
    },
    "648": {
        "latin": "Entoloma conferendum",
        "eng": "star pinkgill",
        "hun": "keresztspórás kupakgomba"
    },
    "649": {
        "latin": "Entoloma cuspidiferum",
        "hun": "mohakupakgomba",
        "hun_syn": [
            "moha döggomba"
        ]
    },
    "650": {
        "latin": "Entoloma dichroum",
        "eng": "bicoloured pinkgill",
        "hun": "kéktönkű pitykegomba"
    },
    "651": {
        "latin": "Entoloma dysthales",
        "hun": "fehérszőrű kupakgomba"
    },
    "652": {
        "latin": "Entoloma dysthaloides",
        "hun": "égeres kupakgomba"
    },
    "653": {
        "latin": "Entoloma euchroum",
        "eng": "stump pinkgill",
        "hun": "ibolyaszínű pitykegomba",
        "hun_syn": [
            "ibolyaszínű döggomba"
        ]
    },
    "654": {
        "latin": "Entoloma griseocyaneum",
        "eng": "felted pinkgill",
        "hun": "szürkéskék pitykegomba"
    },
    "655": {
        "latin": "Entoloma hebes",
        "eng": "pimple pinkgill",
        "hun": "karcsútönkű kupakgomba",
        "hun_syn": [
            "karcsútönkű döggomba"
        ]
    },
    "656": {
        "latin": "Entoloma hirtipes",
        "hun": "törékeny kupakgomba"
    },
    "657": {
        "latin": "Entoloma incanum",
        "eng": "mousepee pinkgill",
        "hun": "zöldes pitykegomba",
        "hun_syn": [
            "barnászöld döggomba"
        ]
    },
    "658": {
        "latin": "Entoloma infula",
        "hun": "rózsáslemezű kupakgomba",
        "hun_syn": [
            "rózsáslemezű döggomba"
        ]
    },
    "659": {
        "latin": "Entoloma jubatum",
        "eng": "sepia pinkgill",
        "hun": "kormoslemezű döggomba"
    },
    "660": {
        "latin": "Entoloma klofacianum",
        "hun": "szürkéslila döggomba"
    },
    "661": {
        "latin": "Entoloma lanicum",
        "hun": "nemezes köldökösgomba"
    },
    "662": {
        "latin": "Entoloma lividocyanulum",
        "hun": "szürkéskéktönkű pitykegomba",
        "hun_syn": [
            "szürkéskéktönkű döggomba"
        ]
    },
    "663": {
        "latin": "Entoloma longistriatum",
        "hun": "bordáskalapú kupakgomba",
        "hun_syn": [
            "bordáskalapú döggomba"
        ]
    },
    "664": {
        "latin": "Entoloma madidum",
        "eng": "brightsky pinkgill",
        "hun": "kék döggomba"
    },
    "665": {
        "latin": "Entoloma majaloides",
        "hun": "sárgásbarna döggomba"
    },
    "666": {
        "latin": "Entoloma melanochroum",
        "hun": "sötétszürke pitykegomba",
        "hun_syn": [
            "sötétszürke döggomba"
        ]
    },
    "667": {
        "latin": "Entoloma nidorosum",
        "hun": "lúgszagú döggomba"
    },
    "668": {
        "latin": "Entoloma nitidum",
        "eng": "pine pinkgill",
        "latin_syn": [
            "Entocybe nitida"
        ],
        "hun": "acélkék döggomba"
    },
    "669": {
        "latin": "Entoloma papillatum",
        "eng": "papillate pinkgill",
        "hun": "csíkoskalapú kupakgomba",
        "hun_syn": [
            "csíkoskalapú döggomba"
        ]
    },
    "670": {
        "latin": "Entoloma parkensis",
        "hun": "sötét köldökösgomba"
    },
    "671": {
        "latin": "Entoloma placidum",
        "hun": "bükkös pitykegomba",
        "hun_syn": [
            "bükkös döggomba"
        ]
    },
    "672": {
        "latin": "Entoloma plebejum",
        "latin_syn": [
            "Entoloma erophilum"
        ],
        "hun": "nemezes döggomba"
    },
    "673": {
        "latin": "Entoloma pleopodium",
        "eng": "aromatic pinkgill",
        "latin_syn": [
            "Entoloma icterinum"
        ],
        "hun": "citromsárga kupakgomba",
        "hun_syn": [
            "illatos döggomba"
        ]
    },
    "674": {
        "latin": "Entoloma poliopus",
        "hun": "simatönkű pitykegomba"
    },
    "675": {
        "latin": "Entoloma porphyrophaeum",
        "eng": "lilac pinkgill",
        "hun": "lilásbarna döggomba"
    },
    "676": {
        "latin": "Entoloma prunuloides",
        "eng": "mealy pinkgill",
        "hun": "lisztszagú döggomba"
    },
    "677": {
        "latin": "Entoloma pseudoturbidum",
        "hun": "változékony döggomba"
    },
    "678": {
        "latin": "Entoloma rhodopolium",
        "eng": "wood pinkgill",
        "hun": "zöldesszürke döggomba",
        "hun_syn": [
            "kis döggomba",
            ""
        ]
    },
    "679": {
        "latin": "Entoloma rusticoides",
        "hun": "barnalemezű köldökösgomba",
        "hun_syn": [
            "barnalemezű döggomba"
        ]
    },
    "680": {
        "latin": "Entoloma saepium",
        "hun": "halványbarna döggomba",
        "hun_syn": [
            "rózsa döggomba"
        ]
    },
    "681": {
        "latin": "Entoloma saundersii",
        "hun": "ezüstszürke döggomba"
    },
    "682": {
        "latin": "Entoloma scabrosum",
        "hun": "szürkéslemezű pitykegomba",
        "hun_syn": [
            "szürkéslemezű döggomba"
        ]
    },
    "683": {
        "latin": "Entoloma sericellum",
        "eng": "cream pinkgill",
        "hun": "fehér pitykegomba"
    },
    "684": {
        "latin": "Entoloma sericeum",
        "eng": "silky pinkgill",
        "hun": "selymes döggomba",
        "hun_syn": [
            "fényeskalapú döggomba"
        ]
    },
    "685": {
        "latin": "Entoloma serrulatum",
        "eng": "blue edge pinkgill",
        "hun": "feketéskék pitykegomba",
        "hun_syn": [
            "feketéskék döggomba"
        ]
    },
    "686": {
        "latin": "Entoloma sinuatum",
        "eng": "livid pinkgill",
        "latin_syn": [
            "Entoloma eulividum"
        ],
        "hun": "nagy döggomba"
    },
    "687": {
        "latin": "Entoloma sodale",
        "hun": "pikkelyes pitykegomba",
        "hun_syn": [
            "társas döggomba"
        ]
    },
    "688": {
        "latin": "Entoloma sordidulum",
        "hun": "piszkos döggomba",
        "hun_syn": [
            "szaruszürke döggomba"
        ]
    },
    "689": {
        "latin": "Entoloma tjallingiorum",
        "eng": "hairy pinkgill",
        "hun": "ibolyástönkű pitykegomba",
        "hun_syn": [
            "ibolyástönkű döggomba"
        ]
    },
    "690": {
        "latin": "Entoloma turbidum",
        "eng": "yellowfoot pinkgill",
        "latin_syn": [
            "Entocybe turbida"
        ],
        "hun": "lápi döggomba",
        "hun_syn": [
            "épszélű döggomba"
        ]
    },
    "691": {
        "latin": "Entoloma undatum",
        "eng": "zoned pinkgill",
        "hun": "sötétlemezű köldökösgomba",
        "hun_syn": [
            "sötétköldökös döggomba"
        ]
    },
    "692": {
        "latin": "Entoloma venosum",
        "hun": "ereslemezű döggomba",
        "hun_syn": [
            "eres döggomba"
        ]
    },
    "693": {
        "latin": "Entoloma vernum",
        "latin_syn": [
            "Rhodophyllus cucullatus"
        ],
        "hun": "tavasz  i döggomba"
    },
    "694": {
        "latin": "Entoloma versatile",
        "hun": "csillámló döggomba"
    },
    "695": {
        "latin": "Exidia albida",
        "hun": "fehéres mirigygomba"
    },
    "696": {
        "latin": "Exidia glandulosa",
        "eng": "witches' butter",
        "latin_syn": [
            "Exidia truncata"
        ],
        "hun": "fekete mirigygomba"
    },
    "697": {
        "latin": "Exidia nigricans",
        "eng": "warlock's butter",
        "latin_syn": [
            "Exidia glandulosa s. auct.",
            "Exidia plana"
        ],
        "hun": "kormos mirigygomba"
    },
    "698": {
        "latin": "Exidia nucleata",
        "eng": "crystal brain",
        "hun": "opálszínű mirigygomba"
    },
    "699": {
        "latin": "Exidia pithya",
        "hun": "fenyő-mirigygomba"
    },
    "700": {
        "latin": "Exidia recisa",
        "eng": "amber jelly",
        "hun": "nyeles mirigygomba"
    },
    "701": {
        "latin": "Exidia repanda",
        "eng": "birch jelly",
        "hun": "karéjos mirigygomba"
    },
    "702": {
        "latin": "Exidia saccharina",
        "eng": "pine jelly",
        "hun": "sárgás mirigygomba"
    },
    "703": {
        "latin": "Exidia umbrinella",
        "hun": "barna mirigygomba"
    },
    "704": {
        "latin": "Exidiopsis calcea",
        "latin_syn": [
            "Corticium calceum"
        ],
        "hun": "csupasz vargomba"
    },
    "705": {
        "latin": "Faerberia carbonaria",
        "eng": "firesite funnel",
        "latin_syn": [
            "Geopetalum carbonarium"
        ],
        "hun": "sötét szénlaska",
        "hun_syn": [
            "szenes rókagomba"
        ]
    },
    "706": {
        "latin": "Femsjonia peziziformis",
        "latin_syn": [
            "Ditiola peziziformis",
            "Femsjonia luteoalba"
        ],
        "hun": "Fenyves álcsészegomba"
    },
    "707": {
        "latin": "Fibricium rude",
        "hun": "gyapjas terülőgomba"
    },
    "708": {
        "latin": "Fibroporia vaillantii",
        "latin_syn": [
            "Antrodia vaillantii"
        ],
        "hun": "pincekéreggomba"
    },
    "709": {
        "latin": "Fischerula macrospora",
        "hun": "érdesspórájú szarvasgomba"
    },
    "710": {
        "latin": "Fistulina hepatica",
        "eng": "beefsteak fungus",
        "hun": "májgomba",
        "hun_syn": [
            "ökörnyelvgomba"
        ]
    },
    "711": {
        "latin": "Flammula alnicola",
        "latin_syn": [
            "Pholiota alnicola"
        ],
        "hun": "sárga tőkegomba"
    },
    "712": {
        "latin": "Flammulaster carpophilus",
        "eng": "leaf spark",
        "latin_syn": [
            "Naucoria carpophila",
            "Phaeomarasmius carpophilus"
        ],
        "hun": "kupacslakó lánggombácska",
        "hun_syn": [
            "bükki lánggombácska"
        ]
    },
    "713": {
        "latin": "Flammulaster granulosus",
        "latin_syn": [
            "Naucoria granulosa",
            "Phaeomarasmius granulosus<"
        ],
        "hun": "szemcsés lánggombácska"
    },
    "714": {
        "latin": "Flammulaster limulatus",
        "hun": "sárgalemezű lánggombácska"
    },
    "715": {
        "latin": "Flammulaster muricatus",
        "hun": "pikkelykés lánggombácska",
        "hun_syn": [
            "lombos pelyhestőkegomba"
        ]
    },
    "716": {
        "latin": "Flammulina fennae",
        "hun": "fehérlemezű fülőke"
    },
    "717": {
        "latin": "Flammulina ononidis",
        "hun": "iglice-fülőke",
        "hun_syn": [
            "mezei télifülőke"
        ]
    },
    "718": {
        "latin": "Flammulina velutipes",
        "eng": "velvet shank",
        "hun": "téli fülőke",
        "hun_syn": [
            "akác-fülőke"
        ]
    },
    "719": {
        "latin": "Flaviporus brownii",
        "hun": "rozsdasárga likacsosgomba"
    },
    "720": {
        "latin": "Flavoscypha cantharella",
        "latin_syn": [
            "Otidea cantharella",
            "Otidea concinna"
        ],
        "hun": "citromsárga fülesgomba",
        "hun_syn": [
            "cakkos fülesgomba",
            "egyszínű fülesgomba",
            "fogazott fülesgomba"
        ]
    },
    "721": {
        "latin": "Floccularia luteovirens",
        "eng": "yellow bracelet",
        "latin_syn": [
            "Floccularia straminea"
        ],
        "hun": "réti pikkelyespereszke",
        "hun_syn": [
            "sárgászöld pikkelyespereszke"
        ]
    },
    "722": {
        "latin": "Fomes fomentarius",
        "eng": "hoof fungus / tinder bracket",
        "hun": "bükkfa-tapló",
        "hun_syn": [
            "bükk-tapló",
            "bükkfatapló",
            "valódi tüzitapló"
        ]
    },
    "723": {
        "latin": "Fomitiporia hartigii",
        "latin_syn": [
            "Phellinus hartigii"
        ],
        "hun": "jegenyefenyő-tapló"
    },
    "724": {
        "latin": "Fomitiporia hippophaëicola",
        "latin_syn": [
            "Phellinus phaëicola"
        ],
        "hun": "homoktövis-tapló"
    },
    "725": {
        "latin": "Fomitiporia punctata",
        "eng": "elbowpatch crust",
        "latin_syn": [
            "Phellinus punctatus"
        ],
        "hun": "domború fekvőtapló"
    },
    "726": {
        "latin": "Fomitiporia robusta",
        "latin_syn": [
            "Phellinus robustus"
        ],
        "hun": "vastag tapló",
        "hun_syn": [
            "vastagtapló"
        ]
    },
    "727": {
        "latin": "Fomitopsis betulina",
        "eng": "birch polypore / razorstrop fungus",
        "latin_syn": [
            "Piptoporus betulinus"
        ],
        "hun": "nyírfa-tapló",
        "hun_syn": [
            "nyírfa-kérgestapló"
        ]
    },
    "728": {
        "latin": "Fomitopsis pinicola",
        "eng": "red-belted bracket",
        "hun": "szegett tapló"
    },
    "729": {
        "latin": "Funalia gallica",
        "latin_syn": [
            "Coriolopsis gallica",
            "Trametes extenuata"
        ],
        "hun": "barna egyrétűtapló",
        "hun_syn": [
            "barna borostástapló"
        ]
    },
    "730": {
        "latin": "Funalia trogii",
        "latin_syn": [
            "Coriolopsis trogii"
        ],
        "hun": "fehér egyrétűtapló"
    },
    "731": {
        "latin": "Funneliformis caledonium",
        "latin_syn": [
            "Glomus caledonium"
        ],
        "hun": "kaledóniai gomolygomba"
    },
    "732": {
        "latin": "Fuscoporia contigua",
        "latin_syn": [
            "Phellinus contiguus"
        ],
        "hun": "kemény fekvőtapló"
    },
    "733": {
        "latin": "Fuscoporia ferrea",
        "eng": "cinnamon porecrust",
        "latin_syn": [
            "Phellinus ferreus"
        ],
        "hun": "rozsdás fekvőtapló"
    },
    "734": {
        "latin": "Fuscoporia ferruginosa",
        "eng": "rusty porecrust",
        "latin_syn": [
            "Phellinus ferruginosus"
        ],
        "hun": "vastag fekvőtapló"
    },
    "735": {
        "latin": "Fuscoporia gilva",
        "latin_syn": [
            "Phellinus gilvus"
        ],
        "hun": "rozsdabarna tapló"
    },
    "736": {
        "latin": "Fuscoporia torulosa",
        "eng": "tufted bracket",
        "latin_syn": [
            "Phellinus torulosus"
        ],
        "hun": "vörös tapló",
        "hun_syn": [
            "vörös fekvőtapló"
        ]
    },
    "737": {
        "latin": "Fuscoporia viticola",
        "latin_syn": [
            "Fomes tenuis",
            "Phellinus viticola",
            "Trametes isabellina"
        ],
        "hun": "barna fekvőtapló",
        "hun_syn": [
            "vékony fekvőtapló"
        ]
    },
    "738": {
        "latin": "Galerina atkinsoniana",
        "eng": "hairy bell",
        "hun": "retekszagú sisakgomba"
    },
    "739": {
        "latin": "Galerina badipes",
        "hun": "barnatönkű sisakgomba"
    },
    "740": {
        "latin": "Galerina graminea",
        "eng": "turf bell",
        "latin_syn": [
            "Galerina laevis"
        ],
        "hun": "gyepi sisakgomba",
        "hun_syn": [
            "füves turjángomba"
        ]
    },
    "741": {
        "latin": "Galerina griseipes",
        "hun": "szürkéstönkű sisakgomba",
        "hun_syn": [
            "szürkéstönkű turjángomba"
        ]
    },
    "742": {
        "latin": "Galerina heterocystis",
        "latin_syn": [
            "Galerina clavata"
        ],
        "hun": "nagyspórás sisakgomba"
    },
    "743": {
        "latin": "Galerina hypnorum",
        "eng": "moss bell",
        "hun": "mohalakó sisakgomba",
        "hun_syn": [
            "mohalakó rozsdásgomba"
        ]
    },
    "744": {
        "latin": "Galerina marginata",
        "eng": "funeral bell",
        "latin_syn": [
            "Galerina hygrophila  "
        ],
        "hun": "fenyves sisakgomba",
        "hun_syn": [
            "fenyő-tőkegomba",
            "fenyves turjángomba"
        ]
    },
    "745": {
        "latin": "Galerina paludosa",
        "eng": "bog bell",
        "hun": "lápi sisakgomba",
        "hun_syn": [
            "lápi turjángomba"
        ]
    },
    "746": {
        "latin": "Galerina pumila",
        "eng": "dwarf bell",
        "latin_syn": [
            "Galerina mycenopsis"
        ],
        "hun": "szálastönkű sisakgomba"
    },
    "747": {
        "latin": "Galerina sideroides",
        "latin_syn": [
            "Galerina stylifera"
        ],
        "hun": "nyálkás sisakgomba",
        "hun_syn": [
            "nyálkás turjángomba"
        ]
    },
    "748": {
        "latin": "Galerina sphagnorum",
        "latin_syn": [
            "Galera sphagnorum"
        ],
        "hun": "tőzeges sisakgomba",
        "hun_syn": [
            "tőzeges turjángomba,  turjángomba"
        ]
    },
    "749": {
        "latin": "Galerina tibiicystis",
        "hun": "tőzegmoha-sisakgomba"
    },
    "750": {
        "latin": "Galerina triscopa",
        "hun": "hegyes sisakgomba",
        "hun_syn": [
            "hegyes szemétgomba"
        ]
    },
    "751": {
        "latin": "Galeropsis desertorum",
        "hun": "pusztai hegyesgomba"
    },
    "752": {
        "latin": "Galzinia pedicellata",
        "hun": "fenyő-kéreghántógomba"
    },
    "753": {
        "latin": "Ganoderma adspersum",
        "latin_syn": [
            "Ganoderma europaeum"
        ],
        "hun": "vastagkérgű tapló"
    },
    "754": {
        "latin": "Ganoderma applanatum",
        "eng": "artist's bracket",
        "latin_syn": [
            "Ganoderma lipsiense"
        ],
        "hun": "deres tapló",
        "hun_syn": [
            "derestapló"
        ]
    },
    "755": {
        "latin": "Ganoderma carnosum",
        "latin_syn": [
            "Ganoderma atkinsonii"
        ],
        "hun": "sötét lakkostapló"
    },
    "756": {
        "latin": "Ganoderma cupreolaccatum",
        "latin_syn": [
            "Ganoderma pfeifferi"
        ],
        "hun": "rézvörös lakkostapló",
        "hun_syn": [
            "rézvörös lakkos tapló"
        ]
    },
    "757": {
        "latin": "Ganoderma lucidum",
        "eng": "lacquered bracket",
        "hun": "pecsétviaszgomba",
        "hun_syn": [
            "lakkostapló"
        ]
    },
    "758": {
        "latin": "Ganoderma resinaceum",
        "hun": "óriás lakkostapló"
    },
    "759": {
        "latin": "Gastrosporium simplex",
        "hun": "árvalányhaj-pöfeteg",
        "hun_syn": [
            "földalatti pöfeteg",
            ""
        ]
    },
    "760": {
        "latin": "Gautieria graveolens",
        "hun": "bűzlő csupaszpöfeteg",
        "hun_syn": [
            "hagymaszagú álpöfeteg"
        ]
    },
    "761": {
        "latin": "Gautieria mexicana",
        "hun": "mexikói csupaszpöfeteg"
    },
    "762": {
        "latin": "Gautieria morchelliformis",
        "hun": "kucsmás csupaszpöfeteg",
        "hun_syn": [
            "hagymaszagú álpöfeteg",
            "papsapka csupasz-álpöfeteg",
            "papsapka-álpöfeteg"
        ]
    },
    "763": {
        "latin": "Gautieria otthii",
        "hun": "rongyos csupaszpöfeteg",
        "hun_syn": [
            "nehézszagú csupasz-álpöfeteg"
        ]
    },
    "764": {
        "latin": "Gautieria pallida",
        "hun": "amerikai csupaszpöfeteg",
        "hun_syn": [
            "amerikai álpöfeteg"
        ]
    },
    "765": {
        "latin": "Gautieria trabutii",
        "hun": "hólyagos csupaszpöfeteg",
        "hun_syn": [
            "rendellenes álpöfeteg"
        ]
    },
    "766": {
        "latin": "Geastrum berkeleyi",
        "eng": "Berkeley's earthstar",
        "hun": "szemcsés csillaggomba"
    },
    "767": {
        "latin": "Geastrum campestre",
        "eng": "field earthstar",
        "hun": "mezei csillaggomba",
        "hun_syn": [
            "szemcsés csillaggomba"
        ]
    },
    "768": {
        "latin": "Geastrum corollinum",
        "eng": "weathered earthstar",
        "hun": "rideg csillaggomba"
    },
    "769": {
        "latin": "Geastrum coronatum",
        "eng": "crowned earthstar",
        "latin_syn": [
            "Geastrum limbatum"
        ],
        "hun": "sötét csillaggomba",
        "hun_syn": [
            "koronás csillaggomba"
        ]
    },
    "770": {
        "latin": "Geastrum elegans",
        "eng": "elegant earthstar",
        "latin_syn": [
            "Geastrum badium"
        ],
        "hun": "elegáns csillaggomba",
        "hun_syn": [
            "kehely csillaggomba"
        ]
    },
    "771": {
        "latin": "Geastrum fimbriatum",
        "eng": "sessile earthstar",
        "latin_syn": [
            "Geastrum sessile"
        ],
        "hun": "közönséges csillaggomba",
        "hun_syn": [
            "erdei csillaggomba",
            "gyakori csillaggomba",
            "molyhos csillaggomba"
        ]
    },
    "772": {
        "latin": "Geastrum floriforme",
        "hun": "molyhos csillaggomba"
    },
    "773": {
        "latin": "Geastrum fornicatum",
        "eng": "arched earthstar",
        "hun": "csészés csillaggomba"
    },
    "774": {
        "latin": "Geastrum hungaricum",
        "hun": "honi csillaggomba",
        "hun_syn": [
            "magyar csillaggomba"
        ]
    },
    "775": {
        "latin": "Geastrum kotlabae",
        "hun": "pusztai csillaggomba",
        "hun_syn": [
            "érdes csillaggomba"
        ]
    },
    "776": {
        "latin": "Geastrum lageniforme",
        "eng": "flask earthstar",
        "hun": "hosszúkarú csillaggomba"
    },
    "777": {
        "latin": "Geastrum melanocephalum",
        "latin_syn": [
            "Trichaster melanocephalus"
        ],
        "hun": "poros csillaggomba",
        "hun_syn": [
            "álcsillaggomba"
        ]
    },
    "778": {
        "latin": "Geastrum minimum",
        "hun": "apró csillaggomba"
    },
    "779": {
        "latin": "Geastrum pectinatum",
        "hun": "fésűs csillaggomba"
    },
    "780": {
        "latin": "Geastrum pseudolimbatum",
        "hun": "homoki csillaggomba"
    },
    "781": {
        "latin": "Geastrum pseudostriatum",
        "hun": "rücskös csillaggomba"
    },
    "782": {
        "latin": "Geastrum quadrifidum",
        "eng": "rayed earthstar",
        "hun": "fészkes csillaggomba",
        "hun_syn": [
            "koronás csillaggomba"
        ]
    },
    "783": {
        "latin": "Geastrum radicans",
        "hun": "gyökeres csillaggomba"
    },
    "784": {
        "latin": "Geastrum rufescens",
        "eng": "rufous earthstar",
        "latin_syn": [
            "Geastrum vulgatum"
        ],
        "hun": "rőt csillaggomba"
    },
    "785": {
        "latin": "Geastrum saccatum",
        "hun": "zsákos csillaggomba",
        "hun_syn": [
            "zacskós csillaggomba"
        ]
    },
    "786": {
        "latin": "Geastrum schmidelii",
        "eng": "dwarf earthstar",
        "latin_syn": [
            "Geastrum nanum"
        ],
        "hun": "kicsiny csillaggomba"
    },
    "787": {
        "latin": "Geastrum striatum",
        "eng": "striate earthstar",
        "hun": "galléros csillaggomba",
        "hun_syn": [
            "fésűs csillaggomba",
            ""
        ]
    },
    "788": {
        "latin": "Geastrum triplex",
        "eng": "collared earthstar",
        "hun": "hármas csillaggomba"
    },
    "789": {
        "latin": "Geastrum xerophilum",
        "hun": "rövidkarú csillaggomba"
    },
    "790": {
        "latin": "Gemmina gemmarum",
        "latin_syn": [
            "Calycina gemmarum"
        ],
        "hun": "nyárfarügy-csészegombácska"
    },
    "791": {
        "latin": "Genabea cerebriformis",
        "latin_syn": [
            "Myrmecocystis cerebriformis"
        ],
        "hun": "szedres kormostrifla",
        "hun_syn": [
            "szeder-szarvasgomba"
        ]
    },
    "792": {
        "latin": "Genabea fragilis",
        "latin_syn": [
            "Genea fragilis"
        ],
        "hun": "törékeny kormostrifla",
        "hun_syn": [
            "kormos szarvasgomba"
        ]
    },
    "793": {
        "latin": "Genabea sphaerospora",
        "latin_syn": [
            "Genea sphaerospora"
        ],
        "hun": "apró kormostrifla",
        "hun_syn": [
            "apró kormos-szarvasgomba",
            "gömbölyűspórás szarvasgomba"
        ]
    },
    "794": {
        "latin": "Genea compressa",
        "hun": "tömör likastrifla"
    },
    "795": {
        "latin": "Genea fragrans",
        "latin_syn": [
            "Genea klotzschii"
        ],
        "hun": "ráncos likastrifla",
        "hun_syn": [
            "ráncos szarvasgomba"
        ]
    },
    "796": {
        "latin": "Genea hispidula",
        "hun": "szőrös likastrifla",
        "hun_syn": [
            "szőrös szarvasgomba"
        ]
    },
    "797": {
        "latin": "Genea lespiaultii",
        "hun": "illatos likastrifla",
        "hun_syn": [
            "bundás likastrifla",
            "szemölcsös szarvasgomba"
        ]
    },
    "798": {
        "latin": "Genea pseudobalsleyi",
        "hun": "pikkelyes likastrifla"
    },
    "799": {
        "latin": "Genea pseudoverrucosa",
        "hun": "álzegzugos likastrifla"
    },
    "800": {
        "latin": "Genea pulchra",
        "hun": "csinos likastrifla",
        "hun_syn": [
            "csinos szarvasgomba"
        ]
    },
    "801": {
        "latin": "Genea sphaerica",
        "hun": "gömbölyű likastrifla",
        "hun_syn": [
            "gömbölyű szarvasgomba"
        ]
    },
    "802": {
        "latin": "Genea vagans",
        "hun": "orosz likastrifla",
        "hun_syn": [
            "orosz szarvasgomba"
        ]
    },
    "803": {
        "latin": "Genea verrucosa",
        "hun": "zegzugos likastrifla",
        "hun_syn": [
            "zegzugos szarvasgomba"
        ]
    },
    "804": {
        "latin": "Geoglossum atropurpureum",
        "eng": "dark-purple earthtongue",
        "latin_syn": [
            "Microglossum atropurpureum"
        ],
        "hun": "vöröslő nyelvgomba"
    },
    "805": {
        "latin": "Geoglossum cookeianum",
        "hun": "gyepi nyelvgomba",
        "hun_syn": [
            "nagy nyelvgomba"
        ]
    },
    "806": {
        "latin": "Geoglossum fallax",
        "eng": "deceptive earthtongue",
        "hun": "csalfa nyelvgomba"
    },
    "807": {
        "latin": "Geoglossum glabrum",
        "latin_syn": [
            "Geoglossum ophioglossoides"
        ],
        "hun": "nagy nyelvgomba"
    },
    "808": {
        "latin": "Geoglossum glutinosum",
        "hun": "ragacsos nyelvgomba"
    },
    "809": {
        "latin": "Geoglossum nigritum",
        "hun": "feketés nyelvgomba",
        "hun_syn": [
            "karcsú nyelvgomba"
        ]
    },
    "810": {
        "latin": "Geoglossum umbratile",
        "eng": "plain earthtongue",
        "hun": "karcsú nyelvgomba"
    },
    "811": {
        "latin": "Geopora arenicola",
        "latin_syn": [
            "Sepultaria arenicola"
        ],
        "hun": "parti földicsészegomba",
        "hun_syn": [
            "nagyspórás parti-csészegomba",
            "particsészegomba"
        ]
    },
    "812": {
        "latin": "Geopora arenosa",
        "eng": "mountain cup",
        "hun": "homoki földicsészegomba",
        "hun_syn": [
            "kisspórás parti-csészegomba"
        ]
    },
    "813": {
        "latin": "Geopora clausa",
        "latin_syn": [
            "Hydnocystis clausa"
        ],
        "hun": "homoki hólyagtrifla",
        "hun_syn": [
            "hólyagos szarvasgomba"
        ]
    },
    "814": {
        "latin": "Geopora cooperi",
        "latin_syn": [
            "Geopora schackii"
        ],
        "hun": "rozsdás földicsészegomba",
        "hun_syn": [
            "rozsdás agykorallgomba",
            "Schack szarvasgombája"
        ]
    },
    "815": {
        "latin": "Geopora foliacea",
        "hun": "karéjos földicsészegomba"
    },
    "816": {
        "latin": "Geopora sumneriana",
        "eng": "cedar cup",
        "hun": "cédrus-földicsészegomba"
    },
    "817": {
        "latin": "Geopora tenuis",
        "hun": "fövenyes földicsészegomba"
    },
    "818": {
        "latin": "Geopyxis carbonaria",
        "eng": "stalked bonfire cup",
        "hun": "szenes kehelygomba",
        "hun_syn": [
            "szenes kehelycsészegomba"
        ]
    },
    "819": {
        "latin": "Gerhardtia incarnatobrunnea",
        "latin_syn": [
            "Lyophyllum incarnatobrunneum",
            "Lyophyllum serius"
        ],
        "hun": "húsbarnás álpereszke"
    },
    "820": {
        "latin": "Gliophorus irrigatus",
        "eng": "slimy waxcap",
        "latin_syn": [
            "Gliophorus unguinosa",
            "Hygrocybe irrigata",
            "Hygrocybe unguinosa"
        ],
        "hun": "szürke nedűgomba"
    },
    "821": {
        "latin": "Gliophorus laetus",
        "eng": "heath waxcap",
        "latin_syn": [
            "Hygrocybe laeta"
        ],
        "hun": "nyálkás nedűgomba",
        "hun_syn": [
            "ragacsos nedűgomba"
        ]
    },
    "822": {
        "latin": "Gliophorus perplexus",
        "latin_syn": [
            "Hygrocybe perplexa",
            "Hygrocybe psittacina var. perplexa"
        ],
        "hun": "hagymavörös nedűgomba"
    },
    "823": {
        "latin": "Gliophorus psittacinus",
        "eng": "parrot waxcap",
        "latin_syn": [
            "Hygrocybe psittacina"
        ],
        "hun": "zöldes nedűgomba",
        "hun_syn": [
            "zöldessárga nedűgomba"
        ]
    },
    "824": {
        "latin": "Gloeocystidiellum leucoxanthum",
        "latin_syn": [
            "Megalocystidium leucoxanthum"
        ],
        "hun": "repedező vargomba"
    },
    "825": {
        "latin": "Gloeophyllum abietinum",
        "hun": "fenyő-lemezestapló"
    },
    "826": {
        "latin": "Gloeophyllum sepiarium",
        "eng": "conifer mazegill",
        "hun": "cifra lemezestapló"
    },
    "827": {
        "latin": "Gloeophyllum trabeum",
        "eng": "timber mazegill",
        "hun": "változékony lemezestapló"
    },
    "828": {
        "latin": "Gloeoporus dichrous",
        "eng": "bicoloured bracket",
        "hun": "kétszínű likacsosgomba"
    },
    "829": {
        "latin": "Gloeoporus taxicola",
        "latin_syn": [
            "Caloporus taxicola",
            "Meruliopsis taxicola"
        ],
        "hun": "vörös kéreggomba",
        "hun_syn": [
            "borvörös redősgomba"
        ]
    },
    "830": {
        "latin": "Gloioxanthomyces vitellinus",
        "eng": "glistening waxcap",
        "latin_syn": [
            "Hygrocybe vitellina"
        ],
        "hun": "citromsárga nedűgomba"
    },
    "831": {
        "latin": "Glomus flavisporum",
        "latin_syn": [
            "Endogone flavispora"
        ],
        "hun": "sárgaspórás gomolygomba",
        "hun_syn": [
            "sárgaspórás földalattigomba",
            "sárgaspórás páncélos-tömlősgomba",
            "sárgaspórás páncéltömlősgomba"
        ]
    },
    "832": {
        "latin": "Glomus macrocarpum",
        "latin_syn": [
            "Endogone macrocarpa"
        ],
        "hun": "molyhos gomolygomba",
        "hun_syn": [
            "molyhos földalattigomba"
        ]
    },
    "833": {
        "latin": "Glomus microcarpum",
        "latin_syn": [
            "Endogone microcarpa"
        ],
        "hun": "apró gomolygomba",
        "hun_syn": [
            "göbös földalattigomba"
        ]
    },
    "834": {
        "latin": "Gomphidius glutinosus",
        "eng": "slimy spike",
        "hun": "barna nyálkásgomba"
    },
    "835": {
        "latin": "Gomphidius maculatus",
        "eng": "larch spike",
        "latin_syn": [
            "Gomphidius gracilis"
        ],
        "hun": "foltos nyálkásgomba"
    },
    "836": {
        "latin": "Gomphidius roseus",
        "eng": "rosy spike",
        "hun": "rózsaszínű nyálkásgomba",
        "hun_syn": [
            "rózsapiros nyálkásgomba",
            "rózsás nyálkásgomba"
        ]
    },
    "837": {
        "latin": "Gomphus clavatus",
        "eng": "pig's ear",
        "latin_syn": [
            "Neurophyllum clavatum"
        ],
        "hun": "disznófülgomba",
        "hun_syn": [
            "lila rókagomba",
            "zápfoggomba"
        ]
    },
    "838": {
        "latin": "Granulobasidium vellereum",
        "latin_syn": [
            "Corticium vellereum"
        ],
        "hun": "szilfa-vargomba"
    },
    "839": {
        "latin": "Grifola frondosa",
        "eng": "hen of the woods",
        "hun": "ágas tapló",
        "hun_syn": [
            "bokrosgomba"
        ]
    },
    "840": {
        "latin": "Guepiniopsis buccina",
        "latin_syn": [
            "Guepinia peziza"
        ],
        "hun": "sárga enyvescsészegomba"
    },
    "841": {
        "latin": "Gymnoglossum connectens",
        "latin_syn": [
            "Dendrogaster connectus"
        ],
        "hun": "labirintusos álpöfeteg"
    },
    "842": {
        "latin": "Gymnomyces mistiformis",
        "latin_syn": [
            "Hydnangium mistiforme"
        ],
        "hun": "szicíliai álpöfeteg"
    },
    "843": {
        "latin": "Gymnopilus junonius",
        "eng": "spectacular rustgill",
        "latin_syn": [
            "Gymnopilus spectabilis"
        ],
        "hun": "aranysárga lánggomba",
        "hun_syn": [
            "aranysárga tőkegomba"
        ]
    },
    "844": {
        "latin": "Gymnopilus liquiritiae",
        "hun": "porostönkű lánggomba"
    },
    "845": {
        "latin": "Gymnopilus odini",
        "hun": "szenes lánggomba"
    },
    "846": {
        "latin": "Gymnopilus penetrans",
        "eng": "common rustgill",
        "latin_syn": [
            "Gymnopilus hybridus"
        ],
        "hun": "foltoslemezű lánggomba"
    },
    "847": {
        "latin": "Gymnopilus picreus",
        "hun": "narancsbarna lánggomba"
    },
    "848": {
        "latin": "Gymnopilus sapineus",
        "eng": "scaly rustgill",
        "hun": "bársonykalapú lánggomba",
        "hun_syn": [
            "jegenyefenyő lánggomba"
        ]
    },
    "849": {
        "latin": "Gymnopus androsaceus",
        "eng": "horsehair parachute",
        "latin_syn": [
            "Marasmius androsaceus"
        ],
        "hun": "lószőrfülőke",
        "hun_syn": [
            "lószőr-szegfűgomba",
            "lószőrszegfűgomba"
        ]
    },
    "850": {
        "latin": "Gymnopus aquosus",
        "eng": "watery toughshank",
        "hun": "rózsásmicéliumú fülőke"
    },
    "851": {
        "latin": "Gymnopus brassicolens",
        "eng": "cabbage parachute",
        "latin_syn": [
            "Micromphale brassicolens"
        ],
        "hun": "káposztaszagú fülőke",
        "hun_syn": [
            "avar szagosszegfűgomba",
            "káposztaszagú büdösszegfűgomba"
        ]
    },
    "852": {
        "latin": "Gymnopus confluens",
        "eng": "clustered toughshank",
        "latin_syn": [
            "Collybia confluens"
        ],
        "hun": "pelyhestönkű fülőke"
    },
    "853": {
        "latin": "Gymnopus dryophilus",
        "eng": "russet toughshank",
        "latin_syn": [
            "Collybia dryophila"
        ],
        "hun": "rozsdásszárú fülőke",
        "hun_syn": [
            "rozsdástönkű fülőke,  vékonytönkű fülőke"
        ]
    },
    "854": {
        "latin": "Gymnopus erythropus",
        "eng": "redleg toughshank",
        "latin_syn": [
            "Collybia erythropus",
            "Collybia marasmioides"
        ],
        "hun": "vöröstönkű fülőke"
    },
    "855": {
        "latin": "Gymnopus foetidus",
        "eng": "foetid parachute",
        "latin_syn": [
            "Marasmiellus foetidus",
            "Micromphale foetidum"
        ],
        "hun": "undorító fülőke",
        "hun_syn": [
            "undorító büdösszegfűgomba",
            "undorító szagosszegfűgomba",
            "undorító szegfűgomba"
        ]
    },
    "856": {
        "latin": "Gymnopus fusipes",
        "eng": "spindle toughshank",
        "latin_syn": [
            "Collybia fusipes"
        ],
        "hun": "árvégű fülőke"
    },
    "857": {
        "latin": "Gymnopus hariolorum",
        "latin_syn": [
            "Collybia hariolorum"
        ],
        "hun": "borzastönkű fülőke"
    },
    "858": {
        "latin": "Gymnopus impudicus",
        "latin_syn": [
            "Collybia impudica"
        ],
        "hun": "büdös fülőke"
    },
    "859": {
        "latin": "Gymnopus ocior",
        "eng": "spring toughshank",
        "hun": "sárgáslemezű fülőke"
    },
    "860": {
        "latin": "Gymnopus perforans",
        "eng": "stinking parachute",
        "latin_syn": [
            "Marasmiellus perforans"
        ],
        "hun": "bordásszélű fülőke",
        "hun_syn": [
            "fenyő szagosszegfűgomba",
            "fenyő-büdösszegfűgomba",
            "fenyő-szegfűgomba"
        ]
    },
    "861": {
        "latin": "Gymnopus peronatus",
        "eng": "wood woollyfoot",
        "latin_syn": [
            "Collybia peronata",
            "Marasmius urens"
        ],
        "hun": "gyapjaslábú fülőke"
    },
    "862": {
        "latin": "Gyrodon lividus",
        "eng": "alder bolete",
        "hun": "éger-tinóru",
        "hun_syn": [
            "égertinóru"
        ]
    },
    "863": {
        "latin": "Gyromitra esculenta",
        "eng": "false morel",
        "hun": "redős papsapkagomba",
        "hun_syn": [
            "közönséges papsapkagomba",
            "közönséges redőgomba"
        ]
    },
    "864": {
        "latin": "Gyromitra gigas",
        "eng": "giants false morel",
        "hun": "óriás papsapkagomba"
    },
    "865": {
        "latin": "Gyromitra infula",
        "eng": "pouched false morel",
        "hun": "püspöksüveggomba",
        "hun_syn": [
            "csúcsos papsapkagomba",
            "csúcsos redőgomba"
        ]
    },
    "866": {
        "latin": "Gyromitra leucoxantha",
        "eng": "fishtail cup",
        "latin_syn": [
            "Discina leucoxantha"
        ],
        "hun": "sárga papsapkagomba",
        "hun_syn": [
            "sárga koronggomba"
        ]
    },
    "867": {
        "latin": "Gyromitra parma",
        "latin_syn": [
            "Discina parma"
        ],
        "hun": "bükkös papsapkagomba"
    },
    "868": {
        "latin": "Gyroporus castaneus",
        "eng": "chestnut bolete",
        "hun": "gesztenyebarna üregestinóru",
        "hun_syn": [
            "gesztenye üregestinóru",
            "gesztenyebarna tinóru",
            "geszte-nyetinóru"
        ]
    },
    "869": {
        "latin": "Gyroporus cyanescens",
        "eng": "cornflower bolete",
        "hun": "kékesedő üregestinóru",
        "hun_syn": [
            "kékesedő tinóru"
        ]
    },
    "870": {
        "latin": "Haasiella venustissima",
        "eng": "orange navel",
        "hun": "késői álbékagomba"
    },
    "871": {
        "latin": "Hapalopilus nidulans",
        "eng": "cinnamon bracket",
        "latin_syn": [
            "Hapalopilus rutilans"
        ],
        "hun": "fahéjszínű likacsosgomba",
        "hun_syn": [
            "agyagsárga likacsosgomba",
            "domború likacsosgomba"
        ]
    },
    "872": {
        "latin": "Haploporus tuberculosus",
        "latin_syn": [
            "Pachykytospora tuberculosa"
        ],
        "hun": "rózsás kéregtapló"
    },
    "873": {
        "latin": "Hebeloma ammophilum",
        "hun": "homoki fakógomba"
    },
    "874": {
        "latin": "Hebeloma birrus",
        "latin_syn": [
            "Hebeloma anthracophilum s. auct."
        ],
        "hun": "szenes fakógomba"
    },
    "875": {
        "latin": "Hebeloma crustuliniforme",
        "eng": "poisonpie",
        "hun": "zsemleszínű fakógomba",
        "hun_syn": [
            "közönséges fakógomba"
        ]
    },
    "876": {
        "latin": "Hebeloma dunense",
        "latin_syn": [
            "Hebeloma collariatum",
            "Hebeloma subcaespitosum"
        ],
        "hun": "csoportos fakógomba",
        "hun_syn": [
            "rózsáslemezű fakógomba"
        ]
    },
    "877": {
        "latin": "Hebeloma eburneum",
        "latin_syn": [
            "Hebeloma ochroalbidum"
        ],
        "hun": "fehéres fakógomba"
    },
    "878": {
        "latin": "Hebeloma incarnatulum",
        "latin_syn": [
            "Hebeloma longicaudum"
        ],
        "hun": "hosszútönkű fakógomba"
    },
    "879": {
        "latin": "Hebeloma laterinum",
        "latin_syn": [
            "Hebeloma edurum",
            "Hebeloma senescens"
        ],
        "hun": "kakaószagú fakógomba"
    },
    "880": {
        "latin": "Hebeloma leucosarx",
        "eng": "pale poisonpie",
        "hun": "fehérhúsú fakógomba"
    },
    "881": {
        "latin": "Hebeloma mesophaeum",
        "eng": "veiled poisonpie",
        "hun": "sötétlábú fakógomba",
        "hun_syn": [
            "barnahúsú fakógomba"
        ]
    },
    "882": {
        "latin": "Hebeloma nauseosum",
        "latin_syn": [
            "Hebeloma fusipes"
        ],
        "hun": "illatos fakógomba"
    },
    "883": {
        "latin": "Hebeloma populinum",
        "hun": "nyárfa-fakógomba"
    },
    "884": {
        "latin": "Hebeloma porphyrosporum",
        "latin_syn": [
            "Hebeloma sarcophyllum s. auct."
        ],
        "hun": "csiperkelemezű fakógomba"
    },
    "885": {
        "latin": "Hebeloma pumilum",
        "hun": "törpe fakógomba"
    },
    "886": {
        "latin": "Hebeloma pusillum",
        "eng": "dwarf poisonpie",
        "hun": "apró fakógomba"
    },
    "887": {
        "latin": "Hebeloma radicosum",
        "eng": "rooting poisonpie",
        "hun": "gyökeres fakógomba"
    },
    "888": {
        "latin": "Hebeloma sacchariolens",
        "eng": "sweet poisonpie",
        "hun": "édesszagú fakógomba",
        "hun_syn": [
            "édesillatú fakógomba",
            "illatos fakógomba"
        ]
    },
    "889": {
        "latin": "Hebeloma sinapizans",
        "eng": "bitter poisonpie",
        "hun": "retekszagú fakógomba"
    },
    "890": {
        "latin": "Hebeloma sordidum",
        "hun": "sápadt fakógomba"
    },
    "891": {
        "latin": "Hebeloma theobrominum",
        "hun": "kakaószínű fakógomba"
    },
    "892": {
        "latin": "Hebeloma vaccinum",
        "eng": "willow poisonpie",
        "hun": "tehénbarna fakógomba"
    },
    "893": {
        "latin": "Hebeloma velutipes",
        "hun": "pelyhestönkű fakógomba"
    },
    "894": {
        "latin": "Helvella acetabulum",
        "eng": "vinegar cup",
        "latin_syn": [
            "Paxina acetabulum  "
        ],
        "hun": "bordás serleggomba",
        "hun_syn": [
            "földalatti serleg gomba",
            "nyeles serleggomba",
            "serleges papsapkagomba"
        ]
    },
    "895": {
        "latin": "Helvella atra",
        "latin_syn": [
            "Leptopodia atra"
        ],
        "hun": "fekete papsapkagomba"
    },
    "896": {
        "latin": "Helvella chinensis",
        "hun": "apró papsapkagomba"
    },
    "897": {
        "latin": "Helvella corium",
        "latin_syn": [
            "Cyathipodia corium"
        ],
        "hun": "feketéllő papsapkagomba",
        "hun_syn": [
            "bőrös papsapkagomba",
            "feketéllő serleggomba",
            "sötét papsapkagomba"
        ]
    },
    "898": {
        "latin": "Helvella costifera",
        "latin_syn": [
            "Paxina costifera"
        ],
        "hun": "bordásnyelű papsapkagomba",
        "hun_syn": [
            "bordásnyelű serleggomba"
        ]
    },
    "899": {
        "latin": "Helvella crispa",
        "eng": "white saddle",
        "hun": "fodros papsapkagomba"
    },
    "900": {
        "latin": "Helvella dissingii",
        "latin_syn": [
            "Cyathipodia villosa",
            "Helvella villosa"
        ],
        "hun": "szöszös papsapkagomba"
    },
    "901": {
        "latin": "Helvella elastica",
        "eng": "elastic saddle",
        "latin_syn": [
            "Leptopodia elastica"
        ],
        "hun": "karcsú papsapkagomba",
        "hun_syn": [
            "fodros papsapkagomba"
        ]
    },
    "902": {
        "latin": "Helvella ephippium",
        "latin_syn": [
            "Leptopodia ephippium"
        ],
        "hun": "bársonyos papsapkagomba"
    },
    "903": {
        "latin": "Helvella fusca",
        "hun": "sujtásos papsapkagomba"
    },
    "904": {
        "latin": "Helvella lacunosa",
        "eng": "elfin saddle",
        "latin_syn": [
            "Helvella sulcata"
        ],
        "hun": "szürke papsapkagomba"
    },
    "905": {
        "latin": "Helvella leucomelaena",
        "eng": "sooty cup",
        "latin_syn": [
            "Paxina leucomelaena"
        ],
        "hun": "sima papsapkagomba",
        "hun_syn": [
            "sima serleggomba"
        ]
    },
    "906": {
        "latin": "Helvella macropus",
        "eng": "felt saddle",
        "latin_syn": [
            "Cyathipodia macropus"
        ],
        "hun": "nyeles papsapkagomba",
        "hun_syn": [
            "nyeles csészegomba",
            "vékonynyelű csészegomba",
            "vékonynyelű papsapkagomba"
        ]
    },
    "907": {
        "latin": "Helvella monachella",
        "latin_syn": [
            "Helvella albipes",
            "Helvella leucopus",
            "Helvella spadicea",
            "Leptopodia monachella"
        ],
        "hun": "homoki papsapkagomba",
        "hun_syn": [
            "nyúlfülegomba"
        ]
    },
    "908": {
        "latin": "Helvella pezizoides",
        "latin_syn": [
            "Leptopodia pezizoides"
        ],
        "hun": "csészés papsapkagomba",
        "hun_syn": [
            "csésze-papsapkagomba"
        ]
    },
    "909": {
        "latin": "Helvella solitaria",
        "hun": "kormos papsapkagomba"
    },
    "910": {
        "latin": "Hemileccinum depilatum",
        "latin_syn": [
            "Boletus depilatus",
            "Xerocomus depilatus"
        ],
        "hun": "ragyás tinóru"
    },
    "911": {
        "latin": "Hemileccinum impolitum",
        "latin_syn": [
            "Boletus impolitus",
            "Xerocomus impolitus"
        ],
        "hun": "okkerszínű tinóru",
        "hun_syn": [
            "okkerszínű vargánya"
        ]
    },
    "912": {
        "latin": "Hemimycena cucullata",
        "hun": "gipszfehér-álkígyógomba",
        "hun_syn": [
            "gipszfehér kígyógomba"
        ]
    },
    "913": {
        "latin": "Hemimycena gracilis",
        "hun": "gyérlemezű kígyógomba"
    },
    "914": {
        "latin": "Hemimycena lactea",
        "eng": "milky bonnet",
        "hun": "tejfehér álkígyógomba",
        "hun_syn": [
            "tejfehér kígyógomba"
        ]
    },
    "915": {
        "latin": "Hemimycena mairei",
        "eng": "fanvault bonnet",
        "hun": "pusztai álkígyógomba"
    },
    "916": {
        "latin": "Hemipholiota populnea",
        "latin_syn": [
            "Pholiota destruens",
            "Pholiota populnea"
        ],
        "hun": "nyárfa-tőkegomba"
    },
    "917": {
        "latin": "Hemistropharia albocrenulata",
        "latin_syn": [
            "Stropharia albocrenulata"
        ],
        "hun": "tüskéskalapú harmatgomba"
    },
    "918": {
        "latin": "Hericium alpestre",
        "latin_syn": [
            "Hericium flagellum"
        ],
        "hun": "jegenyefenyő-petrezselyemgomba"
    },
    "919": {
        "latin": "Hericium cirrhatum",
        "eng": "tiered tooth ",
        "latin_syn": [
            "Creolophus cirrhatus"
        ],
        "hun": "tüskés sörénygomba",
        "hun_syn": [
            "fogazott sörénygomba"
        ]
    },
    "920": {
        "latin": "Hericium coralloides",
        "eng": "coral tooth ",
        "latin_syn": [
            "Hericium clathroides",
            "Hericium ramosum"
        ],
        "hun": "közönséges petrezselyemgomba",
        "hun_syn": [
            "bükkös petrezselyemgomba"
        ]
    },
    "921": {
        "latin": "Hericium erinaceus",
        "eng": "bearded tooth ",
        "hun": "süngomba",
        "hun_syn": [
            "cérnagomba",
            "közönséges süngomba",
            "szakállas süngomba"
        ]
    },
    "922": {
        "latin": "Heterobasidion annosum",
        "eng": "root rot",
        "hun": "gyökérrontó tapló",
        "hun_syn": [
            "gyökértapló"
        ]
    },
    "923": {
        "latin": "Heterochaete spinulosa",
        "latin_syn": [
            "Eichleriella spinulosa"
        ],
        "hun": "tüskés terülőgomba"
    },
    "924": {
        "latin": "Hexagonia nitida",
        "latin_syn": [
            "Apoxon nitida"
        ],
        "hun": "fekete egyrétűtapló"
    },
    "925": {
        "latin": "Heyderia abietis",
        "latin_syn": [
            "Mitrula abietis"
        ],
        "hun": "fenyő-sapkásgomba"
    },
    "926": {
        "latin": "Heyderia cucullata",
        "latin_syn": [
            "Mitrula pusilla"
        ],
        "hun": "csuklyás sapkásgomba",
        "hun_syn": [
            "apró sapkásgomba"
        ]
    },
    "927": {
        "latin": "Hodophilus atropunctus",
        "latin_syn": [
            "Camarophyllopsis atropuncta"
        ],
        "hun": "pettyes békagomba"
    },
    "928": {
        "latin": "Hodophilus foetens",
        "eng": "stinking fanvault",
        "latin_syn": [
            "Camarophyllopsis foetens"
        ],
        "hun": "büdös békagomba",
        "hun_syn": [
            "büdös nyirokgomba"
        ]
    },
    "929": {
        "latin": "Hohenbuehelia atrocoerulea",
        "latin_syn": [
            "Pleurotus atrocoeruleus"
        ],
        "hun": "kocsonyás állaskagomba",
        "hun_syn": [
            "kékesszürke állaska"
        ]
    },
    "930": {
        "latin": "Hohenbuehelia auriscalpium",
        "eng": "spatula oyster",
        "latin_syn": [
            "Acanthocystis auriscalpium",
            "Pleurotus auriscalpium"
        ],
        "hun": "füles állaskagomba",
        "hun_syn": [
            "füles földilaska"
        ]
    },
    "931": {
        "latin": "Hohenbuehelia cyphelliformis",
        "latin_syn": [
            "Resupinatus silvanus"
        ],
        "hun": "apró állaskagomba",
        "hun_syn": [
            "kéreglaskagomba"
        ]
    },
    "932": {
        "latin": "Hohenbuehelia grisea",
        "hun": "szürke állaskagomba",
        "hun_syn": [
            "szürke állaska"
        ]
    },
    "933": {
        "latin": "Hohenbuehelia mastrucata",
        "eng": "woolly oyster",
        "hun": "bundás állaskagomba"
    },
    "934": {
        "latin": "Hohenbuehelia petaloides",
        "latin_syn": [
            "Hohenbuehelia geogenia"
        ],
        "hun": "földi állaskagomba",
        "hun_syn": [
            "lapátalakú földilaska"
        ]
    },
    "935": {
        "latin": "Hohenbuehelia repanda",
        "hun": "kiterülő állaskagomba"
    },
    "936": {
        "latin": "Hohenbuehelia tremula",
        "latin_syn": [
            "Hohenbuehelia rickenii"
        ],
        "hun": "rezgő állaskagomba",
        "hun_syn": [
            "fenyő-állaskagomba",
            "fenyő-laskagomba"
        ]
    },
    "937": {
        "latin": "Homophron spadiceum",
        "latin_syn": [
            "Psathyrella sarcocephala",
            "Psathyrella spadicea"
        ],
        "hun": "vaskos porhanyósgomba",
        "hun_syn": [
            "kakaóbarna porhanyósgomba",
            "targomba"
        ]
    },
    "938": {
        "latin": "Hortiboletus bubalinus",
        "latin_syn": [
            "Xerocomus bubalinus"
        ],
        "hun": "homoki tinóru",
        "hun_syn": [
            "nyárfa-nemezestinóru"
        ]
    },
    "939": {
        "latin": "Hortiboletus engelii",
        "latin_syn": [
            "Xerocomus communis"
        ],
        "hun": "útszéli tinóru",
        "hun_syn": [
            "vörösszálas nemezestinóru"
        ]
    },
    "940": {
        "latin": "Hortiboletus rubellus",
        "latin_syn": [
            "Xerocomus rubellus"
        ],
        "hun": "piros tinóru",
        "hun_syn": [
            "piros nemezestinóru"
        ]
    },
    "941": {
        "latin": "Humaria aurantia",
        "hun": "aranysárga sörtéscsészegomba"
    },
    "942": {
        "latin": "Humaria hemisphaerica",
        "eng": "glazed cup",
        "hun": "fehér sörtéscsészegomba",
        "hun_syn": [
            "fehér sörtés-csészegomba"
        ]
    },
    "943": {
        "latin": "Hyalorbilia inflatula",
        "hun": "áttetsző koronggombácska"
    },
    "944": {
        "latin": "Hydnangium carneum",
        "hun": "húsrózsás pénzecskepöfeteg",
        "hun_syn": [
            "hússzínű álpöfeteg"
        ]
    },
    "945": {
        "latin": "Hydnangium carnicum",
        "hun": "keményedő pénzecskepöfeteg",
        "hun_syn": [
            "keményedő álpöfeteg"
        ]
    },
    "946": {
        "latin": "Hydnangium hessei",
        "hun": "sötétedő pénzecskepöfeteg",
        "hun_syn": [
            "sötétedő álpöfeteg"
        ]
    },
    "947": {
        "latin": "Hydnangium lanigerum",
        "hun": "bundás pénzecskepöfeteg",
        "hun_syn": [
            "bundás álpöfeteg"
        ]
    },
    "948": {
        "latin": "Hydnellum aurantiacum",
        "eng": "orange tooth",
        "hun": "narancssárga fásgereben",
        "hun_syn": [
            "narancssárga gereben"
        ]
    },
    "949": {
        "latin": "Hydnellum caeruleum",
        "eng": "blue tooth",
        "hun": "kék fásgereben",
        "hun_syn": [
            "kék gereben",
            "kék tarkagereben"
        ]
    },
    "950": {
        "latin": "Hydnellum compactum",
        "hun": "vaskos fásgereben",
        "hun_syn": [
            "merev parázsgereben",
            ""
        ]
    },
    "951": {
        "latin": "Hydnellum concrescens",
        "eng": "zoned tooth",
        "latin_syn": [
            "Calodon zonatum",
            "Hydnellum zonatum"
        ],
        "hun": "sávos fásgereben",
        "hun_syn": [
            "sávos gereben",
            "szalagos parázsgereben",
            "vaskos gereben"
        ]
    },
    "952": {
        "latin": "Hydnellum cumulatum",
        "eng": "rosette tooth",
        "hun": "csoportos fásgereben",
        "hun_syn": [
            "csoportos gereben"
        ]
    },
    "953": {
        "latin": "Hydnellum ferrugineum",
        "eng": "mealy tooth",
        "latin_syn": [
            "Calodon ferrugineum"
        ],
        "hun": "izzadó fásgereben",
        "hun_syn": [
            "izzadó gereben",
            "izzadó parázsgereben"
        ]
    },
    "954": {
        "latin": "Hydnellum geogenium",
        "hun": "kénsárga fásgereben"
    },
    "955": {
        "latin": "Hydnellum peckii",
        "eng": "devil's tooth",
        "hun": "csípős fásgereben",
        "hun_syn": [
            "csípős gereben"
        ]
    },
    "956": {
        "latin": "Hydnellum scrobiculatum",
        "eng": "ridged tooth",
        "hun": "gödörkés fásgereben",
        "hun_syn": [
            "csipkés gereben",
            "gödörkés gereben"
        ]
    },
    "957": {
        "latin": "Hydnellum spongiosipes",
        "eng": "velvet tooth",
        "hun": "bársonyos fásgereben",
        "hun_syn": [
            "bársonyos gereben",
            ""
        ]
    },
    "958": {
        "latin": "Hydnellum suaveolens",
        "hun": "ánizsszagú fásgereben",
        "hun_syn": [
            "ánizsszagú gereben"
        ]
    },
    "959": {
        "latin": "Hydnobolites cerebriformis",
        "hun": "dudoros agytrifla",
        "hun_syn": [
            "agyvelő-dudorgomba",
            ""
        ]
    },
    "960": {
        "latin": "Hydnocystis beccarii",
        "hun": "vesespórás hólyagtrifla",
        "hun_syn": [
            "vesespórás szarvasgomba"
        ]
    },
    "961": {
        "latin": "Hydnocystis piligera",
        "hun": "törpe hólyagtrifla",
        "hun_syn": [
            "törpe szarvasgomba"
        ]
    },
    "962": {
        "latin": "Hydnotrya michaelis",
        "eng": "Michael's fold truffle",
        "latin_syn": [
            "Geopora michaelis",
            "Gyrocratera ploettneriana",
            "Hydnotryopsis michaelis"
        ],
        "hun": "lebenyes gödröstrifla",
        "hun_syn": [
            "dió-szarvasgomba",
            "lebenyes szarvasgomba",
            "redős-szarvasgomba"
        ]
    },
    "963": {
        "latin": "Hydnotrya tulasnei",
        "eng": "red fold truffle",
        "latin_syn": [
            "Hydnobolites tulasnei",
            "Hydnotrya carnea"
        ],
        "hun": "vörös gödröstrifla",
        "hun_syn": [
            "gödrös szarvasgomba",
            "rózsás gödrös-szarvasgomba",
            "rózsás szarvasgomba",
            "vörösbarna szarvasgomba"
        ]
    },
    "964": {
        "latin": "Hydnotryopsis suevica",
        "latin_syn": [
            "Geoporella suevica"
        ],
        "hun": "labirintusos gödröskéstrifla",
        "hun_syn": [
            "labirintusos  szarvasgomba"
        ]
    },
    "965": {
        "latin": "Hydnum albidum",
        "hun": "fehér gereben"
    },
    "966": {
        "latin": "Hydnum repandum",
        "eng": "wood hedgehog",
        "hun": "sárga gereben"
    },
    "967": {
        "latin": "Hydnum rufescens",
        "eng": "terracotta hedgehog",
        "latin_syn": [
            "Hydnum repandum var. rufescens"
        ],
        "hun": "sárgásvörös gereben",
        "hun_syn": [
            "barnásvörös gerebengomba"
        ]
    },
    "968": {
        "latin": "Hydropus subalpinus",
        "hun": "bükki álfülőke"
    },
    "969": {
        "latin": "Hygrocybe acutoconica",
        "eng": "persistent waxcap",
        "latin_syn": [
            "Hygrophorus persistens",
            "Hygrophorus subglobispora"
        ],
        "hun": "sáfránysárga nedűgomba",
        "hun_syn": [
            "gömbölyűspórás nedűgomba"
        ]
    },
    "970": {
        "latin": "Hygrocybe calciphila",
        "eng": "limestone waxcap",
        "hun": "mészkedvelő nedűgomba"
    },
    "971": {
        "latin": "Hygrocybe cantharellus",
        "eng": "goblet waxcap",
        "hun": "tölcséres nedűgomba",
        "hun_syn": [
            "íveslemezű nyirokgomba"
        ]
    },
    "972": {
        "latin": "Hygrocybe ceracea",
        "eng": "butter waxcap",
        "hun": "viaszsárga nedűgomba"
    },
    "973": {
        "latin": "Hygrocybe chlorophana",
        "eng": "golden waxcap",
        "hun": "zöldessárga nedűgomba"
    },
    "974": {
        "latin": "Hygrocybe coccinea",
        "eng": "scarlet waxcap",
        "hun": "piros nedűgomba",
        "hun_syn": [
            "cseresznyepiros nedűgomba"
        ]
    },
    "975": {
        "latin": "Hygrocybe conica",
        "eng": "blackening waxcap",
        "hun": "feketedő nedűgomba",
        "hun_syn": [
            "kúpos nedűgomba"
        ]
    },
    "976": {
        "latin": "Hygrocybe conicoides",
        "eng": "dune waxcap",
        "hun": "pusztai nedűgomba"
    },
    "977": {
        "latin": "Hygrocybe glutinipes",
        "eng": "glutinous waxcap",
        "hun": "enyvestönkű nedűgomba"
    },
    "978": {
        "latin": "Hygrocybe marchii",
        "hun": "aranysárga nedűgomba "
    },
    "979": {
        "latin": "Hygrocybe miniata",
        "eng": "vermilion waxcap",
        "hun": "apró nedűgomba",
        "hun_syn": [
            "míniumvörös nedűgomba",
            ""
        ]
    },
    "980": {
        "latin": "Hygrocybe nigrescens",
        "hun": "színváltó nedűgomba"
    },
    "981": {
        "latin": "Hygrocybe obrussea",
        "hun": "sárga nedűgomba"
    },
    "982": {
        "latin": "Hygrocybe parvula",
        "hun": "törpe nedűgomba"
    },
    "983": {
        "latin": "Hygrocybe punicea",
        "eng": "crimson waxcap",
        "hun": "vérvörös nedűgomba",
        "hun_syn": [
            "vörös nedűgomba"
        ]
    },
    "984": {
        "latin": "Hygrocybe reidii",
        "eng": "honey waxcap",
        "hun": "mézszagú nedűgomba"
    },
    "985": {
        "latin": "Hygrocybe subpapillata",
        "eng": "papillate waxcap",
        "hun": "álcsúcsos nedűgomba"
    },
    "986": {
        "latin": "Hygrophoropsis aurantiaca",
        "eng": "false chanterelle",
        "hun": "narancsvörös álrókagomba",
        "hun_syn": [
            "narancssárga álrókagomba",
            "narancsszínű tölcsérgomba, narancsvörös tölcsérgomba"
        ]
    },
    "987": {
        "latin": "Hygrophorus abieticola",
        "latin_syn": [
            "Hygrophorus pudorinus s. auct."
        ],
        "hun": "rózsás csigagomba"
    },
    "988": {
        "latin": "Hygrophorus agathosmus",
        "eng": "almond woodwax",
        "latin_syn": [
            "Hygrophorus cerasinus"
        ],
        "hun": "szagos csigagomba",
        "hun_syn": [
            "mandulaillatú csigagomba"
        ]
    },
    "989": {
        "latin": "Hygrophorus arbustivus",
        "eng": "forest woodwax",
        "hun": "késői csigagomba"
    },
    "990": {
        "latin": "Hygrophorus atramentosus",
        "hun": "barnásszürke csigagomba"
    },
    "991": {
        "latin": "Hygrophorus camarophyllus",
        "eng": "arched woodwax",
        "hun": "kormos csigagomba"
    },
    "992": {
        "latin": "Hygrophorus capreolarius",
        "hun": "borvörös csigagomba"
    },
    "993": {
        "latin": "Hygrophorus chrysodon",
        "eng": "gold flecked woodwax",
        "hun": "sárgapelyhű csigagomba",
        "hun_syn": [
            "sárgapelyhes csigagomba"
        ]
    },
    "994": {
        "latin": "Hygrophorus cossus",
        "hun": "kőrisszagú csigagomba"
    },
    "995": {
        "latin": "Hygrophorus discoideus",
        "hun": "barnakorongos csigagomba"
    },
    "996": {
        "latin": "Hygrophorus discoxanthus",
        "eng": "yellowing woodwax",
        "latin_syn": [
            "Hygrophorus chrysaspis"
        ],
        "hun": "barnuló csigagomba"
    },
    "997": {
        "latin": "Hygrophorus eburneus",
        "eng": "ivory woodwax",
        "hun": "elefántcsont-csigagomba"
    },
    "998": {
        "latin": "Hygrophorus erubescens",
        "eng": "blotched woodwax",
        "hun": "piruló csigagomba"
    },
    "999": {
        "latin": "Hygrophorus hedrychii",
        "eng": "sweet woodwax",
        "latin_syn": [
            "Hygrophorus melizeus"
        ],
        "hun": "nyírfa-csigagomba"
    },
    "1000": {
        "latin": "Hygrophorus hypothejus",
        "eng": "herald of winter",
        "hun": "fagyálló csigagomba"
    },
    "1001": {
        "latin": "Hygrophorus latitabundus",
        "eng": "ebony woodwax",
        "hun": "barnanyálkás csigagomba"
    },
    "1002": {
        "latin": "Hygrophorus leporinus",
        "hun": "kávébarna csigagomba"
    },
    "1003": {
        "latin": "Hygrophorus ligatus",
        "latin_syn": [
            "Hygrophorus flavodiscus",
            "Hygrophorus gliocyclus"
        ],
        "hun": "nyálkagyűrűs csigagomba"
    },
    "1004": {
        "latin": "Hygrophorus lindtneri",
        "latin_syn": [
            "Hygrophorus carpini"
        ],
        "hun": "gyertyán-csigagomba"
    },
    "1005": {
        "latin": "Hygrophorus lucorum",
        "eng": "larch woodwax",
        "hun": "vörösfenyő-csigagomba"
    },
    "1006": {
        "latin": "Hygrophorus marzuolus",
        "hun": "tavaszi csigagomba",
        "hun_syn": [
            "márciusi csigagomba"
        ]
    },
    "1007": {
        "latin": "Hygrophorus mesotephrus",
        "eng": "ashen woodwax",
        "hun": "olívszürke csigagomba"
    },
    "1008": {
        "latin": "Hygrophorus nemoreus",
        "eng": "oak woodwax",
        "hun": "ligeti csigagomba"
    },
    "1009": {
        "latin": "Hygrophorus olivaceoalbus",
        "hun": "olajbarna csigagomba"
    },
    "1010": {
        "latin": "Hygrophorus penarioides",
        "hun": "nagy csigagomba"
    },
    "1011": {
        "latin": "Hygrophorus penarius",
        "eng": "matt woodwax",
        "hun": "száraz csigagomba"
    },
    "1012": {
        "latin": "Hygrophorus persoonii",
        "latin_syn": [
            "Hygrophorus dichrous"
        ],
        "hun": "olajszínű csigagomba",
        "hun_syn": [
            "olajbarna csigagomba"
        ]
    },
    "1013": {
        "latin": "Hygrophorus poëtarum",
        "hun": "izabellvöröses csigagomba",
        "hun_syn": [
            "testes csigagomba"
        ]
    },
    "1014": {
        "latin": "Hygrophorus pudorinus",
        "eng": "rosy woodwax",
        "latin_syn": [
            "Hygrophorus persi-"
        ],
        "hun": "flamingó-csigagomba"
    },
    "1015": {
        "latin": "Hygrophorus pustulatus",
        "eng": "blistered woodwax",
        "hun": "szürke csigagomba"
    },
    "1016": {
        "latin": "Hygrophorus queletii",
        "hun": "pikkelyes csigagomba"
    },
    "1017": {
        "latin": "Hygrophorus russula",
        "eng": "pinkmottle woodwax",
        "hun": "vörösfoltos csigagomba",
        "hun_syn": [
            "csigapereszke"
        ]
    },
    "1018": {
        "latin": "Hygrophorus speciosus",
        "eng": "splendid woodwax",
        "hun": "narancssárga csigagomba"
    },
    "1019": {
        "latin": "Hygrophorus unicolor",
        "eng": "twotone woodwax",
        "latin_syn": [
            "Hygrophorus leucophaeus"
        ],
        "hun": "karcsú csigagomba"
    },
    "1020": {
        "latin": "Hymenochaete rubiginosa",
        "eng": "oak curtain crust",
        "hun": "rozsdás sörtésréteggomba",
        "hun_syn": [
            "rozsdás sörtésréteggomba"
        ]
    },
    "1021": {
        "latin": "Hymenogaster arenarius",
        "latin_syn": [
            "Hymenogaster pusillus"
        ],
        "hun": "homoki hártyáspöfeteg",
        "hun_syn": [
            "homoki álpöfeteg"
        ]
    },
    "1022": {
        "latin": "Hymenogaster asterigmatus",
        "hun": "sterigmátlan hártyáspöfeteg",
        "hun_syn": [
            "sterigmátlan álpöfeteg"
        ]
    },
    "1023": {
        "latin": "Hymenogaster bulliardii",
        "hun": "sima hártyáspöfeteg",
        "hun_syn": [
            "nagy hasgomba",
            "sima álpöfeteg",
            "sima könnyű-álpöfeteg"
        ]
    },
    "1024": {
        "latin": "Hymenogaster calosporus",
        "hun": "nagyspórás hártyáspöfeteg",
        "hun_syn": [
            "nagyspórás-álpöpöfeteg"
        ]
    },
    "1025": {
        "latin": "Hymenogaster cereus",
        "hun": "viaszsárga hártyáspöfeteg",
        "hun_syn": [
            "viaszsárga álpöpöfeteg"
        ]
    },
    "1026": {
        "latin": "Hymenogaster cinereus",
        "hun": "szürkebélű hártyáspöfeteg",
        "hun_syn": [
            "szürkebélű álpöfeteg"
        ]
    },
    "1027": {
        "latin": "Hymenogaster citrinus ",
        "latin_syn": [
            "Hymenogaster bucholtzii",
            "Hymenogaster olivaceus",
            "Hymenogaster sulcatus"
        ],
        "hun": "citromsárga hártyáspöfeteg",
        "hun_syn": [
            "bordás álpöfeteg",
            "citromsárga álpöfeteg",
            "pettyes álpöfeteg"
        ]
    },
    "1028": {
        "latin": "Hymenogaster disciformis",
        "hun": "lencsealakú hártyáspöfeteglencsealakú álpöfeteg"
    },
    "1029": {
        "latin": "Hymenogaster eurysporus",
        "hun": "Soehner-hártyáspöfeteg",
        "hun_syn": [
            "Soehner-álpöfeteg"
        ]
    },
    "1030": {
        "latin": "Hymenogaster gilvus",
        "hun": "fakósárga hártyáspöfeteg",
        "hun_syn": [
            "fakósárga álpöfeteg"
        ]
    },
    "1031": {
        "latin": "Hymenogaster griseus",
        "latin_syn": [
            "Hymenogaster hessei",
            "Hymenogaster lilacinus",
            "Hymenogaster lycoperdineus",
            "Hymenogaster muticus",
            "Hymenogaster populetorum",
            "Hymenogaster vulgaris"
        ],
        "hun": "illatos hártyáspöfeteg",
        "hun_syn": [
            "lilabélű álpöfeteg",
            "nagykamrás álpöfeteg",
            "kerti álpöfeteg",
            "nyárfa-álpöfeteg",
            "repedéses álpöfeteg"
        ]
    },
    "1032": {
        "latin": "Hymenogaster huthii",
        "hun": "Huth-hártyáspöfeteg"
    },
    "1033": {
        "latin": "Hymenogaster intermedius",
        "hun": "uborkaszagú hártyáspöfeteg"
    },
    "1034": {
        "latin": "Hymenogaster luteus",
        "hun": "vanília-hártyáspöfeteg",
        "hun_syn": [
            "puha álpöfeteg",
            "sárgabélű hasgomba"
        ]
    },
    "1035": {
        "latin": "Hymenogaster pilosiusculus",
        "hun": "sárgabélű hártyáspöfeteg",
        "hun_syn": [
            "sárgabélű álpöfeteg"
        ]
    },
    "1036": {
        "latin": "Hymenogaster pruinatus",
        "hun": "korpás hártyáspöfeteg",
        "hun_syn": [
            "korpás álpöfeteg"
        ]
    },
    "1037": {
        "latin": "Hymenogaster rehsteineri",
        "hun": "lándzsásspórájú hártyáspöfeteg"
    },
    "1038": {
        "latin": "Hymenogaster reniformis",
        "hun": "vesealakú hártyáspöfeteg",
        "hun_syn": [
            "vesealakú álpöfeteg"
        ]
    },
    "1039": {
        "latin": "Hymenogaster rufus",
        "hun": "rozsdásbarna hártyáspöfeteg",
        "hun_syn": [
            "rozsdásbarna "
        ]
    },
    "1040": {
        "latin": "Hymenogaster spictensis",
        "hun": "sárgaspórás hártyáspöfeteg",
        "hun_syn": [
            "sárgaspórás álpöfeteg"
        ]
    },
    "1041": {
        "latin": "Hymenogaster submacrosporus",
        "hun": "szélesspórájú hártyáspöfeteg",
        "hun_syn": [
            "szélesspórás álpöfeteg",
            "szélesspórájú álpöfeteg"
        ]
    },
    "1042": {
        "latin": "Hymenogaster suevicus",
        "hun": "sváb hártyáspöfeteg",
        "hun_syn": [
            "sváb álpöfeteg"
        ]
    },
    "1043": {
        "latin": "Hymenogaster tener",
        "hun": "fehérlő hártyáspöfeteg",
        "hun_syn": [
            "ezüstös álpöfeteg",
            "ezüstös hártyáspöfeteg"
        ]
    },
    "1044": {
        "latin": "Hymenogaster thwaitesii",
        "hun": "cifra hártyáspöfeteg",
        "hun_syn": [
            "cifra álpöfeteg"
        ]
    },
    "1045": {
        "latin": "Hymenogaster uliginosus",
        "hun": "mocsári hártyáspöfeteg",
        "hun_syn": [
            "mocsári álpöfeteg"
        ]
    },
    "1046": {
        "latin": "Hymenogaster verrucosus",
        "hun": "szemölcsös hártyáspöfeteg",
        "hun_syn": [
            "szemölcsös álpöfeteg"
        ]
    },
    "1047": {
        "latin": "Hymenopellis radicata",
        "eng": "rooting shank",
        "latin_syn": [
            "Oudemansiella radicata",
            "Xerula radicata"
        ],
        "hun": "nyálkás gyökeresfülőke",
        "hun_syn": [
            "gyökeres fülőke",
            "hosszútönkű gyökeresfülőke"
        ]
    },
    "1048": {
        "latin": "Hymenoscyphus albidus",
        "hun": "fehér nyelescsészegomba"
    },
    "1049": {
        "latin": "Hymenoscyphus fructigenus",
        "eng": "nut disco",
        "hun": "makk-kedvelő nyelescsészegomba"
    },
    "1050": {
        "latin": "Hymenoscyphus menthae",
        "hun": "sárga nyelescsészegomba"
    },
    "1051": {
        "latin": "Hymenoscyphus monticola",
        "hun": "hegyi nyelescsészegomba"
    },
    "1052": {
        "latin": "Hymenoscyphus phyllophilus",
        "hun": "levélkedvelő nyelescsészegomba"
    },
    "1053": {
        "latin": "Hymenoscyphus serotinus",
        "hun": "bükk-kedvelő nyelescsészegomba"
    },
    "1054": {
        "latin": "Hyphoderma argillaceum",
        "hun": "izzadó vargomba"
    },
    "1055": {
        "latin": "Hyphoderma pallidum",
        "hun": "színeződő vargomba"
    },
    "1056": {
        "latin": "Hyphoderma roseocremeum",
        "hun": "könnyező vargomba"
    },
    "1057": {
        "latin": "Hyphoderma setigerum",
        "latin_syn": [
            "Peniophora setigera"
        ],
        "hun": "borostás terülőgomba"
    },
    "1058": {
        "latin": "Hyphodermella corrugata",
        "latin_syn": [
            "Odontia corrugata"
        ],
        "hun": "változékony fésűsgomba"
    },
    "1059": {
        "latin": "Hyphodontia alutacea",
        "latin_syn": [
            "Odontia alutacea"
        ],
        "hun": "sárgás fésűsgomba"
    },
    "1060": {
        "latin": "Hyphodontia arguta",
        "latin_syn": [
            "Grandinia arguta"
        ],
        "hun": "recés fésűsgomba"
    },
    "1061": {
        "latin": "Hyphodontia barba-jovis",
        "hun": "bozontos fésűsgomba"
    },
    "1062": {
        "latin": "Hyphodontia crustosa",
        "hun": "kérges fésűsgomba"
    },
    "1063": {
        "latin": "Hyphodontia quercina",
        "latin_syn": [
            "Radulum quercinum"
        ],
        "hun": "tölgyfa-ráspolygomba"
    },
    "1064": {
        "latin": "Hyphodontia sambuci",
        "latin_syn": [
            "Corticium sambuci",
            "Rogersella sambuci"
        ],
        "hun": "bodza-vargomba"
    },
    "1065": {
        "latin": "Hypholoma capnoides",
        "eng": "conifer tuft",
        "hun": "fenyő-kénvirággomba"
    },
    "1066": {
        "latin": "Hypholoma dispersum",
        "latin_syn": [
            "Hypholoma marginatum"
        ],
        "hun": "díszestönkű kénvirággomba",
        "hun_syn": [
            "foltostönkű kénvirággomba",
            "zöldesbarna kénvirággomba"
        ]
    },
    "1067": {
        "latin": "Hypholoma elongatum",
        "eng": "sphagnum brownie",
        "latin_syn": [
            "Hypholoma elongatipes"
        ],
        "hun": "tőzegmoha-kénvirággomba"
    },
    "1068": {
        "latin": "Hypholoma epixanthum",
        "hun": "büdös kénvirággomba"
    },
    "1069": {
        "latin": "Hypholoma ericaeoides",
        "hun": "bordáskalapú kénvirággomba"
    },
    "1070": {
        "latin": "Hypholoma ericaeum",
        "hun": "fenyér-kénvirággomba"
    },
    "1071": {
        "latin": "Hypholoma fasciculare",
        "eng": "sulphur tuft",
        "hun": "sárga kénvirággomba"
    },
    "1072": {
        "latin": "Hypholoma lateritium",
        "eng": "brick tuft",
        "latin_syn": [
            "Hypholoma sublateritium"
        ],
        "hun": "vöröses kénvirággomba"
    },
    "1073": {
        "latin": "Hypholoma radicosum",
        "eng": "rooting brownie",
        "hun": "gyökeres kénvirággomba"
    },
    "1074": {
        "latin": "Hypholoma subericeaum",
        "hun": "parti kénvirággomba"
    },
    "1075": {
        "latin": "Hypochnella violacea",
        "hun": "lilás szövedékgomba"
    },
    "1076": {
        "latin": "Hypochnicium bombycinum",
        "latin_syn": [
            "Corticium bombycinum"
        ],
        "hun": "borzas vargomba"
    },
    "1077": {
        "latin": "Hypocrea citrina",
        "hun": "sárga zselégomba"
    },
    "1078": {
        "latin": "Hypocrea gelatinosa",
        "hun": "zöldspórás zselégomba"
    },
    "1079": {
        "latin": "Hypocrea lixii",
        "hun": "olajzöld zselégomba"
    },
    "1080": {
        "latin": "Hypoxylon cercidicola",
        "hun": "kőris-ripacsgomba"
    },
    "1081": {
        "latin": "Hypoxylon fragiforme",
        "eng": "beech woodwart",
        "latin_syn": [
            "Hypoxylon coccineum"
        ],
        "hun": "vöröses ripacsgomba",
        "hun_syn": [
            "ripacsosgomba"
        ]
    },
    "1082": {
        "latin": "Hypoxylon fuscum",
        "eng": "hazel woodwart",
        "hun": "barnásvörös ripacsgomba"
    },
    "1083": {
        "latin": "Hypoxylon howeanum",
        "hun": "rozsdavörös ripacsgomba"
    },
    "1084": {
        "latin": "Hypsizygus ulmarius",
        "eng": "elm leech",
        "latin_syn": [
            "Lyophyllum ulmarium"
        ],
        "hun": "laskapereszke",
        "hun_syn": [
            "szilfa-laskagomba"
        ]
    },
    "1085": {
        "latin": "Hysterangium calcareum",
        "hun": "német zápszömörcsög",
        "hun_syn": [
            "német álpöfeteg",
            "német gyökeres-álpöfeteg"
        ]
    },
    "1086": {
        "latin": "Hysterangium clathroides",
        "latin_syn": [
            "Hysterangium clathroides var. crassum",
            "Hysterangium separabile"
        ],
        "hun": "retekszagú zápszömörcsö",
        "hun_syn": [
            "retekszagú gyökeres-álpöfeteg"
        ]
    },
    "1087": {
        "latin": "Hysterangium coriaceum",
        "hun": "bőrhéjú zápszömörcsög",
        "hun_syn": [
            "kettőskérgű gyökeres-álpöfeteg"
        ]
    },
    "1088": {
        "latin": "Hysterangium fragile",
        "hun": "törékeny zápszömörcsög",
        "hun_syn": [
            "törékeny álpöfeteg",
            "törékeny gyökeres-álpöfeteg"
        ]
    },
    "1089": {
        "latin": "Hysterangium membranaceum",
        "hun": "papírhéjú zápszömörcsög"
    },
    "1090": {
        "latin": "Hysterangium nephriticum",
        "hun": "vesealakú zápszömörcsög",
        "hun_syn": [
            "gyapjas gyökeres-álpöfeteg"
        ]
    },
    "1091": {
        "latin": "Hysterangium pompholyx",
        "hun": "fahéjbarna zápszömörcsög",
        "hun_syn": [
            "gömbölyded álpöfeteg",
            "gömbölyded gyökeres-álpöfeteg"
        ]
    },
    "1092": {
        "latin": "Hysterangium rubricatum",
        "hun": "rőthéjú zápszömörcsög",
        "hun_syn": [
            "rőt álpöfeteg",
            "rőt gyökeres-álpöfeteg"
        ]
    },
    "1093": {
        "latin": "Hysterangium separabile",
        "hun": "lazahéjú zápszömörcsög",
        "hun_syn": [
            "retekszagú álpöfeteg",
            "retekszagú gyökeres-álpöfeteg",
            "retek-szagú gyökerespöfeteg"
        ]
    },
    "1094": {
        "latin": "Hysterangium stoloniferum",
        "hun": "porcoshéjú zápszömörcsög",
        "hun_syn": [
            "fehér-álpöfeteg",
            "fehér gyökeres-álpöfeteg"
        ]
    },
    "1095": {
        "latin": "Hysterangium thwaitesii",
        "hun": "barnuló zápszömörcsög",
        "hun_syn": [
            "barnuló-álpöfeteg",
            "barnuló gyökeres-álpöfeteg"
        ]
    },
    "1096": {
        "latin": "Imleria badia",
        "latin_syn": [
            "Boletus badius",
            "Xerocomus badius"
        ],
        "hun": "barna tinóru",
        "hun_syn": [
            "barna nemezestinóru"
        ]
    },
    "1097": {
        "latin": "Imperator luteocupreus",
        "latin_syn": [
            "Boletus luteocupreus"
        ],
        "hun": "rézvörös tinóru"
    },
    "1098": {
        "latin": "Imperator Boletus rhodopurpureus",
        "hun": "borvörös tinóru",
        "hun-syn": [
            "bíborvörös tinóru"
        ]
    },
    "1099": {
        "latin": "Imperator torosus",
        "latin_syn": [
            "Boletus torosus"
        ],
        "hun": "foltosodó tinóru"
    },
    "1100": {
        "latin": "Infundibulicybe costata",
        "latin_syn": [
            "Clitocybe costata"
        ],
        "hun": "bordás tölcsérgomba"
    },
    "1101": {
        "latin": "Infundibulicybe geotropa",
        "eng": "trooping funnel",
        "latin_syn": [
            "Clitocybe geotropa"
        ],
        "hun": "óriás tölcsérgomba"
    },
    "1102": {
        "latin": "Infundibulicybe gibba",
        "eng": "common funnel",
        "latin_syn": [
            "Clitocybe gibba"
        ],
        "hun": "sereges tölcsérgomba"
    },
    "1103": {
        "latin": "Infundibulicybe glareosa",
        "eng": "gravel funnel",
        "latin_syn": [
            "Clitocybe glareosa"
        ],
        "hun": "homoki tölcsérgomba"
    },
    "1104": {
        "latin": "Infundibulicybe squamulosa",
        "latin_syn": [
            "Clitocybe squamulosa"
        ],
        "hun": "pikkelyes tölcsérgomba"
    },
    "1105": {
        "latin": "Inocutis dryophila",
        "latin_syn": [
            "Inonotus dryophilus"
        ],
        "hun": "tölgyfa-rozsdástapló"
    },
    "1106": {
        "latin": "Inocutis rheades",
        "latin_syn": [
            "Inonotus rheades"
        ],
        "hun": "nyárfa-rozsdástapló"
    },
    "1107": {
        "latin": "Inocutis tamaricis",
        "latin_syn": [
            "Inonotus tamaricis"
        ],
        "hun": "tamariska-rozsdástapló"
    },
    "1108": {
        "latin": "Inocybe adaequata",
        "latin_syn": [
            "Inocybe jurana"
        ],
        "hun": "borvörös susulyka"
    },
    "1109": {
        "latin": "Inocybe aeruginascens",
        "hun": "zöldülőtönkű susulyka",
        "hun_syn": [
            "zöldülő susulyka"
        ]
    },
    "1110": {
        "latin": "Inocybe agardhii",
        "hun": "homoki susulyka"
    },
    "1111": {
        "latin": "Inocybe amethystina",
        "hun": "ametisztsusulyka"
    },
    "1112": {
        "latin": "Inocybe appendiculata",
        "hun": "csipkés susulyka"
    },
    "1113": {
        "latin": "Inocybe asterospora",
        "eng": "star fibrecap",
        "hun": "csillagspórás susulyka"
    },
    "1114": {
        "latin": "Inocybe bongardii",
        "eng": "fruity fibrecap",
        "hun": "illatos susulyka"
    },
    "1115": {
        "latin": "Inocybe bresadolae",
        "hun": "rókaszínű susulyka"
    },
    "1116": {
        "latin": "Inocybe calamistrata",
        "eng": "greenfoot fibrecap",
        "hun": "kéklábú susulyka"
    },
    "1117": {
        "latin": "Inocybe calospora",
        "hun": "tüskésspórás susulyka"
    },
    "1118": {
        "latin": "Inocybe cervicolor",
        "hun": "őzbarna susulyka"
    },
    "1119": {
        "latin": "Inocybe cincinnata",
        "eng": "collared fibrecap",
        "latin_syn": [
            "Inocybe phaeocomis"
        ],
        "hun": "lilatönkű susulyka",
        "hun_syn": [
            "barnáskék susulyka"
        ]
    },
    "1120": {
        "latin": "Inocybe cookei",
        "eng": "straw fibrecap",
        "hun": "gumós susulyka",
        "hun_syn": [
            "gumóstönkű susulyka"
        ]
    },
    "1121": {
        "latin": "Inocybe corydalina",
        "eng": "greenflush fibrecap",
        "latin_syn": [
            "Inocybe erinaceomorpha"
        ],
        "hun": "zöldpúpú susulyka",
        "hun_syn": [
            "zöldcsúcsú susulyka"
        ]
    },
    "1122": {
        "latin": "Inocybe dulcamara",
        "eng": "bittersweet fibrecap",
        "hun": "olajsárga susulyka",
        "hun_syn": [
            "okkersárga susulyka",
            "olajbarna susulyka"
        ]
    },
    "1123": {
        "latin": "Inocybe erubescens",
        "eng": "deadly fibrecap",
        "latin_syn": [
            "Inocybe patouillardii"
        ],
        "hun": "téglavörös susulyka"
    },
    "1124": {
        "latin": "Inocybe fibrosa",
        "hun": "karéjos susulyka"
    },
    "1125": {
        "latin": "Inocybe flavella",
        "hun": "fakósárgás susulyka"
    },
    "1126": {
        "latin": "Inocybe flocculosa",
        "eng": "fleecy fibrecap",
        "latin_syn": [
            "Inocybe gausapata"
        ],
        "hun": "pikkelykés susulyka",
        "hun_syn": [
            "sárga susulyka"
        ]
    },
    "1127": {
        "latin": "Inocybe fraudans",
        "eng": "pear fibrecap",
        "latin_syn": [
            "Inocybe pyriodora"
        ],
        "hun": "körteszagú susulyka",
        "hun_syn": [
            "körteillatú susulyka"
        ]
    },
    "1128": {
        "latin": "Inocybe furfurea",
        "hun": "feketecsúcsú susulyka"
    },
    "1129": {
        "latin": "Inocybe fuscidula",
        "eng": "darkish fibrecap",
        "hun": "sötétcsíkos susulyka"
    },
    "1130": {
        "latin": "Inocybe geophylla",
        "eng": "white fibrecap",
        "hun": "selymes susulyka"
    },
    "1131": {
        "latin": "Inocybe godeyi",
        "hun": "piruló susulyka",
        "hun_syn": [
            "piros susulyka"
        ]
    },
    "1132": {
        "latin": "Inocybe grammata",
        "eng": "flushed fibrecap",
        "hun": "kakaóbarna susulyka"
    },
    "1133": {
        "latin": "Inocybe griseovelata",
        "hun": "szürkésfátyolú susulyka"
    },
    "1134": {
        "latin": "Inocybe haemacta",
        "hun": "vörösödő susulyka",
        "hun_syn": [
            "pompás susulyka",
            "zöldtönkű susulyka"
        ]
    },
    "1135": {
        "latin": "Inocybe heimii",
        "hun": "tengerparti susulyka"
    },
    "1136": {
        "latin": "Inocybe hirtella",
        "eng": "bitter almond fibrecap",
        "hun": "mandulaillatú susulyka"
    },
    "1137": {
        "latin": "Inocybe hystrix",
        "eng": "scaly fibrecap",
        "hun": "borzas susulyka"
    },
    "1138": {
        "latin": "Inocybe impexa",
        "latin_syn": [
            "Inocybe maritima"
        ],
        "hun": "föveny-susulyka",
        "hun_syn": [
            "szürke susulyka"
        ]
    },
    "1139": {
        "latin": "Inocybe inconcinna",
        "hun": "finomszálas susulyka"
    },
    "1140": {
        "latin": "Inocybe inodora",
        "hun": "fehérfátylú susulyka"
    },
    "1141": {
        "latin": "Inocybe jacobi",
        "eng": "pineling fibrecap",
        "hun": "apró susulyka"
    },
    "1142": {
        "latin": "Inocybe javorkae",
        "hun": "magyar susulyka"
    },
    "1143": {
        "latin": "Inocybe lacera",
        "eng": "torn fibrecap",
        "hun": "orsóspórás susulyka",
        "hun_syn": [
            "borzas susulyka"
        ]
    },
    "1144": {
        "latin": "Inocybe lanuginosa",
        "eng": "floccose fibrecap",
        "hun": "gyapjas susulyka"
    },
    "1145": {
        "latin": "Inocybe leptophylla",
        "eng": "shorn fibrecap",
        "latin_syn": [
            "Inocybe casimiri"
        ],
        "hun": "keskenylemezű susulyka"
    },
    "1146": {
        "latin": "Inocybe leucoblema",
        "hun": "ezüstfátylú susulyka"
    },
    "1147": {
        "latin": "Inocybe lilacina",
        "eng": "lilac fibrecap",
        "latin_syn": [
            "Inocybe geophylla var. lilacina"
        ],
        "hun": "lila susulyka"
    },
    "1148": {
        "latin": "Inocybe maculata",
        "eng": "frosty fibrecap",
        "hun": "foltos susulyka",
        "hun_syn": [
            "pettyes susulyka"
        ]
    },
    "1149": {
        "latin": "Inocybe mixtilis",
        "hun": "peremesgumós susulyka",
        "hun_syn": [
            "csillagspórás susulyka"
        ]
    },
    "1150": {
        "latin": "Inocybe muricellata",
        "hun": "sárgásbarna susulyka"
    },
    "1151": {
        "latin": "Inocybe mytiliodora",
        "hun": "kagylószagú susulyka"
    },
    "1152": {
        "latin": "Inocybe napipes",
        "eng": "bulbous fibrecap",
        "hun": "répatönkű susulyka"
    },
    "1153": {
        "latin": "Inocybe nitidiuscula",
        "latin_syn": [
            "Inocybe tarda"
        ],
        "hun": "kései susulyka"
    },
    "1154": {
        "latin": "Inocybe obscura",
        "hun": "ibolyás susulyka"
    },
    "1155": {
        "latin": "Inocybe petiginosa",
        "eng": "scurfy fibrecap",
        "hun": "törpe susulyka",
        "hun_syn": [
            "szürkés susulyka"
        ]
    },
    "1156": {
        "latin": "Inocybe piceae",
        "hun": "fenyves susulyka"
    },
    "1157": {
        "latin": "Inocybe praetervisa",
        "hun": "tobozspórás susulyka"
    },
    "1158": {
        "latin": "Inocybe pusio",
        "eng": "laddie's fibrecap",
        "hun": "dióbarna susulyka"
    },
    "1159": {
        "latin": "Inocybe rimosa",
        "eng": "split fibrecap",
        "latin_syn": [
            "Inocybe brunnea",
            "Inocybe fastigiata"
        ],
        "hun": "kerti susulyka"
    },
    "1160": {
        "latin": "Inocybe sambucina",
        "hun": "tömzsi susulyka",
        "hun_syn": [
            "tömör susulyka"
        ]
    },
    "1161": {
        "latin": "Inocybe sindonia",
        "eng": "beige fibrecap",
        "hun": "gyapjaskalapú susulyka"
    },
    "1162": {
        "latin": "Inocybe soluta",
        "eng": "pine fibrecap",
        "latin_syn": [
            "Inocybe brevispora"
        ],
        "hun": "rövidspórás susulyka"
    },
    "1163": {
        "latin": "Inocybe splendens",
        "hun": "fénylő susulyka"
    },
    "1164": {
        "latin": "Inocybe squamata",
        "hun": "pikkelyes susulyka"
    },
    "1165": {
        "latin": "Inocybe striata",
        "hun": "csúcsos susulyka"
    },
    "1166": {
        "latin": "Inocybe subcarpta",
        "eng": "umber fibrecap",
        "latin_syn": [
            "Inocybe boltonii"
        ],
        "hun": "trapézspórás susulyka"
    },
    "1167": {
        "latin": "Inocybe tenebrosa",
        "hun": "sötéttönkű susulyka"
    },
    "1168": {
        "latin": "Inocybe terrigena",
        "hun": "földi susulyka"
    },
    "1169": {
        "latin": "Inocybe umbrina",
        "hun": "gesztenyebarna susulyka",
        "hun_syn": [
            "fehérgumójú susulyka"
        ]
    },
    "1170": {
        "latin": "Inocybe whitei",
        "eng": "blushing fibrecap",
        "hun": "rózsásfehér susulyka"
    },
    "1171": {
        "latin": "Inonotus cuticularis",
        "eng": "clustered bracket",
        "hun": "vékony rozsdástapló",
        "hun_syn": [
            "vékony likacsosgomba"
        ]
    },
    "1172": {
        "latin": "Inonotus hispidus",
        "eng": "shaggy bracket",
        "hun": "almafa-rozsdástapló",
        "hun_syn": [
            "feketedő likacsosgomba"
        ]
    },
    "1173": {
        "latin": "Inonotus nidus-pici",
        "latin_syn": [
            "Xanthochrous nidus-pici"
        ],
        "hun": "kétalakú csertapló"
    },
    "1174": {
        "latin": "Inonotus obliquus",
        "eng": "chaga",
        "hun": "terülő rozsdástapló",
        "hun_syn": [
            "ferde csertapló"
        ]
    },
    "1175": {
        "latin": "Irpex lacteus",
        "hun": "fehérbélű egyrétűtapló"
    },
    "1176": {
        "latin": "Ischnoderma benzoinum",
        "eng": "benzoin bracket",
        "hun": "fenyő-kérgestapló"
    },
    "1177": {
        "latin": "Ischnoderma resinosum",
        "hun": "gyantás kérgestapló"
    },
    "1178": {
        "latin": "Jaapia ochroleuca",
        "latin_syn": [
            "Coniophora ochroleuca"
        ],
        "hun": "nemezes foltgomba"
    },
    "1179": {
        "latin": "Junghuhnia collabens",
        "hun": "fahéjszínű kéreggomba"
    },
    "1180": {
        "latin": "Kavinia himantia",
        "hun": "terülő fésűsgomba"
    },
    "1181": {
        "latin": "Kretzschmaria deusta",
        "eng": "brittle cinder",
        "latin_syn": [
            "Hypoxylon deustum",
            "Ustulina deusta"
        ],
        "hun": "szenes ripacsgomba"
    },
    "1182": {
        "latin": "Kuehneromyces mutabilis",
        "eng": "sheathed woodtuft",
        "hun": "ízletes tőkegomba",
        "hun_syn": [
            "változékony tőkegomba"
        ]
    },
    "1183": {
        "latin": "Laccaria amethystina",
        "eng": "amethyst deceiver",
        "hun": "lila pénzecskegomba"
    },
    "1184": {
        "latin": "Laccaria bicolor",
        "eng": "bicoloured deceiver",
        "hun": "kétszínű pénzecskegomba"
    },
    "1185": {
        "latin": "Laccaria fraterna",
        "eng": "gumtree deceiver",
        "hun": "kétspórás pénzecskegomba"
    },
    "1186": {
        "latin": "Laccaria laccata",
        "eng": "deceiver",
        "hun": "húsbarna pénzecskegomba",
        "hun_syn": [
            "fakólemezű pénzecskegomba",
            "változékony pénzecskegomba"
        ]
    },
    "1187": {
        "latin": "Laccaria proxima",
        "eng": "scurfy deceiver",
        "hun": "pikkelyes pénzecskegomba"
    },
    "1188": {
        "latin": "Laccaria purpureobadia",
        "hun": "vörösesbarna pénzecskegomba"
    },
    "1189": {
        "latin": "Laccaria tortilis",
        "eng": "twisted deceiver",
        "latin_syn": [
            "Laccaria echinospora"
        ],
        "hun": "apró pénzecskegomba"
    },
    "1190": {
        "latin": "Lachnella alboviolascens",
        "latin_syn": [
            "Cyphella alboviolascens"
        ],
        "hun": "bodza-szemcsegomba"
    },
    "1191": {
        "latin": "Lachnum virgineum",
        "eng": "snowy disco",
        "latin_syn": [
            "Dasyscyphus virgineus"
        ],
        "hun": "patyolatfehér kehelygombácska"
    },
    "1192": {
        "latin": "Lacrymaria lacrymabunda",
        "eng": "weeping widow",
        "latin_syn": [
            "Psathyrella velutina"
        ],
        "hun": "könnyező szálkásgomba",
        "hun_syn": [
            "könnyező porhanyósgomba"
        ]
    },
    "1193": {
        "latin": "Lacrymaria pyrotricha",
        "latin_syn": [
            "Psathyrella pyrotricha"
        ],
        "hun": "tűzpiros szálkásgomba",
        "hun_syn": [
            "lángszínű szálkásgomba"
        ]
    },
    "1194": {
        "latin": "Lactarius acerrimus",
        "eng": "two spored milkcap",
        "hun": "kereszterű tejelőgomba"
    },
    "1195": {
        "latin": "Lactarius acris",
        "eng": "raspberry milkcap",
        "hun": "vörösödőtejű tejelőgomba",
        "hun_syn": [
            "rózsaszíntejű tejelőgomba"
        ]
    },
    "1196": {
        "latin": "Lactarius albocarneus",
        "latin_syn": [
            "Lactarius glutinopallens"
        ],
        "hun": "nyálkás tejelőgomba"
    },
    "1197": {
        "latin": "Lactarius aspideus",
        "eng": "willow milkcap",
        "hun": "fakólilás tejelőgomba",
        "hun_syn": [
            "lilásodó keserűgomba"
        ]
    },
    "1198": {
        "latin": "Lactarius aurantiacus",
        "eng": "orange milkcap",
        "latin_syn": [
            "Lactarius mitissimus"
        ],
        "hun": "enyhe tejelőgomba",
        "hun_syn": [
            "narancsszínű tejelőgomba"
        ]
    },
    "1199": {
        "latin": "Lactarius azonites",
        "hun": "füstszínű tejelőgomba",
        "hun_syn": [
            "hamvas tejelőgomba",
            "rózsaszínváltó keserűgomba"
        ]
    },
    "1200": {
        "latin": "Lactarius badiosanguineus",
        "hun": "barnásvörös tejelőgomba"
    },
    "1201": {
        "latin": "Lactarius blennius",
        "eng": "beech milkcap",
        "hun": "zöldes tejelőgomba",
        "hun_syn": [
            "zöldes keserűgomba"
        ]
    },
    "1202": {
        "latin": "Lactarius borzianus",
        "latin_syn": [
            "Arcangeliella borziana"
        ],
        "hun": "édestejű álpöfeteg",
        "hun_syn": [
            "rózsás földitejelő"
        ]
    },
    "1203": {
        "latin": "Lactarius camphoratus",
        "eng": "curry milkcap",
        "hun": "kámfor-tejelőgomba",
        "hun_syn": [
            "cikória tejelőgomba",
            "kámforszagú tejelőgomba"
        ]
    },
    "1204": {
        "latin": "Lactarius chrysorrheus",
        "eng": "yellowdrop milkcap",
        "hun": "sárgulótejű tejelőgomba"
    },
    "1205": {
        "latin": "Lactarius circellatus",
        "eng": "ringed milkcap",
        "hun": "gyöngyös tejelőgomba",
        "hun_syn": [
            "gyöngyös keserűgomba"
        ]
    },
    "1206": {
        "latin": "Lactarius citriolens",
        "hun": "bojtos tejelőgomba"
    },
    "1207": {
        "latin": "Lactarius controversus",
        "eng": "blushing milkcap",
        "hun": "rózsáslemezű tejelőgomba",
        "hun_syn": [
            "vöröspettyes keserűgomba"
        ]
    },
    "1208": {
        "latin": "Lactarius decipiens",
        "hun": "korai tejelőgomba",
        "hun_syn": [
            "kénsárgatejű tejelőgomba"
        ]
    },
    "1209": {
        "latin": "Lactarius deliciosus",
        "eng": "saffron milkcap",
        "hun": "ízletes rizike",
        "hun_syn": [
            "fenyőaljagomba",
            "közönséges rizike",
            "rizike tejelőgomba",
            "rizikegomba"
        ]
    },
    "1210": {
        "latin": "Lactarius deterrimus",
        "eng": "false saffron milkcap",
        "hun": "lucfenyvesi rizike",
        "hun_syn": [
            "zöldsávos tejelőgomba"
        ]
    },
    "1211": {
        "latin": "Lactarius flavidus",
        "hun": "sárgáslila tejelőgomba",
        "hun_syn": [
            "élénksárga tejelőgomba"
        ]
    },
    "1212": {
        "latin": "Lactarius fluens",
        "eng": "nimbus milkcap",
        "hun": "fakószélű tejelőgomba",
        "hun_syn": [
            "barnafoltos tejelőgomba"
        ]
    },
    "1213": {
        "latin": "Lactarius fraxineus",
        "hun": "kőris-tejelőgomba"
    },
    "1214": {
        "latin": "Lactarius fuliginosus",
        "eng": "sooty milkcap",
        "hun": "barna tejelőgomba",
        "hun_syn": [
            "vörösödőhúsú tejelőgomba"
        ]
    },
    "1215": {
        "latin": "Lactarius fulvissimus",
        "eng": "tawny milkcap",
        "latin_syn": [
            "Lactarius ichoratus"
        ],
        "hun": "narancsvörös tejelőgomba"
    },
    "1216": {
        "latin": "Lactarius glyciosmus",
        "eng": "coconut milkcap",
        "hun": "illatos tejelőgomba",
        "hun_syn": [
            "édesszagú tejelőgomba",
            "fakó tejelőgomba"
        ]
    },
    "1217": {
        "latin": "Lactarius helvus",
        "eng": "fenugreek milkcap",
        "hun": "daróc-tejelőgomba"
    },
    "1218": {
        "latin": "Lactarius hepaticus",
        "eng": "liver milkcap",
        "hun": "kései tejelőgomba"
    },
    "1219": {
        "latin": "Lactarius hysginus",
        "eng": "dyed milkcap",
        "hun": "húsvörös tejelőgomba",
        "hun_syn": [
            "húsvörös keserűgomba"
        ]
    },
    "1220": {
        "latin": "Lactarius lacunarum",
        "hun": "vízparti tejelőgomba",
        "hun_syn": [
            "kénsárgatejű tejelőgomba",
            "lápi tejelőgomba",
            "mocsári tejelőgomba"
        ]
    },
    "1221": {
        "latin": "Lactarius lignyotus",
        "eng": "velvet milkcap",
        "hun": "ráncos tejelőgomba",
        "hun_syn": [
            "ráncostönkű tejelőgomba"
        ]
    },
    "1222": {
        "latin": "Lactarius lilacinus",
        "eng": "lilac milkcap",
        "hun": "lila tejelőgomba"
    },
    "1223": {
        "latin": "Lactarius mairei",
        "eng": "whiskery milkcap",
        "hun": "fakó szőrgomba",
        "hun_syn": [
            "fakóbolyhos tejelőgomba"
        ]
    },
    "1224": {
        "latin": "Lactarius mammosus",
        "eng": "pap milkcap",
        "latin_syn": [
            "Lactarius fuscus"
        ],
        "hun": "szagos tejelőgomba",
        "hun_syn": [
            "csúcsoskalapú tejelőgomba"
        ]
    },
    "1225": {
        "latin": "Lactarius musteus",
        "eng": "pine milkcap",
        "hun": "fenyér-tejelőgomba",
        "hun_syn": [
            "csarabos tejelőgomba"
        ]
    },
    "1226": {
        "latin": "Lactarius necator",
        "latin_syn": [
            "Lactarius plumbeus",
            "Lactarius turpis"
        ],
        "hun": "sötét tejelőgomba",
        "hun_syn": [
            "sötétbolyhos tejelőgomba"
        ]
    },
    "1227": {
        "latin": "Lactarius obscuratus",
        "eng": "alder milkcap",
        "hun": "égerfa-tejelőgomba"
    },
    "1228": {
        "latin": "Lactarius omphaliformis",
        "eng": "cracking milkcap",
        "hun": "bordásszélű tejelőgomba"
    },
    "1229": {
        "latin": "Lactarius pallidus",
        "eng": "pale milkcap",
        "hun": "fakó tejelőgomba",
        "hun_syn": [
            "fakósárga tejelőgomba"
        ]
    },
    "1230": {
        "latin": "Lactarius picinus",
        "hun": "fekete tejelőgomba"
    },
    "1231": {
        "latin": "Lactarius porninsis",
        "eng": "larch milkcap",
        "hun": "vörösfenyő-tejelőgomba",
        "hun_syn": [
            "narancssárga keserűgomba"
        ]
    },
    "1232": {
        "latin": "Lactarius pterosporus",
        "hun": "szárnyasspórás tejelőgomba",
        "hun_syn": [
            "rózsáshúsú tejelőgomba"
        ]
    },
    "1233": {
        "latin": "Lactarius pubescens",
        "eng": "bearded milkcap",
        "latin_syn": [
            "Lactarius blumii"
        ],
        "hun": "fehéres szőrgomba",
        "hun_syn": [
            "fehér szőrgomba",
            "fehérbolyhos tejelőgomba"
        ]
    },
    "1234": {
        "latin": "Lactarius pyrogalus",
        "eng": "fiery milkcap",
        "latin_syn": [
            "Lactarius hortensis"
        ],
        "hun": "mogyoró-tejelőgomba",
        "hun_syn": [
            "mogyoró tejelőgomba",
            "mogyoró-keserűgomba"
        ]
    },
    "1235": {
        "latin": "Lactarius quieticolor",
        "eng": "carrot milkcap",
        "hun": "zöldesbarna rizike",
        "hun_syn": [
            "dohánybarna tejelőgomba"
        ]
    },
    "1236": {
        "latin": "Lactarius quietus",
        "eng": "oakbug milkcap",
        "hun": "vörösbarna tejelőgomba",
        "hun_syn": [
            "vörösbarna keserűgomba"
        ]
    },
    "1237": {
        "latin": "Lactarius repraesentaneus",
        "eng": "yellow bearded milkcap",
        "hun": "lilásodó szőrgomba",
        "hun_syn": [
            "vastaggyapjas tejelőgomba"
        ]
    },
    "1238": {
        "latin": "Lactarius resimus",
        "eng": "rollrim milkcap",
        "hun": "borostásszélű tejelőgomba"
    },
    "1239": {
        "latin": "Lactarius romagnesii",
        "hun": "vörösödőhúsú tejelőgomba"
    },
    "1240": {
        "latin": "Lactarius rostratus",
        "latin_syn": [
            "Lactarius cremor"
        ],
        "hun": "ráncoskalapú tejelőgomba",
        "hun_syn": [
            "narancsszínű  tejelőgomba"
        ]
    },
    "1241": {
        "latin": "Lactarius rufus",
        "eng": "rufous milkcap",
        "hun": "rőt tejelőgomba",
        "hun_syn": [
            "rőt keserűgomba"
        ]
    },
    "1242": {
        "latin": "Lactarius salmonicolor",
        "hun": "jegenyefenyő-rizike",
        "hun_syn": [
            "jegenyefenyves tejelőgomba"
        ]
    },
    "1243": {
        "latin": "Lactarius sanguifluus",
        "eng": "bloody milkcap",
        "hun": "vöröstejű rizike",
        "hun_syn": [
            "vöröstejű tejelőgomba"
        ]
    },
    "1244": {
        "latin": "Lactarius scrobiculatus",
        "eng": "spotted milkcap",
        "hun": "szalmasárga tejelőgomba"
    },
    "1245": {
        "latin": "Lactarius semisanguifluus",
        "hun": "vörösödőtejű rizike"
    },
    "1246": {
        "latin": "Lactarius serifluus",
        "eng": "watery milkcap",
        "hun": "poloskaszagú tejelőgomba",
        "hun_syn": [
            "cikória tejelőgomba",
            "cikória-keserűgomba",
            "cikóriaszagú tejelőgomba"
        ]
    },
    "1247": {
        "latin": "Lactarius sphagneti",
        "hun": "tőzegmoha-tejelőgomba"
    },
    "1248": {
        "latin": "Lactarius stephensii",
        "latin_syn": [
            "Zelleromyces stephensii"
        ],
        "hun": "rózsás tejelőpöfeteg",
        "hun_syn": [
            "tejelő álpöfeteg"
        ]
    },
    "1249": {
        "latin": "Lactarius subdulcis",
        "eng": "mild milkcap",
        "hun": "édeskés tejelőgomba",
        "hun_syn": [
            "édes keserűgomba, édeskés keserűgomba"
        ]
    },
    "1250": {
        "latin": "Lactarius tabidus",
        "eng": "birch milkcap",
        "latin_syn": [
            "Lactarius theiogalus"
        ],
        "hun": "lápi tejelőgomba",
        "hun_syn": [
            "csücskös tejelőgomba"
        ]
    },
    "1251": {
        "latin": "Lactarius torminosus",
        "eng": "woolly milkcap",
        "hun": "nyírfa-szőrgomba",
        "hun_syn": [
            "vörösbolyhos tejelőgomba"
        ]
    },
    "1252": {
        "latin": "Lactarius trivialis",
        "eng": "tacked milkcap",
        "hun": "északi tejelőgomba"
    },
    "1253": {
        "latin": "Lactarius uvidus",
        "eng": "shiner",
        "hun": "barnáslilás tejelőgomba",
        "hun_syn": [
            "lilásodó tejelőgomba"
        ]
    },
    "1254": {
        "latin": "Lactarius vietus",
        "eng": "grey milkcap",
        "hun": "szürkülő tejelőgomba",
        "hun_syn": [
            "szürkülő keserűgomba"
        ]
    },
    "1255": {
        "latin": "Lactarius violascens",
        "eng": "violet milkcap",
        "hun": "ibolyásodó tejelőgomba",
        "hun_syn": [
            "ibolyás tejelőgomba"
        ]
    },
    "1256": {
        "latin": "Lactarius zonarioides",
        "latin_syn": [
            "Lactarius bresadolanus"
        ],
        "hun": "lucfenyő-tejelőgomba"
    },
    "1257": {
        "latin": "Lactarius zonarius",
        "latin_syn": [
            "Lactarius insulsus"
        ],
        "hun": "begöngyöltszélű tejelőgomba",
        "hun_syn": [
            "begöngyöltszélű keserűgomba"
        ]
    },
    "1258": {
        "latin": "Lactifluus bertillonii",
        "latin_syn": [
            "Lactarius bertillonii"
        ],
        "hun": "csípőstejű keserűgomba"
    },
    "1259": {
        "latin": "Lactifluus glaucescens",
        "latin_syn": [
            "Lactarius glaucescens",
            "Lactarius pergamenus"
        ],
        "hun": "zöldülőtejű keserűgomba"
    },
    "1260": {
        "latin": "Lactifluus luteolus",
        "latin_syn": [
            "Lactarius luteolus"
        ],
        "hun": "barnafoltos tejelőgomba"
    },
    "1261": {
        "latin": "Lactifluus piperatus",
        "latin_syn": [
            "Lactarius piperatus"
        ],
        "hun": "fehértejű keserűgomba",
        "hun_syn": [
            "borsos tejelőgomba,  közönséges keserűgomba"
        ]
    },
    "1262": {
        "latin": "Lactifluus vellereus",
        "latin_syn": [
            "Lactarius vellereus"
        ],
        "hun": "pelyhes keserűgomba",
        "hun_syn": [
            "pelyhes tejelőgomba"
        ]
    },
    "1263": {
        "latin": "Lactifluus volemus",
        "latin_syn": [
            "Lactarius volemus"
        ],
        "hun": "kenyérgomba",
        "hun_syn": [
            "kenyér tejelőgomba"
        ]
    },
    "1264": {
        "latin": "Laeticutis cristatus",
        "latin_syn": [
            "Albatrellus cristatus"
        ],
        "hun": "zöldhátú zsemlegomba"
    },
    "1265": {
        "latin": "Laetiporus sulphureus",
        "eng": "chicken of the woods",
        "hun": "sárga gévagomba",
        "hun_syn": [
            "kénsárga gévagomba"
        ]
    },
    "1266": {
        "latin": "Lamprospora trachycarpa",
        "latin_syn": [
            "Plicaria trachycarpa"
        ],
        "hun": "gyászos tányérgomba"
    },
    "1267": {
        "latin": "Langermannia gigantea",
        "latin_syn": [
            "Calvatia gigantea"
        ],
        "hun": "óriás pöfeteg"
    },
    "1268": {
        "latin": "Lanmaoa fragrans",
        "latin_syn": [
            "Boletus fragrans"
        ],
        "hun": "illatos tinóru"
    },
    "1269": {
        "latin": "Lanzia echinophila",
        "eng": "hairy nuts disco ",
        "latin_syn": [
            "Rutstroemia echinophila",
            "Sclerotinia echinophila"
        ],
        "hun": "kupacslakó csészegomba"
    },
    "1270": {
        "latin": "Laricifomes officinalis",
        "latin_syn": [
            "Fomitopsis officinalis"
        ],
        "hun": "vörösfenyő-kérgestapló"
    },
    "1271": {
        "latin": "Laxitextum bicolor",
        "latin_syn": [
            "Stereum fuscum"
        ],
        "hun": "redős réteggomba"
    },
    "1272": {
        "latin": "Leccinellum crocipodium",
        "latin_syn": [
            "Leccinum crocipodium"
        ],
        "hun": "sárga érdestinóru"
    },
    "1273": {
        "latin": "Leccinum albostipitatum",
        "eng": "aspen bolete",
        "latin_syn": [
            "Leccinum aurantiacum s. Pilát"
        ],
        "hun": "vörös érdestinóru"
    },
    "1274": {
        "latin": "Leccinum aurantiacum",
        "eng": "orange bolete",
        "latin_syn": [
            "Leccinum quercinum"
        ],
        "hun": "tölgyfa-érdestinóru",
        "hun_syn": [
            "kozákgomba",
            "vörös érdestinóru"
        ]
    },
    "1275": {
        "latin": "Leccinum cyaneobasileucum",
        "eng": "greyshank bolete",
        "latin_syn": [
            "Leccinum brunneogriseolum"
        ],
        "hun": "barnásszürke érdestinóru"
    },
    "1276": {
        "latin": "Leccinum duriusculum",
        "eng": "slate bolete",
        "hun": "nyárfa-érdestinóru",
        "hun_syn": [
            "nyárfa-tinóru"
        ]
    },
    "1277": {
        "latin": "Leccinum holopus",
        "eng": "ghost bolete",
        "hun": "lápi érdestinóru"
    },
    "1278": {
        "latin": "Leccinum pseudoscabrum",
        "eng": "hazel bolete",
        "latin_syn": [
            "Leccinum carpini",
            "Leccinum griseum"
        ],
        "hun": "sötét érdestinóru"
    },
    "1279": {
        "latin": "Leccinum scabrum",
        "eng": "brown birch bolete",
        "latin_syn": [
            "Leccinum avellaneum",
            "Leccinum molle"
        ],
        "hun": "barna érdestinóru",
        "hun_syn": [
            "halvány érdestinóru",
            "puha érdestinorú"
        ]
    },
    "1280": {
        "latin": "Leccinum umbrinoides",
        "hun": "zöldfoltos érdestinóru"
    },
    "1281": {
        "latin": "Leccinum variicolor",
        "eng": "mottled bolete",
        "hun": "tarkahúsú érdestinóru"
    },
    "1282": {
        "latin": "Leccinum versipelle",
        "eng": "orange birch bolete",
        "latin_syn": [
            "Leccinum testaceoscabrum"
        ],
        "hun": "kormostönkű érdestinóru"
    },
    "1283": {
        "latin": "Lentaria afflata",
        "hun": "barnahegyű korallgomba"
    },
    "1284": {
        "latin": "Lentaria byssiseda",
        "hun": "talpas korallgomba"
    },
    "1285": {
        "latin": "Lentinellus cochleatus",
        "eng": "aniseed cockleshell",
        "hun": "ánizsszagú fűrészgomba",
        "hun_syn": [
            "ánizsszagú fagomba"
        ]
    },
    "1286": {
        "latin": "Lentinellus flabelliformis",
        "hun": "legyezőalakú fűrészgomba"
    },
    "1287": {
        "latin": "Lentinellus micheneri",
        "latin_syn": [
            "Lentinellus omphalodes"
        ],
        "hun": "köldökös fűrészgomba",
        "hun_syn": [
            "köldökös fagomba"
        ]
    },
    "1288": {
        "latin": "Lentinellus ursinus",
        "hun": "szöszös fűrészgomba",
        "hun_syn": [
            "nemezes fagomba",
            "szöszös fűrészesgomba"
        ]
    },
    "1289": {
        "latin": "Lentinellus vulpinus",
        "latin_syn": [
            "Lentinus vulpinus"
        ],
        "hun": "nemezes fűrészgomba",
        "hun_syn": [
            "tönktelen fagomba"
        ]
    },
    "1290": {
        "latin": "Lentinula edodes",
        "eng": "shiitake",
        "latin_syn": [
            "Lentinus edodes"
        ],
        "hun": "shiitake",
        "hun_syn": [
            "illatos fagomba",
            "japángomba",
            "shiitake fagomba"
        ]
    },
    "1291": {
        "latin": "Lentinus tigrinus",
        "eng": "tiger sawgill",
        "latin_syn": [
            "Panus tigrinus"
        ],
        "hun": "nyár-fagomba",
        "hun_syn": [
            "közönséges nyárfa-fagomba,  nyárfa dücskőgomba",
            "pikkelyes fagomba"
        ]
    },
    "1292": {
        "latin": "Lenzites betulina",
        "hun": "fakó lemezestapló"
    },
    "1293": {
        "latin": "Leotia lubrica",
        "eng": "jellybaby",
        "latin_syn": [
            "Leotia gelatinosa"
        ],
        "hun": "zöld csuklyásgomba"
    },
    "1294": {
        "latin": "Lepiota boudieri",
        "eng": "girdled dapperling",
        "latin_syn": [
            "Lepiota fulvella"
        ],
        "hun": "rókaszínű őzlábgomba",
        "hun_syn": [
            "sárgásbarna őzlábgomba"
        ]
    },
    "1295": {
        "latin": "Lepiota brunneoincarnata",
        "eng": "deadly dapperling",
        "hun": "húsbarnás őzlábgomba"
    },
    "1296": {
        "latin": "Lepiota castanea",
        "eng": "chestnut dapperling",
        "hun": "gesztenye-őzlábgomba",
        "hun_syn": [
            "gesztenye őzlábgomba"
        ]
    },
    "1297": {
        "latin": "Lepiota cingulum",
        "hun": "sávostönkű őzlábgomba"
    },
    "1298": {
        "latin": "Lepiota clypeolaria",
        "eng": "shield dapperling",
        "hun": "gyapjas őzlábgomba"
    },
    "1299": {
        "latin": "Lepiota cortinarius",
        "hun": "pókhálós őzlábgomba"
    },
    "1300": {
        "latin": "Lepiota cristata",
        "eng": "stinking dapperling",
        "hun": "büdös őzlábgomba"
    },
    "1301": {
        "latin": "Lepiota echinella",
        "latin_syn": [
            "Lepiota setulosa"
        ],
        "hun": "sörtés őzlábgomba"
    },
    "1302": {
        "latin": "Lepiota erminea",
        "eng": "dune dapperling",
        "latin_syn": [
            "Lepiota alba"
        ],
        "hun": "fehér őzlábgomba",
        "hun_syn": [
            "retekszagú őzlábgomba"
        ]
    },
    "1303": {
        "latin": "Lepiota felina",
        "eng": "cat dapperling",
        "hun": "feketepikkelyes őzlábgomba"
    },
    "1304": {
        "latin": "Lepiota forquignonii",
        "hun": "olajszürke őzlábgomba"
    },
    "1305": {
        "latin": "Lepiota fuscovinacea",
        "eng": "dark dapperling",
        "hun": "borbarna őzlábgomba"
    },
    "1306": {
        "latin": "Lepiota grangei",
        "eng": "green dapperling",
        "hun": "kékeszöld őzlábgomba",
        "hun_syn": [
            "zöldes őzlábgomba"
        ]
    },
    "1307": {
        "latin": "Lepiota helveola",
        "hun": "vörhenyes őzlábgomba",
        "hun_syn": [
            "vöröses őzlábgomba"
        ]
    },
    "1308": {
        "latin": "Lepiota ignicolor",
        "hun": "tűzszínű őzlábgomba"
    },
    "1309": {
        "latin": "Lepiota ignivolvata",
        "hun": "vöröslábú őzlábgomba "
    },
    "1310": {
        "latin": "Lepiota kuehneri",
        "hun": "mogyoróbarna őzlábgomba",
        "hun_syn": [
            "rózsásövű őzlábgomba"
        ]
    },
    "1311": {
        "latin": "Lepiota lilacea",
        "hun": "lilás őzlábgomba",
        "hun_syn": [
            "lila őzlábgomba"
        ]
    },
    "1312": {
        "latin": "Lepiota magnispora",
        "eng": "yellowfoot dapplerling",
        "latin_syn": [
            "Lepiota ventriosospora"
        ],
        "hun": "hasasspórás őzlábgomba",
        "hun_syn": [
            "sárgapelyhű őzlábgomba"
        ]
    },
    "1313": {
        "latin": "Lepiota micropholis",
        "hun": "feketeöves őzlábgomba"
    },
    "1314": {
        "latin": "Lepiota ochraceofulva",
        "hun": "okkerbarna őzlábgomba"
    },
    "1315": {
        "latin": "Lepiota oreadiformis",
        "hun": "sima őzlábgomba"
    },
    "1316": {
        "latin": "Lepiota pseudofelina",
        "hun": "szürkés őzlábgomba",
        "hun_syn": [
            "sötétpikkelyes őzlábgomba"
        ]
    },
    "1317": {
        "latin": "Lepiota pseudolilacea",
        "latin_syn": [
            "Lepiota pseudohelveola"
        ],
        "hun": "barnagallérú őzlábgomba",
        "hun_syn": [
            "barnásibolya őzlábgomba",
            "rózsástönkű őzlábgomba"
        ]
    },
    "1318": {
        "latin": "Lepiota subgracilis",
        "hun": "csinos őzlábgomba"
    },
    "1319": {
        "latin": "Lepiota subincarnata",
        "eng": "fatal dapperling",
        "latin_syn": [
            "Lepiota josserandii"
        ],
        "hun": "rózsás őzlábgomba",
        "hun_syn": [
            "húsrózsás őzlábgomba,  rózsáslemezű őzlábgomba"
        ]
    },
    "1320": {
        "latin": "Lepista densifolia",
        "hun": "sűrűlemezű tölcsérgomba",
        "hun_syn": [
            "sűrűlemezű tölcsérpereszke"
        ]
    },
    "1321": {
        "latin": "Lepista glaucocana",
        "hun": "halványlila pereszke"
    },
    "1322": {
        "latin": "Lepista irina",
        "eng": "flowery blewits",
        "hun": "illatos pereszke"
    },
    "1323": {
        "latin": "Lepista nuda",
        "eng": "wood blewits",
        "hun": "lila pereszke",
        "hun_syn": [
            "lila tölcsérpereszke"
        ]
    },
    "1324": {
        "latin": "Lepista panaeolus",
        "eng": "spotted blewits",
        "latin_syn": [
            "Lepista luscina",
            "Lepista rickenii"
        ],
        "hun": "márványos pereszke",
        "hun_syn": [
            "márványos tölcsérpereszke"
        ]
    },
    "1325": {
        "latin": "Lepista personata",
        "latin_syn": [
            "Lepista saeva"
        ],
        "hun": "lilatönkű pereszke",
        "hun_syn": [
            "lilatönkű tölcsérpereszke"
        ]
    },
    "1326": {
        "latin": "Lepista sordida",
        "eng": "sordid blewits",
        "hun": "szürkéslila pereszke",
        "hun_syn": [
            "piszkos pereszke",
            "szürkésbarna tölcsérpereszke"
        ]
    },
    "1327": {
        "latin": "Leptoporus mollis",
        "eng": "soft bracket",
        "latin_syn": [
            "Tyromyces mollis"
        ],
        "hun": "lágy likacsosgomba"
    },
    "1328": {
        "latin": "Leratiomyces ceres",
        "eng": "redlead roundhead",
        "latin_syn": [
            "Stropharia aurantiaca"
        ],
        "hun": "narancsvörös harmatgomba",
        "hun_syn": [
            "téglavörös  harmatgomba"
        ]
    },
    "1329": {
        "latin": "Leratiomyces squamosus",
        "eng": "var. thraustus slender roundhead",
        "latin_syn": [
            "Stropharia squamosa"
        ],
        "hun": "pikkelyes harmatgomba"
    },
    "1330": {
        "latin": "Leucangium carthusianum",
        "latin_syn": [
            "Picoa carthusiana"
        ],
        "hun": "oregoni sötéttrifla",
        "hun_syn": [
            "sötét szarvasgomba",
            "karthauzi szarvasgomba"
        ]
    },
    "1331": {
        "latin": "Leucoagaricus americanus",
        "latin_syn": [
            "Leucocoprinus bresadolea"
        ],
        "hun": "rézvörös őzlábgomba"
    },
    "1332": {
        "latin": "Leucoagaricus barssii",
        "eng": "smoky dapperling",
        "latin_syn": [
            "Leucoagarcius macrorhizus"
        ],
        "hun": "gyökeres tarlógomba"
    },
    "1333": {
        "latin": "Leucoagaricus brunneolilacinus",
        "hun": "barnáslila tarlógomba"
    },
    "1334": {
        "latin": "Leucoagaricus cinerascens",
        "latin_syn": [
            "Leucoagarcius leucothites var. cinerascens"
        ],
        "hun": "szürkés tarlógomba",
        "hun_syn": [
            "szürkekalapú tarlógomba"
        ]
    },
    "1335": {
        "latin": "Leucoagaricus ionidicolor",
        "hun": "lilás tarlógomba",
        "hun_syn": [
            "lilás tarló-őzlábgomba"
        ]
    },
    "1336": {
        "latin": "Leucoagaricus leucothites",
        "eng": "white dapperling",
        "latin_syn": [
            "Leucoagaricus carneifolius",
            "Leucoagaricus holosericeus",
            "Leucoagaricus naucinus",
            "Leucoagarcius pudicus"
        ],
        "hun": "fehér tarlógomba",
        "hun_syn": [
            "rózsáslemezű tarlógomba",
            "selymes tarlógomba",
            "tarló-őzlábgomba"
        ]
    },
    "1337": {
        "latin": "Leucoagaricus meleagris",
        "latin_syn": [
            "Leucocoprinus meleagris"
        ],
        "hun": "vörösödő tarlógomba",
        "hun_syn": [
            "görbetönkű tarlógomba"
        ]
    },
    "1338": {
        "latin": "Leucoagaricus nympharum",
        "latin_syn": [
            "Macrolepiota puellaris"
        ],
        "hun": "kecses őzlábgomba",
        "hun_syn": [
            "gyenge nagyőzlábgomba"
        ]
    },
    "1339": {
        "latin": "Leucoagaricus serenus",
        "latin_syn": [
            "Sericeomyces serenus"
        ],
        "hun": "selymes tarlógomba",
        "hun_syn": [
            "fehér selymes-tarlógomba"
        ]
    },
    "1340": {
        "latin": "Leucoagaricus sericifer",
        "latin_syn": [
            "Lepiota sericifera",
            "Sericeomyces sericatus"
        ],
        "hun": "karcsú tarlógomba",
        "hun_syn": [
            "karcsú selymes-őzlábgomba"
        ]
    },
    "1341": {
        "latin": "Leucoagaricus subvolvatus",
        "latin_syn": [
            "Sericeomyces subvolvatus"
        ],
        "hun": "bocskoros tarlógomba",
        "hun_syn": [
            "gumós selymes-őzlábgomba",
            "gumós tarlógomba"
        ]
    },
    "1342": {
        "latin": "Leucoagaricus wichanskyi",
        "hun": "rózsásbarna tarlógomba"
    },
    "1343": {
        "latin": "Leucocoprinus badhamii",
        "hun": "feketedő őzlábgomba"
    },
    "1344": {
        "latin": "Leucocoprinus birnbaumii",
        "eng": "plantpot dapperling",
        "latin_syn": [
            "Leucocoprinus flos-sulphuris"
        ],
        "hun": "sárga bordásőzlábgomba",
        "hun_syn": [
            "sárga bordás-őzlábgomba"
        ]
    },
    "1345": {
        "latin": "Leucocoprinus cepistipes",
        "hun": "hagymatönkű bordásőzlábgomba",
        "hun_syn": [
            "bordás őzlábgomba"
        ]
    },
    "1346": {
        "latin": "Leucocortinarius bulbiger",
        "eng": "white webcap",
        "hun": "gumós pereszke",
        "hun_syn": [
            "csalóka álpókhálósgomba"
        ]
    },
    "1347": {
        "latin": "Leucocybe candicans",
        "eng": "white funnel",
        "latin_syn": [
            "Clitocybe candicans"
        ],
        "hun": "viaszostönkű tölcsérgomba"
    },
    "1348": {
        "latin": "Leucocybe connata",
        "eng": "white domecap",
        "latin_syn": [
            "Clitocybe connata",
            "Lyophyllum connatum"
        ],
        "hun": "fehércsokros álpereszke"
    },
    "1349": {
        "latin": "Leucogaster nudus",
        "hun": "meztelen triflapöfeteg",
        "hun_syn": [
            "fehérbelű álpöfeteg",
            ""
        ]
    },
    "1350": {
        "latin": "Leucogyrophana mollusca",
        "hun": "lágy redősgomba"
    },
    "1351": {
        "latin": "Leucopaxillus gentianeus",
        "eng": "bitter funnel",
        "hun": "keserű álpereszke"
    },
    "1352": {
        "latin": "Leucopaxillus mirabilis",
        "hun": "csodás álpereszke",
        "hun_syn": [
            "csodás tölcsérgomba"
        ]
    },
    "1353": {
        "latin": "Leucopaxillus paradoxus",
        "hun": "fehéres álpereszke"
    },
    "1354": {
        "latin": "Leucopaxillus tricolor",
        "latin_syn": [
            "Leucopaxillus compactus"
        ],
        "hun": "háromszínű álpereszke",
        "hun_syn": [
            "háromszínű cölöppereszke"
        ]
    },
    "1355": {
        "latin": "Lichenomphalia umbellifera",
        "eng": "heath navel",
        "latin_syn": [
            "Omphalina ericetorum",
            "Phytoconis ericetorum"
        ],
        "hun": "ráncos békagomba"
    },
    "1356": {
        "latin": "Limacella delicata",
        "latin_syn": [
            "Limacella glioderma"
        ],
        "hun": "tapadós nyálkásgalóca",
        "hun_syn": [
            "vörösesbarna nyálkásgomba",
            "nyálkás őzlábgomba"
        ]
    },
    "1357": {
        "latin": "Limacella guttata",
        "eng": "weeping slimecap",
        "latin_syn": [
            "Limacella lenticularis"
        ],
        "hun": "izzadó nyálkásgalóca",
        "hun_syn": [
            "izzadó őzlábgomba"
        ]
    },
    "1358": {
        "latin": "Limacella illinita",
        "eng": "dripping slimecap",
        "hun": "csupasztönkű nyálkásgalóca",
        "hun_syn": [
            "nyálkástönkű őzlábgomba"
        ]
    },
    "1359": {
        "latin": "Limacella ochraceolutea",
        "eng": "ochre slimecap",
        "hun": "okkersárga nyálkásgalóca"
    },
    "1360": {
        "latin": "Lindtneria trachyspora",
        "hun": "lágy kéreggomba"
    },
    "1361": {
        "latin": "Lopharia spadicea",
        "latin_syn": [
            "Porostereum spadiceum"
        ],
        "hun": "sötét réteggomba",
        "hun_syn": [
            "színváltó réteggomba"
        ]
    },
    "1362": {
        "latin": "Lycoperdon atropurpureum",
        "hun": "bíborbélű pöfeteg"
    },
    "1363": {
        "latin": "Lycoperdon caudatum",
        "eng": "pedicel puffball",
        "hun": "nyelesspórás pöfeteg"
    },
    "1364": {
        "latin": "Lycoperdon decipiens",
        "eng": "steppe puffball",
        "hun": "pusztai pöfeteg",
        "hun_syn": [
            "pusztai bimbóspöfeteg"
        ]
    },
    "1365": {
        "latin": "Lycoperdon dermoxanthum",
        "latin_syn": [
            "Lycoperdon hungaricum"
        ],
        "hun": "ráncos pöfeteg"
    },
    "1366": {
        "latin": "Lycoperdon echinatum",
        "eng": "spiny puffball",
        "hun": "tüskés pöfeteg",
        "hun_syn": [
            "tüskés bimbóspöfeteg"
        ]
    },
    "1367": {
        "latin": "Lycoperdon excipuliforme",
        "eng": "pestle puffball",
        "latin_syn": [
            "Calvatia excipuliformis",
            "Calvatia saccata"
        ],
        "hun": "változékony pöfeteg",
        "hun_syn": [
            "változékony szétesőpöfeteg"
        ]
    },
    "1368": {
        "latin": "Lycoperdon lividum",
        "eng": "grassland puffball",
        "latin_syn": [
            "Lycoperdon spadiceum"
        ],
        "hun": "réti pöfeteg",
        "hun_syn": [
            "gesztenyebarna pöfeteg",
            "gyökeres pöfeteg",
            "szürkésbarna bimbóspöfeteg"
        ]
    },
    "1369": {
        "latin": "Lycoperdon mammiforme",
        "eng": "flaky puffball",
        "latin_syn": [
            "Lycoperdon mammiforme"
        ],
        "hun": "cafatos pöfeteg",
        "hun_syn": [
            "pikkelyes bimbóspöfeteg"
        ]
    },
    "1370": {
        "latin": "Lycoperdon molle",
        "eng": "soft puffball",
        "hun": "barnás pöfeteg",
        "hun_syn": [
            "puha bimbóspöfeteg"
        ]
    },
    "1371": {
        "latin": "Lycoperdon nigrescens",
        "eng": "dusky puffball",
        "latin_syn": [
            "Lycoperdon foetidum"
        ],
        "hun": "büdös pöfeteg",
        "hun_syn": [
            "sötét bimbóspöfeteg"
        ]
    },
    "1372": {
        "latin": "Lycoperdon perlatum",
        "eng": "common puffball",
        "latin_syn": [
            "Lycoperdon gemmatum"
        ],
        "hun": "bimbós pöfeteg"
    },
    "1373": {
        "latin": "Lycoperdon pratense",
        "eng": "meadow puffball",
        "latin_syn": [
            "Vascellum pratense"
        ],
        "hun": "szélesszájú pöfeteg",
        "hun_syn": [
            "barnás pöfeteg"
        ]
    },
    "1374": {
        "latin": "Lycoperdon pyriforme",
        "hun": "körtealakú pöfeteg",
        "hun_syn": [
            "csoportos pöfeteg"
        ]
    },
    "1375": {
        "latin": "Lycoperdon radicatum",
        "eng": "rooting puffball",
        "latin_syn": [
            "Bovistella radicata"
        ],
        "hun": "gyökeres pöfeteg"
    },
    "1376": {
        "latin": "Lycoperdon umbrinum",
        "eng": "umber puffball",
        "hun": "üstökös pöfeteg"
    },
    "1377": {
        "latin": "Lycoperdon utriforme",
        "eng": "mosaic puffball",
        "latin_syn": [
            "Calvatia caelata",
            "Calvatia utriformis",
            "Handkea utriformis"
        ],
        "hun": "pikkelyes pöfeteg",
        "hun_syn": [
            "pikkelyes szétesőpöfeteg"
        ]
    },
    "1378": {
        "latin": "Lyophyllum amariusculum",
        "hun": "kesernyés álpereszke"
    },
    "1379": {
        "latin": "Lyophyllum decastes",
        "eng": "clustered domecap",
        "latin_syn": [
            "Lyophyllum aggregatum"
        ],
        "hun": "sötét csoportospereszke",
        "hun_syn": [
            "csoportos álpereszke",
            "csoportos pereszke"
        ]
    },
    "1380": {
        "latin": "Lyophyllum fumosum",
        "hun": "barna csoportospereszke",
        "hun_syn": [
            "bokros álpereszke",
            "csoportos pereszke",
            "sötét pereszke"
        ]
    },
    "1381": {
        "latin": "Lyophyllum hebelomoides",
        "latin_syn": [
            "Collybia hebelomoides"
        ],
        "hun": "fakó szürkefülőke",
        "hun_syn": [
            "fakó fülőke"
        ]
    },
    "1382": {
        "latin": "Lyophyllum loricatum",
        "eng": "gristly domecap",
        "hun": "gesztenyebarna csoportospereszke",
        "hun_syn": [
            "gesztenyebarna álpereszke"
        ]
    },
    "1383": {
        "latin": "Lyophyllum putidum",
        "latin_syn": [
            "Tephrocybe putidellum"
        ],
        "hun": "büdös szürkefülőke",
        "hun_syn": [
            "büdös álpereszke"
        ]
    },
    "1384": {
        "latin": "Lyophyllum semitale",
        "hun": "foltosodó álpereszke",
        "hun_syn": [
            "foltosodó pereszke"
        ]
    },
    "1385": {
        "latin": "Lyophyllum transforme",
        "latin_syn": [
            "Lyophyllum trigonosporum"
        ],
        "hun": "kékülő álpereszke"
    },
    "1386": {
        "latin": "Lysurus corallocephalus",
        "eng": "coralhead stinkhorn",
        "latin_syn": [
            "Kalchbrennera corallocephla"
        ],
        "hun": "korallfejűgomba"
    },
    "1387": {
        "latin": "Macowanites krjukowensis",
        "latin_syn": [
            "Arcangeliella krjukowensis",
            "Hydnangium krjukowense"
        ],
        "hun": "hornyolt álpöfeteg"
    },
    "1388": {
        "latin": "Macowanites mattiroloanus",
        "latin_syn": [
            "Elasmomyces mattiroloanus"
        ],
        "hun": "lemezes galambpöfeteg",
        "hun_syn": [
            "lemezes álpöfeteg"
        ]
    },
    "1389": {
        "latin": "Macrocystidia cucumis",
        "eng": "cucumber cap",
        "hun": "hamvas uborkagomba",
        "hun_syn": [
            "uborkaszagú szemétgomba"
        ]
    },
    "1390": {
        "latin": "Macrolepiota excoriata",
        "hun": "csipkés őzlábgomba"
    },
    "1391": {
        "latin": "Macrolepiota mastoidea",
        "eng": "slender parasol",
        "latin_syn": [
            "Macrolepiota gracilenta",
            "Macrolepiota rickenii"
        ],
        "hun": "karcsú őzlábgomba"
    },
    "1392": {
        "latin": "Macrolepiota permixta",
        "hun": "vörösödő őzlábgomba",
        "hun_syn": [
            "sötéthúsú nagyőzlábgomba"
        ]
    },
    "1393": {
        "latin": "Macrolepiota procera",
        "eng": "parasol",
        "hun": "nagy őzlábgomba",
        "hun_syn": [
            "gyakori nagyőzlábgomba"
        ]
    },
    "1394": {
        "latin": "Marasmiellus candidus",
        "hun": "hófehér szegfűgombácska"
    },
    "1395": {
        "latin": "Marasmiellus ramealis",
        "eng": "twig parachute",
        "hun": "fántermő szegfűgombácska",
        "hun_syn": [
            "fántermő szegfűgomba"
        ]
    },
    "1396": {
        "latin": "Marasmiellus tricolor",
        "hun": "háromszínű szegfűgombácska"
    },
    "1397": {
        "latin": "Marasmiellus vaillantii",
        "eng": "goblet parachute",
        "latin_syn": [
            "Marasmius languidus"
        ],
        "hun": "fakó szegfűgombácska"
    },
    "1398": {
        "latin": "Marasmius anomalus",
        "hun": "homoki szegfűgomba"
    },
    "1399": {
        "latin": "Marasmius bulliardii",
        "hun": "feketeközepű szegfűgomba",
        "hun_syn": [
            "cérnatönkű szegfűgomba"
        ]
    },
    "1400": {
        "latin": "Marasmius cohaerens",
        "eng": "bristled parachute",
        "hun": "szarutönkű szegfűgomba"
    },
    "1401": {
        "latin": "Marasmius collinus",
        "hun": "üregestönkű szegfűgomba"
    },
    "1402": {
        "latin": "Marasmius curreyi",
        "eng": "grass parachute",
        "latin_syn": [
            "Marasmius graminum s. auct."
        ],
        "hun": "narancsvörös szegfűgomba"
    },
    "1403": {
        "latin": "Marasmius epiphylloides",
        "eng": "ivy parachute",
        "hun": "borostyán-szegfűgomba"
    },
    "1404": {
        "latin": "Marasmius epiphyllus",
        "eng": "leaf parachute",
        "hun": "apró szegfűgomba"
    },
    "1405": {
        "latin": "Marasmius hudsonii",
        "eng": "holly parachute",
        "hun": "sörtés szegfűgomba"
    },
    "1406": {
        "latin": "Marasmius oreades",
        "eng": "fairy ring champignon",
        "hun": "mezei szegfűgomba",
        "hun_syn": [
            "csirkegomba",
            "illatos szegfűgomba",
            "közönséges szegfűgomba"
        ]
    },
    "1407": {
        "latin": "Marasmius rotula",
        "eng": "collared parachute",
        "hun": "nyakörves szegfűgomba"
    },
    "1408": {
        "latin": "Marasmius torquescens",
        "latin_syn": [
            "Marasmius lupuletorum"
        ],
        "hun": "sárgás szegfűgomba",
        "hun_syn": [
            "bársonyosövű szegfűgomba"
        ]
    },
    "1409": {
        "latin": "Marasmius undatus",
        "latin_syn": [
            "Marasmius chordalis",
            "Rhizomarasmius undatus"
        ],
        "hun": "vörösfoltos szegfűgomba"
    },
    "1410": {
        "latin": "Marasmius wynneae",
        "latin_syn": [
            "Marasmius wynnei"
        ],
        "hun": "erdei szegfűgomba",
        "hun_syn": [
            "változékony szegfűgomba"
        ]
    },
    "1411": {
        "latin": "Mattirolomyces terfezioides",
        "latin_syn": [
            "Choiromyces terfezioides",
            "Terfezia terfezioides"
        ],
        "hun": "homoki szarvasgomba",
        "hun_syn": [
            "Mattirolo-szarvasgomba"
        ]
    },
    "1412": {
        "latin": "Melanogaster ambiguus",
        "hun": "apró kocsonyáspöfeteg",
        "hun_syn": [
            "fehérkamrás kocsonyás-álpöfeteg",
            "kocsonyás-álpöfeteg"
        ]
    },
    "1413": {
        "latin": "Melanogaster broomeanus",
        "hun": "változékony kocsonyáspöfeteg",
        "hun_syn": [
            "tarka kocsonyás-álpöfeteg"
        ]
    },
    "1414": {
        "latin": "Melanogaster intermedius",
        "hun": "barna kocsonyáspöfeteg",
        "hun_syn": [
            "barna kocsonyásálpöfeteg"
        ]
    },
    "1415": {
        "latin": "Melanogaster macrosporus",
        "hun": "bükkös kocsonyáspöfeteg"
    },
    "1416": {
        "latin": "Melanogaster odoratissimus",
        "hun": "illatos kocsonyáspöfeteg",
        "hun_syn": [
            "illatos kocsonyásálpöfeteg"
        ]
    },
    "1417": {
        "latin": "Melanogaster rubescens",
        "hun": "színtváltó kocsonyáspöfeteg",
        "hun_syn": [
            "színtváltó kocsonyás-álpöfeteg"
        ]
    },
    "1418": {
        "latin": "Melanogaster variegatus",
        "hun": "vörösbarna kocsonyáspöfeteg",
        "hun_syn": [
            "vörös szarvasgomba",
            "vörösbarna álpöfeteg",
            "vörösbarna kocsonyás-álpöfeteg"
        ]
    },
    "1419": {
        "latin": "Melanoleuca brevipes",
        "eng": "stunted cavalier",
        "hun": "rövidtönkű csupaszpereszke",
        "hun_syn": [
            "rövidtönkű lágypereszke",
            "rövidtönkű pereszke"
        ]
    },
    "1420": {
        "latin": "Melanoleuca cinereifolia",
        "eng": "dune cavalier",
        "hun": "szürkéslemezű csupaszpereszke"
    },
    "1421": {
        "latin": "Melanoleuca cognata",
        "eng": "spring cavalier",
        "hun": "fakósárga csupaszpereszke",
        "hun_syn": [
            "fakósárga pereszke",
            "korai lágypereszke"
        ]
    },
    "1422": {
        "latin": "Melanoleuca exscissa",
        "eng": "smoky cavalier",
        "hun": "fakószürke csupaszpereszke",
        "hun_syn": [
            "fakószürke lágypereszke"
        ]
    },
    "1423": {
        "latin": "Melanoleuca friesii",
        "latin_syn": [
            "Melanoleuca albifolia"
        ],
        "hun": "hófehérlemezű csupaszpereszke"
    },
    "1424": {
        "latin": "Melanoleuca graminicola",
        "hun": "zsírosfényű csupaszpereszke"
    },
    "1425": {
        "latin": "Melanoleuca grammopodia",
        "eng": "grooved cavalier",
        "hun": "rovátkolttönkű csupaszpereszke"
    },
    "1426": {
        "latin": "Melanoleuca juliannae",
        "hun": "pusztai csupaszpereszke",
        "hun_syn": [
            "pusztai lágypereszke"
        ]
    },
    "1427": {
        "latin": "Melanoleuca melaleuca",
        "eng": "bald knight",
        "hun": "sötétlábú csupaszpereszke",
        "hun_syn": [
            "sötétlábú pereszke",
            "változékony lágypereszke",
            "változékony pereszke"
        ]
    },
    "1428": {
        "latin": "Melanoleuca metrodii",
        "hun": "lisztestönkű csupaszpereszke",
        "hun_syn": [
            "lisztestönkű lágypereszke"
        ]
    },
    "1429": {
        "latin": "Melanoleuca polioleuca",
        "eng": "common cavalier",
        "hun": "márványos csupaszpereszke",
        "hun_syn": [
            "márványos lágypereszke"
        ]
    },
    "1430": {
        "latin": "Melanoleuca pseudoevenosa",
        "hun": "fehéres csupaszpereszke",
        "hun_syn": [
            "fehéres lágypereszke"
        ]
    },
    "1431": {
        "latin": "Melanoleuca strictipes",
        "latin_syn": [
            "Melanoleuca evenosa",
            "Melanoleuca subalpina"
        ],
        "hun": "fehér csupaszpereszke",
        "hun_syn": [
            "merevtönkű pereszke",
            "hegyaljai lágypereszke"
        ]
    },
    "1432": {
        "latin": "Melanoleuca subpulverulenta",
        "hun": "deres csupaszpereszke",
        "hun_syn": [
            "deres lágypereszke"
        ]
    },
    "1433": {
        "latin": "Melanoleuca verrucipes",
        "eng": "warty cavalier",
        "hun": "szemölcsös csupaszpereszke",
        "hun_syn": [
            "szemcséstönkű lágypereszke",
            "szemölcsös pereszke"
        ]
    },
    "1434": {
        "latin": "Melanoleucea stridula",
        "hun": "kormos csupaszpereszke",
        "hun_syn": [
            "kormos lágypereszke"
        ]
    },
    "1435": {
        "latin": "Melanophyllum haematospermum",
        "eng": "redspored dapperling",
        "latin_syn": [
            "Melanophyllum echinatum"
        ],
        "hun": "vöröslemezű őzlábgomba",
        "hun_syn": [
            "vöröslemezű korpás-őzlábgomba"
        ]
    },
    "1436": {
        "latin": "Melastiza chateri",
        "hun": "cinóbervörös csészegomba"
    },
    "1437": {
        "latin": "Mensularia nodulosa",
        "latin_syn": [
            "Inonotus nodulosus"
        ],
        "hun": "bükkfa-rozsdástapló"
    },
    "1438": {
        "latin": "Mensularia radiata",
        "eng": "alder bracket",
        "latin_syn": [
            "Inonotus radiatus  Xan-"
        ],
        "hun": "égerfa-rozsdástapló",
        "hun_syn": [
            "ráncos likacsosgomba",
            "ráncos rozsdástapló"
        ]
    },
    "1439": {
        "latin": "Meottomyces dissimulans",
        "eng": "winter brownie",
        "latin_syn": [
            "Phaeogalera dissimulans",
            "Phaeogalera oedipus",
            "Pholiota oedipus"
        ],
        "hun": "piszkosbarna tőkegomba"
    },
    "1440": {
        "latin": "Meripilus giganteus",
        "eng": "giant polypore",
        "hun": "óriás bokrosgomba",
        "hun_syn": [
            "óriás likacsosgomba"
        ]
    },
    "1441": {
        "latin": "Microglossum olivaceum",
        "eng": "olive earthtongue",
        "hun": "olajbarna nyelvgombácska",
        "hun_syn": [
            "olajbarna kisnyelvgomba"
        ]
    },
    "1442": {
        "latin": "Microglossum viride",
        "eng": "green earthtongue",
        "hun": "zöld nyelvgombácska",
        "hun_syn": [
            "zöld kisnyelvgomba",
            ""
        ]
    },
    "1443": {
        "latin": "Microstoma protractum",
        "eng": "rosy goblet",
        "latin_syn": [
            "Sarcoscypha protracta"
        ],
        "hun": "csoportos csészegomba",
        "hun_syn": [
            "csoportos piros-csészegomba"
        ]
    },
    "1444": {
        "latin": "Miladina lecithina",
        "latin_syn": [
            "Humaria lecitina",
            "Pustularia lecitina"
        ],
        "hun": "tojássárga koronggombácska"
    },
    "1445": {
        "latin": "Mitrula paludosa",
        "eng": "bog beacon",
        "latin_syn": [
            "Mitrula phalloides"
        ],
        "hun": "mocsári sapkásgomba"
    },
    "1446": {
        "latin": "Mitrula sphaerocephala",
        "hun": "gömbölyű sapkásgomba"
    },
    "1447": {
        "latin": "Mollisia amenticola",
        "hun": "égertoboz-csészegombácska"
    },
    "1448": {
        "latin": "Mollisia cinerea",
        "eng": "common grey disco",
        "hun": "szürkés csészegombácska"
    },
    "1449": {
        "latin": "Mollisia ligni",
        "hun": "bomladék csészegombácska"
    },
    "1450": {
        "latin": "Montagnea radiosa",
        "latin_syn": [
            "Montagnites radiosus"
        ],
        "hun": "kalapos pöfeteg"
    },
    "1451": {
        "latin": "Morchella deliciosa",
        "latin_syn": [
            "Morchella conica s. auct.",
            "Morchella elata s. auct."
        ],
        "hun": "hegyes kucsmagomba",
        "hun_syn": [
            "magas kucsmagomba",
            "nyulánk kucsmagomba"
        ]
    },
    "1452": {
        "latin": "Morchella esculenta",
        "eng": "morel",
        "latin_syn": [
            "Morchella crassipes s. auct.",
            "Morchella rotunda"
        ],
        "hun": "ízletes kucsmagomba",
        "hun_syn": [
            "közönséges kucsmagomba",
            "sötét kucsmagomba",
            "vastaglábú kucsmagomba",
            "vastagtönkű kucsmagomba"
        ]
    },
    "1453": {
        "latin": "Morchella importuna",
        "hun": "fakéreg kucsmagomba"
    },
    "1454": {
        "latin": "Morchella semilibera",
        "eng": "semifree morel",
        "latin_syn": [
            "Mitrophora semilibera",
            "Morchella gigas"
        ],
        "hun": "fattyú kucsmagomba",
        "hun_syn": [
            "hagymasüvegű kucsmagomba",
            "ráncostönkű kucsmagomba",
            "vastagtönkű kucsmagomba"
        ]
    },
    "1455": {
        "latin": "Morchella steppicola",
        "hun": "pusztai kucsmagomba"
    },
    "1456": {
        "latin": "Morchella vulgaris",
        "hun": "sötét kucsmagomba"
    },
    "1457": {
        "latin": "Mucidula mucida",
        "eng": "porcelain fungus",
        "latin_syn": [
            "Oudemansiella mucida"
        ],
        "hun": "gyűrűs fülőke"
    },
    "1458": {
        "latin": "Mucronella calva",
        "eng": "swarming spine",
        "latin_syn": [
            "Mucronella aggregata"
        ],
        "hun": "szórványos tüskegomba"
    },
    "1459": {
        "latin": "Multiclavula mucida",
        "latin_syn": [
            "Lentaria mucida"
        ],
        "hun": "nyálkás bunkógomba"
    },
    "1460": {
        "latin": "Muscinupta laevis",
        "eng": "moss wife",
        "latin_syn": [
            "Cyphella muscigena"
        ],
        "hun": "mohalakó szemcsegomba"
    },
    "1461": {
        "latin": "Mutinus caninus",
        "eng": "dog stinkhorn",
        "hun": "közönséges kutyaszömörcsög",
        "hun_syn": [
            "kutyaszömörcsög"
        ]
    },
    "1462": {
        "latin": "Mutinus ravenelii",
        "hun": "málnavörös kutyaszömörcsög"
    },
    "1463": {
        "latin": "Mycena abramsii",
        "hun": "korai kígyógomba"
    },
    "1464": {
        "latin": "Mycena acicula",
        "eng": "orange bonnet",
        "hun": "narancsvörös kígyógomba"
    },
    "1465": {
        "latin": "Mycena aetites",
        "eng": "drab bonnet",
        "hun": "szürkelemezű kígyógomba"
    },
    "1466": {
        "latin": "Mycena amicta",
        "eng": "coldfoot bonnet",
        "hun": "kéklábú kígyógomba"
    },
    "1467": {
        "latin": "Mycena arcangeliana",
        "eng": "angel's bonnet",
        "hun": "halvány kígyógomba",
        "hun_syn": [
            "olajsárgás kígyógomba"
        ]
    },
    "1468": {
        "latin": "Mycena aurantiomarginata",
        "eng": "golden edge bonnet",
        "hun": "narancsszegélyű kígyógomba",
        "hun_syn": [
            "narancsosélű kígyógomba"
        ]
    },
    "1469": {
        "latin": "Mycena belliae",
        "eng": "reed bonnet",
        "hun": "nádi kígyógomba"
    },
    "1470": {
        "latin": "Mycena capillaris",
        "eng": "beechleaf bonnet",
        "hun": "gombostű-kígyógomba"
    },
    "1471": {
        "latin": "Mycena cinerella",
        "eng": "mealy bonnet",
        "latin_syn": [
            "Mycena serotina  Omphaia grisea"
        ],
        "hun": "hamuszürke kígyógomba"
    },
    "1472": {
        "latin": "Mycena citrinomarginata",
        "eng": "yellowedged bonnet",
        "hun": "sárgaszegélyű kígyógomba"
    },
    "1473": {
        "latin": "Mycena corticola",
        "hun": "ibolyás kígyógomba"
    },
    "1474": {
        "latin": "Mycena crocata",
        "eng": "saffrondrop bonnet",
        "hun": "sárgatejű kígyógomba"
    },
    "1475": {
        "latin": "Mycena diosma",
        "hun": "szagos kígyógomba"
    },
    "1476": {
        "latin": "Mycena epipterygia",
        "eng": "yellowleg bonnet",
        "hun": "enyves kígyógomba"
    },
    "1477": {
        "latin": "Mycena filopes",
        "eng": "iodine bonnet",
        "hun": "púpos kígyógomba"
    },
    "1478": {
        "latin": "Mycena galericulata",
        "eng": "common bonnet",
        "hun": "rózsáslemezű kígyógomba"
    },
    "1479": {
        "latin": "Mycena galopus",
        "eng": "var. nigra black milking bonnet",
        "hun": "fehértejű kígyógomba"
    },
    "1480": {
        "latin": "Mycena haematopus",
        "eng": "burgundydrop bonnet",
        "hun": "vérző kígyógomba"
    },
    "1481": {
        "latin": "Mycena hiemalis",
        "eng": "winter bonnet",
        "hun": "szürke kígyógomba",
        "hun_syn": [
            "szürke kéreggombácska"
        ]
    },
    "1482": {
        "latin": "Mycena inclinata",
        "eng": "clustered bonnet",
        "latin_syn": [
            "Mycena calopus"
        ],
        "hun": "cifra kígyógomba"
    },
    "1483": {
        "latin": "Mycena laevigata",
        "hun": "fenyő-kígyógomba",
        "hun_syn": [
            "kényes kígyógomba"
        ]
    },
    "1484": {
        "latin": "Mycena leptocephala",
        "eng": "nitrous bonnet",
        "hun": "klórszagú kígyógomba",
        "hun_syn": [
            "szagos kígyógomba"
        ]
    },
    "1485": {
        "latin": "Mycena leptophylla",
        "hun": "barackszínű kígyógomba",
        "hun_syn": [
            "kajszikígyógomba"
        ]
    },
    "1486": {
        "latin": "Mycena luteoalcalina",
        "hun": "sárgalábú kígyógomba"
    },
    "1487": {
        "latin": "Mycena lux-coeli",
        "hun": "lámpás kígyógomba"
    },
    "1488": {
        "latin": "Mycena maculata",
        "eng": "stained bonnet",
        "hun": "foltos kígyógomba"
    },
    "1489": {
        "latin": "Mycena olivaceomarginata",
        "eng": "brownedge bonnet",
        "hun": "gyepi kígyógomba"
    },
    "1490": {
        "latin": "Mycena pelianthina",
        "eng": "blackedge bonnet",
        "hun": "feketeszegélyű kígyógomba"
    },
    "1491": {
        "latin": "Mycena polygramma",
        "eng": "grooved bonnet",
        "hun": "barázdálttönkű kígyógomba"
    },
    "1492": {
        "latin": "Mycena pura",
        "eng": "lilac bonnet",
        "hun": "retekszagú kígyógomba"
    },
    "1493": {
        "latin": "Mycena renati",
        "eng": "beautiful bonnet",
        "latin_syn": [
            "Mycena flavipes"
        ],
        "hun": "sárgástönkű kígyógomba"
    },
    "1494": {
        "latin": "Mycena rosea",
        "eng": "rosy bonnet",
        "hun": "rózsás kígyógomba"
    },
    "1495": {
        "latin": "Mycena rosella",
        "eng": "pink bonnet",
        "hun": "rózsaszínélű kígyógomba"
    },
    "1496": {
        "latin": "Mycena rubromarginata",
        "eng": "red edge bonnet",
        "hun": "vörösszegélyű kígyógomba",
        "hun_syn": [
            "vörösbarnaélű kígyógomba"
        ]
    },
    "1497": {
        "latin": "Mycena sanguinolenta",
        "eng": "bleeding bonnet",
        "hun": "vöröstejű kígyógomba",
        "hun_syn": [
            "csillagpapucsú kígyógomba"
        ]
    },
    "1498": {
        "latin": "Mycena stipata",
        "eng": "clustered pine bonnet",
        "latin_syn": [
            "Mycena alcalina"
        ],
        "hun": "lúgszagú kígyógomba"
    },
    "1499": {
        "latin": "Mycena stylobates",
        "eng": "bulbous bonnet",
        "hun": "papucsos kígyógomba"
    },
    "1500": {
        "latin": "Mycena tintinnabulum",
        "hun": "téli kígyógomba"
    },
    "1501": {
        "latin": "Mycena viridimarginata",
        "eng": "olive edge bonnet",
        "latin_syn": [
            "Mycena luteoalcalina"
        ],
        "hun": "zöldesszegélyű kígyógomba"
    },
    "1502": {
        "latin": "Mycena vitilis",
        "eng": "snapping bonnet",
        "hun": "szívóstönkű kígyógomba"
    },
    "1503": {
        "latin": "Mycena vitrea",
        "hun": "üregestönkű kígyógomba"
    },
    "1504": {
        "latin": "Mycena vulgaris",
        "eng": "vulgar bonnet",
        "hun": "nyálkás kígyógomba"
    },
    "1505": {
        "latin": "Mycena zephirus",
        "hun": "rozsdafoltos kígyógomba"
    },
    "1506": {
        "latin": "Mycenastrum corium",
        "hun": "hasadt pöfeteg"
    },
    "1507": {
        "latin": "Mycetinis alliaceus",
        "eng": "garlic parachute",
        "latin_syn": [
            "Marasmius alliaceus"
        ],
        "hun": "sötéttönkű fokhagymagomba",
        "hun_syn": [
            "feketetönkű  szegfűgomba"
        ]
    },
    "1508": {
        "latin": "Mycetinis querceus",
        "latin_syn": [
            "Marasmius prasiosmus",
            "Marasmius querceus"
        ],
        "hun": "nagy fokhagymagomba",
        "hun_syn": [
            "csípős szegfűgomba"
        ]
    },
    "1509": {
        "latin": "Mycetinis scorodonius",
        "eng": "vampires bane",
        "latin_syn": [
            "Marasmius scorodonius"
        ],
        "hun": "barnatönkű fokhagymagomba",
        "hun_syn": [
            "fokhagymaszagú szegfűgomba"
        ]
    },
    "1510": {
        "latin": "Mycoacia aurea",
        "hun": "áttetsző fésűsgomba"
    },
    "1511": {
        "latin": "Myochromella boudieri",
        "latin_syn": [
            "Lyophyllum boudieri",
            "Tephrocybe boudieri"
        ],
        "hun": "kormosbarna szürkefülőke"
    },
    "1512": {
        "latin": "Myochromella inolens",
        "latin_syn": [
            "Lyophyllum inolens",
            "Tephrocybe inolens"
        ],
        "hun": "szagtalan szürkefülőke"
    },
    "1513": {
        "latin": "Myriostoma coliforme",
        "eng": "pepper pot",
        "hun": "szitaszájú csillaggomba"
    },
    "1514": {
        "latin": "Myrmecocystis vallisumbrosae",
        "latin_syn": [
            "Pseudogenea vallisumbrosae"
        ],
        "hun": "málnaszarvasgomba"
    },
    "1515": {
        "latin": "Myxomphalia maura",
        "latin_syn": [
            "Fayodia maura"
        ],
        "hun": "szenes békagomba",
        "hun_syn": [
            "szenes nyálkás-békagomba"
        ]
    },
    "1516": {
        "latin": "Naucoria alnetorum",
        "hun": "éger-lápigomba",
        "hun_syn": [
            "éger-szemétgomba"
        ]
    },
    "1517": {
        "latin": "Naucoria escharioides",
        "eng": "ochre aldercap",
        "latin_syn": [
            "Alnicola escharioides"
        ],
        "hun": "mézsárga lápigomba",
        "hun_syn": [
            "szemcsés szemétgomba"
        ]
    },
    "1518": {
        "latin": "Naucoria scolecina",
        "eng": "cinnamon aldercap",
        "latin_syn": [
            "Alnicola scolecina"
        ],
        "hun": "csupasz lápigomba"
    },
    "1519": {
        "latin": "Naucoria subconspersa",
        "eng": "scurfy aldercap",
        "latin_syn": [
            "Alnicola subconspersa"
        ],
        "hun": "pikkelykés lápigomba"
    },
    "1520": {
        "latin": "Naucoria submelinoides",
        "latin_syn": [
            "Alnicola submelinoides"
        ],
        "hun": "vastaglemezű lápigomba"
    },
    "1521": {
        "latin": "Nectria cinnabarina",
        "eng": "coral spot",
        "hun": "cinóbervörös pattanásgomba"
    },
    "1522": {
        "latin": "Neoboletus luridiformis",
        "latin_syn": [
            "Boletus erythropus",
            "Boletus luridiformis",
            "Polyporus mori"
        ],
        "hun": "céklatinóru"
    },
    "1523": {
        "latin": "Neobulgaria pura",
        "eng": "beech jellydisc",
        "hun": "bükk-koronggomba",
        "hun_syn": [
            "vörösbarna kocsonyáskoronggomba"
        ]
    },
    "1524": {
        "latin": "Neofavolus alveolaris",
        "latin_syn": [
            "Favolus europaeus",
            "Polyporus alveolarius"
        ],
        "hun": "sugaras likacsosgomba"
    },
    "1525": {
        "latin": "Neofavolus suavissimus",
        "latin_syn": [
            "Lentinus suavissimus",
            "Panus suavissimus"
        ],
        "hun": "illatos fagomba",
        "hun_syn": [
            "likacsoslemezű fagomba"
        ]
    },
    "1526": {
        "latin": "Neohygrocybe nitrata",
        "eng": "nitrous waxcap",
        "latin_syn": [
            "Hygrocybe murinacea",
            "Hygrocybe nitrata"
        ],
        "hun": "lúgszagú nedűgomba"
    },
    "1527": {
        "latin": "Neohygrocybe ovina",
        "eng": "blushing waxcap",
        "latin_syn": [
            "Hygrocybe ovina"
        ],
        "hun": "piruló nedűgomba",
        "hun_syn": [
            "piruló nyirokgomba"
        ]
    },
    "1528": {
        "latin": "Neolecta vitellina",
        "latin_syn": [
            "Mitrula vitellina"
        ],
        "hun": "lándzsás sapkásgomba"
    },
    "1529": {
        "latin": "Neolentinus adhaerens",
        "eng": "sticky sawgill",
        "latin_syn": [
            "Lentinus adhaerens"
        ],
        "hun": "gyantás fagomba"
    },
    "1530": {
        "latin": "Neolentinus cyathiformis",
        "latin_syn": [
            "Lentinus cyathiformis",
            "Neolentinus schaefferi"
        ],
        "hun": "rőt fagomba"
    },
    "1531": {
        "latin": "Neolentinus lepideus",
        "eng": "scaly sawgill",
        "latin_syn": [
            "Lentinus lepideus",
            "Neolentinus suffrutescens"
        ],
        "hun": "pikkelyes fagomba"
    },
    "1532": {
        "latin": "Neottiella albocincta",
        "hun": "sárga moha-élősdigomba"
    },
    "1533": {
        "latin": "Nidularia deformis",
        "latin_syn": [
            "Nidularia fracta"
        ],
        "hun": "fészekgombácska",
        "hun_syn": [
            "fészekgomba"
        ]
    },
    "1534": {
        "latin": "Octaviania aculeatospora",
        "latin_syn": [
            "Hydnangium aculeatosporum"
        ],
        "hun": "piszkosbarna bazsalikompöfeteg",
        "hun_syn": [
            "piszkosbarna álpöfeteg"
        ]
    },
    "1535": {
        "latin": "Octaviania asterosperma",
        "eng": "chalky false truffle",
        "hun": "rumillatú bazsalikompöfeteg",
        "hun_syn": [
            "bazsalikom-álpöfeteg",
            "bazsalikomillatú szarvasgomba"
        ]
    },
    "1536": {
        "latin": "Octaviania lutea",
        "latin_syn": [
            "Hydnangium luteum"
        ],
        "hun": "vörösödő bazsalikompöfeteg",
        "hun_syn": [
            "vörösödő álpöfeteg"
        ]
    },
    "1537": {
        "latin": "Octaviania neuhoffii",
        "latin_syn": [
            "Hydnangium neuhoffii"
        ],
        "hun": "dudoros álpöfeteg"
    },
    "1538": {
        "latin": "Octospora humosa",
        "eng": "hotlips",
        "hun": "narancsos mohacsészegomba"
    },
    "1539": {
        "latin": "Odontia fibrosa",
        "latin_syn": [
            "Tomentellina fibrosa"
        ],
        "hun": "rozsdabarna nemezgomba"
    },
    "1540": {
        "latin": "Oligoporus cerifluus",
        "hun": "farontó likacsosgomba"
    },
    "1541": {
        "latin": "Omphaliaster asterosporus",
        "latin_syn": [
            "Hygroaster asterosporus"
        ],
        "hun": "bordás békagombácska",
        "hun_syn": [
            "bordás csillagspórásgomba"
        ]
    },
    "1542": {
        "latin": "Omphalina pyxidata",
        "eng": "cinnamon navel",
        "latin_syn": [
            "Omphalina demissa s. auct."
        ],
        "hun": "fahéjszínű békagomba",
        "hun_syn": [
            "békagomba",
            "selymes békagomba"
        ]
    },
    "1543": {
        "latin": "Omphalina subhepatica",
        "hun": "májbarna békagomba"
    },
    "1544": {
        "latin": "Omphalotus olearius",
        "hun": "világító tölcsérgomba"
    },
    "1545": {
        "latin": "Onnia tomentosa",
        "eng": "velvet rosette",
        "latin_syn": [
            "Coltricia tomentosa",
            "Inonotus tomentosus"
        ],
        "hun": "nemezes likacsosgomba"
    },
    "1546": {
        "latin": "Onnia triquetra",
        "hun": "háromszögletű likacsosgomba"
    },
    "1547": {
        "latin": "Ophiocordyceps clavulata",
        "eng": "bug sputnik",
        "latin_syn": [
            "Cordyceps clavulata"
        ],
        "hun": "pajzstetű-rovarrontógomba",
        "hun_syn": [
            "pajzstetűrontógomba"
        ]
    },
    "1548": {
        "latin": "Ophiocordyceps entomorrhiza",
        "latin_syn": [
            "Cordyceps cinerea",
            "Cordyceps entomorrhiza"
        ],
        "hun": "sárga rovarrontógomba",
        "hun_syn": [
            "szürke rovarrontógomba"
        ]
    },
    "1549": {
        "latin": "Ophiocordyceps sphecocephala",
        "latin_syn": [
            "Cordyceps sphecocephala"
        ],
        "hun": "darázs-rovarrontógomba",
        "hun-syn": [
            "darázs rovarrontógomba"
        ]
    },
    "1550": {
        "latin": "Orbilia delicatula",
        "hun": "aranysárga koronggombácska"
    },
    "1551": {
        "latin": "Orbilia luteorubella",
        "hun": "rózsaszín koronggombácska"
    },
    "1552": {
        "latin": "Osmoporus odoratus",
        "latin_syn": [
            "Gloeophyllum odoratum"
        ],
        "hun": "szagos tapló"
    },
    "1553": {
        "latin": "Ossicaulis lignatilis",
        "eng": "mealy oyster",
        "latin_syn": [
            "Clitocybe lignatilis"
        ],
        "hun": "fehér fatölcsérgomba",
        "hun-syn": [
            "fehér tőketölcsérgomba"
        ]
    },
    "1554": {
        "latin": "Osteina obducta",
        "hun": "csontosodó likacsosgomba"
    },
    "1555": {
        "latin": "Otidea alutacea",
        "eng": "tan ear",
        "hun": "bőrsárga fülesgomba",
        "hun_syn": [
            "rozsdabarna fülesgomba"
        ]
    },
    "1556": {
        "latin": "Otidea apophysata",
        "eng": "big ears",
        "latin_syn": [
            "Pseudotis apophysata"
        ],
        "hun": "fekete fülesgomba "
    },
    "1557": {
        "latin": "Otidea bufonia",
        "eng": "toad's ear",
        "hun": "barna fülesgomba",
        "hun_syn": [
            "sötét fülesgomba"
        ]
    },
    "1558": {
        "latin": "Otidea cochleata",
        "eng": "brown ear",
        "hun": "csomós fülesgomba",
        "hun_syn": [
            "csigafülesgomba"
        ]
    },
    "1559": {
        "latin": "Otidea felina",
        "hun": "macskafülesgomba",
        "hun_syn": [
            "macskafülegomba"
        ]
    },
    "1560": {
        "latin": "Otidea grandis",
        "hun": "nagy fülesgomba",
        "hun_syn": [
            "barna fülesgomba"
        ]
    },
    "1561": {
        "latin": "Otidea leporina",
        "hun": "tapsifülgomba",
        "hun-syn": [
            "porcos fülesgomba",
            "tapsifülegomba"
        ]
    },
    "1562": {
        "latin": "Otidea onotica",
        "eng": "hare's ear",
        "latin_syn": [
            "Otidea abietina"
        ],
        "hun": "nyúlfülegomba",
        "hun_syn": [
            "nyeles fülesgomba",
            "nyúlfüle gomba"
        ]
    },
    "1563": {
        "latin": "Oxyporus corticola",
        "hun": "csinos kéreggomba"
    },
    "1564": {
        "latin": "Oxyporus populinus",
        "eng": "poplar bracket",
        "hun": "lépcsőzetes kéregtapló"
    },
    "1565": {
        "latin": "Oxyporus ravidus",
        "hun": "csoportos kéregtapló"
    },
    "1566": {
        "latin": "Pachyella violaceonigra",
        "eng": "midnight disco",
        "hun": "feketéslila kocsonyás-csészegomba"
    },
    "1567": {
        "latin": "Pachyphlodes citrina",
        "latin_syn": [
            "Pachyphloeus citrinus"
        ],
        "hun": "krómsárga kérgestrifla",
        "hun-syn": [
            "krómsárga kérgesszarvasgomba",
            "krómsárga szarvasgomba"
        ]
    },
    "1568": {
        "latin": "Pachyphlodes conglomerata",
        "latin_syn": [
            "Pachyphloeus conglomeratus"
        ],
        "hun": "sima kérgestrifla",
        "hun-syn": [
            "sárga kérgesszarvasgomba",
            "sárga szarvasgomba"
        ]
    },
    "1569": {
        "latin": "Pachyphlodes melanoxantha",
        "latin_syn": [
            "Pachyphloeus melanoxanthus"
        ],
        "hun": "fekete kérgestrifla",
        "hun-syn": [
            "feketedő kérgesszarvasgomba",
            "feketedő szarvasgomba"
        ]
    },
    "1570": {
        "latin": "Pachyphlodes nemoralis",
        "hun": "ligeti kérgestrifla"
    },
    "1571": {
        "latin": "Panaeolina foenisecii",
        "eng": "brown mottlegill",
        "hun": "réti trágyagombácska",
        "hun-syn": [
            "réti trágyagomba"
        ]
    },
    "1572": {
        "latin": "Panaeolus cinctulus",
        "eng": "banded mottlegill",
        "latin_syn": [
            "Panaeolus subbalteatus"
        ],
        "hun": "sötétszélű trágyagomba",
        "hun-syn": [
            "rézvörös trágyagomba"
        ]
    },
    "1573": {
        "latin": "Panaeolus fimicola",
        "eng": "turf mottlegill",
        "latin_syn": [
            "Panaeolus ater"
        ],
        "hun": "sötét trágyagomba"
    },
    "1574": {
        "latin": "Panaeolus olivaceus",
        "hun": "pontozottspórás trágyagomba"
    },
    "1575": {
        "latin": "Panaeolus papilionaceus",
        "eng": "petticoat mottlegill",
        "latin_syn": [
            "Panaeolus campanulatus",
            "Panaeolus sphinctrinus"
        ],
        "hun": "csipkés trágyagomba",
        "hun_syn": [
            "fakó trágyagomba",
            "halvány trágyagomba",
            "harangos trágyagomba"
        ]
    },
    "1576": {
        "latin": "Panaeolus rickenii",
        "latin_syn": [
            "Panaeolus acuminatus"
        ],
        "hun": "csúcsos trágyagomba",
        "hun_syn": [
            "vörösbarna trágyagomba"
        ]
    },
    "1577": {
        "latin": "Panaeolus semiovatus",
        "eng": "egghead mottlegill",
        "latin_syn": [
            "Anellaria semiovata",
            "Panaeolus separatus"
        ],
        "hun": "gyűrűs trágyagomba"
    },
    "1578": {
        "latin": "Panellus mitis",
        "eng": "elastic oysterling",
        "latin_syn": [
            "Panus mitis",
            "Urosporellina mitis"
        ],
        "hun": "fehér áldücskőgomba"
    },
    "1579": {
        "latin": "Panellus serotinus",
        "latin_syn": [
            "Sarcomyxa serotina"
        ],
        "hun": "sárgatönkű áldücskőgomba",
        "hun_syn": [
            "sárgatönkű  dücskőgomba"
        ]
    },
    "1580": {
        "latin": "Panellus stipticus",
        "eng": "bitter oysterling",
        "hun": "kis áldücskőgomba",
        "hun_syn": [
            "kis dücskőgomba"
        ]
    },
    "1581": {
        "latin": "Panus conchatus",
        "eng": "lilac oysterling",
        "latin_syn": [
            "Panus torulosus"
        ],
        "hun": "lilás dücskőgomba",
        "hun_syn": [
            "nyírfa-dücskőgomba"
        ]
    },
    "1582": {
        "latin": "Panus rudis",
        "latin_syn": [
            "Lentinus strigosus"
        ],
        "hun": "sörtés dücskőgomba"
    },
    "1583": {
        "latin": "Paralepista flaccida",
        "eng": "tawny funnel",
        "latin_syn": [
            "Lepista flaccida",
            "Lepista inversa",
            "Lepista splendens"
        ],
        "hun": "rozsdasárga tölcsérgomba",
        "hun_syn": [
            "fakósárga tölcsérpereszke",
            "rozsdásbarna tölcsérpereszke"
        ]
    },
    "1584": {
        "latin": "Paralepista gilva",
        "latin_syn": [
            "Lepista gilva"
        ],
        "hun": "fakósárga tölcsérgomba"
    },
    "1585": {
        "latin": "Paralepistopsis amoenolens",
        "latin_syn": [
            "Clitocybe amoenolens"
        ],
        "hun": "körteillatú tölcsérgomba"
    },
    "1586": {
        "latin": "Parasola auricoma",
        "eng": "goldenhaired inkcap",
        "latin_syn": [
            "Coprinus auricomus"
        ],
        "hun": "barnaszöszös áltintagomba"
    },
    "1587": {
        "latin": "Parasola conopilus",
        "latin_syn": [
            "Psathyrella conopilus"
        ],
        "hun": "szöszös áltintagomba",
        "hun_syn": [
            "szöszös porhanyósgomba"
        ]
    },
    "1588": {
        "latin": "Parasola leiocephala",
        "eng": "bald inkcap",
        "latin_syn": [
            "Coprinus leiocephalus"
        ],
        "hun": "sárgásbarna áltintagomba"
    },
    "1589": {
        "latin": "Parasola plicatilis",
        "eng": "pleated inkcap",
        "latin_syn": [
            "Coprinus plicatilis",
            "Xerula caussei"
        ],
        "hun": "gyenge áltintagomba"
    },
    "1590": {
        "latin": "Paraxerula caussei",
        "eng": "hoary rooting shank",
        "latin_syn": [
            "Oudemansiella caussei  "
        ],
        "hun": "feketésbarna gyökeresfülőke"
    },
    "1591": {
        "latin": "Paxillus involutus",
        "eng": "brown rollrim",
        "hun": "begöngyöltszélű cölöpgomba",
        "hun_syn": [
            "begöngyöltperemű cölöpgomba"
        ]
    },
    "1592": {
        "latin": "Paxillus rubicundulus",
        "eng": "alder rollrim",
        "latin_syn": [
            "Paxillus filamentosus"
        ],
        "hun": "éger-cölöpgomba"
    },
    "1593": {
        "latin": "Paxina queletii",
        "latin_syn": [
            "Helvella queletii"
        ],
        "hun": "bordástönkű serleggomba",
        "hun_syn": [
            "bordástönkű papsapkagomba"
        ]
    },
    "1594": {
        "latin": "Peniophora cinerea",
        "hun": "szürke terülőgomba"
    },
    "1595": {
        "latin": "Peniophora incarnata",
        "eng": "rosy crust",
        "hun": "élénkszínű terülőgomba",
        "hun_syn": [
            "rejtett terülőgomba"
        ]
    },
    "1596": {
        "latin": "Peniophora limitata",
        "latin_syn": [
            "Peniophora fraxinea"
        ],
        "hun": "kőris-terülőgomba"
    },
    "1597": {
        "latin": "Peniophora lycii",
        "eng": "ashen crust",
        "hun": "lícium-terülőgomba"
    },
    "1598": {
        "latin": "Peniophora nuda",
        "hun": "csupasz terülőgomba"
    },
    "1599": {
        "latin": "Peniophora pini",
        "eng": "pine crust",
        "latin_syn": [
            "Stereum pini"
        ],
        "hun": "fenyő-terülőgomba"
    },
    "1600": {
        "latin": "Peniophora polygonia",
        "latin_syn": [
            "Aleurodiscus polygonius"
        ],
        "hun": "csúcsos terülőgomba",
        "hun_syn": [
            "csúcsos koronggombácska"
        ]
    },
    "1601": {
        "latin": "Peniophora quercina",
        "eng": "oak crust",
        "hun": "tölgyfa-terülőgomba"
    },
    "1602": {
        "latin": "Peniophora rufomarginata",
        "hun": "hársfa-terülőgomba"
    },
    "1603": {
        "latin": "Perenniporia fraxinea",
        "latin_syn": [
            "Perenniporia cytisina"
        ],
        "hun": "kőris-tapló"
    },
    "1604": {
        "latin": "Perenniporia medulla-panis",
        "eng": "pancake crust",
        "hun": "szétterülő kéregtapló"
    },
    "1605": {
        "latin": "Peziza ammophila",
        "eng": "dune cup",
        "latin_syn": [
            "Sarcosphaera ammophila"
        ],
        "hun": "homoki csészegomba",
        "hun_syn": [
            "homoki tulipángomba"
        ]
    },
    "1606": {
        "latin": "Peziza ampliata",
        "hun": "rozsdás csészegomba"
    },
    "1607": {
        "latin": "Peziza arvernensis",
        "latin_syn": [
            "Peziza sylvestris"
        ],
        "hun": "erdei csészegomba"
    },
    "1608": {
        "latin": "Peziza badia",
        "eng": "bay cup",
        "latin_syn": [
            "Plicaria badia"
        ],
        "hun": "barna csészegomba"
    },
    "1609": {
        "latin": "Peziza cerea",
        "eng": "cellar cup",
        "latin_syn": [
            "Peziza muralis"
        ],
        "hun": "fali csészegomba",
        "hun_syn": [
            "pincecsészegomba"
        ]
    },
    "1610": {
        "latin": "Peziza cornui",
        "hun": "bársonyos csészegomba"
    },
    "1611": {
        "latin": "Peziza depressa",
        "hun": "gesztenyebarna csészegomba",
        "hun_syn": [
            "barnáslila csészegomba"
        ]
    },
    "1612": {
        "latin": "Peziza domiciliana",
        "latin_syn": [
            "Peziza adae"
        ],
        "hun": "üvegházi csészegomba"
    },
    "1613": {
        "latin": "Peziza echinospora",
        "eng": "charcoal cup",
        "hun": "szemcsés csészegomba"
    },
    "1614": {
        "latin": "Peziza fimeti",
        "hun": "trágyacsészegomba"
    },
    "1615": {
        "latin": "Peziza gerardii",
        "eng": "mull disco",
        "hun": "lila csészegomba"
    },
    "1616": {
        "latin": "Peziza howsei",
        "latin_syn": [
            "Peziza emileia"
        ],
        "hun": "fodros csészegomba"
    },
    "1617": {
        "latin": "Peziza limnaea",
        "hun": "pocsolya-csészegomba"
    },
    "1618": {
        "latin": "Peziza michelii",
        "hun": "sárgahúsú csészegomba"
    },
    "1619": {
        "latin": "Peziza micropus",
        "eng": "pedicel cup",
        "hun": "mogyoróbarna csészegomba",
        "hun_syn": [
            "rövidtönkű csészegomba"
        ]
    },
    "1620": {
        "latin": "Peziza phyllogena",
        "latin_syn": [
            "Peziza badioconfusa"
        ],
        "hun": "sötétbarna csészegomba",
        "hun_syn": [
            "olajbarna csészegomba"
        ]
    },
    "1621": {
        "latin": "Peziza proteana",
        "eng": "bonfire cauliflower",
        "hun": "fehér csészegomba"
    },
    "1622": {
        "latin": "Peziza pseudoammophila",
        "hun": "nagyspórás homoki-csészegomba"
    },
    "1623": {
        "latin": "Peziza queletii",
        "latin_syn": [
            "Peziza ampelina"
        ],
        "hun": "ibolyásbarna csészegomba"
    },
    "1624": {
        "latin": "Peziza repanda",
        "eng": "palamino cup",
        "hun": "terülő csészegomba"
    },
    "1625": {
        "latin": "Peziza saniosa",
        "hun": "ibolyástejű csészegomba"
    },
    "1626": {
        "latin": "Peziza subisabellina",
        "hun": "bordó csészegomba"
    },
    "1627": {
        "latin": "Peziza succosa",
        "eng": "yellowing cup",
        "hun": "sárgatejű csészegomba"
    },
    "1628": {
        "latin": "Peziza tenacella ",
        "latin_syn": [
            "Peziza praetervisa"
        ],
        "hun": "lilásbarna csészegomba"
    },
    "1629": {
        "latin": "Peziza vacini",
        "eng": "bonfire disco",
        "hun": "tűznyomlakó csészegomba"
    },
    "1630": {
        "latin": "Peziza varia",
        "eng": "layered cup",
        "hun": "változékony csészegomba"
    },
    "1631": {
        "latin": "Peziza vesiculosa",
        "eng": "blistered cup",
        "hun": "melegágyi csészegomba"
    },
    "1632": {
        "latin": "Peziza violacea",
        "hun": "ibolya-csészegomba"
    },
    "1633": {
        "latin": "Pezizella vulgaris",
        "latin_syn": [
            "Calycina vulgaris"
        ],
        "hun": "mészfehér csészegombácska"
    },
    "1634": {
        "latin": "Phaeoclavulina abietina",
        "latin_syn": [
            "Ramaria abietina",
            "Ramaria ochraceovirens"
        ],
        "hun": "zöldfogú korallgomba",
        "hun_syn": [
            "sárgászöld korallgomba"
        ]
    },
    "1635": {
        "latin": "Phaeoclavulina flaccida",
        "latin_syn": [
            "Ramaria flaccida"
        ],
        "hun": "bőrsárga korallgomba",
        "hun_syn": [
            "lenge korallgomba"
        ]
    },
    "1636": {
        "latin": "Phaeocollybia christinae",
        "eng": "Christina's rootshank+C1239",
        "hun": "narancsvörös gyökeresgomba",
        "hun_syn": [
            "okkervörös porcosgomba"
        ]
    },
    "1637": {
        "latin": "Phaeocollybia festiva",
        "hun": "zöldes gyökeresgomba",
        "hun_syn": [
            "zöldes szemétgomba"
        ]
    },
    "1638": {
        "latin": "Phaeocollybia jennyae",
        "hun": "csúcsos gyökeresgomba",
        "hun_syn": [
            "csúcsos porcosfülőke"
        ]
    },
    "1639": {
        "latin": "Phaeocollybia lugubris",
        "eng": "russet rootshank",
        "hun": "foltos gyökeresgomba"
    },
    "1640": {
        "latin": "Phaeohelotium epiphyllum",
        "hun": "sárga porcos-csészegombácska"
    },
    "1641": {
        "latin": "Phaeohelotium fagineum",
        "hun": "bükkmakk porcos-csészegombácska"
    },
    "1642": {
        "latin": "Phaeohelotium geogenum",
        "hun": "fehér porcos-csészegombácska"
    },
    "1643": {
        "latin": "Phaeohelotium umbilicatum",
        "hun": "köldökös porcos-csészegombácska",
        "hun_syn": [
            "köldökös csészegomba"
        ]
    },
    "1644": {
        "latin": "Phaeolepiota aurea",
        "eng": "golden bootleg",
        "hun": "szemcsés aranygomba"
    },
    "1645": {
        "latin": "Phaeolus schweinitzii",
        "eng": "dyer's mazegill",
        "latin_syn": [
            "Phaeolus spadiceus"
        ],
        "hun": "fenyő-likacsosgomba"
    },
    "1646": {
        "latin": "Phaeomarasmius erinaceus",
        "eng": "hedgehog scalycap",
        "hun": "tüskés pikkelygombácska",
        "hun_syn": [
            "tüskés szemétgomba"
        ]
    },
    "1647": {
        "latin": "Phaeonematoloma myosotis",
        "latin_syn": [
            "Hypholoma myosotis"
        ],
        "hun": "olajbarna kénvirággomba",
        "hun_syn": [
            "öves szemétgomba"
        ]
    },
    "1648": {
        "latin": "Phaeotremella mycophaga",
        "latin_syn": [
            "Tremella mycophaga"
        ],
        "hun": "élősködő rezgőgomba"
    },
    "1649": {
        "latin": "Phallus duplicatus",
        "eng": "flasher",
        "hun": "fátyolos szömörcsög"
    },
    "1650": {
        "latin": "Phallus hadriani",
        "eng": "sand stinkhorn",
        "hun": "homoki szömörcsög"
    },
    "1651": {
        "latin": "Phallus impudicus",
        "eng": "stinkhorn",
        "hun": "erdei szömörcsög",
        "hun_syn": [
            "szemérmetlen szömörcsög",
            "szemtelen szömörcsög"
        ]
    },
    "1652": {
        "latin": "Phallus indusiatus",
        "eng": "veiled lady",
        "latin_syn": [
            "Dyctiophora indusiata"
        ],
        "hun": "szoknyás szömörcsög",
        "hun_syn": [
            "szoknyásgomba"
        ]
    },
    "1653": {
        "latin": "Phanerochaete sanguinea",
        "latin_syn": [
            "Peniophora aurantiaca",
            "Peniophora sanguinea"
        ],
        "hun": "vörös terülőgomba"
    },
    "1654": {
        "latin": "Phanerochaete velutina",
        "latin_syn": [
            "Peniophora velutina"
        ],
        "hun": "bársonyos terülőgomba"
    },
    "1655": {
        "latin": "Phellidinium ferrugineofuscum",
        "latin_syn": [
            "Phellinus ferrugineofuscus"
        ],
        "hun": "rozsdavörösszélű fekvőtapló"
    },
    "1656": {
        "latin": "Phellinopsis conchata",
        "latin_syn": [
            "Phellinus conchatus"
        ],
        "hun": "vékony tapló"
    },
    "1657": {
        "latin": "Phellinus igniarius",
        "eng": "willow bracket",
        "hun": "parázstapló",
        "hun_syn": [
            "tűzi tapló"
        ]
    },
    "1658": {
        "latin": "Phellinus laevigatus",
        "eng": "smooth bristle bracket",
        "hun": "sötétbarna fekvőtapló"
    },
    "1659": {
        "latin": "Phellinus tremulae",
        "eng": "aspen bracket",
        "hun": "nyárfa-tapló"
    },
    "1660": {
        "latin": "Phellinus tuberculosus",
        "latin_syn": [
            "Phellinus pomaceus"
        ],
        "hun": "szilva-tapló"
    },
    "1661": {
        "latin": "Phellodon confluens",
        "eng": "fused tooth",
        "hun": "dudoros szagosgereben",
        "hun_syn": [
            "dudoros gereben"
        ]
    },
    "1662": {
        "latin": "Phellodon melaleucus",
        "eng": "grey tooth",
        "latin_syn": [
            "Phellodon connatus"
        ],
        "hun": "szalagos szagosgereben",
        "hun_syn": [
            "szagos gereben"
        ]
    },
    "1663": {
        "latin": "Phellodon niger",
        "eng": "black tooth",
        "hun": "fekete szagosgereben",
        "hun_syn": [
            "fekete gereben",
            "fekete illatosgereben"
        ]
    },
    "1664": {
        "latin": "Phellodon tomentosus",
        "eng": "woolly tooth",
        "latin_syn": [
            "Phellodon cyathiformis"
        ],
        "hun": "tölcséres szagosgereben",
        "hun_syn": [
            "tölcséres gereben",
            "tölcséres illatosgereben"
        ]
    },
    "1665": {
        "latin": "Phellopilus nigrolimitatus",
        "latin_syn": [
            "Phellinus nigrolimitatus"
        ],
        "hun": "sávos tapló"
    },
    "1666": {
        "latin": "Phellorinia herculeana",
        "hun": "fás pöfeteg"
    },
    "1667": {
        "latin": "Phlebia livida",
        "latin_syn": [
            "Corticium lividum"
        ],
        "hun": "színváltó redősgomba",
        "hun_syn": [
            "színváltó vargomba"
        ]
    },
    "1668": {
        "latin": "Phlebia radiata",
        "eng": "wrinkled crust",
        "latin_syn": [
            "Phlebia aurantiaca",
            "Phlebia merismoides"
        ],
        "hun": "narancssárga redősgomba"
    },
    "1669": {
        "latin": "Phlebia serialis",
        "latin_syn": [
            "Peniophora serilis"
        ],
        "hun": "szegett redősgomba",
        "hun_syn": [
            "szegett terülőgomba"
        ]
    },
    "1670": {
        "latin": "Phlebia tremellosa",
        "eng": "jelly rot",
        "latin_syn": [
            "Merulius tremellosus"
        ],
        "hun": "kocsonyás redősgomba"
    },
    "1671": {
        "latin": "Phlebiella vaga",
        "eng": "yellow cobweb",
        "latin_syn": [
            "Hypochnus fumosus",
            "Trechispora vaga"
        ],
        "hun": "kénsárga nemezgomba",
        "hun_syn": [
            "kénsárga vargomba"
        ]
    },
    "1672": {
        "latin": "Phlebiopsis gigantea",
        "hun": "óriás terülőgomba"
    },
    "1673": {
        "latin": "Phleogena faginea",
        "eng": "fenugreek stalkball",
        "hun": "bükkös fejecskésgomba"
    },
    "1674": {
        "latin": "Phloeomana minutula",
        "latin_syn": [
            "Mycena olida"
        ],
        "hun": "fehér kígyógomba",
        "hun_syn": [
            "fehér kéreggombácska"
        ]
    },
    "1675": {
        "latin": "Pholiota adiposa",
        "hun": "nyálkás tőkegomba",
        "hun_syn": [
            "óriás tőkegomba"
        ]
    },
    "1676": {
        "latin": "Pholiota astragalina",
        "eng": "conifer scalycap",
        "hun": "sáfrányvörös tőkegomba",
        "hun_syn": [
            "narancsvörös tőkegomba",
            "sáfrányszínű lánggomba"
        ]
    },
    "1677": {
        "latin": "Pholiota cerifera",
        "latin_syn": [
            "Pholiota aurivella"
        ],
        "hun": "rozsdasárga tőkegomba"
    },
    "1678": {
        "latin": "Pholiota conissans",
        "hun": "fűz-tőkegomba",
        "hun_syn": [
            "kerti lánggomba"
        ]
    },
    "1679": {
        "latin": "Pholiota flammans",
        "eng": "flaming scalycap",
        "hun": "lángszínű tőkegomba"
    },
    "1680": {
        "latin": "Pholiota gummosa",
        "eng": "sticky scalycap",
        "hun": "zöldes tőkegomba",
        "hun_syn": [
            "zöldes lánggomba",
            "zöldessárga tőkegomba"
        ]
    },
    "1681": {
        "latin": "Pholiota highlandensis",
        "eng": "bonfire scalycap",
        "latin_syn": [
            "Pholiota carbonaria"
        ],
        "hun": "szenes tőkegomba",
        "hun_syn": [
            "szenes lánggomba"
        ]
    },
    "1682": {
        "latin": "Pholiota jahnii",
        "hun": "sötétpikkelyes tőkegomba"
    },
    "1683": {
        "latin": "Pholiota lenta",
        "hun": "fakó tőkegomba",
        "hun_syn": [
            "fakó lánggomba"
        ]
    },
    "1684": {
        "latin": "Pholiota lubrica",
        "hun": "fehérpelyhes tőkegomba",
        "hun_syn": [
            "fehérpelyhes lánggomba"
        ]
    },
    "1685": {
        "latin": "Pholiota microspora",
        "latin_syn": [
            "Pholiota nameko"
        ],
        "hun": "japán tőkegomba"
    },
    "1686": {
        "latin": "Pholiota scamba",
        "eng": "crooked scalycap ",
        "hun": "selymes tőkegomba",
        "hun_syn": [
            "barnás álcölöpgomba"
        ]
    },
    "1687": {
        "latin": "Pholiota spumosa",
        "hun": "ragadós tőkegomba",
        "hun_syn": [
            "ragadós lánggomba"
        ]
    },
    "1688": {
        "latin": "Pholiota squarrosa",
        "eng": "shaggy scalycap",
        "hun": "tüskés tőkegomba",
        "hun_syn": [
            "alma-tőkegomba"
        ]
    },
    "1689": {
        "latin": "Pholiota squarrosoadiposa",
        "hun": "tüskés-nyálkás tőkegomba"
    },
    "1690": {
        "latin": "Pholiota squarrosoides",
        "hun": "fakópikkelyes tőkegomba",
        "hun_syn": [
            "fakótüskés tőkegomba"
        ]
    },
    "1691": {
        "latin": "Pholiotina aporos",
        "latin_syn": [
            "Conocybe aporos"
        ],
        "hun": "tavaszi tőkegombácska",
        "hun_syn": [
            "korai tőkegombácska"
        ]
    },
    "1692": {
        "latin": "Pholiotina brunnea",
        "latin_syn": [
            "Conocybe brunnea"
        ],
        "hun": "sötétbarna tőkegombácska"
    },
    "1693": {
        "latin": "Pholiotina dasypus",
        "latin_syn": [
            "Conocybe dasypus"
        ],
        "hun": "fehérszálas tőkegombácska",
        "hun_syn": [
            "fehértönkű haranggomba",
            "fehértönkű harangszemétgomba"
        ]
    },
    "1694": {
        "latin": "Pholiotina mairei",
        "latin_syn": [
            "Conocybe mairei"
        ],
        "hun": "bordás tőkegombácska"
    },
    "1695": {
        "latin": "Pholiotina nemoralis",
        "latin_syn": [
            "Conocybe nemoralis"
        ],
        "hun": "ligeti tőkegombácska"
    },
    "1696": {
        "latin": "Pholiotina striaepes",
        "latin_syn": [
            "Conocybe striaepes"
        ],
        "hun": "fehértönkű tőkegombácska"
    },
    "1697": {
        "latin": "Pholiotina teneroides",
        "latin_syn": [
            "Conocybe teneroides"
        ],
        "hun": "kétspórás tőkegombácska"
    },
    "1698": {
        "latin": "Pholiotina velata",
        "latin_syn": [
            "Conocybe appendiculata",
            "Pholiotina vexans",
            "Conocybe blattaria",
            "Pholiotina blattaria"
        ],
        "hun": "koronás tőkegombácska"
    },
    "1699": {
        "latin": "Phylloporia ribis",
        "latin_syn": [
            "Phellinus ribis"
        ],
        "hun": "ribizke-tapló"
    },
    "1700": {
        "latin": "Phylloporus pelletierii",
        "latin_syn": [
            "Xerocomus pelletieri"
        ],
        "hun": "lemezes tinóru"
    },
    "1701": {
        "latin": "Phyllotopsis nidulans",
        "eng": "orange mock oyster",
        "hun": "narancssárga laskagomba",
        "hun_syn": [
            "nemezes narancsoslaska"
        ]
    },
    "1702": {
        "latin": "Picipes badius",
        "latin_syn": [
            "Polyporus badius",
            "Royoporus badius"
        ],
        "hun": "fényes likacsosgomba",
        "hun_syn": [
            "szagos likacsosgomba"
        ]
    },
    "1703": {
        "latin": "Picipes melanopus",
        "latin_syn": [
            "Polyporus melanopus"
        ],
        "hun": "feketelábú likacsosgomba",
        "hun_syn": [
            "vízfoltos likacsgomba"
        ]
    },
    "1704": {
        "latin": "Picipes rhizophilus",
        "latin_syn": [
            "Polyporus rhizophilus"
        ],
        "hun": "gyepi likacsosgomba"
    },
    "1705": {
        "latin": "Picoa juniperi",
        "hun": "boróka-sötéttrifla",
        "hun_syn": [
            "boróka-szarvasgomba"
        ]
    },
    "1706": {
        "latin": "Piloderma byssinum",
        "latin_syn": [
            "Corticium byssinum"
        ],
        "hun": "fonalas vargomba"
    },
    "1707": {
        "latin": "Pisolithus arrhizus",
        "latin_syn": [
            "Pisolithus arenarius",
            "Pisolithus tinctorius"
        ],
        "hun": "osztott pöfeteg"
    },
    "1708": {
        "latin": "Plectania melastoma",
        "eng": "corona cup",
        "latin_syn": [
            "Urnula melastoma"
        ],
        "hun": "kis kehelygomba",
        "hun_syn": [
            "kis fekete-kehelygomba"
        ]
    },
    "1709": {
        "latin": "Pleurocybella porrigens",
        "eng": "angel's wings",
        "latin_syn": [
            "Phyllotus porrigens"
        ],
        "hun": "hegyi laskagomba",
        "hun_syn": [
            "fenyves fehérlaska"
        ]
    },
    "1710": {
        "latin": "Pleurotellus chioneus",
        "hun": "molyhos laskagombácska",
        "hun_syn": [
            "molyhos laskagomba"
        ]
    },
    "1711": {
        "latin": "Pleurotus calyptratus",
        "hun": "fátyolos laskagomba",
        "hun_syn": [
            "galléros laskagomba"
        ]
    },
    "1712": {
        "latin": "Pleurotus cornucopiae",
        "eng": "branching oyster",
        "hun": "erestönkű laskagomba"
    },
    "1713": {
        "latin": "Pleurotus dryinus",
        "eng": "veiled oyster",
        "hun": "pihés laskagomba"
    },
    "1714": {
        "latin": "Pleurotus eryngii",
        "eng": "king oyster",
        "hun": "ördögszekér-laskagomba",
        "hun_syn": [
            "ördögszekér laskagomba"
        ]
    },
    "1715": {
        "latin": "Pleurotus ostreatus",
        "eng": "oyster mushroom",
        "latin_syn": [
            "Pleurotus columbinus"
        ],
        "hun": "kései laskagomba",
        "hun_syn": [
            "késői laskagomba"
        ]
    },
    "1716": {
        "latin": "Pleurotus pulmonarius",
        "eng": "pale oyster",
        "hun": "nyári laskagomba"
    },
    "1717": {
        "latin": "Plicaria endocarpoides",
        "latin_syn": [
            "Plicaria leiocarpa"
        ],
        "hun": "kormos tányérgomba"
    },
    "1718": {
        "latin": "Plicaria umbilicata",
        "hun": "köldökös csészegomba"
    },
    "1719": {
        "latin": "Plicaturopsis crispa",
        "latin_syn": [
            "Plicatura crispa",
            "Plicatura faginea"
        ],
        "hun": "bükk-eresgomba",
        "hun_syn": [
            "bükk eresgombácska"
        ]
    },
    "1720": {
        "latin": "Pluteus atromarginatus",
        "eng": "blackedged shield",
        "latin_syn": [
            "Pluteus nigrofloccosus"
        ],
        "hun": "feketeélű csengettyűgomba"
    },
    "1721": {
        "latin": "Pluteus aurantiorugosus",
        "eng": "flame shield",
        "hun": "tűzpiros csengettyűgomba"
    },
    "1722": {
        "latin": "Pluteus cervinus",
        "eng": "deer shield",
        "latin_syn": [
            "Pluteus atricapillus"
        ],
        "hun": "barna csengettyűgomba",
        "hun_syn": [
            "változékony csengettyűgomba"
        ]
    },
    "1723": {
        "latin": "Pluteus chrysophaeus",
        "eng": "yellow shield",
        "latin_syn": [
            "Pluteus luteovirens"
        ],
        "hun": "sárgászöld csengettyűgomba"
    },
    "1724": {
        "latin": "Pluteus cinereofuscus",
        "eng": "ashen shield",
        "hun": "zöldesszürke csengettyűgomba"
    },
    "1725": {
        "latin": "Pluteus ephebeus",
        "eng": "sooty shield",
        "latin-syn": [
            "Pluteus lepiotoides",
            "Pluteus murinus",
            "Pluteus pearsonii",
            "Pluteus villosus"
        ],
        "hun": "szürkepikkelyű csengettyűgomba",
        "hun_syn": [
            "pelyhes csengettyűgomba"
        ]
    },
    "1726": {
        "latin": "Pluteus hiatulus",
        "latin_syn": [
            "Pluteus depauperatus",
            "Pluteus plautus"
        ],
        "hun": "ráncos csengettyűgomba"
    },
    "1727": {
        "latin": "Pluteus hispidulus",
        "eng": "fleecy shield",
        "latin_syn": [
            "Pluteus exiguus"
        ],
        "hun": "hajas csengettyűgomba"
    },
    "1728": {
        "latin": "Pluteus leoninus",
        "eng": "lion shield",
        "latin_syn": [
            "Pluteus luteomarginatus"
        ],
        "hun": "sárga csengettyűgomba"
    },
    "1729": {
        "latin": "Pluteus nanus",
        "eng": "dwarf shield",
        "hun": "apró csengettyűgomba",
        "hun_syn": [
            "pelyhes csengettyűgomba"
        ]
    },
    "1730": {
        "latin": "Pluteus pallescens",
        "hun": "fakó csengettyűgomba",
        "hun_syn": [
            "fakuló csengettyűgomba"
        ]
    },
    "1731": {
        "latin": "Pluteus pellitus",
        "eng": "ghost shield",
        "hun": "fehér csengettyűgomba"
    },
    "1732": {
        "latin": "Pluteus petasatus",
        "eng": "scaly shield",
        "latin_syn": [
            "Pluteus curtisii",
            "Pluteus patricius"
        ],
        "hun": "selymes csengettyűgomba",
        "hun_syn": [
            "csoportos csengettyűgomba"
        ]
    },
    "1733": {
        "latin": "Pluteus phlebophorus",
        "eng": "wrinkled shield",
        "hun": "ereskalapú csengettyűgomba"
    },
    "1734": {
        "latin": "Pluteus podospileus",
        "latin_syn": [
            "Pluteus minutissimus"
        ],
        "hun": "szemcsés csengettyűgomb"
    },
    "1735": {
        "latin": "Pluteus pseudorobertii",
        "hun": "szürkecsúcsú csengettyűgomba"
    },
    "1736": {
        "latin": "Pluteus romellii",
        "eng": "goldleaf shield",
        "latin_syn": [
            "Pluteus lutescens"
        ],
        "hun": "sárgalábú csengettyűgomba",
        "hun_syn": [
            "sárgatönkű csengettyűgomba"
        ]
    },
    "1737": {
        "latin": "Pluteus salicinus",
        "eng": "willow shield",
        "hun": "zöldülő csengettyűgomba",
        "hun_syn": [
            "szürke csengettyűgomba"
        ]
    },
    "1738": {
        "latin": "Pluteus semibulbosus",
        "hun": "gumós csengettyűgomba",
        "hun_syn": [
            "ráncos csengettyűgomba"
        ]
    },
    "1739": {
        "latin": "Pluteus thomsonii",
        "eng": "veined shield",
        "latin_syn": [
            "Pluteus cinereus"
        ],
        "hun": "barnaeres csengettyűgomba",
        "hun_syn": [
            "szürketönkű csengettyűgomba"
        ]
    },
    "1740": {
        "latin": "Pluteus umbrosus",
        "eng": "velvet shield",
        "hun": "feketepelyhes csengettyűgomba",
        "hun_syn": [
            "pelyhes csengettyűgomba"
        ]
    },
    "1741": {
        "latin": "Pluteus variabilicolor",
        "hun": "narancsos csengettyűgomba",
        "hun_syn": [
            "változószínű csengettyűgomba"
        ]
    },
    "1742": {
        "latin": "Pogonoloma macrocephalum",
        "latin_syn": [
            "Leucopaxillus  "
        ],
        "hun": "gyökeres álpereszke",
        "hun_syn": [
            "gyökeres cölöppereszke",
            "gyökeres pereszke"
        ]
    },
    "1743": {
        "latin": "Pogonoloma spinulosum",
        "latin_syn": [
            "Porpoloma spinulosum"
        ],
        "hun": "bolyhosszélű álpereszke"
    },
    "1744": {
        "latin": "Polyporus arcularius",
        "hun": "fagyálló likacsosgomba"
    },
    "1745": {
        "latin": "Polyporus brumalis",
        "eng": "winter polypore",
        "hun": "téli likacsosgomba"
    },
    "1746": {
        "latin": "Polyporus ciliatus",
        "eng": "fringed polypore",
        "latin_syn": [
            "Polyporus lepideus"
        ],
        "hun": "tavaszi likacsosgomba"
    },
    "1747": {
        "latin": "Polyporus squamosus",
        "eng": "dryad's saddle",
        "hun": "pisztricgomba",
        "hun_syn": [
            "bagolygomba"
        ]
    },
    "1748": {
        "latin": "Polyporus tuberaster",
        "eng": "tuberous polypore",
        "latin_syn": [
            "Polyporus forquignonii",
            "Polyporus lentus"
        ],
        "hun": "olaszgomba",
        "hun_syn": [
            "gumós likacsosgomba"
        ]
    },
    "1749": {
        "latin": "Polyporus varius",
        "latin_syn": [
            "Polyporus leptocephalus"
        ],
        "hun": "változékony likacsosgomba",
        "hun_syn": [
            "feketeövű likacsosgomba"
        ]
    },
    "1750": {
        "latin": "Porodaedalea pini",
        "eng": "pine bracket",
        "latin_syn": [
            "Phellinus pini"
        ],
        "hun": "fenyő-tapló"
    },
    "1751": {
        "latin": "Poronia punctata",
        "eng": "nail fungus",
        "hun": "pontozott szitagomba",
        "hun_syn": [
            "pontozott maggomba"
        ]
    },
    "1752": {
        "latin": "Porotheleum fimbriatum",
        "hun": "telepes réteggomba",
        "hun_syn": [
            "telepes-réteggomba"
        ]
    },
    "1753": {
        "latin": "Porphyrellus porphyrosporus",
        "eng": "dusky bolete",
        "latin_syn": [
            "Porphyrellus pseudoscaber",
            "Tylopilus porphyrosporus"
        ],
        "hun": "sötét tinóru",
        "hun_syn": [
            "bársonyos sötéttinóru"
        ]
    },
    "1754": {
        "latin": "Porpolomopsis calyptriformis",
        "eng": "pink waxcap ",
        "latin_syn": [
            "Hygrocybe calyptriformis"
        ],
        "hun": "rózsaszínű nedűgomba",
        "hun_syn": [
            "rózsás nedűgomba"
        ]
    },
    "1755": {
        "latin": "Postia caesia",
        "eng": "conifer blueing bracket",
        "latin_syn": [
            "Oligoporus caesius",
            "Spongiporus caesius",
            "Tyromyces caesius"
        ],
        "hun": "kékesedő likacsosgomba",
        "hun_syn": [
            "elkékülő likacsosgomba"
        ]
    },
    "1756": {
        "latin": "Postia floriformis",
        "latin_syn": [
            "Oligoporus floriformis"
        ],
        "hun": "virágszerű likacsosgomba"
    },
    "1757": {
        "latin": "Postia fragilis",
        "latin-syn": [
            "Oligoporus fragilis",
            "Spongiporus fragilis",
            "Tyromyces fragilis"
        ],
        "hun": "barnuló likacsosgomba",
        "hun_syn": [
            "törékeny likacsosgomba"
        ]
    },
    "1758": {
        "latin": "Postia lactea",
        "latin_syn": [
            "Tyromyces lacteus"
        ],
        "hun": "tejfehér likacsosgomba",
        "hun_syn": [
            "foszlós likacsosgomba"
        ]
    },
    "1759": {
        "latin": "Postia leucomallella",
        "latin_syn": [
            "Oligoporus leucomallellus",
            "Spongiporus leucomallellus",
            "Tyromyces gloeocystidiatus"
        ],
        "hun": "porhanyós likacsosgomba"
    },
    "1760": {
        "latin": "Postia lowei<",
        "latin_syn": [
            "Oligoporus lowei",
            "Tyromyces lowei"
        ],
        "hun": "kicsiny likacsosgomba"
    },
    "1761": {
        "latin": "Postia ptychogaster",
        "eng": "powderpuff bracket",
        "latin_syn": [
            "Postia albus",
            "Ptychogaster pulverulentus"
        ],
        "hun": "fehér vánkosgomba"
    },
    "1762": {
        "latin": "Postia stiptica",
        "eng": "bitter bracket",
        "latin-syn": [
            "Oligoporus stipticus",
            "Spongiporus stipticus",
            "Tyromyces stipticus"
        ],
        "hun": "keserű likacsosgomba",
        "hun_syn": [
            "fehéres likacsosgomba"
        ]
    },
    "1763": {
        "latin": "Postia subcaesia",
        "eng": "blueing bracket",
        "latin_syn": [
            "Tyromyces subcaesius"
        ],
        "hun": "kékülő likacsosgomba"
    },
    "1764": {
        "latin": "Postia tephroleuca",
        "eng": "greyling bracket",
        "latin_syn": [
            "Oligoporus tephroleucus",
            "Spongiporus tephroleucus",
            "Tyromyes tephroleucus"
        ],
        "hun": "szürkésfehér likacsosgomba"
    },
    "1765": {
        "latin": "Propolis farinosa",
        "hun": "propoliszgomba"
    },
    "1766": {
        "latin": "Protostropharia semiglobata",
        "latin_syn": [
            "Stropharia semiglobata"
        ],
        "hun": "domború harmatgomba",
        "hun_syn": [
            "félgömbalakú harmatgomba"
        ]
    },
    "1767": {
        "latin": "Psathyrella ammophila",
        "eng": "dune brittlestem",
        "hun": "homoki porhanyósgomba"
    },
    "1768": {
        "latin": "Psathyrella artemisiae",
        "eng": "petticoat brittlestem",
        "latin_syn": [
            "Psathyrella squamosa"
        ],
        "hun": "pelyhes porhanyósgomba"
    },
    "1769": {
        "latin": "Psathyrella berolinensis",
        "hun": "ganéj-porhanyósgomba"
    },
    "1770": {
        "latin": "Psathyrella bifrons",
        "hun": "fehérszegélyű porhanyósgomba"
    },
    "1771": {
        "latin": "Psathyrella bipellis",
        "hun": "pirosbarna porhanyósgomba"
    },
    "1772": {
        "latin": "Psathyrella candolleana",
        "eng": "pale brittlestem",
        "hun": "fehér porhanyósgomba"
    },
    "1773": {
        "latin": "Psathyrella caput-medusae",
        "eng": "medusa brittlestem",
        "hun": "medúza-porhanyósgomba",
        "hun_syn": [
            "pikkelyes porhanyósgomba"
        ]
    },
    "1774": {
        "latin": "Psathyrella coronata",
        "hun": "sárgapelyhes porhanyósgomba"
    },
    "1775": {
        "latin": "Psathyrella corrugis",
        "eng": "red edge brittlestem",
        "latin_syn": [
            "Psathyrella atrolaminata",
            "Psathyrella caudata",
            "Psathyrella gracilis"
        ],
        "hun": "törékeny porhanyósgomba",
        "hun_syn": [
            "hosszúgyökerű porhanyósgomba",
            "karcsú porhanyósgomba",
            "karcsú tücsökgomba",
            "sötétlemezű porhanyósgomba"
        ]
    },
    "1776": {
        "latin": "Psathyrella cotonea",
        "eng": "yellowfoot brittlestem",
        "hun": "sárgatövű porhanyósgomba",
        "hun_syn": [
            "szürkepikkelyű porhanyósgomba"
        ]
    },
    "1777": {
        "latin": "Psathyrella dunensis",
        "hun": "vörösesszélű porhanyósgomba"
    },
    "1778": {
        "latin": "Psathyrella epimyces",
        "hun": "élősködő porhanyósgomba"
    },
    "1779": {
        "latin": "Psathyrella fusca",
        "hun": "vörösbarna porhanyósgomba"
    },
    "1780": {
        "latin": "Psathyrella hirta",
        "hun": "trágya-porhanyósgomba"
    },
    "1781": {
        "latin": "Psathyrella impexa",
        "hun": "rózsapiros porhanyósgomba"
    },
    "1782": {
        "latin": "Psathyrella leucotephra",
        "hun": "gyűrűs porhanyósgomba"
    },
    "1783": {
        "latin": "Psathyrella lutensis",
        "hun": "sárgásbarna porhanyósgomba"
    },
    "1784": {
        "latin": "Psathyrella maculata",
        "eng": "spotted brittlestem",
        "hun": "sötétpikkelyes porhanyósgomba"
    },
    "1785": {
        "latin": "Psathyrella microrrhiza",
        "hun": "gyökeres porhanyósgomba"
    },
    "1786": {
        "latin": "Psathyrella multipedata",
        "eng": "clustered brittlestem",
        "latin_syn": [
            "Psathyrella stipatissima"
        ],
        "hun": "csoportos porhanyósgomba"
    },
    "1787": {
        "latin": "Psathyrella noli-tangere",
        "hun": "gyenge porhanyósgomba"
    },
    "1788": {
        "latin": "Psathyrella olympiana",
        "hun": "fehérpihés porhanyósgomba"
    },
    "1789": {
        "latin": "Psathyrella panaeoloides",
        "hun": "feketéslemezű porhanyósgomba"
    },
    "1790": {
        "latin": "Psathyrella pennata",
        "hun": "szenes porhanyósgomba",
        "hun_syn": [
            "faszenes porhanyósgomba"
        ]
    },
    "1791": {
        "latin": "Psathyrella piluliformis",
        "eng": "common stump brittlestem",
        "latin_syn": [
            "Psathyrella hydrophila"
        ],
        "hun": "barna porhanyósgomba"
    },
    "1792": {
        "latin": "Psathyrella prona",
        "eng": "bent brittlestem",
        "latin_syn": [
            "Psathyrella orbitrum"
        ],
        "hun": "útszéli porhanyósgomba"
    },
    "1793": {
        "latin": "Psathyrella pseudocorrugis",
        "hun": "változékony porhanyósgomba",
        "hun_syn": [
            "változatosszínű porhanyósgomba"
        ]
    },
    "1794": {
        "latin": "Psathyrella spadiceogrisea",
        "eng": "spring brittlestem",
        "latin_syn": [
            "Psathyrella vernalis"
        ],
        "hun": "korai porhanyósgomba",
        "hun_syn": [
            "barnásszürke porhanyósgomba",
            "keskenylemezű porhanyósgomba"
        ]
    },
    "1795": {
        "latin": "Psathyrella typhae",
        "hun": "nádi porhanyósgomba"
    },
    "1796": {
        "latin": "Pseudoboletus parasiticus",
        "eng": "parasitic bolete",
        "latin_syn": [
            "Xerocomus parasiticus"
        ],
        "hun": "élősdi tinóru",
        "hun_syn": [
            "élősdi nemezestinóru"
        ]
    },
    "1797": {
        "latin": "Pseudochaete tabacina",
        "latin_syn": [
            "Hymenochaete tabacina"
        ],
        "hun": "sárgaperemű sörtésréteggomba",
        "hun_syn": [
            "sárgaperemű sörtés-réteggomba"
        ]
    },
    "1798": {
        "latin": "Pseudoclitocybe cyathiformis",
        "eng": "goblet",
        "hun": "kávébarna áltölcsérgomba"
    },
    "1799": {
        "latin": "Pseudoclitocybe obbata",
        "latin_syn": [
            "Cantharellula obbata"
        ],
        "hun": "kékesszürke áltölcsérgomba"
    },
    "1800": {
        "latin": "Pseudoclitopilus rhodoleucus ",
        "latin_syn": [
            "Leucopaxillus rhodoleucus"
        ],
        "hun": "rózsáslemezű álpereszke",
        "hun_syn": [
            "rózsás cölöppereszke",
            "rózsás kajszagomba",
            "rózsáslemezű álcölöpgomba"
        ]
    },
    "1801": {
        "latin": "Pseudocolus fusiformis",
        "eng": "stinky squid",
        "hun": "gyertyagomba"
    },
    "1802": {
        "latin": "Pseudocraterellus undulatus",
        "latin_syn": [
            "Craterellus crispus",
            "Craterellus sinuosus",
            "Pseudocraterellus sinuosus"
        ],
        "hun": "fodros trombitagomba",
        "hun_syn": [
            "fodros áltrombitagomba",
            "tömör trombitagomba"
        ]
    },
    "1803": {
        "latin": "Pseudohydnum gelatinosum",
        "eng": "jelly tooth",
        "latin_syn": [
            "Tremellodon gelatinosum"
        ],
        "hun": "kocsonyás álgereben"
    },
    "1804": {
        "latin": "Pseudoinonotus dryadeus",
        "eng": "oak bracket",
        "latin_syn": [
            "Inonotus dryadeus"
        ],
        "hun": "könnyező rozsdástapló",
        "hun_syn": [
            "kérges rozsdástapló",
            "könnyező kérgestapló",
            "könnyező likacsosgomba"
        ]
    },
    "1805": {
        "latin": "Pseudomerulius aureus",
        "eng": "orange netcrust",
        "hun": "aranyos álredőgomba"
    },
    "1806": {
        "latin": "Pseudoomphalina kalchbrenneri",
        "hun": "homoki álbékagomba"
    },
    "1807": {
        "latin": "Pseudoplectania melaena",
        "latin_syn": [
            "Pseudoplectania vogesiaca"
        ],
        "hun": "nyeles gyászoscsészegomba"
    },
    "1808": {
        "latin": "Pseudoplectania nigrella",
        "eng": "ebony cup",
        "hun": "ülő gyászoscsészegomba",
        "hun_syn": [
            "ülő gyászos csészegomba"
        ]
    },
    "1809": {
        "latin": "Psilocybe cyanescens",
        "eng": "blueleg brownie",
        "hun": "kékülő badargomba"
    },
    "1810": {
        "latin": "Psilocybe laetissima",
        "hun": "élénkszínű badargomba"
    },
    "1811": {
        "latin": "Psilocybe mexicana",
        "hun": "mexikói badargomba"
    },
    "1812": {
        "latin": "Psilocybe semilanceata",
        "eng": "magic mushroom / liberty cap",
        "hun": "hegyes badargomba",
        "hun_syn": [
            "hegyescsúcsú badargomba"
        ]
    },
    "1813": {
        "latin": "Pteridomyces galzinii",
        "latin_syn": [
            "Epithele galzinii"
        ],
        "hun": "fehéres szövedékgomba"
    },
    "1814": {
        "latin": "Pterula multifida",
        "hun": "fehéres sertegomba"
    },
    "1815": {
        "latin": "Pterula subulata",
        "eng": "angel hair coral",
        "hun": "csapzott sertegomba"
    },
    "1816": {
        "latin": "Pulvinula carbonaria",
        "hun": "tűznyom-párnagombácska"
    },
    "1817": {
        "latin": "Pulvinula convexella",
        "hun": "narancsos párnagombácska"
    },
    "1818": {
        "latin": "Pycnoporellus fulgens",
        "latin_syn": [
            "Polyporus fibrillosus",
            "Pycnoporellus fibrillosus"
        ],
        "hun": "aranyos likacsosgomba"
    },
    "1819": {
        "latin": "Pycnoporus cinnabarinus",
        "eng": "cinnabar bracket",
        "hun": "cinóbertapló"
    },
    "1820": {
        "latin": "Pycnoporus sanguineus",
        "eng": "blood red bracket",
        "hun": "vérvörös tapló"
    },
    "1821": {
        "latin": "Pyronema domesticum",
        "hun": "közönséges tűznyomcsészegomba"
    },
    "1822": {
        "latin": "Pyronema omphalodes",
        "hun": "parázsló tűznyomcsészegomba"
    },
    "1823": {
        "latin": "Radulomyces confluens",
        "latin_syn": [
            "Corticium confluens"
        ],
        "hun": "csipkés vargomba",
        "hun_syn": [
            "csipkés fogasvargomba"
        ]
    },
    "1824": {
        "latin": "Radulomyces molaris",
        "eng": "oak tooth crust",
        "latin_syn": [
            "Cerocorticium molare"
        ],
        "hun": "barnás fogasvargomba",
        "hun_syn": [
            "barna fogasgereben"
        ]
    },
    "1825": {
        "latin": "Ramaria apiculata",
        "hun": "hegyesvégű korallgomba"
    },
    "1826": {
        "latin": "Ramaria aurea",
        "hun": "aranysárga korallgomba",
        "hun_syn": [
            "narancsszínű korallgomba"
        ]
    },
    "1827": {
        "latin": "Ramaria botrytis",
        "eng": "rosso coral",
        "hun": "rózsáságú korallgomba"
    },
    "1828": {
        "latin": "Ramaria eosanguinea",
        "hun": "vörösödő korallgomba"
    },
    "1829": {
        "latin": "Ramaria eumorpha",
        "latin_syn": [
            "Ramaria invalii"
        ],
        "hun": "fenyő-korallgomba"
    },
    "1830": {
        "latin": "Ramaria fagetorum",
        "hun": "bükkös korallgomba"
    },
    "1831": {
        "latin": "Ramaria fennica",
        "hun": "lilatönkű korallgomba"
    },
    "1832": {
        "latin": "Ramaria flava",
        "hun": "sárga korallgomba"
    },
    "1833": {
        "latin": "Ramaria flavescens",
        "hun": "narancssárga korallgomba"
    },
    "1834": {
        "latin": "Ramaria formosa",
        "eng": "salmon coral",
        "latin_syn": [
            "Corallium formosum"
        ],
        "hun": "cifra korallgomba",
        "hun_syn": [
            "háromszínű korallgomba"
        ]
    },
    "1835": {
        "latin": "Ramaria fumigata",
        "hun": "ibolyás korallgomba"
    },
    "1836": {
        "latin": "Ramaria gracilis",
        "hun": "karcsú korallgomba",
        "hun_syn": [
            "kecses korallgomba"
        ]
    },
    "1837": {
        "latin": "Ramaria ignicolor",
        "hun": "parázsszínű korallgomba"
    },
    "1838": {
        "latin": "Ramaria largentii",
        "hun": "hegyi korallgomba"
    },
    "1839": {
        "latin": "Ramaria lutea",
        "hun": "sárgás korallgomba"
    },
    "1840": {
        "latin": "Ramaria neoformosa",
        "hun": "élénkrózsás korallgomba"
    },
    "1841": {
        "latin": "Ramaria pallida",
        "eng": "pallid coral",
        "latin_syn": [
            "Ramaria mairei"
        ],
        "hun": "halvány korallgomba"
    },
    "1842": {
        "latin": "Ramaria rufescens",
        "hun": "vörhenyes korallgomba"
    },
    "1843": {
        "latin": "Ramaria sanguinea",
        "eng": "bloody coral",
        "hun": "vörösfoltos korallgomba"
    },
    "1844": {
        "latin": "Ramaria stricta",
        "eng": "upright coral",
        "hun": "merev korallgomba",
        "hun_syn": [
            "fésűs korallgomba"
        ]
    },
    "1845": {
        "latin": "Ramaria subbotrytis",
        "hun": "pompás korallgomba"
    },
    "1846": {
        "latin": "Ramaria testaceoflava",
        "hun": "színváltó korallgomba"
    },
    "1847": {
        "latin": "Ramariopsis kunzei",
        "eng": "ivory coral",
        "hun": "tömzsi korallgomba"
    },
    "1848": {
        "latin": "Ramariopsis pulchella",
        "eng": "lilac coral",
        "hun": "lila korallgomba"
    },
    "1849": {
        "latin": "Resinicium bicolor",
        "eng": "hallowed crust",
        "latin_syn": [
            "Odontia bicolor"
        ],
        "hun": "vékony fésűsgomba"
    },
    "1850": {
        "latin": "Resupinatus trichotis",
        "eng": "hairy oysterling",
        "hun": "pihés terülőlaskagomba",
        "hun_syn": [
            "fekete laskagomba",
            "pihés terülőlaska"
        ]
    },
    "1851": {
        "latin": "Rheubarbariboletus armeniacus",
        "latin_syn": [
            "Xerocomus armeniacus"
        ],
        "hun": "baracksárga tinóru",
        "hun_syn": [
            "barackszínű tinóru"
        ]
    },
    "1852": {
        "latin": "Rhizina undulata",
        "eng": "pine firefungus",
        "latin_syn": [
            "Rhizina inflata"
        ],
        "hun": "gyökeres csészegomba",
        "hun_syn": [
            "gyökeres csészegomba",
            "terülő gyökeres-csészegomba"
        ]
    },
    "1853": {
        "latin": "Rhizocybe vermicularis",
        "latin_syn": [
            "Clitocybe vermicularis"
        ],
        "hun": "fenyő-tölcsérgomba"
    },
    "1854": {
        "latin": "Rhizophagus fasciculatus",
        "latin_syn": [
            "Glomus fasciculatum"
        ],
        "hun": "csokros gomolygomba"
    },
    "1855": {
        "latin": "Rhizopogon aestivus",
        "hun": "vörös istrángospöfeteg",
        "hun_syn": [
            "vörös istrángos-álpöfeteg"
        ]
    },
    "1856": {
        "latin": "Rhizopogon angustisepta",
        "hun": "litvániai istrángospöfeteg",
        "hun_syn": [
            "litvániai álpöfeteg"
        ]
    },
    "1857": {
        "latin": "Rhizopogon hymenogastrosporus",
        "hun": "váltóspórás istrángospöfeteg",
        "hun_syn": [
            "váltóspórás álpöfeteg"
        ]
    },
    "1858": {
        "latin": "Rhizopogon luteolus",
        "eng": "yellow false truffle",
        "latin_syn": [
            "Rhizopogon obtextus"
        ],
        "hun": "sárgás istrángospöfeteg",
        "hun_syn": [
            "istrángos álpöfeteg,  sárgás álpöfeteg",
            "sárgás istrángos-pöfeteg"
        ]
    },
    "1859": {
        "latin": "Rhizopogon melanogastroides",
        "hun": "Petrak-istrángospöfeteg",
        "hun_syn": [
            "Petrak álpöfetegje"
        ]
    },
    "1860": {
        "latin": "Rhizopogon occidentalis",
        "hun": "nyugati istrángospöfeteg"
    },
    "1861": {
        "latin": "Rhizopogon roseolus",
        "eng": "blushing false truffle",
        "latin_syn": [
            "Rhizopogon rubescens  "
        ],
        "hun": "rózsaszínes istrángospöfeteg",
        "hun_syn": [
            "rózsaszínes "
        ]
    },
    "1862": {
        "latin": "Rhizopogon vulgaris",
        "hun": "istrángos-álpöfeteg",
        "hun_syn": [
            "vöröses istrángos-álpöfeteg",
            "vöröses istrángospöfeteg"
        ]
    },
    "1863": {
        "latin": "Rhizopogon suavis",
        "hun": "szöszös istrángospöfeteg",
        "hun_syn": [
            "szöszös álpöfeteg",
            ""
        ]
    },
    "1864": {
        "latin": "Rhizopogon villosulus",
        "latin_syn": [
            "Rhizopogon reticulatus"
        ],
        "hun": "hálós istrángospöfeteg",
        "hun_syn": [
            "fröcskölt álpöfeteg",
            ""
        ]
    },
    "1865": {
        "latin": "Rhodocollybia butyracea",
        "eng": "butter cap",
        "latin_syn": [
            "Collybia butyracea"
        ],
        "hun": "bunkóslábú fülőke"
    },
    "1866": {
        "latin": "Rhodocollybia butyracea var. asema",
        "latin_syn": [
            "Collybia butyracea f. asema",
            "Rhodocollybia butyracea f. asema"
        ],
        "hun": "szaruszürke fülőke"
    },
    "1867": {
        "latin": "Rhodocollybia fodiens",
        "latin_syn": [
            "Collybia fodiens"
        ],
        "hun": "fűrészeslemezű fülőke",
        "hun_syn": [
            "barázdálttönkű fülőke"
        ]
    },
    "1868": {
        "latin": "Rhodocollybia maculata",
        "eng": "spotted toughshank",
        "latin_syn": [
            "Collybia maculata"
        ],
        "hun": "foltos fülőke"
    },
    "1869": {
        "latin": "Rhodocollybia prolixa",
        "eng": "toothed toughshank",
        "latin_syn": [
            "Collybia distorta"
        ],
        "hun": "csavarttönkű fülőke"
    },
    "1870": {
        "latin": "Rhodocybe caelata",
        "latin_syn": [
            "Clitopilus caelatus"
        ],
        "hun": "homoki álcölöpgomba"
    },
    "1871": {
        "latin": "Rhodocybe gemina",
        "eng": "tan pinkgill",
        "latin_syn": [
            "Clitopilus geminus  Rho-"
        ],
        "hun": "csalóka pereszke",
        "hun_syn": [
            "csalóka álcölöpgomba",
            "pereszkés álcölöpgomba"
        ]
    },
    "1872": {
        "latin": "Rhodofomes roseus",
        "eng": "rose bracket",
        "latin_syn": [
            "Fomitopsis rosea"
        ],
        "hun": "rózsás tapló"
    },
    "1873": {
        "latin": "Rhodophana nitellina",
        "latin_syn": [
            "Clitopilus nitellinus",
            "Rhodocybe nitellina"
        ],
        "hun": "narancsbarna álcölöpgomba",
        "hun_syn": [
            "sárgás cölöpgomba"
        ]
    },
    "1874": {
        "latin": "Rhodotus palmatus",
        "eng": "wrinkled peach",
        "hun": "tönkös kacskagomba",
        "hun_syn": [
            "rózsás tönkgomba"
        ]
    },
    "1875": {
        "latin": "Rickenella fibula",
        "eng": "orange mosscap",
        "hun": "narancsos mohakígyógomba",
        "hun_syn": [
            "sárga mohakígyógomba"
        ]
    },
    "1876": {
        "latin": "Rickenella swartzii",
        "eng": "collared mosscap",
        "hun": "kékes mohakígyógomba",
        "hun_syn": [
            "kékes moha-kígyógomba"
        ]
    },
    "1877": {
        "latin": "Rigidoporus ulmarius",
        "eng": "giant elm bracket",
        "hun": "szilfa-tapló"
    },
    "1878": {
        "latin": "Ripartites tricholoma",
        "eng": "bearded seamine",
        "latin_syn": [
            "Ripartites metrodii"
        ],
        "hun": "borostás nemezesgomba",
        "hun_syn": [
            "borostás álcölöpgomba",
            "borostás szöszösgomba",
            "foltos nemezesgomba"
        ]
    },
    "1879": {
        "latin": "Roesleria subterranea",
        "latin_syn": [
            "Roesleria hypogaea"
        ],
        "hun": "szegecsfejű gyökérgomba"
    },
    "1880": {
        "latin": "Rubroboletus dupainii",
        "latin_syn": [
            "Boletus dupainii"
        ],
        "hun": "kárminvörös tinóru"
    },
    "1881": {
        "latin": "Rubroboletus legaliae",
        "latin_syn": [
            "Boletus legaliae"
        ],
        "hun": "rózsáskalapú tinóru",
        "hun_syn": [
            "fényes tinóru"
        ]
    },
    "1882": {
        "latin": "Rubroboletus lupinus",
        "latin_syn": [
            "Boletus lupinus"
        ],
        "hun": "ordas tinóru"
    },
    "1883": {
        "latin": "Rubroboletus rhodoxanthus",
        "latin_syn": [
            "Boletus rhodoxanthus"
        ],
        "hun": "bíbor tinóru"
    },
    "1884": {
        "latin": "Rubroboletus satanas",
        "latin_syn": [
            "Boletus satanas"
        ],
        "hun": "sátántinóru",
        "hun_syn": [
            "sátángomba"
        ]
    },
    "1885": {
        "latin": "Russula acrifolia",
        "eng": "hotlips brittlegill",
        "hun": "csípőslemezű galambgomba"
    },
    "1886": {
        "latin": "Russula adusta",
        "eng": "winecork brittlegill",
        "hun": "sötétedő galambgomba"
    },
    "1887": {
        "latin": "Russula aeruginea",
        "eng": "green brittlegill",
        "hun": "fűzöld galambgomba"
    },
    "1888": {
        "latin": "Russula albonigra",
        "eng": "menthol brittlegill",
        "hun": "színváltó galambgomba"
    },
    "1889": {
        "latin": "Russula alutacea",
        "hun": "ízletes galambgomba"
    },
    "1890": {
        "latin": "Russula amarissima",
        "hun": "epeízű galambgomba"
    },
    "1891": {
        "latin": "Russula amoena",
        "hun": "változékony galambgomba"
    },
    "1892": {
        "latin": "Russula amoenicolor",
        "hun": "csinos galambgomba",
        "hun_syn": [
            "zöldeslila galambgomba"
        ]
    },
    "1893": {
        "latin": "Russula amoenolens",
        "eng": "camembert brittlegill",
        "hun": "sajtszagú galambgomba"
    },
    "1894": {
        "latin": "Russula anatina",
        "hun": "acélkékes galambgomba",
        "hun_syn": [
            "gyöngyszürke galambgomba"
        ]
    },
    "1895": {
        "latin": "Russula anthracina",
        "eng": "coal brittlegill",
        "hun": "rózsáslemezű galambgomba",
        "hun_syn": [
            "kormoshúsú galambgomba"
        ]
    },
    "1896": {
        "latin": "Russula aquosa",
        "eng": "red swamp brittlegill",
        "hun": "almaillatú galambgomba"
    },
    "1897": {
        "latin": "Russula atrorubens",
        "latin_syn": [
            "Russula olivaceo-violascens"
        ],
        "hun": "feketéspiros galambgomba"
    },
    "1898": {
        "latin": "Russula aurea",
        "eng": "gilded brittlegill",
        "latin_syn": [
            "Russula aurata"
        ],
        "hun": "aranyos galambgomba"
    },
    "1899": {
        "latin": "Russula azurea",
        "hun": "azúrkék galambgomba"
    },
    "1900": {
        "latin": "Russula badia",
        "eng": "burning brittlegill",
        "hun": "cédrusszagú galambgomba",
        "hun_syn": [
            "cédrusillatú galambgomba"
        ]
    },
    "1901": {
        "latin": "Russula betularum",
        "eng": "birch brittlegill",
        "hun": "nyírfa-galambgomba"
    },
    "1902": {
        "latin": "Russula brunneoviolacea",
        "hun": "bíborlilás galambgomba"
    },
    "1903": {
        "latin": "Russula caerulea",
        "eng": "humpback brittlegill",
        "latin_syn": [
            "Russula amara"
        ],
        "hun": "púpos galambgomba"
    },
    "1904": {
        "latin": "Russula carpini",
        "hun": "gyertyán-galambgomba"
    },
    "1905": {
        "latin": "Russula cavipes",
        "hun": "üregestönkű galambgomba"
    },
    "1906": {
        "latin": "Russula cessans",
        "eng": "tardy brittlegill",
        "hun": "késői galambgomba",
        "hun_syn": [
            "barnásközepű galambgomba"
        ]
    },
    "1907": {
        "latin": "Russula chloroides",
        "eng": "blue band brittlegill",
        "hun": "kékeslemezű galambgomba",
        "hun_syn": [
            "keskenylemezű galambgomba"
        ]
    },
    "1908": {
        "latin": "Russula claroflava",
        "eng": "yellow swamp brittlegill",
        "latin_syn": [
            "Russula flava"
        ],
        "hun": "krómsárga galambgomba"
    },
    "1909": {
        "latin": "Russula clavipes",
        "latin_syn": [
            "Russula elaeodes s. auct.",
            "Russula pseudo-olivascens"
        ],
        "hun": "olajzöldes galambgomba"
    },
    "1910": {
        "latin": "Russula consobrina",
        "hun": "koromszürke galambgomba",
        "hun_syn": [
            "ólomszürke galambgomba"
        ]
    },
    "1911": {
        "latin": "Russula cremeoavellanea",
        "eng": "cream brittlegill",
        "hun": "rózsásbarna galambgomba",
        "hun_syn": [
            "élénkvörös galambgomba"
        ]
    },
    "1912": {
        "latin": "Russula cyanoxantha",
        "eng": "charcoal burner",
        "hun": "kékhátú galambgomba",
        "hun_syn": [
            "galambica"
        ]
    },
    "1913": {
        "latin": "Russula decipiens",
        "hun": "csalóka galambgomba"
    },
    "1914": {
        "latin": "Russula decolorans",
        "eng": "copper brittlegill",
        "hun": "tarkahúsú galambgomba"
    },
    "1915": {
        "latin": "Russula delica",
        "eng": "milk white brittlegill",
        "hun": "földtoló galambgomba",
        "hun_syn": [
            "földtúró galambgomba"
        ]
    },
    "1916": {
        "latin": "Russula densifolia",
        "eng": "crowded brittlegill",
        "hun": "feketedő galambgomba",
        "hun_syn": [
            "piruló galambgomba"
        ]
    },
    "1917": {
        "latin": "Russula depallens",
        "latin_syn": [
            "Russula exalbicans",
            "Russula pulchella"
        ],
        "hun": "halványuló galambgomba"
    },
    "1918": {
        "latin": "Russula emetica",
        "eng": "sickener",
        "hun": "hánytató galambgomba"
    },
    "1919": {
        "latin": "Russula faginea",
        "hun": "bükkös galambgomba"
    },
    "1920": {
        "latin": "Russula farinipes",
        "eng": "floury brittlegill",
        "hun": "korpástönkű galambgomba"
    },
    "1921": {
        "latin": "Russula fellea",
        "eng": "geranium brittlegill",
        "hun": "fakó galambgomba",
        "hun_syn": [
            "epeízű galambgomba"
        ]
    },
    "1922": {
        "latin": "Russula firmula",
        "latin_syn": [
            "Russula transiens"
        ],
        "hun": "csípős galambgomba",
        "hun_syn": [
            "ibolyaszínű galambgomba"
        ]
    },
    "1923": {
        "latin": "Russula foetens",
        "eng": "stinking brittlegill",
        "hun": "büdös galambgomba"
    },
    "1924": {
        "latin": "Russula font-queri",
        "eng": "beautiful brittlegill",
        "hun": "rézvörös galambgomba"
    },
    "1925": {
        "latin": "Russula fragilis",
        "eng": "fragile brittlegill",
        "hun": "törékeny galambgomba"
    },
    "1926": {
        "latin": "Russula galochroa",
        "hun": "gyöngyszürke galambgomba",
        "hun_syn": [
            "fakó galambgomba"
        ]
    },
    "1927": {
        "latin": "Russula gracillima",
        "eng": "slender brittlegill",
        "hun": "kecses galambgomba"
    },
    "1928": {
        "latin": "Russula grata",
        "eng": "bitter almond brittlegill",
        "latin_syn": [
            "Russula laurocerasi"
        ],
        "hun": "szagos galambgomba",
        "hun_syn": [
            "mandulaillatú galambgomba"
        ]
    },
    "1929": {
        "latin": "Russula graveolens",
        "eng": "oldcrab brittlegill",
        "hun": "halszagú galambgomba",
        "hun_syn": [
            "erősszagú galambgomba"
        ]
    },
    "1930": {
        "latin": "Russula grisea",
        "eng": "false charcoal burner",
        "hun": "szürkészöld galambgomba"
    },
    "1931": {
        "latin": "Russula heterophylla",
        "eng": "greasy green brittlegill",
        "hun": "dióízű galambgomba",
        "hun_syn": [
            "simahátú galambgomba"
        ]
    },
    "1932": {
        "latin": "Russula ilicis",
        "hun": "tölgyfa-galambgomba"
    },
    "1933": {
        "latin": "Russula illota",
        "eng": "freckled brittlegill",
        "hun": "piszkos galambgomba",
        "hun_syn": [
            "pontozottélű galambgomba"
        ]
    },
    "1934": {
        "latin": "Russula insignis",
        "latin_syn": [
            "Russula livescens"
        ],
        "hun": "sárgatövű galambgomba",
        "hun_syn": [
            "szürkésbarna galambgomba"
        ]
    },
    "1935": {
        "latin": "Russula integra",
        "eng": "nutty brittlegill",
        "hun": "barnásvörös galambgomba",
        "hun_syn": [
            "veres galambgomba"
        ]
    },
    "1936": {
        "latin": "Russula ionochlora",
        "eng": "oilslick brittlegill",
        "hun": "papagáj-galambgomba",
        "hun_syn": [
            "lilászöld galambgomba"
        ]
    },
    "1937": {
        "latin": "Russula laccata",
        "eng": "willow brittlegill",
        "hun": "fűzlápi galambgomba"
    },
    "1938": {
        "latin": "Russula laeta",
        "hun": "élénkvörös galambgomba"
    },
    "1939": {
        "latin": "Russula lepida",
        "eng": "rosy brittlegill",
        "latin_syn": [
            "Russula rosacea"
        ],
        "hun": "piros galambgomba",
        "hun_syn": [
            "rózsás galambgomba"
        ]
    },
    "1940": {
        "latin": "Russula lilacea",
        "eng": "lilac brittlegill",
        "hun": "rózsáslila galambgomba"
    },
    "1941": {
        "latin": "Russula lutensis",
        "hun": "tömzsi galambgomba"
    },
    "1942": {
        "latin": "Russula luteotacta",
        "eng": "yellowstaining brittlegill",
        "hun": "sárguló galambgomba"
    },
    "1943": {
        "latin": "Russula maculata",
        "hun": "foltos galambgomba"
    },
    "1944": {
        "latin": "Russula mairei",
        "hun": "bükkfa-galambgomba"
    },
    "1945": {
        "latin": "Russula medullata",
        "hun": "acélszürke galambgomba"
    },
    "1946": {
        "latin": "Russula melliolens",
        "hun": "mézszagú galambgomba"
    },
    "1947": {
        "latin": "Russula mustelina",
        "eng": "russet brittlegill",
        "hun": "sárgásbarna galambgomba"
    },
    "1948": {
        "latin": "Russula nauseosa",
        "eng": "nauseous brittlegill",
        "hun": "többszínű galambgomba"
    },
    "1949": {
        "latin": "Russula nigricans",
        "eng": "blackening brittlegill",
        "hun": "szenes galambgomba"
    },
    "1950": {
        "latin": "Russula nitida",
        "eng": "purple swamp brittlegill",
        "hun": "fényes galambgomba"
    },
    "1951": {
        "latin": "Russula ochroleuca",
        "eng": "ochre brittlegill",
        "hun": "fakósárga galambgomba",
        "hun_syn": [
            "sárga galambgomba"
        ]
    },
    "1952": {
        "latin": "Russula odorata",
        "hun": "illatos galambgomba"
    },
    "1953": {
        "latin": "Russula olivacea",
        "eng": "olive brittlegill",
        "hun": "vöröstönkű galambgomba",
        "hun_syn": [
            "olajbarna galambgomba"
        ]
    },
    "1954": {
        "latin": "Russula paludosa",
        "eng": "hintapink",
        "hun": "lápi galambgomba"
    },
    "1955": {
        "latin": "Russula parazurea",
        "eng": "powdery brittlegill",
        "hun": "deres galambgomba",
        "hun_syn": [
            "kékes galambgomba"
        ]
    },
    "1956": {
        "latin": "Russula pectinata",
        "hun": "fésűs galambgomba"
    },
    "1957": {
        "latin": "Russula persicina",
        "hun": "rózsásvörös galambgomba"
    },
    "1958": {
        "latin": "Russula pseudointegra",
        "eng": "scarlet brittlegill",
        "hun": "keserű galambgomba"
    },
    "1959": {
        "latin": "Russula puellaris",
        "eng": "yellowing brittlegill",
        "hun": "sárgulótönkű galambgomba"
    },
    "1960": {
        "latin": "Russula puellula",
        "hun": "lánykagalambgomba"
    },
    "1961": {
        "latin": "Russula purpurata",
        "hun": "sötétpiros galambgomba"
    },
    "1962": {
        "latin": "Russula queletii",
        "eng": "fruity brittlegill",
        "hun": "lucfenyő-galambgomba"
    },
    "1963": {
        "latin": "Russula raoultii",
        "eng": "pallid brittlegill",
        "hun": "halványsárga galambgomba"
    },
    "1964": {
        "latin": "Russula recondita",
        "latin_syn": [
            "Russula pectinatoides s. auct."
        ],
        "hun": "enyhe galambgomba"
    },
    "1965": {
        "latin": "Russula rhodomelanea",
        "hun": "szürkülőtövű galambgomba"
    },
    "1966": {
        "latin": "Russula rhodopus",
        "latin_syn": [
            "Russula rhodopoda"
        ],
        "hun": "lángvöröstönkű galambgomba"
    },
    "1967": {
        "latin": "Russula risigallina",
        "eng": "golden brittlegill",
        "latin_syn": [
            "Russula chamaeleontina",
            "Russula vitellina"
        ],
        "hun": "cifra galambgomba"
    },
    "1968": {
        "latin": "Russula romellii",
        "hun": "sárgalemezű galambgomba",
        "hun_syn": [
            "sárgahasú galambgomba"
        ]
    },
    "1969": {
        "latin": "Russula roseipes",
        "hun": "rózsástönkű galambgomba"
    },
    "1970": {
        "latin": "Russula rubroalba",
        "hun": "vörösfoltos galambgomba",
        "hun_syn": [
            "vörösfehér galambgomba"
        ]
    },
    "1971": {
        "latin": "Russula sanguinea",
        "latin_syn": [
            "Russula sanguinaria"
        ],
        "hun": "vérvörös galambgomba"
    },
    "1972": {
        "latin": "Russula sardonia",
        "eng": "primrose brittlegill",
        "hun": "citromlemezű galambgomba",
        "hun_syn": [
            "izzadó galambgomba"
        ]
    },
    "1973": {
        "latin": "Russula seperina",
        "hun": "gyászos galambgomba"
    },
    "1974": {
        "latin": "Russula silvestris",
        "eng": "woodland brittlegill",
        "hun": "erdei galambgomba"
    },
    "1975": {
        "latin": "Russula solaris",
        "eng": "sunny brittlegill",
        "hun": "élénksárga galambgomba"
    },
    "1976": {
        "latin": "Russula sororia",
        "eng": "sepia brittlegill",
        "hun": "barna galambgomba"
    },
    "1977": {
        "latin": "Russula torulosa",
        "eng": "brawny brittlegill",
        "hun": "fenyő-galambgomb"
    },
    "1978": {
        "latin": "Russula turci",
        "eng": "pirate brittlegill",
        "hun": "jodoformszagú galambgomba"
    },
    "1979": {
        "latin": "Russula undulata",
        "latin_syn": [
            "Russula atropurpurea"
        ],
        "hun": "feketésvörös galambgomba"
    },
    "1980": {
        "latin": "Russula velenovskyi",
        "eng": "coral brittlegill",
        "hun": "téglavörös galambgomba"
    },
    "1981": {
        "latin": "Russula velutipes",
        "eng": "dawn brittlegill",
        "latin-syn": [
            "Russula aurora",
            "Russula rosea"
        ],
        "hun": "rózsás galambgomba"
    },
    "1982": {
        "latin": "Russula versicolor",
        "eng": "variable brittlegill",
        "hun": "sokszínű galambgomba"
    },
    "1983": {
        "latin": "Russula vesca",
        "eng": "the flirt",
        "hun": "ráncos galambgomba",
        "hun_syn": [
            "ráncostönkű galambgomba"
        ]
    },
    "1984": {
        "latin": "Russula veternosa",
        "hun": "húsrózsás galambgomba",
        "hun_syn": [
            "rózsapiros galambgomba"
        ]
    },
    "1985": {
        "latin": "Russula vinosa",
        "eng": "darkening brittlegill",
        "hun": "szürkülő galambgomba",
        "hun_syn": [
            "borvörös galambgomba"
        ]
    },
    "1986": {
        "latin": "Russula vinosobrunnea",
        "eng": "naked brittlegill",
        "hun": "borbarna galambgomba"
    },
    "1987": {
        "latin": "Russula vinosopurpurea",
        "hun": "borvörös galambgomba"
    },
    "1988": {
        "latin": "Russula violeipes",
        "eng": "velvet brittlegill",
        "hun": "ibolyástönkű galambgomba"
    },
    "1989": {
        "latin": "Russula virescens",
        "eng": "greencracked brittlegill",
        "hun": "varashátú galambgomba",
        "hun_syn": [
            "varas galambgomba"
        ]
    },
    "1990": {
        "latin": "Russula viscida",
        "eng": "viscid brittlegill",
        "hun": "bőrsárgatönkű galambgomba"
    },
    "1991": {
        "latin": "Russula vitellina",
        "latin_syn": [
            "Russula lutea"
        ],
        "hun": "baracksárga galambgomba"
    },
    "1992": {
        "latin": "Russula xerampelina",
        "eng": "crab brittlegill",
        "hun": "barnulóhúsú galambgomba"
    },
    "1993": {
        "latin": "Rutstroemia bolaris",
        "hun": "bordás-csapos sztrómacsészegomba",
        "hun_syn": [
            "bordás-csapos csészegombácska"
        ]
    },
    "1994": {
        "latin": "Rutstroemia bulgarioides",
        "hun": "luctoboz sztrómacsészegomba"
    },
    "1995": {
        "latin": "Rutstroemia firma",
        "eng": "brown cup",
        "hun": "szívós sztrómacsészegomba"
    },
    "1996": {
        "latin": "Sarcodon glaucopus",
        "hun": "kékestönkű gereben"
    },
    "1997": {
        "latin": "Sarcodon imbricatus",
        "hun": "cserepes gereben"
    },
    "1998": {
        "latin": "Sarcodon joeides",
        "hun": "lilahúsú gereben"
    },
    "1999": {
        "latin": "Sarcodon leucopus",
        "hun": "szagos gereben"
    },
    "2000": {
        "latin": "Sarcodon scabrosus",
        "hun": "korpás gereben"
    },
    "2001": {
        "latin": "Sarcodon squamosus",
        "eng": "scaly tooth",
        "hun": "sötétpikkelyű gereben"
    },
    "2002": {
        "latin": "Sarcoscypha austriaca",
        "eng": "scarlet elfcup",
        "hun": "osztrák csészegomba"
    },
    "2003": {
        "latin": "Sarcoscypha coccinea",
        "eng": "ruby elfcup",
        "latin_syn": [
            "Plectania coccinea"
        ],
        "hun": "piros csészegomba"
    },
    "2004": {
        "latin": "Sarcoscypha jurana",
        "hun": "hárscsészegomba"
    },
    "2005": {
        "latin": "Sarcosphaera coronaria",
        "eng": "violet crowncup",
        "latin_syn": [
            "Sarcosphaera crassa",
            "Sarcosphaera eximia"
        ],
        "hun": "tulipán-csészegomba",
        "hun_syn": [
            "lila tulipángomba"
        ]
    },
    "2006": {
        "latin": "Schizophyllum amplum",
        "latin_syn": [
            "Auriculariopsis ampla",
            "Cytidia flocculenta"
        ],
        "eng": "poplar bells",
        "hun": "pihés gyűszűgomba",
        "hun_syn": [
            "fülecskegomba"
        ]
    },
    "2007": {
        "latin": "Schizophyllum commune",
        "eng": "splitgill",
        "hun": "hasadtlemezű gomba"
    },
    "2008": {
        "latin": "Schizopora flavipora",
        "latin_syn": [
            "Schizopora carneolutea"
        ],
        "hun": "sárgapórusú kéreggomba",
        "hun_syn": [
            "bibircses kéregtapló"
        ]
    },
    "2009": {
        "latin": "Schizopora paradoxa",
        "eng": "split porecrust",
        "hun": "változékony kéreggomba"
    },
    "2010": {
        "latin": "Scleroderma areolatum",
        "eng": "leopard earthball",
        "hun": "leopárdáltrifla",
        "hun_syn": [
            "pettyes áltrifla"
        ]
    },
    "2011": {
        "latin": "Scleroderma bovista",
        "eng": "potato earthball",
        "latin_syn": [
            "Phlyactospora fusca"
        ],
        "hun": "fakó áltrifla"
    },
    "2012": {
        "latin": "Scleroderma cepa",
        "hun": "hagymaszerű áltrifla"
    },
    "2013": {
        "latin": "Scleroderma citrinum",
        "eng": "common earthball",
        "latin_syn": [
            "Scleroderma aurantium",
            "Scleroderma vulgare"
        ],
        "hun": "rőt áltrifla",
        "hun_syn": [
            "erdei áltrifla"
        ]
    },
    "2014": {
        "latin": "Scleroderma meridionale",
        "hun": "déli áltrifla"
    },
    "2015": {
        "latin": "Scleroderma verrucosum",
        "eng": "scaly earthball",
        "hun": "nyeles áltrifla"
    },
    "2016": {
        "latin": "Sclerogaster candidus",
        "hun": "francia tömörpöfeteg",
        "hun_syn": [
            "francia álpöfeteg"
        ]
    },
    "2017": {
        "latin": "Sclerogaster compactus",
        "hun": "apró tömörpöfeteg",
        "hun_syn": [
            "tömör álpöfeteg"
        ]
    },
    "2018": {
        "latin": "Sclerogaster gastrosporioides",
        "hun": "telt tömörpöfeteg",
        "hun_syn": [
            "apró álpöfeteg"
        ]
    },
    "2019": {
        "latin": "Sclerogaster liospermus",
        "hun": "kristályos tömörpöfeteg",
        "hun_syn": [
            "kristályos álpöfeteg"
        ]
    },
    "2020": {
        "latin": "Sclerogaster siculus",
        "hun": "pirinyó tömörpöfeteg",
        "hun_syn": [
            "pirinyó álpöfeteg"
        ]
    },
    "2021": {
        "latin": "Scopuloides rimosa",
        "hun": "gerebenszerű terülőgomba"
    },
    "2022": {
        "latin": "Scutellinia crinita",
        "hun": "hosszúpillás sörtéscsészegomba"
    },
    "2023": {
        "latin": "Scutellinia kerguelensis",
        "hun": "rövidpillás sörtéscsészegomba"
    },
    "2024": {
        "latin": "Scutellinia scutellata",
        "eng": "common eyelash",
        "hun": "piros sörtéscsészegomba",
        "hun_syn": [
            "sörtéscsészegomba"
        ]
    },
    "2025": {
        "latin": "Scutellinia setosa",
        "hun": "sündisznó-sörtéscsészegomba"
    },
    "2026": {
        "latin": "Scutellinia trechispora",
        "hun": "kerekspórás sörtéscsészegomba"
    },
    "2027": {
        "latin": "Scutellinia umbrorum",
        "hun": "barnáspiros sörtéscsészegomba"
    },
    "2028": {
        "latin": "Scutiger pes-caprae",
        "latin_syn": [
            "Albatrellus pes-caprae"
        ],
        "hun": "barnahátú zsemlegomba",
        "hun_syn": [
            "kecskeláb zsemlegomba"
        ]
    },
    "2029": {
        "latin": "Sebacina incrustans",
        "eng": "enveloping crust",
        "hun": "terjengő bevonatgomba"
    },
    "2030": {
        "latin": "Septobasidium rameale",
        "latin_syn": [
            "Stereum rameale"
        ],
        "hun": "gallyonülő réteggomba"
    },
    "2031": {
        "latin": "Serpula himantioides",
        "hun": "rostos redőgomba",
        "hun_syn": [
            "papírszerű redőgomba"
        ]
    },
    "2032": {
        "latin": "Serpula lacrymans",
        "eng": "dry rot",
        "hun": "könnyező házigomba",
        "hun_syn": [
            "könnyező fagomba"
        ]
    },
    "2033": {
        "latin": "Sidera lenis",
        "latin_syn": [
            "Antrodia lenis",
            "Poria lenis",
            "Skeletocutis lenis"
        ],
        "hun": "sárga kéreggomba"
    },
    "2034": {
        "latin": "Simocybe centunculus",
        "eng": "dingy twiglet",
        "hun": "kisspórás olajgombácska"
    },
    "2035": {
        "latin": "Simocybe reducta",
        "latin_syn": [
            "Ramicola reducta"
        ],
        "hun": "borostyánszínű olajgombácska"
    },
    "2036": {
        "latin": "Singerocybe phaeophthalma",
        "eng": "chicken run funnel",
        "latin_syn": [
            "Clitocybe hydrogramma",
            "Clitocybe phaeophthalma"
        ],
        "hun": "dohos tölcsérgomba"
    },
    "2037": {
        "latin": "Sistotrema confluens",
        "eng": "aromatic earthfan",
        "hun": "nyeles fogasgereben"
    },
    "2038": {
        "latin": "Sistotrema muscicola",
        "latin_syn": [
            "Granadinia muscicola"
        ],
        "hun": "sárga kéreggereben"
    },
    "2039": {
        "latin": "Skeletocutis amorpha",
        "eng": "rusty crust",
        "latin_syn": [
            "Gloeoporus amorphus"
        ],
        "hun": "narancspórusú likacsosgomba",
        "hun_syn": [
            "alaktalan likacsosgomba"
        ]
    },
    "2040": {
        "latin": "Skeletocutis nivea",
        "eng": "hazel bracket",
        "latin_syn": [
            "Incrustoporia semipileta"
        ],
        "hun": "szegett likacsosgomba"
    },
    "2041": {
        "latin": "Smardaea planchonis",
        "latin_syn": [
            "Greletia planchonis"
        ],
        "hun": "feketés lilacsészegomba"
    },
    "2042": {
        "latin": "Smardaea proteana",
        "hun": "sötét lilacsészegomba"
    },
    "2043": {
        "latin": "Sowerbyella imperialis",
        "eng": "imperial rooting cup",
        "hun": "egyszínű gyökeres-csészegomba"
    },
    "2044": {
        "latin": "Sowerbyella radiculata",
        "latin_syn": [
            "Otidea radiculata"
        ],
        "hun": "gyökeres fülesgomba"
    },
    "2045": {
        "latin": "Sowerbyella rhenana",
        "eng": "stalked orange peel fungus",
        "hun": "narancssárga fülesgomba"
    },
    "2046": {
        "latin": "Sparassis crispa",
        "eng": "wood cauliflower",
        "hun": "fodros káposztagomba"
    },
    "2047": {
        "latin": "Sparassis laminosa",
        "latin_syn": [
            "Sparassis brevipes"
        ],
        "hun": "leveles káposztagomba"
    },
    "2048": {
        "latin": "Spathularia flavida",
        "eng": "yellow fan",
        "hun": "sárga lapátgomba"
    },
    "2049": {
        "latin": "Spathularia neesii",
        "hun": "barna lapátgomba"
    },
    "2050": {
        "latin": "Sphaerobolus stellatus",
        "eng": "shooting star",
        "hun": "golyógomba"
    },
    "2051": {
        "latin": "Sphaerosoma fuscescens",
        "hun": "recés avarcsészegomba"
    },
    "2052": {
        "latin": "Sphaerozone ostiolatum",
        "latin_syn": [
            "Sphaerosoma ostiolatum"
        ],
        "hun": "szemölcsös avarcsészegomba"
    },
    "2053": {
        "latin": "Sphagnurus paluster",
        "latin_syn": [
            "Lyophyllum palustre"
        ],
        "hun": "lápi szürkefülőke"
    },
    "2054": {
        "latin": "Spongipellis litschaueri",
        "latin_syn": [
            "Spongipellis irpex"
        ],
        "hun": "fehér csertapló",
        "hun_syn": [
            "fehér likacsosgomba"
        ]
    },
    "2055": {
        "latin": "Spongipellis pachyodon",
        "latin_syn": [
            "Sarcodontia pachyodon"
        ],
        "hun": "fogas likacsosgomba"
    },
    "2056": {
        "latin": "Spongipellis spumeus",
        "latin_syn": [
            "Sarcodontia spumea",
            "Spongipellis foetidus"
        ],
        "hun": "lombfa-likacsosgomba",
        "hun_syn": [
            "büdös likacsosgomba"
        ]
    },
    "2057": {
        "latin": "Squamanita odorata",
        "eng": "fragrant strangler",
        "hun": "illatos pikkelyesgalóca"
    },
    "2058": {
        "latin": "Squamanita schreieri",
        "hun": "sárga pikkelyesgalóca"
    },
    "2059": {
        "latin": "Steccherinum fimbriatum",
        "hun": "rojtos tányérosgereben"
    },
    "2060": {
        "latin": "Steccherinum ochraceum",
        "hun": "szöszös tányérosgereben"
    },
    "2061": {
        "latin": "Stephanospora caroticolor",
        "eng": "carroty false truffle",
        "hun": "répaszínű narancspöfeteg",
        "hun_syn": [
            "narancsszínű álpöfeteg"
        ]
    },
    "2062": {
        "latin": "Stephensia bombycina",
        "hun": "bűzlő nemezestrifla",
        "hun_syn": [
            "parti szarvasgomba"
        ]
    },
    "2063": {
        "latin": "Stereum gausapatum",
        "eng": "bleeding oak crust",
        "latin_syn": [
            "Stereum spadiceum"
        ],
        "hun": "nemezes réteggomba"
    },
    "2064": {
        "latin": "Stereum hirsutum",
        "eng": "hairy curtain crust",
        "hun": "borostás réteggomba"
    },
    "2065": {
        "latin": "Stereum ochroleucum",
        "hun": "selymes réteggomba"
    },
    "2066": {
        "latin": "Stereum rugosum",
        "eng": "bleeding broadleaf crust",
        "hun": "évelő réteggomba"
    },
    "2067": {
        "latin": "Stereum sanguinolentum",
        "eng": "bleeding conifer crust",
        "hun": "vörösödő réteggomba"
    },
    "2068": {
        "latin": "Stereum subtomentosum",
        "eng": "yellowing curtain crust",
        "hun": "bársonyos réteggomba",
        "hun_syn": [
            "molyhos réteggomba"
        ]
    },
    "2069": {
        "latin": "Strobilomyces strobilaceus",
        "eng": "old man of the woods",
        "latin_syn": [
            "Strobilomyces floccopus"
        ],
        "hun": "pikkelyes tinóru"
    },
    "2070": {
        "latin": "Strobilurus esculentus",
        "eng": "sprucecone cap",
        "hun": "lucos tobozfülőke",
        "hun_syn": [
            "luc tobozfülőke"
        ]
    },
    "2071": {
        "latin": "Strobilurus stephanocystis",
        "eng": "Russian conecap",
        "hun": "enyhe tobozfülőke",
        "hun_syn": [
            "bordás tobozfülőke",
            ""
        ]
    },
    "2072": {
        "latin": "Strobilurus tenacellus",
        "eng": "pinecone cap",
        "hun": "keserű tobozfülőke"
    },
    "2073": {
        "latin": "Stropharia aeruginosa",
        "eng": "verdigris roundhead ",
        "hun": "zöld harmatgomba"
    },
    "2074": {
        "latin": "Stropharia albonitens",
        "hun": "fehér harmatgomba"
    },
    "2075": {
        "latin": "Stropharia caerulea",
        "eng": "blue roundhead",
        "latin_syn": [
            "Stropharia cyanea"
        ],
        "hun": "zöldeskék harmatgomba"
    },
    "2076": {
        "latin": "Stropharia coronilla",
        "eng": "garland roundhead",
        "hun": "sárga harmatgomba"
    },
    "2077": {
        "latin": "Stropharia hornemannii",
        "eng": "conifer roundhead",
        "latin_syn": [
            "Stropharia depilata"
        ],
        "hun": "fenyves harmatgomba",
        "hun_syn": [
            "termetes harmatgomba"
        ]
    },
    "2078": {
        "latin": "Stropharia inuncta",
        "eng": "smoky roundhead",
        "hun": "szürke harmatgomba"
    },
    "2079": {
        "latin": "Stropharia melanosperma",
        "hun": "feketéslemezű harmatgomba"
    },
    "2080": {
        "latin": "Stropharia pseudocyanea",
        "eng": "peppery roundhead",
        "latin_syn": [
            "Stropharia albocyanea"
        ],
        "hun": "halványkék harmatgomba"
    },
    "2081": {
        "latin": "Stropharia rugosoannulata",
        "eng": "wine roundhead",
        "hun": "óriás harmatgomba"
    },
    "2082": {
        "latin": "Suillellus luridus",
        "latin_syn": [
            "Boletus luridus"
        ],
        "hun": "változékony tinóru"
    },
    "2083": {
        "latin": "Suillellus mendax",
        "hun": "csalóka tinóru"
    },
    "2084": {
        "latin": "Suillellus queletii",
        "latin_syn": [
            "Boletus queletii"
        ],
        "hun": "vörös tinóru"
    },
    "2085": {
        "latin": "Suillus bellini",
        "hun": "barnafoltos fenyőtinóru"
    },
    "2086": {
        "latin": "Suillus bovinus",
        "eng": "bovine bolete",
        "hun": "tehéntinóru"
    },
    "2087": {
        "latin": "Suillus bresadolae",
        "eng": "var. flavogriseus  bearded bolete",
        "hun": "alpesi gyűrűstinóru"
    },
    "2088": {
        "latin": "Suillus cavipes",
        "eng": "hollow bolete",
        "latin_syn": [
            "Boletinus cavipes"
        ],
        "hun": "csövestönkű fenyőtinóru",
        "hun_syn": [
            "csövestönkű tinóru"
        ]
    },
    "2089": {
        "latin": "Suillus collinitus",
        "latin_syn": [
            "Suillus fluryi"
        ],
        "hun": "rózsástövű fenyőtinóru"
    },
    "2090": {
        "latin": "Suillus flavidus",
        "eng": "jellied bolete",
        "hun": "lápi gyűrűstinóru",
        "hun_syn": [
            "lápi fenyőtinóru"
        ]
    },
    "2091": {
        "latin": "Suillus granulatus",
        "eng": "weeping bolete",
        "hun": "szemcsésnyelű fenyőtinóru",
        "hun_syn": [
            "fenyőaljagomba",
            "fenyővargánya"
        ]
    },
    "2092": {
        "latin": "Suillus grevillei",
        "eng": "larch bolete",
        "latin_syn": [
            "Suillus elegans"
        ],
        "hun": "sárga gyűrűstinóru",
        "hun_syn": [
            "sárga vajgomba",
            "sárgagyűrűs fenyőtinóru"
        ]
    },
    "2093": {
        "latin": "Suillus lakei",
        "hun": "duglász-fenyőtinóru"
    },
    "2094": {
        "latin": "Suillus luteus",
        "eng": "slippery Jack",
        "hun": "barna gyűrűstinóru",
        "hun_syn": [
            "barna vajgomba",
            "barnagyűrűs fenyőtinóru"
        ]
    },
    "2095": {
        "latin": "Suillus mediterraneensis",
        "hun": "mediterrán fenyőtinóru"
    },
    "2096": {
        "latin": "Suillus placidus",
        "eng": "slippery white bolete",
        "hun": "elefántcsont-fenyőtinóru"
    },
    "2097": {
        "latin": "Suillus plorans",
        "hun": "cirbolya-fenyőtinóru",
        "hun_syn": [
            "cirbolya fenyőtinóru"
        ]
    },
    "2098": {
        "latin": "Suillus tridentinus",
        "eng": "orange larch bolete",
        "hun": "rozsdavörös gyűrűstinóru",
        "hun_syn": [
            "rozsdavörös fenyőtinóru"
        ]
    },
    "2099": {
        "latin": "Suillus variegatus",
        "eng": "velvet bolete",
        "hun": "tarka tinóru",
        "hun_syn": [
            "tarka fenyőtinóru"
        ]
    },
    "2100": {
        "latin": "Suillus viscidus",
        "eng": "sticky bolete",
        "latin_syn": [
            "Suillus aeruginascens"
        ],
        "hun": "szürke gyűrűstinóru",
        "hun_syn": [
            "ragadós gyűrűstinóru,  szürke tinóru",
            "szürkegyűrűs fenyőtinóru"
        ]
    },
    "2101": {
        "latin": "Tapesia fusca",
        "hun": "kékesszürke csészegombácska"
    },
    "2102": {
        "latin": "Tapinella atrotomentosa",
        "eng": "velvet rollrim",
        "latin_syn": [
            "Paxillus atrotomentosus"
        ],
        "hun": "bársonyostönkű cölöpgomba"
    },
    "2103": {
        "latin": "Tapinella panuoides",
        "eng": "oyster rollrim",
        "latin_syn": [
            "Paxillus panuoides"
        ],
        "hun": "nyeletlen cölöpgomba",
        "hun_syn": [
            "házi cölöpgomba"
        ]
    },
    "2104": {
        "latin": "Tarzetta catinus",
        "eng": "greater toothed cup",
        "latin-syn": [
            "Geopyxis catinus",
            "Pustularia catinus"
        ],
        "hun": "sárga kehelygomba",
        "hun_syn": [
            "sárga kehely-csészegomba"
        ]
    },
    "2105": {
        "latin": "Tarzetta cupularis",
        "eng": "toothed cup",
        "latin_syn": [
            "Geopyxis cupularis"
        ],
        "hun": "fogacskás kehelygomba"
    },
    "2106": {
        "latin": "Tectella patellaris",
        "hun": "ragadós dücskőgomba"
    },
    "2107": {
        "latin": "Tephrocybe ambusta",
        "latin_syn": [
            "Lyophyllum ambustum"
        ],
        "hun": "szenes szürkefülőke",
        "hun_syn": [
            "púpos álpereszke"
        ]
    },
    "2108": {
        "latin": "Tephrocybe anthracophila",
        "latin_syn": [
            "Lyophyllum anthracophilum",
            "Tephrocybe carbonaria"
        ],
        "hun": "tűznyom-szürkefülőke"
    },
    "2109": {
        "latin": "Tephrocybe confusa",
        "latin_syn": [
            "Lyophyllum confusum"
        ],
        "hun": "dohos szürkefülőke"
    },
    "2110": {
        "latin": "Tephrocybe rancida",
        "eng": "rancid greyling",
        "latin_syn": [
            "Lyophyllum rancidum"
        ],
        "hun": "gyökeres szürkefülőke",
        "hun_syn": [
            "gyökerező szürkefülőke"
        ]
    },
    "2111": {
        "latin": "Terfezia aphroditis",
        "hun": "ciprusi homoktrifla",
        "hun_syn": [
            "ciprusi szarvasgomba"
        ]
    },
    "2112": {
        "latin": "Terfezia arenaria",
        "eng": "moroccan desert truffle",
        "latin_syn": [
            "Terfezia leonis"
        ],
        "hun": "berber homoktrifla",
        "hun_syn": [
            "arab szarvasgomba"
        ]
    },
    "2113": {
        "latin": "Terfezia berberiodora",
        "hun": "borbolya-homoktrifla",
        "hun_syn": [
            "borbolya-szarvasgomba"
        ]
    },
    "2114": {
        "latin": "Terfezia castanea",
        "hun": "gesztenyeszínű homoktrifla",
        "hun_syn": [
            "gesztenyeszínű szarvasgomba"
        ]
    },
    "2115": {
        "latin": "Terfezia fanfani",
        "hun": "sima homoktrifla",
        "hun_syn": [
            "sima szarvasgomba"
        ]
    },
    "2116": {
        "latin": "Terfezia leptoderma",
        "hun": "pikkelyes homoktrifla",
        "hun_syn": [
            "pusztai szarvasgomba"
        ]
    },
    "2117": {
        "latin": "Terfezia lutescens",
        "hun": "sárguló homoktrifla",
        "hun_syn": [
            "sárguló szarvasgomba"
        ]
    },
    "2118": {
        "latin": "Terfezia magnusii",
        "latin_syn": [
            "Choiromyces magnusii"
        ],
        "hun": "szardíniai homoktrifla",
        "hun_syn": [
            "szardíniai szarvasgomba"
        ]
    },
    "2119": {
        "latin": "Terfezia olbiensis",
        "hun": "talpas homoktrifla",
        "hun_syn": [
            "talpas szarvasgomba"
        ]
    },
    "2120": {
        "latin": "Terfezia pallida",
        "hun": "spanyol homoktrifla",
        "hun_syn": [
            "spanyol szarvasgomba"
        ]
    },
    "2121": {
        "latin": "Terfezia sinuosa",
        "hun": "változatos homoktrifla",
        "hun_syn": [
            "változatos szarvasgomba"
        ]
    },
    "2122": {
        "latin": "Thelephora anthocephala",
        "hun": "virágos szemölcsösgomba"
    },
    "2123": {
        "latin": "Thelephora caryophyllea",
        "eng": "carnation earthfan",
        "hun": "tölcséres szemölcsösgomba"
    },
    "2124": {
        "latin": "Thelephora mollissima",
        "hun": "csoportos szemölcsösgomba"
    },
    "2125": {
        "latin": "Thelephora palmata",
        "eng": "stinking earthfan",
        "hun": "büdös szemölcsösgomba",
        "hun_syn": [
            "büdös bőrkorallgomba"
        ]
    },
    "2126": {
        "latin": "Thelephora penicillata",
        "eng": "urchin earthfan",
        "latin_syn": [
            "Thelephora spiculosa"
        ],
        "hun": "pamacsos szemölcsösgomba",
        "hun_syn": [
            "ecset szemölcsösgomba",
            "pamatos szemölcsösgomba"
        ]
    },
    "2127": {
        "latin": "Thelephora terrestris",
        "eng": "earthfan",
        "hun": "talajlakó szemölcsösgomba",
        "hun_syn": [
            "áttelelő szemölcsösgomba"
        ]
    },
    "2128": {
        "latin": "Tirmania nivea",
        "latin_syn": [
            "Tirmania africana"
        ],
        "hun": "óriás gumótrifla",
        "hun_syn": [
            "afrikai szarvasgomba"
        ]
    },
    "2129": {
        "latin": "Tolypocladium capitatum",
        "eng": "capitate truffleclub",
        "latin_syn": [
            "Cordyceps capitata",
            "Elaphocordyceps capitata"
        ],
        "hun": "triflarontó gomba",
        "hun_syn": [
            "fejes rontógomba"
        ]
    },
    "2130": {
        "latin": "Tolypocladium ophioglossoides",
        "eng": "snaketongue truffleclub",
        "latin_syn": [
            "Cordyceps ophioglossoides",
            "Elaphocordyceps ophioglossoides"
        ],
        "hun": "triflaáruló gomba",
        "hun_syn": [
            "nyelves rontógomba"
        ]
    },
    "2131": {
        "latin": "Tomentella fusca",
        "latin_syn": [
            "Tomentella ferruginea"
        ],
        "hun": "rozsdás nemezgomba"
    },
    "2132": {
        "latin": "Tomentella punicea",
        "latin_syn": [
            "Tomentella elaeodes"
        ],
        "hun": "vörös nemezgomba"
    },
    "2133": {
        "latin": "Tomentella subfusca",
        "hun": "lilásbarna nemezgomba"
    },
    "2134": {
        "latin": "Trametes gibbosa",
        "eng": "lumpy bracket",
        "hun": "púpos egyrétűtapló"
    },
    "2135": {
        "latin": "Trametes hirsuta",
        "eng": "hairy bracket",
        "hun": "borostás egyrétűtapló"
    },
    "2136": {
        "latin": "Trametes ochracea",
        "eng": "ochre bracket",
        "latin_syn": [
            "Trametes multicolor",
            "Trametes zonata",
            "Trametes zonatella"
        ],
        "hun": "öves egyrétűtapló"
    },
    "2137": {
        "latin": "Trametes pubescens",
        "latin_syn": [
            "Coriolus pubescens"
        ],
        "hun": "bársonyos egyrétűtapló"
    },
    "2138": {
        "latin": "Trametes suaveolens",
        "eng": "fragrant bracket",
        "hun": "ánizstapló"
    },
    "2139": {
        "latin": "Trametes versicolor",
        "eng": "turkeytail",
        "hun": "lepketapló"
    },
    "2140": {
        "latin": "Trametes warnieri",
        "latin_syn": [
            "Lenzites warnieri"
        ],
        "hun": "feketés lemezestapló",
        "hun_syn": [
            "fekete lemezestapló"
        ]
    },
    "2141": {
        "latin": "Trametopsis cervina",
        "latin_syn": [
            "Trametes cervina"
        ],
        "hun": "őztapló",
        "hun_syn": [
            "őzbarna lepketapló"
        ]
    },
    "2142": {
        "latin": "Trechispora farinacea",
        "latin_syn": [
            "Grandinai farinacea"
        ],
        "hun": "lisztes kéreggereben"
    },
    "2143": {
        "latin": "Trechispora fastidiosa",
        "latin_syn": [
            "Thelephora fastidiosa"
        ],
        "hun": "büdös kéreggereben",
        "hun_syn": [
            "büdös szemölcsösgomba"
        ]
    },
    "2144": {
        "latin": "Tremella encephala",
        "eng": "conifer brain",
        "latin_syn": [
            "Naematelia encephala"
        ],
        "hun": "fenyő-rezgőgomba"
    },
    "2145": {
        "latin": "Tremella foliacea",
        "eng": "leafy brain",
        "hun": "fodros rezgőgomba"
    },
    "2146": {
        "latin": "Tremella indecorata",
        "hun": "színváltó rezgőgomba"
    },
    "2147": {
        "latin": "Tremella mesenterica",
        "eng": "yellow brain",
        "latin_syn": [
            "Tremella lutescens"
        ],
        "hun": "aranyos rezgőgomba"
    },
    "2148": {
        "latin": "Tremella moriformis",
        "eng": "mulberry brain",
        "hun": "szederalakú rezgőgomba"
    },
    "2149": {
        "latin": "Tremiscus helvelloides",
        "latin_syn": [
            "Guepinia helvelloides",
            "Gyrocephalus rufus"
        ],
        "hun": "narancsvörös kocsonyásgomba",
        "hun_syn": [
            "vörös enyvestölcsérgomba"
        ]
    },
    "2150": {
        "latin": "Trichaptum abietinum",
        "eng": "purplepore bracket",
        "latin_syn": [
            "Hirschioporus abietinus"
        ],
        "hun": "fenyő-egyrétűtapló",
        "hun_syn": [
            "ibolyás egyrétűtapló"
        ]
    },
    "2151": {
        "latin": "Trichaptum biforme",
        "latin_syn": [
            "Hirschioporus paragmenus"
        ],
        "hun": "lilaszegélyű egyrétűtapló",
        "hun_syn": [
            "bükk-egyrétűtapló",
            "lila egyrétűtapló"
        ]
    },
    "2152": {
        "latin": "Trichaptum fuscoviolceum",
        "latin_syn": [
            "Hirschioporus fuscoviolaceus",
            "Trichaptum hollii"
        ],
        "hun": "fogas egyrétűtapló"
    },
    "2153": {
        "latin": "Trichoglossum hirsutum",
        "eng": "hairy earthtongue",
        "latin_syn": [
            "Geoglossum hirsutum"
        ],
        "hun": "borostás nyelvgomba",
        "hun_syn": [
            "sötét borostás-nyelvgomba"
        ]
    },
    "2154": {
        "latin": "Tricholoma acerbum",
        "eng": "bitter knight",
        "hun": "keserű pereszke"
    },
    "2155": {
        "latin": "Tricholoma aestuans",
        "eng": "acrid knight",
        "hun": "epeízű pereszke",
        "hun_syn": [
            "csípős pereszke"
        ]
    },
    "2156": {
        "latin": "Tricholoma albobrunneum",
        "hun": "kesernyés pereszke"
    },
    "2157": {
        "latin": "Tricholoma album",
        "eng": "white knight",
        "hun": "fehér pereszke"
    },
    "2158": {
        "latin": "Tricholoma apium",
        "eng": "scented knight",
        "latin_syn": [
            "Tricholoma luteovirens"
        ],
        "hun": "zellerszagú pereszke"
    },
    "2159": {
        "latin": "Tricholoma argyraceum",
        "hun": "kúposkalapú pereszke",
        "hun_syn": [
            "sárguló pereszke"
        ]
    },
    "2160": {
        "latin": "Tricholoma aurantium",
        "eng": "orange knight",
        "hun": "narancsvörös pereszke"
    },
    "2161": {
        "latin": "Tricholoma basirubens",
        "hun": "bíbortövű pereszke",
        "hun_syn": [
            "bíbortönkű pereszke"
        ]
    },
    "2162": {
        "latin": "Tricholoma batschii",
        "latin_syn": [
            "Tricholoma fracticum",
            "Tricholoma subannulatum"
        ],
        "hun": "álgyűrűs pereszke"
    },
    "2163": {
        "latin": "Tricholoma bresadolanum",
        "hun": "pikkelyestönkű pereszke",
        "hun_syn": [
            "feketeszegélyű pereszke"
        ]
    },
    "2164": {
        "latin": "Tricholoma bufonium",
        "hun": "békapereszke",
        "hun_syn": [
            "kénes pereszke"
        ]
    },
    "2165": {
        "latin": "Tricholoma caligatum",
        "eng": "true booted knight",
        "hun": "krokodilpereszke",
        "hun_syn": [
            "foltostönkű gyűrűspereszke"
        ]
    },
    "2166": {
        "latin": "Tricholoma cingulatum",
        "eng": "girdled knight",
        "hun": "öves pereszke",
        "hun_syn": [
            "fűzfa pereszke"
        ]
    },
    "2167": {
        "latin": "Tricholoma colossus",
        "eng": "giant knight ",
        "hun": "óriás pereszke "
    },
    "2168": {
        "latin": "Tricholoma columbetta",
        "eng": "blue spot knight",
        "hun": "galambpereszke"
    },
    "2169": {
        "latin": "Tricholoma equestre",
        "eng": "yellow knight",
        "latin_syn": [
            "Tricholoma auratum",
            "Tricholoma flavovirens"
        ],
        "hun": "sárgászöld pereszke"
    },
    "2170": {
        "latin": "Tricholoma filamentosus",
        "hun": "nemezes pereszke"
    },
    "2171": {
        "latin": "Tricholoma focale",
        "eng": "booted knight",
        "hun": "galléros pereszke",
        "hun_syn": [
            "nyakörves pereszke"
        ]
    },
    "2172": {
        "latin": "Tricholoma fucatum",
        "hun": "olajbarna pereszke",
        "hun_syn": [
            "többszínű pereszke"
        ]
    },
    "2173": {
        "latin": "Tricholoma fulvum",
        "eng": "birch knight",
        "latin_syn": [
            "Tricholoma flavobrunneum",
            "Tricholoma pseudonictitans"
        ],
        "hun": "sárgalemezű pereszke"
    },
    "2174": {
        "latin": "Tricholoma imbricatum",
        "eng": "matt knight",
        "hun": "aprópikkelyű pereszke"
    },
    "2175": {
        "latin": "Tricholoma inamoenum",
        "eng": "gassy knight",
        "hun": "nehézszagú pereszke"
    },
    "2176": {
        "latin": "Tricholoma inocybeoides",
        "hun": "susulykaképű pereszke"
    },
    "2177": {
        "latin": "Tricholoma inodermeum",
        "hun": "rőt pereszke"
    },
    "2178": {
        "latin": "Tricholoma joachimii",
        "hun": "olajzöldes pereszke"
    },
    "2179": {
        "latin": "Tricholoma josserandii",
        "hun": "homoki pereszke"
    },
    "2180": {
        "latin": "Tricholoma lascivum",
        "eng": "aromatic knight",
        "latin_syn": [
            "Tricholoma pseudoalbum"
        ],
        "hun": "émelyítő pereszke",
        "hun_syn": [
            "bőrsárga pereszke"
        ]
    },
    "2181": {
        "latin": "Tricholoma luridum",
        "hun": "szürkelemezű pereszke"
    },
    "2182": {
        "latin": "Tricholoma orirubens",
        "hun": "rózsáslemezű pereszke"
    },
    "2183": {
        "latin": "Tricholoma pardinum",
        "eng": "leopard knight",
        "hun": "párducpereszke",
        "hun_syn": [
            "izzadó pereszke"
        ]
    },
    "2184": {
        "latin": "Tricholoma pessundatum",
        "eng": "tacked knight",
        "latin_syn": [
            "Tricholoma tridentinum"
        ],
        "hun": "foltos pereszke"
    },
    "2185": {
        "latin": "Tricholoma populinum",
        "eng": "poplar knight",
        "hun": "nyárfa-pereszke"
    },
    "2186": {
        "latin": "Tricholoma portentosum",
        "eng": "coalman",
        "hun": "szürke pereszke"
    },
    "2187": {
        "latin": "Tricholoma psammopus",
        "eng": "larch knight",
        "hun": "vörösfenyő-pereszke"
    },
    "2188": {
        "latin": "Tricholoma saponaceum",
        "eng": "soapy knight",
        "hun": "szappanszagú pereszke"
    },
    "2189": {
        "latin": "Tricholoma scalpturatum",
        "eng": "yellowing knight",
        "hun": "sárguló pereszke"
    },
    "2190": {
        "latin": "Tricholoma sciodes",
        "eng": "beech knight",
        "hun": "bükki pereszke"
    },
    "2191": {
        "latin": "Tricholoma sejunctum",
        "eng": "deceiving knight",
        "hun": "cirmoskalapú pereszke",
        "hun_syn": [
            "zöldessárga pereszke"
        ]
    },
    "2192": {
        "latin": "Tricholoma squarrulosum",
        "hun": "feketepikkelyes pereszke"
    },
    "2193": {
        "latin": "Tricholoma stiparophyllum",
        "eng": "chemical knight",
        "hun": "szagos pereszke",
        "hun_syn": [
            "fehér pereszke"
        ]
    },
    "2194": {
        "latin": "Tricholoma sulphurescens",
        "eng": "yellow staining knight",
        "hun": "okkerfoltos pereszke"
    },
    "2195": {
        "latin": "Tricholoma sulphureum",
        "eng": "sulphur knight",
        "hun": "büdös pereszke"
    },
    "2196": {
        "latin": "Tricholoma terreum",
        "eng": "grey knight",
        "latin_syn": [
            "Tricholoma gausapatum",
            "Tricholoma myomyces"
        ],
        "hun": "fenyő-pereszke",
        "hun_syn": [
            "rákízű pereszke"
        ]
    },
    "2197": {
        "latin": "Tricholoma ustale",
        "eng": "burnt knight",
        "hun": "szenesedő pereszke"
    },
    "2198": {
        "latin": "Tricholoma ustaloides",
        "eng": "charred knight",
        "hun": "gesztenyebarna pereszke"
    },
    "2199": {
        "latin": "Tricholoma vaccinum",
        "eng": "scaly knight",
        "hun": "szakállas pereszke"
    },
    "2200": {
        "latin": "Tricholoma virgatum",
        "eng": "ashen knight",
        "hun": "csípős pereszke"
    },
    "2201": {
        "latin": "Tricholomella constricta",
        "eng": "mealy domecap",
        "latin_syn": [
            "Calocybe constricta",
            "Calocybe leucocephala"
        ],
        "hun": "gyűrűs pereszke"
    },
    "2202": {
        "latin": "Tricholomopsis decora",
        "eng": "prunes and custard",
        "hun": "olajsárga fapereszke",
        "hun_syn": [
            "olajsárgás fapereszke"
        ]
    },
    "2203": {
        "latin": "Tricholomopsis flammula",
        "hun": "bíborszínű fapereszke"
    },
    "2204": {
        "latin": "Tricholomopsis rutilans",
        "eng": "plums and custard",
        "hun": "bársonyos fapereszke"
    },
    "2205": {
        "latin": "Tricholosporum goniospermum",
        "latin_syn": [
            "Tricholosporum subgoniospermum"
        ],
        "hun": "keresztspórás pereszke"
    },
    "2206": {
        "latin": "Trichophaea gregaria",
        "hun": "csoportos szőröscsészegomba"
    },
    "2207": {
        "latin": "Trichophaea woolhopeia",
        "hun": "Woolhop-szőröscsészegomba"
    },
    "2208": {
        "latin": "Tubaria albostipitata",
        "hun": "fehértönkű szemétgomba",
        "hun_syn": [
            "fehértönkű tölcsérszemétgomba"
        ]
    },
    "2209": {
        "latin": "Tubaria confragosa",
        "eng": "ringed twiglet",
        "hun": "galléros szemétgomba",
        "hun_syn": [
            "nagy tölcsérszemétgomba"
        ]
    },
    "2210": {
        "latin": "Tubaria conspersa",
        "eng": "felted twiglet",
        "hun": "pelyhes szemétgomba",
        "hun_syn": [
            "pelyhes tölcsérszemétgomba"
        ]
    },
    "2211": {
        "latin": "Tubaria dispersa",
        "eng": "hawthorn twiglet",
        "latin_syn": [
            "Tubaria autochthona"
        ],
        "hun": "sárgalemezű szemétgomba",
        "hun_syn": [
            "sárgalemezű  tölcsérszemétgomba"
        ]
    },
    "2212": {
        "latin": "Tubaria furfuracea",
        "eng": "scurfy twiglet",
        "hun": "gyakori szemétgomba",
        "hun_syn": [
            "gyakori tölcsérszemétgomba"
        ]
    },
    "2213": {
        "latin": "Tubaria hiemalis",
        "hun": "téli szemétgomba"
    },
    "2214": {
        "latin": "Tubaria minutalis",
        "hun": "parányi szemétgomba",
        "hun_syn": [
            "parányi tölcsérszemétgomba"
        ]
    },
    "2215": {
        "latin": "Tuber aestivum",
        "eng": "summer truffle",
        "hun": "nyári szarvasgomba",
        "hun_syn": [
            "burgundi szarvasgomba",
            "európai fekete szarvasgomba",
            "földi kenyér",
            "nyári gímgomba",
            "nyári trifola"
        ]
    },
    "2216": {
        "latin": "Tuber affine",
        "hun": "gyérerű szarvasgomba",
        "hun_syn": [
            "gyéreres szarvasgomba"
        ]
    },
    "2217": {
        "latin": "Tuber bernardinii",
        "hun": "Bernardini-szarvasgomba"
    },
    "2218": {
        "latin": "Tuber borchii",
        "hun": "késői szarvasgomba"
    },
    "2219": {
        "latin": "Tuber brumale",
        "latin_syn": [
            "Tuber hiemalbum"
        ],
        "hun": "téli szarvasgomba",
        "hun_syn": [
            "tavaszi szarvasgomba"
        ]
    },
    "2220": {
        "latin": "Tuber debaryanum",
        "hun": "lágy szarvasgomba"
    },
    "2221": {
        "latin": "Tuber dryophilum",
        "hun": "bozótszarvasgomba",
        "hun_syn": [
            "savanyú szarvasgomba"
        ]
    },
    "2222": {
        "latin": "Tuber excavatum",
        "hun": "üreges szarvasgomba"
    },
    "2223": {
        "latin": "Tuber exiguum",
        "hun": "német szarvasgomba"
    },
    "2224": {
        "latin": "Tuber ferrugineum",
        "hun": "rozsdaszínű szarvasgomba",
        "hun_syn": [
            "rozsdás szarvasgomba"
        ]
    },
    "2225": {
        "latin": "Tuber foetidum",
        "hun": "büdös szarvasgomba",
        "hun_syn": [
            "rossz-szagú szarvasgomba",
            "szagos szarvasgomba"
        ]
    },
    "2226": {
        "latin": "Tuber fulgens",
        "hun": "narancsvörös szarvasgomba",
        "hun_syn": [
            "üreges vörösszarvasgomba",
            "vörös szarvasgomba"
        ]
    },
    "2227": {
        "latin": "Tuber gennadii",
        "latin_syn": [
            "Loculotuber gennadii",
            "Terfezia gennadii"
        ],
        "hun": "peloponnészoszi szarvasgomba"
    },
    "2228": {
        "latin": "Tuber lucidum",
        "hun": "csillogó szarvasgomba",
        "hun_syn": [
            "lakkos szarvasgomba"
        ]
    },
    "2229": {
        "latin": "Tuber macrosporum",
        "hun": "nagyspórás szarvasgomba",
        "hun_syn": [
            "aprópikkelyes szarvasgomba"
        ]
    },
    "2230": {
        "latin": "Tuber maculatum",
        "hun": "foltos szarvasgomba",
        "hun_syn": [
            "keserű szarvasgomba"
        ]
    },
    "2231": {
        "latin": "Tuber magentipunctatum",
        "hun": "bíborfoltos szarvasgomba"
    },
    "2232": {
        "latin": "Tuber magnatum",
        "eng": "white truffle",
        "hun": "isztriai szarvasgomba",
        "hun_syn": [
            "hagymaszagú főúri",
            "gímgomba",
            "mágnás szarvasgomba",
            "piemonti szarvagomba",
            "piroshúsú szarvasgomba",
            "piros husu trifola"
        ]
    },
    "2233": {
        "latin": "Tuber malacodermum",
        "hun": "puha szarvasgomba"
    },
    "2234": {
        "latin": "Tuber melanosporum",
        "eng": "black truffle",
        "hun": "francia szarvasgomba",
        "hun_syn": [
            "éti szarvasgomba",
            "fekete gímgomba",
            "fekete szarvasgomba",
            "périgordi szarvasgomba"
        ]
    },
    "2235": {
        "latin": "Tuber mesentericum",
        "hun": "fodrosbélű szarvasgomba",
        "hun_syn": [
            "kátrányszagú szarvasgomba",
            "petróleumszagú szarvasgomba",
            "zsigeres szarvasgomba"
        ]
    },
    "2236": {
        "latin": "Tuber microsporum",
        "hun": "apróspórás szarvasgomba"
    },
    "2237": {
        "latin": "Tuber monosporum",
        "latin_syn": [
            "Paradoxa monospora"
        ],
        "hun": "egyspórás szarvasgomba"
    },
    "2238": {
        "latin": "Tuber montanum",
        "hun": "hegyi szarvasgomba"
    },
    "2239": {
        "latin": "Tuber moravicum",
        "hun": "morva szarvasgomba"
    },
    "2240": {
        "latin": "Tuber nitidum",
        "hun": "fényes szarvasgomba",
        "hun_syn": [
            "fényes rőt-szarvasgomba"
        ]
    },
    "2241": {
        "latin": "Tuber nuciforme",
        "hun": "mogyoró-szarvasgomba"
    },
    "2242": {
        "latin": "Tuber oligospermum",
        "latin_syn": [
            "Lespinaultinia oligosperma",
            "Tuber asa"
        ],
        "hun": "karfiolalakú szarvasgomba",
        "hun_syn": [
            "olajspórás szarvasgomba",
            "undorítószagú szarvasgomba"
        ]
    },
    "2243": {
        "latin": "Tuber panniferum",
        "hun": "taplós szarvasgomba",
        "hun_syn": [
            "rongyos gímgomba"
        ]
    },
    "2244": {
        "latin": "Tuber petrophilum",
        "hun": "szirti szarvasgomba"
    },
    "2245": {
        "latin": "Tuber piperatum",
        "hun": "csípős szarvasgomba"
    },
    "2246": {
        "latin": "Tuber puberulum",
        "hun": "bolyhos szarvasgomba",
        "hun_syn": [
            "apró szarvasgomba"
        ]
    },
    "2247": {
        "latin": "Tuber rapaeodorum",
        "hun": "mustárszagú szarvasgomba"
    },
    "2248": {
        "latin": "Tuber regianum",
        "hun": "Reggio-szarvasgomba",
        "hun_syn": [
            "hegyi szarvasgomba"
        ]
    },
    "2249": {
        "latin": "Tuber requienii",
        "hun": "Requien-szarvasgomba",
        "hun_syn": [
            "Requien szarvasgombája"
        ]
    },
    "2250": {
        "latin": "Tuber rufum",
        "hun": "rőt szarvasgomba"
    },
    "2251": {
        "latin": "Tuber scruposum",
        "hun": "kénsárga szarvasgomba"
    },
    "2252": {
        "latin": "Tulasnella cystidiophora",
        "hun": "sötétedő kéregrontógomba"
    },
    "2253": {
        "latin": "Tulasnella pruinosa",
        "hun": "deres kéregrontógomba"
    },
    "2254": {
        "latin": "Tulasnella violacea",
        "hun": "lila kéregrontógomba"
    },
    "2255": {
        "latin": "Tulasnella violea",
        "hun": "ibolyás kéregrontógomba",
        "hun-syn": [
            "lila kéregrontógomba"
        ]
    },
    "2256": {
        "latin": "Tulostoma brumale",
        "eng": "winter stalkball",
        "latin_syn": [
            "Tulostoma mammosum"
        ],
        "hun": "öves nyelespöfeteg"
    },
    "2257": {
        "latin": "Tulostoma cyclophorum",
        "hun": "bársonyos nyelespöfeteg"
    },
    "2258": {
        "latin": "Tulostoma fimbriatum",
        "eng": "fringed stalkball",
        "latin_syn": [
            "Tulostoma granulosum"
        ],
        "hun": "pillás nyelespöfeteg"
    },
    "2259": {
        "latin": "Tulostoma giovanellae",
        "hun": "zömök nyelespöfeteg"
    },
    "2260": {
        "latin": "Tulostoma kotlabae",
        "latin_syn": [
            "Tulostoma nanum"
        ],
        "hun": "világostönkű nyelespöfeteg"
    },
    "2261": {
        "latin": "Tulostoma leiosporum",
        "hun": "apró nyelespöfeteg"
    },
    "2262": {
        "latin": "Tulostoma macrocephalum",
        "hun": "nagyfejű nyelespöfeteg"
    },
    "2263": {
        "latin": "Tulostoma melanocyclum",
        "eng": "scaly stalkball",
        "hun": "sötétsávos nyelespöfeteg"
    },
    "2264": {
        "latin": "Tulostoma obesum",
        "latin_syn": [
            "Tulostoma volvulatum"
        ],
        "hun": "bocskoros nyelespöfeteg"
    },
    "2265": {
        "latin": "Tulostoma pulchellum",
        "hun": "sima nyelespöfeteg"
    },
    "2266": {
        "latin": "Tulostoma squamosum",
        "hun": "szálkás nyelespöfeteg"
    },
    "2267": {
        "latin": "Tylopilus felleus",
        "eng": "bitter bolete",
        "hun": "epeízű tinóru"
    },
    "2268": {
        "latin": "Typhrasa gossypina",
        "latin_syn": [
            "Psathyrella gossypina"
        ],
        "hun": "fehérszálas porhanyósgomba"
    },
    "2269": {
        "latin": "Typhula abietina",
        "hun": "fenyves fonalgomba"
    },
    "2270": {
        "latin": "Typhula erythropus",
        "eng": "redleg club",
        "hun": "rőt fonalgomba"
    },
    "2271": {
        "latin": "Typhula filiformis",
        "latin_syn": [
            "Macrotyphula filiformis"
        ],
        "hun": "kákagomba",
        "hun-syn": [
            "káka nagy-fonálgomba"
        ]
    },
    "2272": {
        "latin": "Typhula fistulosa",
        "latin_syn": [
            "Macrotyphula fistulosa"
        ],
        "hun": "gémüstökgomba",
        "hun-syn": [
            "csöves bunkógomba",
            "gémüstök fonálgomba"
        ]
    },
    "2273": {
        "latin": "Typhula gyrans",
        "hun": "molyhos fonalgomba"
    },
    "2274": {
        "latin": "Typhula micans",
        "latin_syn": [
            "Pistillaria micans"
        ],
        "hun": "rózsaszínű fonalgomba",
        "hun-syn": [
            "bíborszínű törőgomba",
            "narancsszínű törőgomba",
            "rózsaszínű törőgomba"
        ]
    },
    "2275": {
        "latin": "Typhula phacorrhiza",
        "eng": "tuberous club",
        "hun": "nyurga fonalgomba"
    },
    "2276": {
        "latin": "Typhula quisquiliaris",
        "eng": "bracken club",
        "hun": "páfrány-fonalgomba"
    },
    "2277": {
        "latin": "Typhula sclerotioides",
        "hun": "csomós fonalgomba"
    },
    "2278": {
        "latin": "Typhula setipes",
        "hun": "fehér fonalgomba",
        "hun_syn": [
            "fehér törőgomba"
        ]
    },
    "2279": {
        "latin": "Tyromyces chioneus",
        "hun": "foszlós likacsosgomba"
    },
    "2280": {
        "latin": "Tyromyces kmetii",
        "hun": "narancsszínű likacsosgomba"
    },
    "2281": {
        "latin": "Urnula craterium",
        "eng": "devil's urn",
        "hun": "fekete kehelygomba",
        "hun_syn": [
            "nagy fekete-kehelygomba"
        ]
    },
    "2282": {
        "latin": "Vararia investiens",
        "latin_syn": [
            "Asterostromella investiens"
        ],
        "hun": "csillagosgomba"
    },
    "2283": {
        "latin": "Veluticeps abietina",
        "latin_syn": [
            "Stereum crispum"
        ],
        "hun": "zsindelyes réteggomba"
    },
    "2284": {
        "latin": "Verpa bohemica",
        "latin_syn": [
            "Ptychoverpa bohemica"
        ],
        "hun": "cseh kucsmagomba"
    },
    "2285": {
        "latin": "Verpa conica",
        "eng": "thimble morel",
        "latin_syn": [
            "Verpa digitaliformis"
        ],
        "hun": "simasüvegű kucsmagomba"
    },
    "2286": {
        "latin": "Vibrissea leptospora",
        "hun": "sárga félgömbgomba"
    },
    "2287": {
        "latin": "Vibrissea truncorum",
        "eng": "stream beacon",
        "hun": "lengőke félgömbgomba",
        "hun_syn": [
            "lengőke"
        ]
    },
    "2288": {
        "latin": "Volvariella bombycina",
        "eng": "silky rosegill",
        "hun": "óriás bocskorosgomba"
    },
    "2289": {
        "latin": "Volvariella caesiotincta",
        "hun": "acélszürke bocskorosgomba"
    },
    "2290": {
        "latin": "Volvariella hypopithys",
        "hun": "pelyhestönkű bocskorosgomba",
        "hun-syn": [
            "fenyőtűbocskorosgomba"
        ]
    },
    "2291": {
        "latin": "Volvariella media",
        "hun": "középnagy bocskorosgomba"
    },
    "2292": {
        "latin": "Volvariella murinella",
        "hun": "egérszürke bocskorosgomba"
    },
    "2293": {
        "latin": "Volvariella pusilla",
        "latin_syn": [
            "Volvariella parvula"
        ],
        "hun": "apró bocskorosgomba",
        "hun-syn": [
            "barnabocskorú bocskorosgomba"
        ]
    },
    "2294": {
        "latin": "Volvariella surrecta",
        "eng": "piggyback rosegill",
        "latin_syn": [
            "Volvariella loveiana"
        ],
        "hun": "élősködő bocskorosgomba",
        "hun-syn": [
            "élősdi bocskorosgomba"
        ]
    },
    "2295": {
        "latin": "Volvariella taylorii",
        "hun": "barnabocskorú bocskorosgomba"
    },
    "2296": {
        "latin": "Volvariella volvacea",
        "hun": "csíkos bocskorosgomba",
        "hun-syn": [
            "termesztett bocskorosgomba"
        ]
    },
    "2297": {
        "latin": "Volvopluteus gloiocephala",
        "latin_syn": [
            "Volvariella gloiocephala",
            "Volvariella speciosa"
        ],
        "hun": "ragadós bocskorosgomba",
        "hun-syn": [
            "közönséges bocskorosgomba"
        ]
    },
    "2298": {
        "latin": "Vuilleminia comedens",
        "eng": "waxy crust",
        "hun": "tölgy-kéreghántógomba"
    },
    "2299": {
        "latin": "Vuilleminia megalospora",
        "hun": "alma-kéreghántógomba"
    },
    "2300": {
        "latin": "Wakefieldia macrospora",
        "hun": "nagyspórás agyagpöfeteg",
        "hun-syn": [
            "angol álpöfeteg"
        ]
    },
    "2301": {
        "latin": "Wynnella atrofusca",
        "latin_syn": [
            "Helvella silvicola",
            "Otidea auricula"
        ],
        "hun": "porcos fülesgomba"
    },
    "2302": {
        "latin": "Xerocomellus chrysenteron",
        "latin_syn": [
            "Xerocomus chrysenteron"
        ],
        "hun": "arany tinóru",
        "hun-syn": [
            "arany nemezestinóru"
        ]
    },
    "2303": {
        "latin": "Xerocomellus cisalpinus",
        "latin_syn": [
            "Xerocomus cisalpinus"
        ],
        "hun": "keskenyspórás tinóru",
        "hun-syn": [
            "keskenyspórás aranytinóru"
        ]
    },
    "2304": {
        "latin": "Xerocomellus porosporus",
        "latin_syn": [
            "Xerocomus porosporus",
            "Xerocomus marekii"
        ],
        "hun": "csapottspórás tinóru",
        "hun-syn": [
            "repedéses nemezestinóru"
        ]
    },
    "2305": {
        "latin": "Xerocomellus pruinatus",
        "latin_syn": [
            "Boletellus pruinatus",
            "Xerocomus pruinatus"
        ],
        "hun": "hamvas tinóru",
        "hun-syn": [
            "pompás nemezestinóru"
        ]
    },
    "2306": {
        "latin": "Xerocomellus ripariellus",
        "latin_syn": [
            "Xerocomus ripariellus"
        ],
        "hun": "húsrózsás tinóru"
    },
    "2307": {
        "latin": "Xerocomus chrysonemus",
        "eng": "goldenthread bolete",
        "hun": "sárgatövű tinóru"
    },
    "2308": {
        "latin": "Xerocomus ferrugineus",
        "latin_syn": [
            "Xerocomus spadiceus"
        ],
        "hun": "rozsdabarna tinóru",
        "hun-syn": [
            "recéstönkű nemezestinóru",
            "rozsdabarna nemezestinóru"
        ]
    },
    "2309": {
        "latin": "Xerocomus subtomentosus",
        "hun": "molyhos tinóru",
        "hun_syn": [
            "molyhos nemezestinóru"
        ]
    },
    "2310": {
        "latin": "Xeromphalina campanella",
        "eng": "pinewood gingertail",
        "hun": "sárga köldökös-szegfűgomba",
        "hun-syn": [
            "sárga szegfűgomba"
        ]
    },
    "2311": {
        "latin": "Xeromphalina cauticinalis",
        "eng": "pinelitter gingertail",
        "latin_syn": [
            "Xeromphalina fellea"
        ],
        "hun": "keserű köldökös-szegfűgomba",
        "hun-syn": [
            "szagos köldökös-szegfűgomba"
        ]
    },
    "2312": {
        "latin": "Xeromphalina cornui",
        "hun": "barnás köldökös-szegfűgomba",
        "hun-syn": [
            "szarutönkű béka-tölcsérgomba"
        ]
    },
    "2313": {
        "latin": "Xerula melanotricha",
        "latin_syn": [
            "Oudemansiella melanotricha"
        ],
        "hun": "borostás gyökeresfülőke"
    },
    "2314": {
        "latin": "Xerula pudens",
        "latin_syn": [
            "Collybia badia",
            "Oudemansi-ella longipes",
            "Xerula longipes"
        ],
        "hun": "bársonyos gyökeresfülőke",
        "hun-syn": [
            "hosszúszárú fülőke"
        ]
    },
    "2315": {
        "latin": "Xylaria carpophila",
        "eng": "beechmast candlesnuff",
        "hun": "kupacsagancsgomba"
    },
    "2316": {
        "latin": "Xylaria filiformis",
        "hun": "vékony agancsgomba"
    },
    "2317": {
        "latin": "Xylaria hypoxylon",
        "eng": "candlesnuff fungus",
        "hun": "szarvas agancsgomba",
        "hun-syn": [
            "szarvasagancsgomba"
        ]
    },
    "2318": {
        "latin": "Xylaria longipes",
        "eng": "dead moll's fingers",
        "hun": "nyeles agancsgomba"
    },
    "2319": {
        "latin": "Xylaria polymorpha",
        "eng": "dead man's fingers",
        "hun": "bunkós agancsgomba"
    },
    "2320": {
        "latin": "Xylobolus frustulatus",
        "latin_syn": [
            "Stereum frustulatum"
        ],
        "hun": "összenőtt mozaikgomba",
        "hun-syn": [
            "összenőtt réteggomba"
        ]
    },
    "2321": {
        "latin": "Xylobolus subpileatus",
        "latin_syn": [
            "Stereum subpileatum"
        ],
        "hun": "gyapjas mozaikgomba"
    },
    "2322": {
        "latin": "Zelleromyces soehneri",
        "latin_syn": [
            "Hydnangium soehneri"
        ],
        "hun": "Soehner-tejelőpöfeteg",
        "hun-syn": [
            "rózsás álpöfeteg"
        ]
    }
}